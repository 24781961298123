import { Form, Button, Select, Input, Table, Checkbox, Row, Col } from 'antd'
import {
  GET_SP_AGREEMENT_DATA,
  GET_SP_AUTO_ADVISE,
  NON_INDIVIDUAL_AGREEMENT_MAIL_SEND,
} from './graphql/Queries'
import React, { useContext, useEffect, useState } from 'react'
import { get } from 'lodash'

import './verification.css'
import { GET_PLANS_BASED_POINTS } from 'modules/Questionnaires/graphql/Query'
import { COMPLETE_STEP, INVESTMENT_PLANNING_MAIL } from './graphql/Mutations'
import { UPDATE_USER } from 'modules/Users/graphql/Mutations'
import openNotification from 'utils/Notification'
import { useQuery } from '@apollo/react-hooks'
import CustomMeta from 'components/CustomMeta'
import Page404 from 'components/Page404'
import { AppContext } from 'AppContext'
import client from 'apollo'
import { GET_MAX_EXPOSURE, GET_NIFTY } from 'modules/Reports/graphql/Queries'
import { GENERATE_NON_INDIVIDUAL_REPORT } from 'modules/Settings/graphql/Queries'
import {
  convertNumberToCommaSeparate,
  formatToLakhsOrCrores,
  numberToWord,
} from 'utils/utilities'
import RightArrowIconBlack from 'assets/images/new-images/right-arrow-black.svg'
import RightArrowIconWhite from 'assets/images/new-images/right-arrow-white.svg'
import KeyInfo from './KeyInfo'

const FormItem = Form.Item
const { Option } = Select

const InvestmentPlanningNew = ({ next, newEdit }) => {
  const {
    state: { isMobile },
  } = useContext(AppContext)
  const [form] = Form.useForm()
  const { dispatch, state } = useContext(AppContext)
  const {
    id,
    annualIncome,
    clientScore,
    initialExposure,
    initialInvestment,
    entityStatus,
    isPanCardIndividual,
    isNewAuaAdvisoryGenerate,
  } = state.currentUser
  const { niftyLotSize } = state.generalSetting
  let { clientSummaryDetails } = state
  const [reviewPlanData, setReviewPlanData] = useState(undefined)
  const [agree, setAgree] = useState(true)
  const [loader, setLoader] = useState(false)
  const [planLoader, setPlanLoader] = useState(false)
  const [exposureValue, setExposureValue] = useState(initialExposure)
  const [errorMessage, setErrorMessage] = useState()

  const { loading, error, data } = useQuery(GET_PLANS_BASED_POINTS, {
    variables: { points: clientScore },
    fetchPolicy: 'network-only',
  })
  const { data: niftyData, loading: niftyLoading } = useQuery(GET_NIFTY, {
    fetchPolicy: 'network-only',
  })

  let { data: maxExposure, loading: exposureLoading } = useQuery(
    GET_MAX_EXPOSURE,
    {
      fetchPolicy: 'network-only',
    }
  )

  useEffect(() => {
    const tempAnnualIncome = annualIncome || 0
    let minimumExposure =
      50000 * Math.round((niftyData?.getNifty50[0]?.CMP * niftyLotSize) / 50000)

    if (exposureValue && tempAnnualIncome) {
      if (maxExposure?.getMaxExposure?.maxExposure < exposureValue) {
        setErrorMessage(
          `Exposure value can't be more than ${convertNumberToCommaSeparate(
            +maxExposure?.getMaxExposure?.maxExposure
          )}`
        )
      } else if (minimumExposure > exposureValue) {
        setErrorMessage(
          `Exposure value can't be less than ${convertNumberToCommaSeparate(
            +minimumExposure
          )}`
        )
      } else {
        setErrorMessage('')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exposureValue])

  if (error) return <Page404 error={error} />
  let allPlans =
    !loading &&
    !niftyLoading &&
    !exposureLoading &&
    get(data, 'fetchPlansBasedOnPoints')
      ? get(data, 'fetchPlansBasedOnPoints')
      : []

  if (
    !exposureLoading &&
    !niftyLoading &&
    !loading &&
    state?.currentUser?.isNewAuaAdvisoryGenerate
  ) {
    maxExposure.getMaxExposure.maxExposure =
      parseInt(state?.currentUser?.annualIncome) - state?.currentUser?.exposure
  }
  const onFinish = (values) => {
    values = {
      ...values,
      exposure: exposureValue,
    }
    setLoader(true)
    dispatch({ type: 'IS_RESUBMIT_LOADING', data: true })
    client
      .query({
        query: GET_SP_AUTO_ADVISE,
        variables: {
          where: {
            dontBuyEquity: 'NO',
            exposure: parseInt(values.exposure),
            planId: parseInt(values.plan),
            udInvestment: values.udInvestment,
          },
        },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        entityStatus &&
          client.query({
            query: GENERATE_NON_INDIVIDUAL_REPORT,
            variables: {
              where: {
                dontBuyEquity: 'YES',
              },
            },
            fetchPolicy: 'network-only',
          })
        setReviewPlanData(res?.data?.spAutoAdvise)
        dispatch({ type: 'SHOW_REVIEW_PLAN', data: true })
        dispatch({ type: 'IS_RESUBMIT_LOADING', data: false })
      })

      .finally(() => {
        setLoader(false)
        dispatch({ type: 'IS_RESUBMIT_LOADING', data: false })
      })
  }

  const Columns = [
    {
      title: 'Description',
      dataIndex: 'Description',
      key: 'Description',
      className: 'report-text',
    },
    {
      title: 'Value',
      dataIndex: 'Value',
      key: 'Value',
      className: 'report-numeric review_plan',
      render: (record) =>
        record?.includes('Plan')
          ? record
          : convertNumberToCommaSeparate(+record, false),
    },
  ]

  const newColumns = [
    {
      title: 'Description',
      dataIndex: 'Description',
      key: 'Description',
      className: 'report-text',
    },
    {
      title: 'Value',
      dataIndex: 'Value',
      key: 'Value',
      className: 'report-numeric review_plan',
      render: (record) => convertNumberToCommaSeparate(+record, false),
    },
    {
      title: 'Annualised %',
      dataIndex: 'Per',
      key: 'Per',
      className: 'report-numeric review_plan',
      render: (text) => parseFloat(text).toFixed(2),
    },
  ]

  const planMail = () => {
    setPlanLoader(true)
    dispatch({ type: 'IS_ACCEPT_LOADING', data: true })
    form.validateFields().then((values) => {
      values = {
        ...values,
        exposure: exposureValue,
      }
      client
        .mutate({
          initialInvestment: values.udInvestment,
          mutation: INVESTMENT_PLANNING_MAIL,
          variables: {
            where: {
              planId: parseInt(values.plan),
              dontBuyEquity: 'YES',
              exposure: parseInt(values.exposure),
              udInvestment: parseInt(values.udInvestment),
            },
          },
        })
        .then((res) => {
          client
            .query({
              query: GET_SP_AGREEMENT_DATA,
              variables: {
                where: {
                  dontBuyEquity: 'YES',
                },
              },
            })
            .then(() => {
              !isPanCardIndividual &&
                client.query({ query: NON_INDIVIDUAL_AGREEMENT_MAIL_SEND })
              let updateUserData = {
                planId: parseInt(values.plan),
                initialInvestment: values.udInvestment,
              }
              if (isNewAuaAdvisoryGenerate) {
                updateUserData.newAuaAdvisoryCompletedSteps =
                  'INVESTMENT_PLANNING'
              } else {
                updateUserData.annualIncome =
                  maxExposure.getMaxExposure.maxExposure.toString()
                updateUserData.completedSteps = 'INVESTMENT_PLANNING'
              }
              client
                .mutate({
                  mutation: UPDATE_USER,
                  variables: {
                    id: id,
                    data: { ...updateUserData },
                  },
                })
                .then((res) => {
                  if (!isNewAuaAdvisoryGenerate) {
                    const currentIndex = clientSummaryDetails?.findIndex(
                      (item) => item?.name === 'Investment Planning'
                    )
                    const updateClientSummaryDetails = {
                      name: 'Investment Planning',
                      isDisplayEdit: true,
                      completedSteps: isPanCardIndividual
                        ? 'KYC_FORM'
                        : 'SUMMARY',
                      data: {
                        'Your Selected Exposure':
                          formatToLakhsOrCrores(exposureValue),
                        'Your Advisory Plan': allPlans.find(
                          (item) => item?.id === values?.plan
                        )?.name,
                      },
                    }
                    if (currentIndex !== -1) {
                      clientSummaryDetails[currentIndex] =
                        updateClientSummaryDetails
                    } else {
                      clientSummaryDetails.push(updateClientSummaryDetails)
                    }

                    client.mutate({
                      mutation: COMPLETE_STEP,
                      variables: {
                        data: {
                          step: 'INVESTMENT_PLANNING',
                          from: 'WEB',
                        },
                      },
                    })
                  }
                  setPlanLoader(false)
                  dispatch({ type: 'IS_ACCEPT_LOADING', data: false })
                  if (!isPanCardIndividual) {
                    dispatch({ type: 'SHOW_REVIEW_PLAN', data: false })
                  }
                  dispatch({
                    type: 'CURRENT_USER',
                    data: {
                      ...res.data.updateUser,
                      investmentAmount: values.udInvestment,
                      exposure: parseInt(values.exposure),
                    },
                  })
                  dispatch({
                    type: 'CLIENT_SUMMARY_DETAILS',
                    data: clientSummaryDetails,
                  })
                  openNotification(
                    'success',
                    'Investment Planning Updated Successfully and Mail Sent'
                  )
                  next()
                })
            })
        })
        .catch((err) => {
          setLoader(false)
          dispatch({ type: 'IS_ACCEPT_LOADING', data: false })
          dispatch({ type: 'IS_RESUBMIT_LOADING', data: false })
          console.log(err)
        })
    })
  }

  const handleAgree = (e) => {
    setAgree(e.target.checked)
  }

  if (!allPlans.length) return <h1>Plan not found based on your risk score</h1>

  return (
    <>
      <Row className='row-style'>
        <Col
          lg={!isNewAuaAdvisoryGenerate ? 16 : 24}
          sm={24}
          className={`${
            !isNewAuaAdvisoryGenerate
              ? 'col-left risk-profile-col'
              : 'risk-profile-col'
          }? `}
        >
          <div>
            <CustomMeta title='Investment Planning' />
            <div className='investment_planning_wrapper'>
              {/* <div
                style={{
                  fontSize: '16px',
                  fontWeight: '500',
                  lineHeight: '18.8px',
                  textAlign: 'left',
                  color: '#AF0000',
                  marginBottom: '16px',
                }}
              >
                Note: Kindly fill the data carefully to receive advice
                correctly.
              </div> */}
              <Form
                form={form}
                name='register'
                labelAlign='left'
                onFinish={onFinish}
                scrollToFirstError
                initialValues={{
                  plan: allPlans[0]?.id || undefined,
                  udInvestment: initialInvestment,
                  dontBuyEquity: 'YES',
                }}
                layout='vertical'
                className='client-detail-form'
                style={{ height: '100%' }}
              >
                <Row className='client-profile-row'>
                  <Col className='gutter-row client-column' lg={12} sm={24}>
                    <FormItem label='How much exposure value do you want to take?'>
                      <Input
                        value={convertNumberToCommaSeparate(exposureValue)}
                        placeholder='e.g 10000000'
                        autoFocus={true}
                        style={{
                          width: '100%',
                          textAlign: 'right',
                          backgroundColor: 'transparent',
                          borderRadius: '8px',
                          marginBottom: '8px',
                        }}
                        onChange={(e) => {
                          setExposureValue(+e.target.value?.replaceAll(',', ''))
                        }}
                        autoComplete='off'
                        suffix={'₹'}
                      />
                      <span className='wording-text'>
                        {numberToWord(Math.round(exposureValue))}
                      </span>
                      <br />
                      <span style={{ color: 'red' }}>{errorMessage}</span>
                    </FormItem>
                  </Col>
                  <Col className='gutter-row client-column' lg={12} sm={24}>
                    <FormItem
                      name='plan'
                      label='Select your Plan?'
                      rules={[
                        {
                          required: true,
                          message: 'Please input your Plan !',
                        },
                      ]}
                    >
                      <Select
                        placeholder='Plan'
                        getPopupContainer={(trigger) => trigger.parentElement}
                        className='display-field'
                        defaultValue={{ label: 'Select Dept', value: 0 }}
                      >
                        {allPlans.map((plan, key) => (
                          <Option key={plan.id} value={plan.id}>
                            {plan.name}
                          </Option>
                        ))}
                      </Select>
                    </FormItem>
                  </Col>

                  <div style={{ display: ' none' }}>
                    <Button
                      id='review-plan'
                      disabled={errorMessage?.length || !exposureValue}
                      style={{ margin: '0 8px' }}
                      type='primary'
                      htmlType='submit'
                      loading={loader}
                    >
                      Review Plan
                    </Button>
                  </div>
                </Row>
              </Form>

              {reviewPlanData?.map((data) => {
                if (data.Description === 'Stocks/MF.')
                  data.Description = 'Collateral'
                if (data.Description === 'SIP (Per Month)')
                  data.Value = parseInt(data?.Value).toFixed(0)
                return <></>
              })}

              {reviewPlanData ? (
                <div>
                  <Table
                    className='review-plan-data-table investment-planning'
                    dataSource={reviewPlanData
                      .filter(
                        (data) =>
                          data.Description !== '-------: Features :-------'
                      )
                      .slice(0, 6)}
                    columns={Columns}
                    pagination={false}
                    style={{
                      padding: isMobile ? '8px' : '16px',
                    }}
                  />
                  <br />
                  <Table
                    className='review-plan-data-table'
                    dataSource={reviewPlanData.filter(
                      (data) => data.Per && data.Description !== 'Net Payment'
                    )}
                    columns={newColumns}
                    pagination={false}
                    style={{
                      padding: isMobile ? '8px' : '16px',
                    }}
                  />
                  <div>
                    <div className='agree-wrapper aua-verification'>
                      <Checkbox onChange={handleAgree} checked={agree} />
                      <p className='agree-content'>
                        I/We hereby declare that the details furnished above are
                        true and correct to the best of my/our knowledge and
                        belief and I/We undertake to inform you of any changes
                        therein, immediately. In case any of the above
                        information is found to be false or untrue or misleading
                        or misrepresenting, I am/We are aware that I/We may be
                        held liable for it.
                      </p>
                    </div>

                    <Button
                      id='btn-click'
                      style={{
                        display: 'none',
                      }}
                      type='primary'
                      disabled={!agree}
                      loading={planLoader}
                      onClick={() => planMail()}
                    >
                      Yes and Next
                    </Button>
                  </div>
                </div>
              ) : (
                ''
              )}
              <div
                className='verify_next_button'
                style={{
                  marginRight: '18px',
                  marginTop: '20px',
                  marginBottom: isMobile ? '10dvh' : '2px',
                }}
              >
                <Button
                  loading={loader}
                  onClick={() => {
                    let ele = document.getElementById('review-plan')
                    ele.click()
                  }}
                  className={`${
                    !reviewPlanData?.length
                      ? 'yes-next-button'
                      : 'show-plan-button'
                  }`}
                  style={
                    !reviewPlanData?.length
                      ? {
                          background: '#AF0000',
                        }
                      : {
                          background: '#fff',
                        }
                  }
                >
                  Review Plans &nbsp;
                  <img
                    src={
                      !reviewPlanData?.length
                        ? RightArrowIconWhite
                        : RightArrowIconBlack
                    }
                    alt='RightArrowIconBlack'
                  />
                </Button>

                {reviewPlanData?.length ? (
                  <Button
                    id='btn-click'
                    className='yes-next-button'
                    type='primary'
                    disabled={!agree}
                    loading={planLoader}
                    onClick={() => planMail()}
                  >
                    Yes and Next
                  </Button>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </Col>
        {!isNewAuaAdvisoryGenerate && (
          <Col lg={8} sm={24} className='col-right risk-profile-col'>
            <KeyInfo newEdit={newEdit} />
          </Col>
        )}
      </Row>
    </>
  )
}

export default InvestmentPlanningNew
