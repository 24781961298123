import React, { useContext, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Button, Form, Select, DatePicker, Input, Popover, Drawer } from 'antd'
import { FilterOutlined, DownloadOutlined } from '@ant-design/icons'

import '../index.css'
import Page404 from 'components/Page404'
// import DownloadReport from '../DownloadReport'
import { GET_ROLL_OVER_OPPORTUNITY } from '../graphql/Queries'
import RolloverOpportunityTable from './RolloverOpportunityTable'
import FilterBackButton from 'assets/images/new-images/filter-back-button.svg'
import PdfIcon from 'assets/images/new-images/pdficon.svg'
import ExcelIcon from 'assets/images/new-images/excelIcon.svg'
import { exportGraphAsPdf } from 'utils/Graph'
import { CSVLink } from 'react-csv'
import { AppContext } from 'AppContext'

const { Option } = Select

export default function RolloverOpportunity() {
  const [form] = Form.useForm()
  const [filters, setFilters] = useState({})
  const [open, setOpen] = useState(false)

  const {
    state: { isMobile },
  } = useContext(AppContext)

  const {
    data = {},
    loading,
    error,
  } = useQuery(GET_ROLL_OVER_OPPORTUNITY, {
    variables: { where: filters },
    fetchPolicy: 'network-only',
  })

  const { rollOverOpportunity = [] } = data
  const columns = [
    {
      title: 'Rollover Opportunity',
      className: 'report-title main-title',
      children: [
        {
          title: 'Clientcode',
          dataIndex: 'clientcode',
          key: 'clientcode',
          className: 'report-text',
          width: 80,
        },
        {
          title: 'Strike',
          dataIndex: 'Strike',
          key: 'Strike',
          className: 'report-numeric',
          width: 100,
        },
        {
          title: 'ExpiryDate',
          dataIndex: 'ExpiryDate',
          key: 'ExpiryDate',
          className: 'report-numeric',
          width: 100,
        },
        {
          title: 'qty',
          dataIndex: 'qty',
          key: 'qty',
          className: 'report-numeric',
          width: 100,
        },
        {
          title: 'Fstage',
          dataIndex: 'fstage',
          key: 'fstage',
          className: 'report-numeric',
          width: 100,
        },
        {
          title: 'Flevel',
          dataIndex: 'flevel',
          key: 'flevel',
          className: 'report-numeric',
          width: 100,
        },
        {
          title: 'Fremqty',
          dataIndex: 'fremqty',
          key: 'fremqty',
          className: 'report-numeric',
          width: 100,
        },
        {
          title: 'Fqty',
          dataIndex: 'fqty',
          key: 'fqty',
          className: 'report-numeric',
          width: 100,
        },
        {
          title: 'Shift_to',
          dataIndex: 'shift_to',
          key: 'shift_to',
          className: 'report-numeric',
          width: 100,
        },
        {
          title: 'Amt',
          dataIndex: 'amt',
          key: 'amt',
          className: 'report-numeric',
          width: 100,
        },
        {
          title: 'Ledger',
          dataIndex: 'ledger',
          key: 'ledger',
          className: 'report-numeric',
          width: 100,
        },
        {
          title: 'Liquid',
          dataIndex: 'liquid',
          key: 'liquid',
          className: 'report-numeric',
          width: 100,
        },
        {
          title: 'EBBETF',
          dataIndex: 'ebbetf',
          key: 'ebbetf',
          className: 'report-numeric',
          width: 100,
        },
        {
          title: 'Amt required',
          dataIndex: 'amt_req',
          key: 'amt_req',
          className: 'report-numeric',
          width: 100,
        },
        {
          title: 'Remaining fund',
          dataIndex: 'rem_fund',
          key: 'rem_fund',
          className: 'report-numeric',
          width: 150,
        },
        {
          title: 'Final Action',
          dataIndex: 'act_f',
          key: 'act_f',
          className: 'report-numeric',
          width: 100,
        },
        {
          title: 'Niftybees',
          dataIndex: 'niftybees',
          key: 'niftybees',
          className: 'report-numeric',
          width: 100,
        },
        {
          title: 'Liquid_s',
          dataIndex: 'liquid_s',
          key: 'liquid_s',
          className: 'report-numeric',
          width: 100,
        },
        {
          title: 'Clientcode_EBB',
          dataIndex: 'clientcode_ebb',
          key: 'clientcode_ebb',
          className: 'report-numeric',
          width: 150,
        },
        {
          title: 'EBBETF0423',
          dataIndex: 'EBBETF0423',
          key: 'EBBETF0423',
          className: 'report-numeric',
          width: 100,
        },
        {
          title: 'EBBETF0425',
          dataIndex: 'EBBETF0425',
          key: 'EBBETF0425',
          className: 'report-numeric',
          width: 100,
        },
        {
          title: 'EBBETF0430',
          dataIndex: 'EBBETF0430',
          key: 'EBBETF0430',
          className: 'report-numeric',
          width: 100,
        },
        {
          title: 'EBBETF0431',
          dataIndex: 'EBBETF0431',
          key: 'EBBETF0431',
          className: 'report-numeric',
          width: 100,
        },
        {
          title: 'EBBETF423',
          dataIndex: 'EBBETF423',
          key: 'EBBETF423',
          className: 'report-numeric',
          width: 100,
        },
      ],
    },
  ]

  const onFinish = (values) => {
    const data = {
      ...values,
      filter_exp: values.filter_exp ? values.filter_exp : '',
      shift_exp: values.shift_exp ? values.shift_exp : '',
    }
    setFilters(data)
    setOpen(false)
  }
  let staticHtml = ``
  if (!loading && rollOverOpportunity) {
    staticHtml = `
  <html>
  <head>
  <style>
        table {
        width: 100%;
        border-spacing: 0px;
    }
        td {
          border: 1px solid;
        border-spacing: 0px;
    }

        table,
        th,
        td
        {
        border : 1px solid #fafafa;
        border-collapse:collapse;
    }
      </style>
      </head>
      <body>
  <div>
  <table class="table table-bordered" style={{ border: '1px solid', borderSpacing: '0px', width: '100%' }}>
    <tbody>
      <tr>
        <td colspan="23">
          <div style="text-align:center;"><strong>Rollover Opportunity</strong></div>
        </td>
      </tr>
      <tr>
        <td>
          <div style="text-align:center;"><b>Client code</b></div>
        </td>
        <td>
          <div style="text-align:center;"><b>Strike</b></div>
        </td>
        <td>
          <div style="text-align:center;"><b>Expiry Date</b></div>
        </td>
        <td>
          <div style="text-align:center;"><b>qty</b></div>
        </td>
        <td>
          <div style="text-align:center;"><b>fstage</b></div>
        </td>
        <td>
          <div style="text-align:center;"><b>Fievel</b></div>
        </td>
        <td>
          <div style="text-align:center;"><b>Fremqty</b></div>
        </td>
        <td>
          <div style="text-align:center;"><b>Fqty</b></div>
        </td>
        <td>
          <div style="text-align:center;"><b>shift to</b></div>
        </td>
        <td>
          <div style="text-align:center;"><b>Amt</b></div>
        </td>
        <td>
          <div style="text-align:center;"><b>Ledger</b></div>
        </td>
        <td>
          <div style="text-align:center;"><b>Liquid</b></div>
        </td>
        <td>
          <div style="text-align:center;"><b>EBBETF</b></div>
        </td>
        <td>
          <div style="text-align:center;"><b>Amt required</b></div>
        </td>
        <td>
          <div style="text-align:center;"><b>Remaining fund</b></div>
        </td>
        <td>
          <div style="text-align:center;"><b>Final Action</b></div>
        </td>
        <td>
          <div style="text-align:center;"><b>Niftybees</b></div>
        </td>
        <td>
          <div style="text-align:center;"><b>Liquid s</b></div>
        </td>
        <td>
          <div style="text-align:center;"><b>Clientcode EBB</b></div>
        </td>
        <td>
          <div style="text-align:center;"><b>EBBETF 0423</b></div>
        </td>
        <td>
          <div style="text-align:center;"><b>EBBETF 0425</b></div>
        </td>
        <td>
          <div style="text-align:center;"><b>EBBETF 0430</b></div>
        </td>
        <td>
          <div style="text-align:center;"><b>EBBETF 0431</b></div>
        </td>
        <td>
          <div style="text-align:center;"><b>EBBETF 423</b></div>
        </td>
      </tr>`
    data.rollOverOpportunity &&
      data.rollOverOpportunity.forEach((report) => {
        const {
          clientcode = '',
          Strike = '',
          ExpiryDate = '',
          qty = '',
          fstage = '',
          flevel = '',
          fremqty = '',
          fqty = '',
          shift_to = '',
          amt = '',
          ledger = '',
          liquid = '',
          ebbetf = '',
          amt_req = '',
          rem_fund = '',
          act_f = '',
          niftybees = '',
          liquid_s = '',
          clientcode_ebb = '',
          EBBETF0423 = '',
          EBBETF0425 = '',
          EBBETF0430 = '',
          EBBETF0431 = '',
          EBBETF423 = '',
        } = report
        staticHtml = `${staticHtml}
                <tr>
                  <td>
                    <div>${clientcode || ''}</div>
                  </td>
                  <td>
                    <div style="text-align:center; float:right;">${
                      Strike || ''
                    }</div>
                  </td>
                  <td>
                    <div style="text-align:center; float:right;">${
                      ExpiryDate || ''
                    }</div>
                  </td>
                  <td>
                    <div style="text-align:center; float:right;">${
                      qty || ''
                    }</div>
                  </td>
                  <td>
                    <div style="text-align:center; float:right;">${
                      fstage || ''
                    }</div>
                  </td>
                  <td>
                    <div style="text-align:center; float:right;">${
                      flevel || ''
                    }</div>
                  </td>
                  <td>
                    <div style="text-align:center; float:right;">${
                      fremqty || ''
                    }</div>
                  </td>
                  <td>
                    <div style="text-align:center; float:right;">${
                      fqty || ''
                    }</div>
                  </td>
                  <td>
                    <div style="text-align:center; float:right;">${
                      shift_to || ''
                    }</div>
                  </td>
                  <td>
                    <div style="text-align:center; float:right;">${
                      amt || ''
                    }</div>
                  </td>
                  <td>
                    <div style="text-align:center; float:right;">${
                      ledger || ''
                    }</div>
                  </td>
                  <td>
                    <div style="text-align:center; float:right;">${
                      liquid || ''
                    }</div>
                  </td>
                  <td>
                    <div style="text-align:center; float:right;">${
                      ebbetf || ''
                    }</div>
                  </td>
                  <td>
                    <div style="text-align:center; float:right;">${
                      amt_req || ''
                    }</div>
                  </td>
                  <td>
                    <div style="text-align:center; float:right;">${
                      rem_fund || ''
                    }</div>
                  </td>
                  <td>
                    <div style="text-align:center; float:right;">${
                      act_f || ''
                    }</div>
                  </td>
                  <td>
                    <div style="text-align:center; float:right;">${
                      niftybees || ''
                    }</div>
                  </td>
                  <td>
                    <div style="text-align:center; float:right;">${
                      liquid_s || ''
                    }</div>
                  </td>
                  <td>
                    <div style="text-align:center; float:right;">${
                      clientcode_ebb || ''
                    }</div>
                  </td>
                  <td>
                    <div style="text-align:center; float:right;">${
                      EBBETF0423 || ''
                    }</div>
                  </td>
                  <td>
                    <div style="text-align:center; float:right;">${
                      EBBETF0425 || ''
                    }</div>
                  </td>
                  <td>
                    <div style="text-align:center; float:right;">${
                      EBBETF0430 || ''
                    }</div>
                  </td>
                  <td>
                    <div style="text-align:center; float:right;">${
                      EBBETF0431 || ''
                    }</div>
                  </td>
                  <td>
                    <div style="text-align:center; float:right;">${
                      EBBETF423 || ''
                    }</div>
                  </td>
                </tr>
              `
      })
    staticHtml = `${staticHtml}
                </tbody>
                </table>
              </div>
              </body>
              </html>
  `
  }

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  const handleDownloadPdf = () => {
    exportGraphAsPdf(
      'ROLLOVER-OPPORTUNITY-report',
      'ROLLOVER-OPPORTUNITY-REPORT-PDF',
      staticHtml,
      true
    ).finally(() =>
      console.log(' ====== ROLLOVER-TRACKING-report downloaded ====== ')
    )
  }
  const content = (
    <div style={{ width: '100px' }}>
      <div
        onClick={handleDownloadPdf}
        style={{
          marginBottom: `${isMobile ? '0px' : '10px'}`,
          fontSize: '14px',
          fontWeight: '600',
          lineHeight: '24px',
          textAlign: 'left',
          color: ' #000',
          cursor: 'pointer',
        }}
      >
        <img src={PdfIcon} style={{ marginRight: '10px' }} alt='pdf' /> PDF
      </div>

      <CSVLink
        style={{ paddingRight: 0 }}
        data={data.rollOverOpportunity}
        className='csv-btn'
        filename={`ROLLOVER-OPPORTUNITY-report`}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          style={{
            marginBottom: `${isMobile ? '0px' : '10px'}`,
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '24px',
            textAlign: 'left',
            color: ' #000',
            cursor: 'pointer',
          }}
        >
          <img src={ExcelIcon} style={{ marginRight: '10px' }} alt='excel' />{' '}
          Excel
        </div>
      </CSVLink>
    </div>
  )

  return (
    <>
      {/* {!error && rollOverOpportunity && (
        <DownloadReport
          staticHtml={staticHtml}
          data={data.rollOverOpportunity}
          fileName='ROLLOVER-OPPORTUNITY-report'
          id='ROLLOVER-OPPORTUNITY-REPORT-PDF'
        />
      )}
      <br />
      <br /> */}

      <Drawer
        title={null}
        headerStyle={{ display: 'none' }}
        closable={false}
        placement='right'
        onClose={onClose}
        visible={open}
        width={472}
      >
        <div className='Filter_Form'>
          <div onClick={onClose}>
            <img src={FilterBackButton} alt='FilterBackButton' />
          </div>
          <div className='filter_panel_heading'>Filter</div>
        </div>

        <Form
          form={form}
          onFinish={onFinish}
          name='advanced_search'
          style={{ marginTop: '16px' }}
          layout='vertical'
          className='client-detail-form'
          initialValues={{
            filter_stk: '0',
            shift_stk: '0',
            filter_opt: '',
            stage: '',
            all: '0',
            ledger: '0',
            filter_exp: '',
            shift_exp: '',
          }}
        >
          {/* <div className='filter_Form_div_Container' > */}
          <div
            style={{
              height: isMobile ? 'calc(100vh - 11rem)' : 'calc(100vh - 12rem)',
              overflowY: 'auto',
              scrollbarWidth: 'none',
            }}
          >
            <Form.Item name='filter_stk' label='Filter_Stk'>
              <Input placeholder='Enter Filter_Stk' />
            </Form.Item>

            <Form.Item name='shift_stk' label='Shift_Stk'>
              <Input placeholder='Enter Shift_Stk' />
            </Form.Item>

            <Form.Item label='Filter_Opt' name='filter_opt'>
              <Select
                getPopupContainer={(trigger) => trigger.parentElement}
                placeholder='filter_opt'
              >
                <Option value='PE'>PE</Option>
                <Option value='CE'>CE</Option>
                <Option value='XX'>XX</Option>
              </Select>
            </Form.Item>

            <Form.Item name='stage' label='Stage'>
              <Input placeholder='Enter Stage' />
            </Form.Item>

            <Form.Item name='all' label='All'>
              <Input placeholder='Enter All' />
            </Form.Item>

            <Form.Item name='ledger' label='Ledger'>
              <Input placeholder='Enter Ledger' />
            </Form.Item>

            <Form.Item name='filter_exp' label='Filter_Exp'>
              <DatePicker
                format={'DD-MMM-YYYY'}
                getPopupContainer={(trigger) => trigger.parentElement}
                inputReadOnly
                autoComplete='off'
                showToday={false}
                style={{ width: "'50px'%" }}
              />
            </Form.Item>

            <Form.Item name='shift_exp' label='Shift_Exp'>
              <DatePicker
                format={'DD-MMM-YYYY'}
                getPopupContainer={(trigger) => trigger.parentElement}
                inputReadOnly
                autoComplete='off'
                showToday={false}
                style={{ width: "'50px'%" }}
              />
            </Form.Item>
          </div>
          <div className='filter_panel_button'>
            <Button
              onClick={() => {
                form.resetFields()
                setFilters({})
              }}
            >
              Clear
            </Button>
            <Button loading={loading} type='primary' htmlType='submit'>
              Search
            </Button>
          </div>
        </Form>
      </Drawer>
      {error ? (
        <Page404 error={error} isShow={false} />
      ) : (
        <div className='report_Container'>
          <div className='container_Header'>
            <div className='report_Heading'>Rollover Opportunity</div>

            <div className='container_Export_Popover'>
              {!error && data.rollOverOpportunity ? (
                <Popover content={content} placement='bottom' trigger='click'>
                  <div className='container_Export_Popover'>
                    <Button>
                      Export{' '}
                      <DownloadOutlined
                        color='#AF0000'
                        style={{ fontSize: '16px' }}
                      />
                    </Button>
                  </div>
                </Popover>
              ) : (
                ''
              )}

              <Button
                style={{
                  color: '#000000',
                  border: '1px solid #919EAB52',
                  backgroundColor: 'white',
                }}
                onClick={showDrawer}
              >
                Filter{' '}
                <FilterOutlined color='#000000' style={{ fontSize: '16px' }} />
              </Button>
            </div>
          </div>
          <div
            className='report-table-wrapper rollover-opportunity-wrapper'
            id='ROLLOVER-OPPORTUNITY-REPORT-PDF'
          >
            <RolloverOpportunityTable
              reportData={rollOverOpportunity || []}
              columns={columns}
              loading={loading}
            />
          </div>
        </div>
      )}
    </>
  )
}
