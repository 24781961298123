import React, { useContext, useEffect, useState } from 'react'
import { Progress, Steps, Modal, Button } from 'antd'
import { DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons'
import client from 'apollo'
// import moment from 'moment'
import { useHistory } from 'react-router-dom'

// import { useQuery } from '@apollo/react-hooks'
// import { GET_ADVISORY_TIME } from 'modules/AdvisoryTime/graphql/Query'
// import { GET_CURRENT_USER } from 'auth/graphql/Queries'

import './verification.css'
import { AppContext } from 'AppContext'
import AgreementSignNew from './AgreementSignNew'
import { UPDATE_USER } from 'modules/Users/graphql/Mutations'
import ClientProfile2New from './ClientProfile2New'
import RiskProfileNew from './RiskProfileNew'
import SummaryNew from './SummaryNew'
import RiskProfileFormNew from './RiskProfileFormNew'
import SelectTypeFormNew from './SelectTypeFormNew'
import ClientProfile1New from './ClientProfile1New'
import KycDocNew from './KycDocNew'
import KycFormNew from './KycFormNew'
import InvestmentPlanningNew from './InvestmentPlanningNew'
import EMandateRegistrationNew from './EMandateRegistrationNew'
import CongratulationsNew from './CongratulationsNew'
import PendingBookedAdvisoryNew from './PendingBookedAdvisoryNew'
import FAQsModelNew from './Model/FAQsModalNew'
import ClientProfileNon1New from './ClientProfileNon1New'
import ClientProfileNon2New from './ClientProfileNon2New'
import { isEmpty } from 'lodash'
import { UPDATE_ADVISORY_STATUS } from 'modules/PendingAdvisory/graphql/Mutation'
import { UPDATE_BOOK_ADVISORY } from './graphql/Mutations'

const { Step } = Steps
const { confirm } = Modal

const Verification = () => {
  const history = useHistory()
  let {
    dispatch,
    state: { currentUser, clientSummaryDetails, showFAQScreen },
  } = useContext(AppContext)
  const { state } = useContext(AppContext)
  const { completedSteps, isPanCardIndividual } = state?.currentUser
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [header, setHeader] = useState(true)

  // const { data: advisoryData, loading: advisoryTimeLoading } = useQuery(
  //   GET_ADVISORY_TIME,
  //   {
  //     fetchPolicy: 'network-only',
  //   }
  // )

  // useEffect(() => {
  //   if (!advisoryTimeLoading) {
  //     setInterval(() => {
  //       if (
  //         moment
  //           .utc(advisoryData?.getAdvisoryTime?.startTime, 'hh:mm A')
  //           .add(2, 'minutes')
  //           .format('LTS') > moment().format('LTS') &&
  //         currentUser?.completedSteps === 'E_MANDATE' &&
  //         currentUser?.isBookingAdvisoryDateSelected
  //       ) {
  //         client
  //           .query({ query: GET_CURRENT_USER, fetchPolicy: 'network-only' })
  //           .then((res) => {
  //             if (res?.data?.currentUser?.completedSteps === 'CONGRATULATION') {
  //               window.location.reload()
  //             }
  //           })
  //           .catch((err) => console.log(err))
  //       }
  //       return () => clearInterval()
  //     }, [10000])
  //   }
  // }, [advisoryTimeLoading])

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    window.location.hash = 'no-back-button'
    window.location.hash = 'Again-No-back-button'
    window.onhashchange = function () {
      window.location.hash = 'no-back-button'
    }
  }, [])

  const stepsIndexList = {
    LOGIN_CREATED: 1,
    SELECT_USER_TYPE: 2,
    CLIENT_PROFILE_1: 3,
    CLIENT_PROFILE_2: 4,
    RISK_PROFILE: 5,
    SUMMARY: 6,
    KYC_DOC: 7,
    KYC_FORM: 8,
    INVESTMENT_PLANNING: 9,
    AGREEMENT_SIGN: 10,
    E_MANDATE: 11,
    CONGRATULATION: 12,
    DONE: 13,
  }
  let tempCurrent = 0
  switch (completedSteps) {
    case 'LOGIN_CREATED':
      tempCurrent = 1
      break
    case 'SELECT_USER_TYPE':
      tempCurrent = 2
      break
    case 'CLIENT_PROFILE_1':
      tempCurrent = 3
      break
    case 'CLIENT_PROFILE_2':
      tempCurrent = 4
      break
    case 'RISK_PROFILE':
      tempCurrent = 5
      break
    case 'SUMMARY':
      tempCurrent = 6
      break
    case 'KYC_DOC':
      tempCurrent = 7
      break
    case 'KYC_FORM':
      tempCurrent = 8
      break
    case 'INVESTMENT_PLANNING':
      tempCurrent = 9
      break
    case 'AGREEMENT_SIGN':
      tempCurrent = 10
      break
    case 'E_MANDATE':
      tempCurrent = 11
      break
    case 'CONGRATULATION':
      tempCurrent = 12
      break
    case 'DONE':
      tempCurrent = 13
      break
    default:
      break
  }
  const [current, setCurrent] = useState(tempCurrent)
  const next = (nextStep = 1, isSkip = false) => {
    if (
      current === 5 &&
      currentUser.reEvaluate === true &&
      currentUser.cvlStatus === true
    ) {
      setCurrent(8)
    } else if (current === 5 && currentUser.cvlStatus === true) {
      setCurrent(8)
    } else if (
      (current === 7 && currentUser.reEvaluate) ||
      (current === 7 && currentUser?.isReUploadKyc)
    ) {
      let updatedData = { completedSteps: 'DONE' }
      if (currentUser.reEvaluate) {
        updatedData.reEvaluate = false
      } else if (currentUser?.isReUploadKyc) {
        updatedData.isReUploadKyc = false
        updatedData.completedSteps = currentUser?.currentCompletedSteps
      }
      client
        .mutate({
          mutation: UPDATE_USER,
          variables: {
            data: updatedData,
            id: parseInt(currentUser.id),
          },
        })
        .then((res) => {
          dispatch({ type: 'CURRENT_USER', data: res.data.updateUser })
          if (
            currentUser.reEvaluate ||
            res.data.updateUser?.completedSteps === 'DONE'
          ) {
            dispatch({ type: 'IS_VERIFICATION_DONE', data: true })
            history.push('/')
          } else if (currentUser?.isReUploadKyc) {
            setCurrent(stepsIndexList[res?.data?.updateUser?.completedSteps])
          }
        })
        .catch((err) => console.log(err))
    } else if (currentUser?.completedSteps === 'INVESTMENT_PLANNING') {
      if (currentUser?.mandateAmount <= currentUser?.compareMandateAmount) {
        dispatch({ type: 'IS_RESUBMIT_LOADING', data: true })
        client
          .mutate({
            mutation: UPDATE_USER,
            variables: {
              data: { completedSteps: 'E_MANDATE' },
              id: parseInt(currentUser?.id),
            },
          })
          .then((res) => {
            const currentIndex = clientSummaryDetails.findIndex(
              (item) => item?.name === 'E-Mandate'
            )

            const updateClientSummaryUi = {
              name: 'E-Mandate',
              completedSteps: 'AGREEMENT_SIGN',
              isDisplayEdit: true,
              data: {
                'Bank Name': currentUser?.bank,
                'Account Number': currentUser?.accountNumber,
                'ISFC Code': currentUser?.ifscCode,
                'Account Type': currentUser?.accountType,
                'EMandate Amount': currentUser?.compareMandateAmount,
              },
            }
            if (currentIndex !== -1) {
              clientSummaryDetails[currentIndex] = updateClientSummaryUi
            } else {
              clientSummaryDetails.push(updateClientSummaryUi)
            }
            dispatch({
              type: 'CLIENT_SUMMARY_DETAILS',
              data: clientSummaryDetails,
            })
            dispatch({ type: 'CURRENT_USER', data: res.data.updateUser })
            dispatch({ type: 'IS_RESUBMIT_LOADING', data: false })
            setCurrent(stepsIndexList[res?.data?.updateUser?.completedSteps])
          })
      } else {
        setCurrent(current + nextStep)
      }
    }
    // else if (isSkip) {
    //   dispatch({ type: 'IS_RESUBMIT_LOADING', data: true })
    //   client
    //     .mutate({
    //       mutation: UPDATE_USER,
    //       variables: {
    //         data: { completedSteps: 'E_MANDATE' },
    //         id: parseInt(currentUser.id),
    //       },
    //     })
    //     .then((res) => {
    //       const currentIndex = clientSummaryDetails.findIndex(
    //         (item) => item?.name === 'E-Mandate'
    //       )
    //       const updateClientSummaryUi = {
    //         name: 'E-Mandate',
    //         completedSteps: 'AGREEMENT_SIGN',
    //         isDisplayEdit: true,
    //         data: {
    //           'Bank Name': currentUser?.bank,
    //           'Account Number': currentUser?.accountNumber,
    //           'ISFC Code': currentUser?.ifscCode,
    //           'Account Type': currentUser?.accountType,
    //           'EMandate Amount': currentUser?.mandateAmount,
    //         },
    //       }
    //       if (currentIndex !== -1) {
    //         clientSummaryDetails[currentIndex] = updateClientSummaryUi
    //       } else {
    //         clientSummaryDetails.push(updateClientSummaryUi)
    //       }
    //       dispatch({
    //         type: 'CLIENT_SUMMARY_DETAILS',
    //         data: clientSummaryDetails,
    //       })
    //       dispatch({ type: 'CURRENT_USER', data: res.data.updateUser })
    //       dispatch({ type: 'IS_RESUBMIT_LOADING', data: false })
    //       setCurrent(stepsIndexList[res?.data?.updateUser?.completedSteps])
    //     })
    //     .catch((err) => {
    //       dispatch({ type: 'IS_RESUBMIT_LOADING', data: false })
    //       console.log(err)
    //     })
    // }
    else {
      setCurrent(current + nextStep)
    }
  }

  const back = (backStep = 1) => {
    if (currentUser?.completedSteps === 'RISK_PROFILE') {
      setCurrent(current - backStep)
    } else if (currentUser?.completedSteps === 'CONGRATULATION') {
      setCurrent(11)
    }
  }

  const updateUserForEdit = (data) => {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: UPDATE_USER,
          variables: {
            id: currentUser?.id,
            data: {
              completedSteps: data?.completedSteps,
              isBookingAdvisoryDateSelected: false,
            },
          },
        })
        .then((res) => {
          dispatch({ type: 'CURRENT_USER', data: res?.data?.updateUser })
          setCurrent(stepsIndexList[data?.completedSteps])
          resolve(true)
        })
        .catch((err) => {
          console.log(err)
          reject(err)
        })
    })
  }

  const rejectAdvisory = (data, advisoryData) => {
    let modal
    modal = confirm({
      okText: 'Confirm',
      cancelText: 'Close',
      centered: true,
      okButtonProps: { style: { display: 'none' } },
      cancelButtonProps: { style: { display: 'none' } },
      content: (
        <div>
          <div className='modal_text'>
            First of all, you need to reject the advisory; after that, you can
            edit it.
          </div>
          <div className='book_advisory_btn'>
            <Button
              className='advisory_cancel_btn'
              onClick={() => modal.destroy()}
              disabled={false}
            >
              Cancel
            </Button>
            <Button
              className='advisory_verify_btn'
              type='primary'
              onClick={() => onOk()}
            >
              Confirm
            </Button>
          </div>
        </div>
      ),
    })

    const updateAdvisoryStatus = async () => {
      if (
        currentUser?.completedSteps === 'CONGRATULATION' &&
        currentUser?.isBookingAdvisoryDateSelected
      ) {
        return new Promise((resolve, reject) => {
          const statusData = advisoryData?.advisory.map((d) => {
            return {
              advisorId: parseInt(d.AdvisorID),
              clientCode: d.ClientCode,
              status: 'Reject',
            }
          })
          client
            .mutate({
              mutation: UPDATE_ADVISORY_STATUS,
              variables: { data: statusData },
            })
            .then((res) => {
              if (res?.data?.updateAdvisoryStatus) {
                resolve(res?.data?.updateAdvisoryStatus)
              }
            })
            .catch((err) => {
              console.log(err)
              reject(err)
            })
        })
      } else {
        return true
      }
    }
    const onOk = async () => {
      const updateStatus = await updateAdvisoryStatus()

      if (updateStatus) {
        client
          .mutate({
            mutation: UPDATE_BOOK_ADVISORY,
            variables: {
              data: {
                isPendingAdvisoryAccepted: true,
                isCancelAdvisory: true,
                userId: currentUser?.id,
                advisoryCancelBy: currentUser?.id,
              },
              where: { id: parseInt(advisoryData?.id) },
            },
          })
          .then((response) => {
            const update = updateUserForEdit(data)
            if (update) {
              modal.destroy()
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }
  }

  const newEdit = (data, advisoryData = {}) => {
    if (
      (currentUser?.completedSteps === 'E_MANDATE' ||
        currentUser?.completedSteps === 'CONGRATULATION') &&
      currentUser?.isBookingAdvisoryDateSelected &&
      !isEmpty(advisoryData)
    ) {
      rejectAdvisory(data, advisoryData)
    } else {
      updateUserForEdit(data)
    }
  }

  const steps = [
    {
      title: 'Disclaimer',
      content: <RiskProfileFormNew next={next} />,
    },
    {
      title: 'Select Type',
      content: <SelectTypeFormNew next={next} />,
    },
    {
      title: 'Client Basic Details',
      content: (
        <>
          {isPanCardIndividual ? (
            <ClientProfile1New
              next={next}
              newEdit={newEdit}
              reEvaluate={currentUser.reEvaluate}
            />
          ) : (
            <ClientProfileNon1New
              next={next}
              reEvaluate={currentUser.reEvaluate}
            />
          )}
        </>
      ),
    },
    {
      title: 'Client Profile',
      content: (
        <>
          {isPanCardIndividual ? (
            // <ClientProfile2 next={next} reEvaluate={currentUser.reEvaluate} />
            <ClientProfile2New
              next={next}
              newEdit={newEdit}
              reEvaluate={currentUser.reEvaluate}
              currentUser={currentUser}
            />
          ) : (
            <ClientProfileNon2New
              next={next}
              reEvaluate={currentUser.reEvaluate}
            />
          )}
        </>
      ),
    },
    {
      title: 'Risk Profile Assessment',
      content: (
        <RiskProfileNew
          next={next}
          reEvaluate={currentUser.reEvaluate}
          newEdit={newEdit}
        />
      ),
    },
    {
      title: 'Summary',
      content: (
        <SummaryNew
          back={back}
          next={next}
          newEdit={newEdit}
          reEvaluate={currentUser.reEvaluate}
        />
        // <Summary back={back} next={next} reEvaluate={currentUser.reEvaluate} />
      ),
    },
    {
      title: 'Kyc Document',
      content: (
        <KycDocNew
          next={next}
          reEvaluate={currentUser.reEvaluate}
          newEdit={newEdit}
        />
      ),
    },
    {
      title: 'Kyc E-Sign',
      content: (
        <KycFormNew
          next={next}
          reEvaluate={currentUser.reEvaluate}
          newEdit={newEdit}
        />
      ),
    },
    {
      title: 'Investment Planning',
      content: (
        <InvestmentPlanningNew
          next={next}
          newEdit={newEdit}
          reEvaluate={currentUser.reEvaluate}
        />
      ),
    },
    {
      title: 'Agreement Sign',
      content: (
        <AgreementSignNew
          next={next}
          reEvaluate={currentUser.reEvaluate}
          newEdit={newEdit}
        />
      ),
    },
    {
      title: 'E-Mandate Registration',
      content: (
        <EMandateRegistrationNew
          next={next}
          newEdit={newEdit}
          reEvaluate={currentUser.reEvaluate}
        />
      ),
    },
    {
      title: 'Congratulations',
      content: (
        <CongratulationsNew
          next={next}
          reEvaluate={currentUser.reEvaluate}
          newEdit={newEdit}
        />
      ),
    },
    {
      title: 'Pending Advisory',
      content: (
        <PendingBookedAdvisoryNew
          next={next}
          back={back}
          newEdit={newEdit}
          reEvaluate={currentUser.reEvaluate}
        />
      ),
    },
  ]

  return (
    <>
      <div className='.gx-app-login-content.content-wrapper' style={{height: '100%'}}>
        {showFAQScreen ? (
          <FAQsModelNew />
        ) : (
          <>
            {header && windowWidth <= 480 ? (
              <div className='steps_collapse' style={{ display: 'none' }}>
                <Steps current={true} size='small'>
                  <Step
                    key={steps[current].title}
                    title={steps[current].title}
                  />
                </Steps>
                <div className='steps_icon' onClick={() => setHeader(!header)}>
                  <DownCircleOutlined className='steps_icon_span' />
                </div>
              </div>
            ) : (
              <div style={{ display: 'none' }}>
                <div className='steps_icon' onClick={() => setHeader(!header)}>
                  <UpCircleOutlined
                    className='steps_icon_span'
                    style={windowWidth <= 480 ? {} : { display: 'none' }}
                  />
                </div>
                <Steps size='small' current={current}>
                  {steps.map((item) => (
                    <Step key={item.title} title={item.title} />
                  ))}
                </Steps>
              </div>
            )}
            <div className='progress' style={{ display: 'none' }}>
              <Progress
                percent={Math.round((100 / 14) * (tempCurrent + 1))}
                format={(p) => `${p}% Progress`}
              />
            </div>
            <div className='steps-content' style={{height: '100%'}}>{steps[current]?.content}</div>
          </>
        )}
      </div>
    </>
  )
}

export default Verification
