import React, { useContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { get } from 'lodash'

import NoData from 'components/NoData'
import { AppContext } from 'AppContext'
import Page404 from 'components/Page404'
import Loader from 'components/loaders/Loader'
import AverageIndexTable from './AverageIndexTable'
import { GET_AVERAGE_INDEX_REPORT } from '../graphql/Queries'
import { DownloadOutlined } from '@ant-design/icons'
import '../index.css'
import PdfIcon from 'assets/images/new-images/pdficon.svg'
import ExcelIcon from 'assets/images/new-images/excelIcon.svg'
import { Button, Popover } from 'antd'
import { CSVLink } from 'react-csv'
import { exportGraphAsPdf } from 'utils/Graph'

export default function () {
  const {
    state: {
      currentUser: { clientCode, firstName, lastName },
      isMobile,
    },
  } = useContext(AppContext)
  const { data, loading, error } = useQuery(GET_AVERAGE_INDEX_REPORT, {
    variables: { where: { clientCode, date: null, strategy: 'ILTS' } },
  })

  if (loading) return <Loader />
  if (error) return <Page404 error={error} />
  if (!get(data, 'spAverageIndexreport')) return <NoData />

  const {
    spAverageIndexreport: [spAverageIndexreport],
  } = data

  const handleDownloadPdf = () => {
    exportGraphAsPdf('average-report', 'AVG-REPORT-PDF', null, true).finally(
      () => console.log('=== average-report download ====')
    )
  }

  const content = (
    <div style={{ width: '100px' }}>
      <div
        onClick={handleDownloadPdf}
        style={{
          marginBottom: `${isMobile ? '0px' : '10px'}`,
          fontSize: '14px',
          fontWeight: '600',
          lineHeight: '24px',
          textAlign: 'left',
          color: ' #000',
          cursor: 'pointer',
        }}
      >
        <img src={PdfIcon} style={{ marginRight: '10px' }} alt='pdf' /> Pdf
      </div>

      <CSVLink
        style={{ paddingRight: 0 }}
        data={JSON.parse(JSON.stringify(spAverageIndexreport))?.map((item) => {
          return {
            EntryDate: item.entrydate,
            Type: item.Type,
            ClosingIndexValue: item.ClosingIndexValue,
            Quantity: item.Qty,
            Value: item.Value,
          }
        })}
        className='csv-btn'
        filename={`average-report`}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          style={{
            marginBottom: `${isMobile ? '0px' : '10px'}`,
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '24px',
            textAlign: 'left',
            color: ' #000',
            cursor: 'pointer',
          }}
        >
          <img src={ExcelIcon} style={{ marginRight: '10px' }} alt='excel' />{' '}
          Excel
        </div>
      </CSVLink>
    </div>
  )
  return (
    <>
      {/* <DownloadReport
        data={JSON.parse(JSON.stringify(spAverageIndexreport))?.map((item) => {
          return {
            EntryDate: item.entrydate,
            Type: item.Type,
            ClosingIndexValue: item.ClosingIndexValue,
            Quantity: item.Qty,
            Value: item.Value,
          }
        })}
        fileName='average-report'
        id='AVG-REPORT-PDF'
      /> */}

      <div className='report_Container'>
        <div className='container_Header'>
          <div className='report_Heading'>Average Index Report</div>

          <Popover content={content} placement='bottom' trigger='click'>
            <div className='container_Export_Popover'>
              <Button>
                Export{' '}
                <DownloadOutlined
                  color='#AF0000'
                  style={{ fontSize: '16px' }}
                />
              </Button>
            </div>
          </Popover>
        </div>

        <div className='report_first_Container'>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className='Client_Info_Container'>
              <div className='client_Info'>
                Client Code:&nbsp;
                <span>{clientCode}</span>
              </div>
              <div className='client_Info'>
                Client Name:&nbsp;
                <span>
                  {firstName} {lastName}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div
          className='report-table-wrapper average-table-report'
          id='AVG-REPORT-PDF'
        >
          <AverageIndexTable
            reportData={spAverageIndexreport}
            ClientCode={clientCode}
            firstName={firstName}
            lastName={lastName}
          />
        </div>
      </div>
    </>
  )
}
