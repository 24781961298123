import React from 'react'
import moment from 'moment'
import { get } from 'lodash'
import {
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  DatePicker,
  Button,
} from 'antd'

const { Option } = Select

const CollectionCreateForm = ({
  visible,
  onCreate,
  onCancel,
  loading,
  isUpdate,
  onUpdate,
  amount,
  data,
  date,
}) => {
  date = date ? moment() : moment(date)
  if (get(data, 'whichYear')) {
    data.whichYear = moment(data.whichYear.toString())
  }

  if (get(data, 'client.id')) {
    data.clientId = get(data, 'client.id')
  }

  if (get(data, 'nextDueDate')) {
    data.nextDueDate = moment(data.nextDueDate)
  }

  if (get(data, 'startDate')) {
    data.startDate = moment(data.startDate)
  }

  if (get(data, 'paidAt')) {
    data.paidAt = moment(data.paidAt)
  }

  const [form] = Form.useForm()

  const whichYear = moment()
  const status = 'COMPLETED'

  function resetForm() {
    form.resetFields()
  }

  const paymentTypes = [
    { text: 'CASH', value: 'CASH' },
    { text: 'CHEQUE', value: 'CHEQUE' },
    { text: 'ONLINE', value: 'ONLINE' },
  ]

  const statusTypes = [
    { text: 'PENDING', value: 'PENDING' },
    { text: 'COMPLETED', value: 'COMPLETED' },
  ]

  const amcTypes = [
    { text: 'New', value: 'New' },
    { text: 'Renewal', value: 'Renewal' },
    { text: 'Existing New', value: 'Existing New' },
  ]

  return (
    <Modal
      maskClosable={false}
      visible={visible}
      title={isUpdate ? 'Update AMC' : 'Create AMC'}
      okText={isUpdate ? 'Update' : 'Create'}
      okButtonProps={{
        loading,
      }}
      cancelText='Cancel'
      onCancel={() => {
        resetForm()
        onCancel()
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            if (isUpdate) {
              onUpdate(values, resetForm)
            } else {
              onCreate(values, resetForm)
            }
          })
          .catch((info) => console.log('Validate Failed:', info))
      }}
    >
      <Form
        form={form}
        layout='vertical'
        name='form_in_modal'
        initialValues={{
          date,
          status,
          amount,
          whichYear,
          modifier: 'public',
          amcType: data?.amcType ? data?.amcType : 'New',
          ...data,
        }}
      >
        <Form.Item
          name='amount'
          label='Amount'
          rules={[{ required: true, message: 'Please input your Amount!' }]}
        >
          <InputNumber
            min={0}
            style={{ width: '100%' }}
            placeholder='Enter Amount'
          />
        </Form.Item>
        <Form.Item
          name='amcQty'
          label='AMC Quantity'
          rules={[
            { required: true, message: 'Please input your AMC Quantity!' },
          ]}
        >
          <InputNumber
            min={0}
            style={{ width: '100%' }}
            placeholder='Enter AMC Quantity'
          />
        </Form.Item>
        <Form.Item name='taxInvoiceNumber' label='Tax Invoice Number'>
          <Input
            min={0}
            style={{ width: '100%' }}
            placeholder='Enter Tax Invoice Number'
          />
        </Form.Item>
        <Form.Item
          name='whichYear'
          label='Year Of'
          rules={[{ required: true, message: 'Please input your Which Year!' }]}
        >
          <DatePicker
            format={'DD-MMM-YYYY'}
            getPopupContainer={trigger => trigger.parentElement}
            picker='year'
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item
          name='startDate'
          label='Start Date'
          rules={[
            { required: true, message: 'Please input your Next Start Date!' },
          ]}
        >
          <DatePicker
            format={'DD-MMM-YYYY'}
            getPopupContainer={trigger => trigger.parentElement}
            autoComplete='off'
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item
          name='nextDueDate'
          label='Next Due Date'
          rules={[
            { required: true, message: 'Please input your Next Due Date!' },
          ]}
        >
          <DatePicker
            format={'DD-MMM-YYYY'}
            getPopupContainer={trigger => trigger.parentElement}
            autoComplete='off'
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item
          name='paidAt'
          label='Paid At'
          rules={[{ required: true, message: 'Please input your Paid At!' }]}
        >
          <DatePicker
            format={'DD-MMM-YYYY'}
            getPopupContainer={trigger => trigger.parentElement}
            autoComplete='off'
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item
          name='paymentType'
          label='Select Payment Type'
          rules={[
            {
              required: true,
              message: 'Please Select Payment Type!',
              whitespace: true,
            },
          ]}
        >
          <Select getPopupContainer={(trigger) => trigger.parentElement} placeholder='Select Payment Type'>
            {paymentTypes.map((paymentType) => (
              <Option key={paymentType.value} value={paymentType.value}>
                {paymentType.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name='amcType'
          label='AMC Type'
          rules={[
            {
              required: true,
              message: 'Please Select AMC Type!',
              whitespace: true,
            },
          ]}
        >
          <Select getPopupContainer={(trigger) => trigger.parentElement} placeholder='Select AMC Type'>
            {amcTypes.map((statusType) => (
              <Option key={statusType.value} value={statusType.value}>
                {statusType.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name='status'
          label='Select Status Type'
          rules={[
            {
              required: true,
              message: 'Please Select Status Type!',
              whitespace: true,
            },
          ]}
        >
          <Select getPopupContainer={(trigger) => trigger.parentElement} placeholder='Select Status Type'>
            {statusTypes.map((statusType) => (
              <Option key={statusType.value} value={statusType.value}>
                {statusType.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          hasFeedback
          name='description'
          label='Description'
          // rules={[
          //   {
          //     required: true,
          //     message: 'Please write your description!',
          //     whitespace: true,
          //   },
          // ]}
        >
          <Input.TextArea rows={5} />
        </Form.Item>
        <Form.Item name='date' style={{ display: 'none' }}>
          <DatePicker getPopupContainer={trigger => trigger.parentElement} format={'DD-MMM-YYYY'} />
        </Form.Item>
        <Form.Item
          name='invoiceNo'
          label='Invoice Number'
          rules={[
            { required: true, message: 'Please input your Invoice Number' },
          ]}
        >
          <Input
            min={0}
            style={{ width: '100%' }}
            placeholder='Enter Invoice Number'
          />
        </Form.Item>

        <div className='footer-btn-div'>
          <Button
            className='footer-cancel-btn'
            onClick={() => {
              resetForm()
              onCancel()
            }}
          >
            Cancel
          </Button>
          <Button
            className='footer-submit-btn'
            onClick={() => {
              form
                .validateFields()
                .then((values) => {
                  if (isUpdate) {
                    onUpdate(values, resetForm)
                  } else {
                    onCreate(values, resetForm)
                  }
                })
                .catch((info) => console.log('Validate Failed:', info))
            }}
            type='primary'
          >
            {isUpdate ? 'Update' : 'Create'}
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

const CreateTicketModal = (props) => {
  return (
    <div>
      <CollectionCreateForm {...props} />
    </div>
  )
}

export default CreateTicketModal
