import map from 'lodash/map'

import TotalFund from 'assets/images/new-images/revenue-red.svg'
import TotalUser from 'assets/images/new-images/user-tree-red.svg'
import TotalTickets from 'assets/images/new-images/ticket-red.svg'
import TotalPL from 'assets/images/new-images/percentage-red.svg'
import NewClient from 'assets/images/new-images/user-red.svg'
import Calender from 'assets/images/new-images/calendar-red.svg'
import TotalRevenue from 'assets/images/new-images/revenue-red.svg'

export function numDifferentiation(value) {
  if (!value) return '-'
  var val = Math.abs(value)
  if (val >= 10000000) {
    val = (val / 10000000).toFixed(2) + ' Cr'
  } else if (val >= 100000) {
    val = (val / 100000).toFixed(2) + ' Lac'
  }
  return val
}

export function generateUserPerformanceData(data) {
  return map(data, (item, key) => {
    switch (item.Description) {
      case 'New Clients':
        return {
          cardColor: 'teal',
          icon: NewClient,
          subTitle: item.Description,
          title: item.Value,
        }
      case 'Redim AUM':
      case 'Redeem AUM':
        return {
          cardColor: 'cyan',
          icon: TotalRevenue,
          subTitle: 'Redeem AUM',
          title: item.Value,
        }
      case 'AMC collection':
        return {
          cardColor: 'orange',
          icon: Calender,
          subTitle: item.Description,
          title: item.Value,
        }
      case 'SIP collection':
        return {
          cardColor: 'indigo',
          icon: TotalRevenue,
          subTitle: item.Description,
          title: item.Value,
        }
      case 'Redim Client':
      case 'Redeem Client':
        return {
          cardColor: 'sepia',
          icon: TotalUser,
          subTitle: 'Redeem Client',
          title: item.Value,
        }
      case 'New AUM':
        return {
          cardColor: 'purple',
          icon: TotalRevenue,
          subTitle: item.Description,
          title: item.Value,
        }
      default:
        break
    }
  })
}

export function generateDashboardData(data) {
  return map(data, (item, key) => {
    switch (key) {
      case 'totalFund':
        return {
          cardColor: 'cyan',
          icon: 'revenue-new',
          title: numDifferentiation(item),
          subTitle: 'Total Fund',
        }
      case 'totalClients':
        return {
          cardColor: 'orange',
          icon: 'profile2',
          title: item || '-',
          subTitle: 'Clients',
        }
      case 'totalTickets':
        return {
          cardColor: 'teal',
          icon: 'tag-o',
          title: item || '-',
          subTitle: 'Open Tickets',
        }
      case 'totalProfitLoss':
        return {
          cardColor: 'purple',
          icon: 'growth',
          title: item ? `${item}%` : '-',
          subTitle: 'Total Profit Loss',
        }
      case 'totalGroups':
        return {
          cardColor: 'purple',
          icon: 'team',
          title: item || '-',
          subTitle: 'Groups',
        }
      default:
        break
    }
  })
}

export function generateDashboardSummary(data) {
  return map(data, (item, key) => {
    switch (key) {
      case 'TotalFund':
        return {
          cardColor: 'cyan',
          icon: TotalFund,
          title: item || '-',
          subTitle: 'Total Fund',
        }
      case 'Clients':
        return {
          cardColor: 'orange',
          icon: TotalUser,
          title: !!item ? item : item === 0 ? 0 : '-',
          subTitle: 'Clients',
        }
      case 'Tickets':
        return {
          cardColor: 'teal',
          icon: TotalTickets,
          title: item || '-',
          subTitle: 'Open Tickets',
        }
      case 'Pnlper':
        return {
          cardColor: 'purple',
          icon: TotalPL,
          title: item || '-',
          subTitle: 'Total Profit Loss',
        }
      case 'totalGroups':
        return {
          cardColor: 'purple',
          icon: TotalUser,
          title: item || '-',
          subTitle: 'Groups',
        }
      default:
        break
    }
  })
}
