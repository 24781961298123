import gql from 'graphql-tag'

export const GET_ALL_ACCESS_SETTINGS = gql`
  query getAllAccessSettings {
    getAllAccessSettings {
      id
      title
      key
      admin
      staff
      client
      associate
      salesExecutive
      masterAssociate
      createdAt
      updatedAt
    }
  }
`

export const GET_STRATEGY_LIST = gql`
  query getStrategyList {
    getStrategyList
  }
`
export const GET_STRATEGY = gql`
  query getStrategy($where: GetStrategyWhereUniqueInput!) {
    getStrategy(where: $where)
  }
`

export const GET_SITE_SETTING = gql`
  query getSiteSettings {
    getSiteSettings {
      id
      url
      status
    }
  }
`

export const GET_AGREEMENT_DATA_HTML = gql`
  query getAgreementDataHtml($where: AgreementTemplate) {
    getAgreementDataHtml(where: $where)
  }
`

export const POST_AGREEMENT_DATA_HTML = gql`
  query getAgreementDataHtmlFromFrontEnd($data: AgreementDataHtmlInput!) {
    getAgreementDataHtmlFromFrontEnd(data: $data)
  }
`

export const GET_TEMPLATES_DATA_HTML = gql`
  query getTemplatesDataHtml($where: TemplateTypeInput) {
    getTemplatesDataHtml(where: $where)
  }
`

export const POST_TEMPLATES_DATA_HTML = gql`
  query getTemplatesDataHtmlFromFrontEnd($data: TemplateDataHtmlInput!) {
    getTemplatesDataHtmlFromFrontEnd(data: $data)
  }
`

export const GENERATE_PDF_AGREEMENT = gql`
  query generatePDF($data: generatePDFInput!) {
    generatePDF(data: $data)
  }
`
export const GENERATE_NON_INDIVIDUAL_REPORT = gql`
  query generateNonIndividualReport($where: spAgreementInput) {
    generateNonIndividualReport(where: $where)
  }
`

export const GET_SYSTEM_ALERT = gql`
  query getSystemAlert {
    getSystemAlert {
      id
      message
      isActive
      type
      createdAt
      updatedAt
    }
  }
`
export const GET_INVOICE_SETTING = gql`
  query getInvoiceSetting {
    getInvoiceSetting {
      id
      type
      companyName
      webEmail
      address
      invoiceNumber
      accountDetails
      bankName
      branchName
      ifscCode
      panNumber
      autoGenerateInvoiceStartNumber
      invoiceDateType
      invoiceDate
      gstinNumber
      upiId
      qrImage
      invoiceYear
      billTypeParticular
      otherMailId
      amcDates
      amcPercentage
      selectAmcPercentage
    }
  }
`

export const GET_GENERAL_SETTING = gql`
  query {
    getGeneralSetting {
      id
      company
      amcEndDate
      niftyLotSize
    }
  }
`

export const GET_CLIENTS_FROM_EMAIL = gql`
  query getClientsFromEmail($where: ClientEmailInput) {
    getClientsFromEmail(where: $where)
  }
`

export const GET_PRE_DETAIL_FOR_INVOICE = gql`
  query getPreDetailForInvoice($where: ClientCodeInput) {
    getPreDetailForInvoice(where: $where)
  }
`
export const GET_INVOICES = gql`
  query getInvoices(
    $where: InvoiceMasterWhereUniqueInput
    $skip: Int
    $limit: Int
    $sort: SortInput
  ) {
    getInvoices(where: $where, skip: $skip, limit: $limit, sort: $sort) {
      count
      rows {
        id
        clientType
        deduction
        amcType
        clientName
        clientCode
        emailId
        address
        state
        discountInPer
        commissionInPer
        niftySpot
        niftyLot
        quantity
        assetUnderAdvisory
        amcInPer
        discountAmcInPer
        startDate
        endDate
        dueDay
        amcAmount
        discountAmount
        commissionAmount
        grossTotalAmount
        cgst9InPer
        sgst9InPer
        igst18InPer
        totalNetAmount
        mailBody
        invoicePdf
        amcExplanation
        amcCalculateType
        additionalDiscountAmount
        eMandateDiscountPer
        eMandateDiscountAmount
        subject
        amountInWord
        pdfFileName
        invoiceNo
        otherMailId
        ccMailIds
        createdAt
        amc {
          id
        }
        client {
          firstName
          lastName
          id
          umrnNumber
          createdBy {
            firstName
            lastName
            id
            createdBy {
              firstName
              lastName
              id
            }
          }
        }
      }
    }
  }
`

export const GET_TEMPLATE_DATA = gql`
  query getTemplateData($type: String) {
    getTemplateData(type: $type) {
      createdAt
      htmlData
      id
      type
    }
  }
`
export const GET_CC_EMAIL = gql`
  query ($clientCode: String!) {
    getCCEmails(clientCode: $clientCode)
  }
`

export const GET_CLIENT_FORM = gql`
  query getClientsForm($where: whereClientFormFind) {
    getClientsForm(where: $where) {
      id
      isFilled
    }
  }
`
export const GET_PAY_NOW_SETTING = gql`
  query GetPayNowSetting {
    getPayNowSetting {
      id
      isMandate
      isNonMandate
    }
  }
`
