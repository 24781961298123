import React, { useContext, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Table, Modal, Tooltip, Button, Form, Upload, Input } from 'antd'
import {
  ExclamationCircleOutlined,
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { GET_POPULAR_BLOGS } from './graphql/Queries'
import Page404 from 'components/Page404'
import { CREATE_POPULAR_BLOG, DELETE_POPULAR_BLOG } from './graphql/Mutation'
import client from 'apollo'
import openNotification from 'utils/Notification'
import { AppContext } from 'AppContext'
import './index.css'

const { confirm } = Modal

export default function PopularBlogs() {
  const [form] = Form.useForm()
  const [showModal, setShowModal] = useState(false)
  const [fileList, setFileList] = useState(undefined)
  const [btnLoading, setBtnLoading] = useState(false)

  const {
    state: { isMobile },
  } = useContext(AppContext)

  const { data, loading, error } = useQuery(GET_POPULAR_BLOGS, {
    fetchPolicy: 'network-only',
  })

  if (error) return <Page404 error={error} />

  const showConfirm = (id) => {
    confirm({
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
      title: `Do you want to delete this  image?`,
      content: `When clicked the OK button, this image will be Deleted`,
      onOk() {
        client
          .mutate({
            mutation: DELETE_POPULAR_BLOG,
            variables: { id: +id },
            refetchQueries: [{ query: GET_POPULAR_BLOGS }],
          })
          .catch((err) => console.log(err))
        openNotification('success', 'Blog Deleted Successfully')
      },
    })
  }

  const renderAction = (record) => {
    return (
      <div className='action-icons'>
        {
          <Tooltip title='Delete Image'>
            <DeleteOutlined onClick={() => showConfirm(record.id)} />
          </Tooltip>
        }
      </div>
    )
  }

  const column = [
    {
      title: 'Image',
      dataIndex: 'imageUrl',
      render: (item) => (
        <img
          style={{
            borderRadius: '10px',
          }}
          height={100}
          width={100}
          src={item}
          alt='img'
        />
      ),
      key: 'imageUrl',
      width: isMobile && 150,
    },
    {
      title: 'Image Name',
      dataIndex: 'imageName',
      key: 'imageName',
    },
    {
      title: 'Blog Link',
      dataIndex: 'blogLink',
      key: 'blogLink',
      render: (link) => (
        <a href={link} target='_blank' rel='noopener noreferrer'>
          {link}
        </a>
      ),
    },
    {
      title: 'Blogs Header',
      dataIndex: 'blogHeader',
      key: 'blogHeader',
    },
    {
      title: 'Action',
      key: 'action',
      width: '110px',
      render: (text, record) => renderAction(record),
    },
  ]

  const normFile = (e) => {
    setFileList([])
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const customProps = {
    multiple: false,
    onRemove: (file) => setFileList([]),
    beforeUpload: (file) => {
      setFileList(file)
      return false
    },
    fileList,
  }

  const onFinish = (values) => {
    setBtnLoading(true)
    client
      .mutate({
        mutation: CREATE_POPULAR_BLOG,
        variables: {
          data: {
            file: values?.fileUrl[0]?.originFileObj,
            blogHeader: values?.blogHeader,
            blogLink: values?.blogLink,
          },
        },
        refetchQueries: [{ query: GET_POPULAR_BLOGS }],
      })
      .then((res) => {
        setBtnLoading(false)
        setShowModal(false)
        form.resetFields()
        openNotification('success', 'Blog Created Successfully')
      })
      .catch((err) => {
        console.log(err)
        setBtnLoading(false)
        setShowModal(false)
      })
  }

  return (
    <>
      <div className='main_dashboard_div'>
        <div className='agreement_main_div'>
          <div className='agreement_text'>Popular Blogs</div>
          <div style={{ display: 'flex', gap: '10px' }}>
            <Button className='export_btn' onClick={() => setShowModal(true)}>
              Add Blog
            </Button>
          </div>
        </div>
        <Table
          style={{ overflow: 'auto' }}
          dataSource={data?.getAllPopularBlogs}
          columns={column}
          loading={loading}
        />
      </div>

      {showModal && (
        <Modal
          title='Create Popular Blog'
          visible={showModal}
          footer={null}
          onCancel={() => setShowModal(false)}
        >
          <Form
            form={form}
            layout='vertical'
            name='form_in_modal'
            onFinish={onFinish}
          >
            <Form.Item
              name='fileUrl'
              valuePropName='fileList'
              getValueFromEvent={normFile}
              rules={[{ required: true, message: 'Please upload image!' }]}
            >
              <Upload
                name='attachment'
                listType='picture'
                {...customProps}
                accept='.jpeg,.png,.jpg'
                maxCount={1}
              >
                <>
                  <Button type='primary'>
                    <PlusOutlined /> Upload Blog Image
                  </Button>
                </>
              </Upload>
            </Form.Item>
            <Form.Item
              name='blogHeader'
              label='Blog Header'
              rules={[
                { required: true, message: 'Please input blog heaeder!' },
              ]}
            >
              <Input
                placeholder='Enter Blog Header'
                autoComplete='off'
                style={{ width: '100%' }}
              />
            </Form.Item>

            <Form.Item
              name='blogLink'
              label='Blog Link'
              rules={[
                {
                  required: true,
                  message: 'Please input blog link!',
                  pattern: new RegExp(
                    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
                  ),
                },
              ]}
            >
              <Input
                placeholder='Enter Blog Link'
                autoComplete='off'
                style={{ width: '100%' }}
              />
            </Form.Item>

            <Form.Item>
              <Button type='primary' htmlType='submit' loading={btnLoading}>
                Submit Blog
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  )
}
