import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from 'AppContext'
import client from 'apollo'
import { UPDATE_USER } from 'modules/Users/graphql/Mutations'
import Loader from 'components/loaders/Loader'
import AuaNewSteps from 'modules/Verification/AuaNewSteps'

const NewAuaAdvisory = () => {
  const {
    state: { currentUser },
    dispatch,
  } = useContext(AppContext)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!currentUser?.isBookingAdvisoryDateSelected) {
      setLoading(true)
      client
        .mutate({
          mutation: UPDATE_USER,
          variables: {
            data: {
              isNewAuaAdvisoryGenerate: true,
              newAuaAdvisoryCompletedSteps: 'KYC_FORM',
              isBookingAdvisoryDateSelected: false,
            },
            id: currentUser?.id,
          },
        })
        .then((res) => {
          dispatch({ type: 'SHOW_REVIEW_PLAN', data: false })
          dispatch({
            type: 'CURRENT_USER',
            data: res.data.updateUser,
          })
          setLoading(false)
        })
        .catch((err) => {
          console.log(err)
          setLoading(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <AuaNewSteps />
        </div>
      )}
    </>
  )
}
export default NewAuaAdvisory
