import React from 'react'
import { Modal, Form, Button, DatePicker } from 'antd'
import moment from 'moment'

const UpdateAnalysisExpiryDateModal = ({
  openModal,
  onCancel,
  btnLoading,
  updateData,
  onFinish,
}) => {
  const [form] = Form.useForm()
  return (
    <div>
      <Modal
        title='Update Analysis Expiry Date'
        visible={openModal}
        footer={null}
        onCancel={onCancel}
      >
        <Form
          form={form}
          layout='vertical'
          name='form_in_modal'
          initialValues={{
            expiryDate: updateData ? moment(updateData?.expiryDate) : undefined,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name='expiryDate'
            label='Analysis Expiry Date'
            rules={[
              {
                required: true,
                message: 'Please select Analysis Expiry Date!',
              },
            ]}
          >
            <DatePicker getPopupContainer={trigger => trigger.parentElement} format={'YYYY-MM-DD'} style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item>
            <Button type='primary' htmlType='submit' loading={btnLoading}>
              Submit Analysis Expiry Date
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default UpdateAnalysisExpiryDateModal
