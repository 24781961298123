import React, { useState } from 'react'
import { UploadOutlined } from '@ant-design/icons'
import { Modal, Form, Input, Select, Upload, Button } from 'antd'
import '../../Verification/verification.css'

const { Option } = Select

const CollectionCreateForm = ({
  visible,
  onCreate,
  onCancel,
  loading,
  fromFaq,
}) => {
  const [form] = Form.useForm()
  const [fileList, setFileList] = useState([])

  function resetForm() {
    form.resetFields()
  }

  const subjects = [
    { text: 'Report', value: 'Report' },
    { text: 'Staff', value: 'Staff' },
    { text: 'Technical', value: 'Technical' },
    { text: 'Others', value: 'Others' },
  ]

  const normFile = (e) => {
    setFileList([])
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const customProps = {
    multiple: false,
    onRemove: (file) => setFileList([]),
    beforeUpload: (file) => {
      setFileList(file)
      return false
    },
    fileList,
  }

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => onCreate(values, resetForm))
      .catch((info) => console.log('Validate Failed:', info))
  }

  return (
    <Modal
      maskClosable={false}
      visible={visible}
      title={'Generate Ticket'}
      okText={'Generate'}
      okButtonProps={{
        loading,
      }}
      className='create-ticket-modal'
      footer={null}
      onCancel={() => {
        resetForm()
        onCancel()
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <img
          src='https://finideas-india.blr1.digitaloceanspaces.com/Finideas-black-png.png'
          alt='Finideas'
          width={150}
          height={50}
        />
      </div>
      <Form
        form={form}
        layout='vertical'
        name='form_in_modal'
        initialValues={{
          modifier: 'public',
        }}
        onFinish={onFinish}
      >
        {fromFaq ? (
          <Form.Item
            name='subject'
            label='Subject'
            rules={[
              {
                required: true,
                message: 'Please write your subject!',
                whitespace: true,
              },
            ]}
          >
            <Input placeholder='Write your subject' />
          </Form.Item>
        ) : (
          <Form.Item
            name='subject'
            label='Choose Subject'
            rules={[
              {
                required: true,
                message: 'Please choose your Subject!',
                whitespace: true,
              },
            ]}
          >
            <Select getPopupContainer={(trigger) => trigger.parentElement} placeholder='Choose Subject'>
              {subjects.map((subject) => (
                <Option key={subject.value} value={subject.value}>
                  {subject.text}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          name='description'
          label={fromFaq ? 'Describe your issue in detail' : 'Description'}
          rules={[
            {
              required: true,
              message: 'Please write your description!',
              whitespace: true,
            },
          ]}
          hasFeedback
        >
          <Input.TextArea
            placeholder='Please attach a screenshot/image to help answer your better.'
            rows={5}
          />
        </Form.Item>
        {fromFaq ? (
          <>
            <Form.Item
              name='fileUrl'
              label='File'
              valuePropName='fileList'
              className='file_upload'
              getValueFromEvent={normFile}
              rules={[
                {
                  validator: (_, value) =>
                    value?.length > 6
                      ? Promise.reject(
                          new Error('Cannot upload more than 6 files')
                        )
                      : Promise.resolve(),
                },
              ]}
            >
              <Upload
                name='attachment'
                listType='text'
                {...customProps}
                accept='.jpeg,.png,.jpg,.pdf,.docx,.doc'
              >
                <Button>Choose file</Button>
              </Upload>
            </Form.Item>
            <div className='create_ticket_upload_text_div'>
              <b className='create_ticket_upload_text'>
                Max 6 files allowed of 3MB each
              </b>
              <p className='create_ticket_upload_text'>
                Supported types: .jpeg,.png,.jpg,.pdf,.docx,.doc
              </p>
            </div>
          </>
        ) : (
          <Form.Item
            name='fileUrl'
            label='Attachment'
            valuePropName='fileList'
            getValueFromEvent={normFile}
            rules={[
              {
                validator: (_, value) =>
                  value?.length > 6
                    ? Promise.reject(
                        new Error('Cannot upload more than 6 files')
                      )
                    : Promise.resolve(),
              },
            ]}
          >
            <Upload
              name='attachment'
              listType='text'
              {...customProps}
              accept='.jpeg,.png,.jpg,.pdf,.docx,.doc'
            >
              <Button>
                <UploadOutlined /> Click to upload
              </Button>
            </Upload>
          </Form.Item>
        )}
        <Form.Item>
          <Button type='primary' htmlType='submit' loading={loading}>
            {fromFaq ? 'Submit' : 'Generate'}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

const CreateTicketModal = (props) => {
  return (
    <div>
      <CollectionCreateForm {...props} />
    </div>
  )
}

export default CreateTicketModal
