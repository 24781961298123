/* eslint-disable array-callback-return */
import React, { useContext, useState } from 'react'
import { Button, Col, Form, Row, Tabs } from 'antd'

import SystemUsers from 'modules/Users/components/SystemUsers'
import UserClients from 'modules/Users/components/UserClients'
import { userDetails, userDocuments } from 'utils/User'
import { AppContext } from 'AppContext'
import AMC from 'modules/Clients/AMC'
import SIP from 'modules/Clients/SIP'
import AboutItem from './AboutItem'
import Widget from '../Widget'
import UserEMandate from 'modules/Users/components/UserEMandate'
import UserQuestionnaire from 'modules/Users/components/UserQuestionnaire'
import { pdfjs } from 'react-pdf'
import { useQuery } from '@apollo/react-hooks'
import RationalStatus from 'components/RationalStatus'
import Loader from 'components/loaders/Loader'
import UploadFileModal from './uploadFileModal'
import { GET_USER_PDF } from 'modules/Plans/graphql/Query'
import DigioAutoRequest from './DigioAutoRequest'

const TabPane = Tabs.TabPane
export default function About(props) {
  const {
    details,
    userId,
    isProfile,
    id,
    details: { annualIncome },
  } = props
  const {
    state: {
      isClient,
      currentUser: { role },
    },
  } = useContext(AppContext)
  const aboutList = userDetails(details)
  const documentList = userDocuments(details.kyc, isClient)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [pdfType, setPDFType] = useState({
    type: '',
    notify: '',
    updateField: '',
  })
  const [uploadFileValues, setUploadFileValues] = useState({})

  const [form] = Form.useForm()

  const showModal = (type, notify, updateField) => {
    setIsModalVisible(true)
    setUploadFileValues({})

    setPDFType({
      type,
      notify,
      updateField,
    })
  }
  const { data, loading } = useQuery(GET_USER_PDF, {
    variables: { userId: parseInt(id) },
    fetchPolicy: 'network-only',
  })

  const PDFs = data?.usersPdf
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

  const importBulkAmcsModalClose = () => {
    setIsModalVisible(false)
  }

  if (loading) return <Loader />
  return (
    <>
      {isModalVisible && (
        <UploadFileModal
          type={pdfType?.type}
          notify={pdfType?.notify}
          updateField={pdfType?.updateField}
          id={id}
          setIsModalVisible={setIsModalVisible}
          importBulkAmcsModalClose={importBulkAmcsModalClose}
          uploadFileValues={uploadFileValues}
          setUploadFileValues={setUploadFileValues}
        />
      )}

      <label style={{ fontSize: '24px', marginLeft: '24px' }}>Details</label>
      <Widget title=''>
        <Tabs defaultActiveKey='1'>
          {role === 'ADMIN' && (
            <TabPane tab='CVL File' key='1'>
              <div>
                <Form
                  form={form}
                  name='register'
                  className='user-form'
                  scrollToFirstError
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'end',
                      marginRight: '30px',
                      marginBottom: '20px',
                    }}
                  >
                    <Form.Item name='cvlReportStatus'>
                      <Button
                        onClick={() =>
                          showModal(
                            'cvlReportStatus',
                            'CVL Status',
                            'cvlReportStatus'
                          )
                        }
                      >
                        Upload
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
                <div className='gx-mb-2'>
                  {PDFs &&
                    PDFs?.length !== 0 &&
                    PDFs.map((pdf, key) => {
                      if (pdf.type === 'cvlReportStatus')
                        return (
                          <RationalStatus
                            pdf={pdf}
                            key={key}
                            id={id}
                            style={{ width: '100%' }}
                          />
                        )
                    })}
                </div>
              </div>
            </TabPane>
          )}
          {role === 'ADMIN' && (
            <TabPane tab='Risk Assessment' key='2'>
              <div>
                <Form
                  form={form}
                  name='register'
                  className='user-form'
                  scrollToFirstError
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'end',
                      marginRight: '30px',
                      marginBottom: '20px',
                      borderBottom: '1px solid #d9d9d9',
                    }}
                  >
                    <Form.Item name='riskAssessment'>
                      <Button
                        onClick={() =>
                          showModal(
                            'riskAssessment',
                            'RiskAssessment Report',
                            'riskSuitabilityReportStatus'
                          )
                        }
                      >
                        Upload
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
                <div className='gx-mb-2'>
                  {PDFs &&
                    PDFs?.length !== 0 &&
                    PDFs.map((pdf, key) => {
                      if (pdf.type === 'riskAssessment')
                        return (
                          <RationalStatus
                            pdf={pdf}
                            key={key}
                            id={id}
                            style={{
                              width: '100%',
                            }}
                          />
                        )
                    })}
                </div>
              </div>
            </TabPane>
          )}
          {role === 'ADMIN' && (
            <TabPane tab='Suitability' key='3'>
              <div>
                <Form
                  form={form}
                  name='register'
                  className='user-form'
                  scrollToFirstError
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'end',
                      marginRight: '30px',
                      marginBottom: '20px',
                    }}
                  >
                    <Form.Item name='suitabilityReport'>
                      <Button
                        onClick={() =>
                          showModal(
                            'suitabilityReport',
                            'Suitability Report',
                            'suitabilityReportStatus'
                          )
                        }
                      >
                        Upload
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
                <div className='gx-mb-2'>
                  {PDFs &&
                    PDFs?.length !== 0 &&
                    PDFs.map((pdf, key) => {
                      if (pdf.type === 'suitabilityReport')
                        return (
                          <RationalStatus
                            pdf={pdf}
                            key={key}
                            id={id}
                            style={{ width: '100%' }}
                          />
                        )
                    })}
                </div>
              </div>
            </TabPane>
          )}
          {role === 'ADMIN' && (
            <TabPane tab='Agreement Report' key='4'>
              <div>
                <Form
                  form={form}
                  name='register'
                  className='user-form'
                  scrollToFirstError
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'end',
                      marginRight: '30px',
                      marginBottom: '20px',
                    }}
                  >
                    <Form.Item name='agreementReport'>
                      <Button
                        onClick={() =>
                          showModal(
                            'agreementReport',
                            'Agreement Report',
                            'agreementReportStatus'
                          )
                        }
                      >
                        Upload
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
                <div className='gx-mb-2'>
                  {PDFs &&
                    PDFs?.length !== 0 &&
                    PDFs.map((pdf, key) => {
                      if (pdf.type === 'agreementReport')
                        return (
                          <div style={{ margin: '5px' }}>
                            <RationalStatus pdf={pdf} key={key} id={id} />
                          </div>
                        )
                    })}
                </div>
              </div>
            </TabPane>
          )}
          {role === 'ADMIN' && (
            <TabPane tab='Invoice' key='5'>
              <div>
                <Form
                  form={form}
                  name='register'
                  className='user-form'
                  scrollToFirstError
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'end',
                      marginRight: '30px',
                      marginBottom: '20px',
                    }}
                  >
                    <Form.Item name='invoiceStatus'>
                      <Button
                        onClick={() =>
                          showModal(
                            'invoiceStatus',
                            'Invoice Status',
                            'invoiceStatus'
                          )
                        }
                      >
                        Upload
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </div>
              <div className='gx-mb-2'>
                {PDFs &&
                  PDFs?.length !== 0 &&
                  PDFs.map((pdf, key) => {
                    if (pdf.type === 'invoiceStatus')
                      return (
                        <RationalStatus
                          pdf={pdf}
                          key={key}
                          id={id}
                          style={{ width: '100%' }}
                        />
                      )
                  })}
              </div>
            </TabPane>
          )}
          {role === 'ADMIN' && (
            <TabPane tab='Rational' key='6'>
              <div>
                <Form
                  form={form}
                  name='register'
                  className='user-form'
                  scrollToFirstError
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'end',
                      marginRight: '30px',
                      marginBottom: '20px',
                    }}
                  >
                    <Form.Item name='rationalStatus'>
                      <Button
                        onClick={() =>
                          showModal(
                            'rationalStatus',
                            'Rational Status',
                            'rationalStatus'
                          )
                        }
                      >
                        Upload
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </div>
              <div className='gx-mb-2'>
                {PDFs &&
                  PDFs?.length !== 0 &&
                  PDFs.map((pdf, key) => {
                    if (pdf.type === 'rationalStatus')
                      return (
                        <RationalStatus
                          pdf={pdf}
                          key={key}
                          id={id}
                          style={{ width: '100%' }}
                        />
                      )
                  })}
              </div>
            </TabPane>
          )}
          {!isProfile &&
            details.role === 'CLIENT' &&
            props?.details?.mandateId && (
              <TabPane tab='E-mandate Report' key='7'>
                <div className='gx-mb-2'>
                  <Row>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <UserEMandate userId={parseInt(props?.details?.id)} />
                    </Col>
                  </Row>
                </div>
              </TabPane>
            )}
          {details.role === 'CLIENT' && (
            <TabPane tab='AMC' key='8'>
              <div className='gx-mb-2'>
                <Row>
                  <AMC {...details} />
                </Row>
              </div>
            </TabPane>
          )}
          <TabPane tab='Overview' key='9'>
            <div className='gx-mb-2'>
              <Row>
                {aboutList.map((about, index) => (
                  <Col key={index} xl={6} lg={12} md={12} sm={12} xs={24}>
                    <AboutItem {...about} />
                  </Col>
                ))}
              </Row>
            </div>
          </TabPane>
          {!isProfile &&
            details.role === 'CLIENT' &&
            props?.details?.plan?.id && (
              <TabPane tab='Questionnaire' key='10'>
                <div className='gx-mb-2'>
                  <Row>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <UserQuestionnaire
                        annualIncome={annualIncome}
                        userId={parseInt(props?.details?.id)}
                        planId={props?.details?.plan?.id}
                      />
                    </Col>
                  </Row>
                </div>
              </TabPane>
            )}
          {details.role === 'CLIENT' && (
            <TabPane tab='Documents' key='11'>
              <div className='gx-mb-2'>
                <Row>
                  {documentList.map((doc, index) => (
                    <Col key={index} xl={8} lg={12} md={12} sm={12} xs={24}>
                      <AboutItem {...doc} />
                    </Col>
                  ))}
                </Row>
              </div>
            </TabPane>
          )}
          {details.role === 'CLIENT' && (
            <TabPane tab='SIP' key='12'>
              <div className='gx-mb-2'>
                <Row>
                  <SIP {...details} />
                </Row>
              </div>
            </TabPane>
          )}
          {!isProfile && details.role !== 'CLIENT' && (
            <TabPane tab='Users' key='13'>
              <div className='gx-mb-2'>
                <Row>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <SystemUsers userId={parseInt(userId)} />
                  </Col>
                </Row>
              </div>
            </TabPane>
          )}
          {!isProfile && details.role !== 'CLIENT' && (
            <TabPane tab='Clients' key='14'>
              <div className='gx-mb-2'>
                <Row>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <UserClients userId={parseInt(userId)} />
                  </Col>
                </Row>
              </div>
            </TabPane>
          )}
          {role === 'ADMIN' && (
            <TabPane tab='Digio Auto Debit Request' key='15'>
              <div className='gx-mb-2'>
                <Row>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <DigioAutoRequest id={id} />
                  </Col>
                </Row>
              </div>
            </TabPane>
          )}
          {/* {!isProfile && !isClient && role === 'ADMIN' && (
            <TabPane tab='Revenue Income' key='15'>
              <div className='gx-mb-2'>
                <Row>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <UserRevenue userId={userId} />
                  </Col>
                </Row>
              </div>
            </TabPane>
          )} */}
        </Tabs>
      </Widget>
    </>
  )
}
