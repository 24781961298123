import { Button, Col, Row, Table, Tooltip } from 'antd'
import React, { useContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import {
  GET_DASHBOARD_SIP_AMC_CLIENT,
  GET_DASHBOARD_STRATEGY_INDEX_CLIENT,
} from 'modules/Dashboard/graphql/Queries'
import { AppContext } from 'AppContext'
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts'
import history from 'CustomHistory'
import Chart from 'react-google-charts'
import Loader from 'components/loaders/Loader'
import NLVChartReport from '../NLVChartReport'
import { get, sumBy } from 'lodash'
import { convertNumberToCommaSeparate } from 'utils/utilities'
import Page404 from 'components/Page404'
import { GET_ILTS_REPORT } from 'modules/Reports/graphql/Queries'
import '../index.css'

const InvestmentSummary = ({ clientCode }) => {
  const {
    state: {
      currentUser: { clientCode: userCode, id: clientId },
      selectedStrategy,
      isMobile,
    },
  } = useContext(AppContext)

  const {
    data: spIltsData,
    loading: spIltsLoading,
    error,
  } = useQuery(GET_ILTS_REPORT, {
    variables: {
      where: { clientCode: userCode, date: null, strategy: '1005' },
    },
    fetchPolicy: 'network-only',
  })

  const { data: datas, loading } = useQuery(
    GET_DASHBOARD_STRATEGY_INDEX_CLIENT,
    {
      variables: { where: { clientCode: clientCode || userCode } },
      fetchPolicy: 'network-only',
    }
  )

  const { data: spAmcSipData, loading: spAmcSipLoading } = useQuery(
    GET_DASHBOARD_SIP_AMC_CLIENT,
    {
      variables: {
        where: {
          userId: parseInt(clientId),
          strategyCode: selectedStrategy,
        },
      },
      fetchPolicy: 'network-only',
    }
  )

  if (loading || spAmcSipLoading || spIltsLoading) return <Loader />
  if (error) return <Page404 />

  let iltsData
  let reportsData = {}
  let NetInvestment
  if (!spIltsLoading && get(spIltsData, 'spIltsreport')) {
    iltsData = spIltsData?.spIltsreport[0]

    const stockReport = iltsData.filter(
      (report, key) => report.Script && report.Script.endsWith(' EQ')
    )
    const ledgers = iltsData.filter(
      (report, key) =>
        key !== 0 &&
        key !== 1 &&
        key !== 2 &&
        !report.Script &&
        !report.isHypothetical
    )

    let ledgerReport = ledgers.map((reportLedger) => {
      console.log({
        DR_Amount: Math.round(reportLedger.DR_Amount),
        CR_Amount: Math.round(reportLedger.CR_Amount),
      })
      return {
        ...reportLedger,
        DR_Amount: Math.round(reportLedger.DR_Amount),
        CR_Amount: Math.round(reportLedger.CR_Amount),
      }
    })
    ledgerReport = [
      ...ledgerReport,
      {
        Entrydate: 'Total',
        DR_Amount: sumBy(ledgerReport, 'DR_Amount'),
        CR_Amount: sumBy(ledgerReport, 'CR_Amount'),
        CumAmount:
          sumBy(ledgerReport, 'DR_Amount') - sumBy(ledgerReport, 'CR_Amount'),

        remark: '',
      },
    ]
    if (ledgerReport?.length) {
      let data = ledgerReport.map((report, key) => {
        return { key, ...report }
      })

      NetInvestment =
        data.slice(data.length - 1)[0].CR_Amount -
        data.slice(data.length - 1)[0].DR_Amount
    }
    const futuresOptionsReport = iltsData.filter(
      (report, key) => report.Script && !report.Script.endsWith(' EQ')
    )

    reportsData = {
      stockBal: sumBy(stockReport, 'Value'),
      stockPnl: sumBy(stockReport, 'Pnl'),
      FOBal: sumBy(futuresOptionsReport, 'Value'),
      FOPnl: sumBy(futuresOptionsReport, 'Pnl'),
    }
  }

  const options = {
    legend: { position: 'none' },
    hAxis: {
      minValue: 0,
      textStyle: {
        fontSize: isMobile ? 9 : 12,
      },
    },
    vAxis: {
      minValue: 0,
      format: 'decimal',
      maxAlternation: 0,
      textStyle: {
        fontSize: 12,
      },
    },
  }

  const colors = ['#50CD89', '#3E97FF', '#AF0000']

  const validData = datas?.reportsGraph?.nlvGraph.slice(1)
  const totalSum = validData.reduce((sum, item) => sum + Math.abs(item[1]), 0)
  const data = validData.map((item, index) => ({
    name: item[0],
    value: (Math.abs(item[1]) / totalSum) * 100,
    color: colors[index % colors.length],
  }))

  const renderLegend = (props) => {
    const { payload } = props
    return (
      <ul className='legend_ul'>
        {payload.map((entry, index) => {
          return (
            <>
              <li className='legend_li' key={`item-${index}`}>
                <div className='legend_title'>
                  <svg width='10' height='10' style={{ marginRight: 5 }}>
                    <circle cx='5' cy='5' r='5' fill={entry.color} />
                  </svg>
                  <div className='legend_title_text'>
                    {entry.value === 'Total Value (Stock Report)'
                      ? 'Equity Value'
                      : entry.value === 'Total Value (FO Report)'
                      ? 'F&O Value'
                      : 'Ledger Balance'}
                  </div>
                </div>

                <div className='legend_title_data_div'>
                  {data?.forEach((item) => {
                    if (item?.name === entry.value) {
                      return `${!item?.value ? 0 : item?.value.toFixed(2)}%`
                    }
                  })}
                </div>
              </li>
            </>
          )
        })}
      </ul>
    )
  }

  const dataSource = [
    {
      key: '1',
      particular: 'Stock Holding',
      value:
        convertNumberToCommaSeparate(Math.round(reportsData?.stockBal)) || 0,
      profitLoss:
        convertNumberToCommaSeparate(Math.round(reportsData?.stockPnl)) || 0,
    },
    {
      key: '2',
      particular: 'Futures & Options',
      value: convertNumberToCommaSeparate(Math.round(reportsData?.FOBal)) || 0,
      profitLoss:
        convertNumberToCommaSeparate(Math.round(reportsData?.FOPnl)) || 0,
    },
    {
      key: '3',
      particular: 'Ledger Balance',
      value:
        convertNumberToCommaSeparate(Math.round(iltsData[0]?.LedgerBalance)) ||
        0,
      profitLoss: 0,
    },
  ]

  const columns = [
    {
      title: 'Particular',
      dataIndex: 'particular',
      key: 'particular',
      width: isMobile && 120,
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      align: 'right',
    },
    {
      title: 'Profit & Loss',
      dataIndex: 'profitLoss',
      key: 'profitLoss',
      align: 'right',
    },
  ]

  const modifyData = (data) => {
    const annualChartData = data?.map((item) => {
      let array = []
      const decimalPart = item[1].toString().split('.')[1]
      let number = item[1]

      if (decimalPart && decimalPart.length === 1) {
        number = item[1] + 0.01
      }
      if (item[0]?.includes('Strategy (%)')) {
        array = [
          `${item[0]
            ?.replaceAll('%', +number?.toFixed(2))
            .replace('(', '')
            .replace(')', '')}%`,
          +number?.toFixed(2),
          '#3E97FF',
        ]
      } else if (item[0]?.includes('Index (%)')) {
        array = [
          `${item[0]
            ?.replaceAll('%', +number?.toFixed(2))
            .replaceAll('(', '')
            .replaceAll(')', '')}%`,
          +number?.toFixed(2),
          '#50CD89',
        ]
      } else {
        array = item
      }
      return array
    })
    return annualChartData
  }

  return (
    <>
      {spIltsData?.spIltsreport[0]?.length ? (
        <div>
          <Row gutter={16} className='investment_row'>
            <Col xs={24} sm={24} md={24} lg={10} className='investment_col_1'>
              <div className='investment_summary_row_col_1'>
                <div className='investment_summary_text'>
                  Investments Summary
                </div>
                <div className='investment_summary_div'>
                  <div className='investment_exposure'>
                    Current Exposure
                    <div className='investment_exposure_value'>
                      ₹{' '}
                      {convertNumberToCommaSeparate(
                        Math.round(iltsData[0]?.TotalExposure)
                      ) || 0}
                    </div>
                  </div>
                  <div className='investment_exposure investment_pl'>
                    Net Profit & Loss
                    <div className='investment_exposure_value'>
                      ₹{' '}
                      {convertNumberToCommaSeparate(
                        Math.round(iltsData[0]?.TotalReturn)
                      ) || 0}
                    </div>
                  </div>
                </div>
                <ResponsiveContainer width='100%' height={isMobile ? 200 : 257}>
                  <PieChart
                    width={isMobile ? 300 : 423}
                    height={257}
                    className='pie-legend-investment-summary'
                  >
                    <Pie
                      data={data}
                      cx='50%'
                      cy='50%'
                      innerRadius={isMobile ? 30 : 50}
                      outerRadius={isMobile ? 60 : 80}
                      fill='#8884d8'
                      dataKey='value'
                    >
                      {data?.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={colors[index % colors.length]}
                        />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend
                      layout={isMobile ? 'horizontal' : 'vertical'}
                      verticalAlign={isMobile ? 'bottom' : 'middle'}
                      align={isMobile ? 'center' : 'right'}
                      content={renderLegend}
                      wrapperStyle={{
                        top: !isMobile ? '50%' : 'auto',
                        transform: !isMobile ? 'translateY(-50%)' : 'none',
                      }}
                    />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={14} className='investment_col_2'>
              <div className='investment_breakup_col_1'>
                <div className='investment_breakup_text_div'>
                  Investments Breakup
                </div>

                <div className='investment_breakup_div'>
                  <div className='investment_exposure'>
                    Net Liquidation Value
                    <div className='investment_exposure_value'>
                      ₹{' '}
                      {convertNumberToCommaSeparate(
                        Math.round(iltsData[0]?.NLV)
                      ) || 0}
                    </div>
                  </div>
                  <div className='investment_exposure'>
                    Net Investment
                    <div className='investment_exposure_value'>
                      ₹ {convertNumberToCommaSeparate(NetInvestment) || 0}
                    </div>
                  </div>
                  <div className='investment_exposure'>
                    Saving of interest
                    <div className='investment_exposure_value'>
                      ₹{' '}
                      {convertNumberToCommaSeparate(
                        Math.round(iltsData[0]?.InterestSaved)
                      ) || 0}
                    </div>
                  </div>
                  <div className='investment_exposure'>
                    P&L without Interest
                    <div className='investment_exposure_value'>
                      ₹{' '}
                      {convertNumberToCommaSeparate(
                        Math.round(iltsData[0]?.PnlWithOutInterest)
                      ) || 0}
                    </div>
                  </div>
                </div>

                <div style={{ marginTop: '16px' }}>
                  <Table
                    className='investment-summary-table'
                    dataSource={dataSource}
                    columns={columns}
                    pagination={false}
                    style={{ overflow: 'auto' }}
                  />
                </div>

                <div className='amc_pending_main_div'>
                  <div>
                    <div className='investment_exposure'>AMC Pending</div>
                    <div className='investment_exposure_value'>
                      ₹
                      {convertNumberToCommaSeparate(
                        get(spAmcSipData, 'spDashbordSIPAMCClient')[1]?.Pending
                      ) || 0}
                    </div>
                    <div className='amc_total_div'>Total Amount</div>
                  </div>
                  <div>
                    <Button
                      className='view_all_btn'
                      onClick={() =>
                        history.push(`${!!clientCode ? '/' : ''}amc`)
                      }
                    >
                      View all
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row gutter={16} className='chart_row'>
            <Col xs={24} sm={24} md={12} lg={12} className='chart_col_1'>
              <div className='chart_div'>
                <div className='chart_div_text'>Annual Return</div>
                <Chart
                  height={'400px'}
                  chartType='ColumnChart'
                  options={options}
                  data={modifyData(datas?.reportsGraph?.annualReturnGraph)}
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} className='chart_col_2'>
              <div className='chart_div chart_div_2'>
                <div className='chart_div_text'>Absolute Return</div>
                <Chart
                  height={'400px'}
                  chartType='ColumnChart'
                  options={options}
                  data={modifyData(datas?.reportsGraph?.absoluteReturnGraph)}
                />
              </div>
            </Col>
          </Row>

          <div className='nlv_main_div'>
            <NLVChartReport
              clientId={clientId}
              clientCode={clientCode || userCode}
            />
          </div>
        </div>
      ) : (
        <div className='client_dashboard_description'>
          We are verifing your data. It will be ready within 48 hours.
          <br /> Thank you for your patience
        </div>
      )}
    </>
  )
}

export default InvestmentSummary
