import React, { useContext, useEffect, useState } from 'react'
import {
  Button,
  Input,
  Space,
  DatePicker,
  Select,
  Form,
  Popover,
  Drawer,
} from 'antd'
import { get } from 'lodash'
import PdfIcon from 'assets/images/new-images/pdficon.svg'
import client from 'apollo'
import { SearchOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/react-hooks'
import '../index.css'
import { GET_CLIENT_USERS_FOR_STAFF } from 'modules/Users/graphql/Queries'
import { GET_LEDGER_REPORT_DATA } from 'modules/Reports/graphql/Queries'
import { GET_ILTS_REPORT } from 'modules/Reports/graphql/Queries'
import { AppContext } from 'AppContext'
import { getName } from 'utils/User'
import { sumBy } from 'lodash'
import { DownloadOutlined, FilterOutlined } from '@ant-design/icons'
import ExcelIcon from 'assets/images/new-images/excelIcon.svg'
import FilterBackButton from 'assets/images/new-images/filter-back-button.svg'
import { CSVLink } from 'react-csv'
import _get from 'lodash/get'
import HypotheticalLedgerTable from './hypotheticalLedgerTable'
import { convertNumberToCommaSeparate } from 'utils/utilities'
const { RangePicker } = DatePicker
const { Option } = Select

export default function () {
  const {
    state: {
      currentUser: { clientCode, firstName, lastName, role, id },
    },
  } = useContext(AppContext)
  const [form] = Form.useForm()
  const [filters, setFilters] = useState({})
  const [open, setOpen] = useState(false)

  const { data: allClient, loading: userLoading } = useQuery(
    GET_CLIENT_USERS_FOR_STAFF,
    {
      variables: { where: {}, limit: 10000 },
      fetchPolicy: 'network-only',
    }
  )
  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      id: role !== 'admin' ? id : null,
    }))
  }, [id, role])

  const { data, loading } = useQuery(GET_ILTS_REPORT, {
    variables: {
      where: {
        clientCode: role === 'CLIENT' ? clientCode : filters.clientCode,
        date: null,
        strategy: 'ILTS',
      },
    },
    fetchPolicy: 'network-only',
  })
  let spIltsreport
  let hypothetical_Ledger_Report = []

  if (!loading && _get(data, 'spIltsreport')) {
    spIltsreport = data.spIltsreport[0]

    const hypothetical_Ledger = spIltsreport.filter(
      (report, key) =>
        key !== 0 &&
        key !== 1 &&
        key !== 2 &&
        !report.Script &&
        report.isHypothetical === 'True'
    )
    hypothetical_Ledger_Report = hypothetical_Ledger.map((reportLedger) => {
      return {
        ...reportLedger,
        DR_Amount: Math.round(reportLedger.DR_Amount),
        CR_Amount: Math.round(reportLedger.CR_Amount),
      }
    })
    hypothetical_Ledger_Report = [
      ...hypothetical_Ledger_Report,
      {
        Entrydate: 'Total',
        DR_Amount: sumBy(hypothetical_Ledger_Report, 'DR_Amount'),
        CR_Amount: sumBy(hypothetical_Ledger_Report, 'CR_Amount'),
        CumAmount:
          sumBy(hypothetical_Ledger_Report, 'DR_Amount') -
          sumBy(hypothetical_Ledger_Report, 'CR_Amount'),
        remark: '',
      },
    ]
  }
  const onFinish = (values) => {
    // console.log('values------>', JSON.parse(values?.clientCode))

    const selectedUser = JSON.parse(values.clientCode)
    setFilters(() => {
      return {
        ...filters,
        clientCode: selectedUser?.clientCode,
        id: selectedUser?.id,
      }
    })
    setOpen(false)
  }

  function handleSearch(value, confirm, filedName) {
    value = typeof value === 'object' ? value[0] : value
    confirm()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = value
    setFilters(tempFilters)
  }

  function handleReset(clearFilters, filedName) {
    clearFilters()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = ''
    setFilters(tempFilters)
  }
  console.log(hypothetical_Ledger_Report)

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          autoFocus={true}
          value={selectedKeys[0]}
          placeholder={`Search ${dataIndex}`}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys[0], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, dataIndex)}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    sorter: (a, b) => a.age - b.age,
  })
  const filteredData = hypothetical_Ledger_Report.map(
    ({ isHypothetical, ...rest }) => rest
  )

  console.log(filteredData)
  const getColumnDateProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <RangePicker
          format={'DD-MMM-YYYY'}
          onChange={(e) => {
            const from = `fromDate`
            const to = `toDate`
            let tempFilters
            if (e) {
              confirm()
              tempFilters = JSON.parse(JSON.stringify(filters))
              tempFilters[from] = e[0]
              tempFilters[to] = e[1]
            } else {
              clearFilters()
              tempFilters = JSON.parse(JSON.stringify(filters))
              delete tempFilters[from]
              delete tempFilters[to]
            }
            setFilters(tempFilters)
          }}
        />
      </div>
    ),
  })
  const {
    state: { isMobile },
  } = useContext(AppContext)
  const columns = [
    {
      title: 'Entry Date',
      dataIndex: 'Entrydate',
      key: 'Entrydate',
      width: isMobile && 120,
      className: 'report-text',
      ...getColumnDateProps,
    },
    {
      title: 'Client Code',
      dataIndex: 'ClientCode',
      key: 'ClientCode',
      width: isMobile && 120,
      className: 'report-text',
      getColumnSearchProps,
    },

    {
      title: 'Debit Amount',
      dataIndex: 'DR_Amount',
      key: 'DR_Amount',
      className: 'report-numeric',
      render: (record) => convertNumberToCommaSeparate(record, true),
    },
    {
      title: 'Credit Amount',
      dataIndex: 'CR_Amount',
      key: 'CR_Amount',
      className: 'report-numeric',
      // render: (record) => console.log(record),
      render: (record) => convertNumberToCommaSeparate(record, true),
    },
    {
      title: 'Cum Amount',
      dataIndex: 'CumAmount',
      key: 'CumAmount',
      className: 'report-numeric',
      render: (record) => convertNumberToCommaSeparate(record, true),
    },
    {
      title: 'Remarks',
      dataIndex: 'remark',
      key: 'remark',
      className: 'report-numeric-remark',
    },
    {
      title: 'Strategy Code',
      dataIndex: 'StrategyCode',
      key: 'StrategyCode',
      width: isMobile && 150,
      className: 'report-text',
    },
    {
      title: 'Member Code',
      dataIndex: 'membercode',
      key: 'membercode',
      className: 'report-text',
    },
  ]
  const handleDownloadPdf = () => {
    client
      .query({
        query: GET_LEDGER_REPORT_DATA,
        variables: { where: { id: filters.id, isLedger: false } },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        get(res, 'data.getLedgerReport') &&
          window.open(get(res, 'data.getLedgerReport'), '_blank')
      })
      .catch((err) => console.log(err))
  }
  const content = (
    <div style={{ width: '100px' }}>
      <div
        onClick={handleDownloadPdf}
        style={{
          marginBottom: `${isMobile ? '0px' : '10px'}`,
          fontSize: '14px',
          fontWeight: '600',
          lineHeight: '24px',
          textAlign: 'left',
          color: ' #000',
          cursor: 'pointer',
        }}
      >
        <img src={PdfIcon} style={{ marginRight: '10px' }} alt='pdf' /> Pdf
      </div>

      <CSVLink
        style={{ paddingRight: 0 }}
        data={filteredData}
        className='csv-btn'
        filename={`LEDGER-Beta-report`}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          style={{
            marginBottom: `${isMobile ? '0px' : '10px'}`,
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '24px',
            textAlign: 'left',
            color: ' #000',
            cursor: 'pointer',
          }}
        >
          <img src={ExcelIcon} style={{ marginRight: '10px' }} alt='csv' />{' '}
          Excel
        </div>
      </CSVLink>
    </div>
  )

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Drawer
        title={null}
        headerStyle={{ display: 'none' }}
        closable={false}
        placement='right'
        onClose={onClose}
        visible={open}
        width={472}
      >
        <div
          style={{
            margin: '32px 0 50px 0',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <div onClick={onClose}>
            <img src={FilterBackButton} alt='FilterBackButton' />
          </div>
          <div
            style={{
              fontSize: '24px',
              fontWeight: '700',
              lineHeight: '28px',
              textAlign: 'left',
              color: '#000000',
              marginLeft: '16px',
            }}
          >
            Filter
          </div>
        </div>
        <Form
          form={form}
          onFinish={onFinish}
          name='advanced_search'
          style={{ marginTop: '16px' }}
          layout='vertical'
          className='client-detail-form'
        >
          <div
            style={{
              height: isMobile ? 'calc(100vh - 15rem)' : 'calc(100vh - 12rem)',
              overflowY: 'auto',
            }}
          >
            <Form.Item
              name='clientCode'
              label='Select Client Code'
              rules={[{ required: true, message: 'Please select clientCode!' }]}
              hasFeedback
            >
              <Select
                style={{ width: '100%' }}
                getPopupContainer={(trigger) => trigger.parentElement}
                className='display-field'
                showSearch
                loading={userLoading}
                placeholder='Select Client Code'
                optionFilterProp='children'
                filterOption={(input, option) =>
                  (option?.children[0] ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase()) ||
                  (option?.children[2] ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {!userLoading &&
                  allClient &&
                  allClient?.getClientUsersForStaff?.users.map((user, key) => {
                    return (
                      <Option
                        key={key}
                        value={JSON.stringify({
                          clientCode: user.clientCode,
                          id: user.id,
                        })}
                        title={getName(user)}
                      >
                        {user.clientCode}-{getName(user)}
                      </Option>
                    )
                  })}
              </Select>
            </Form.Item>
          </div>

          <div style={{ display: 'flex', gap: '20px', height: '56px' }}>
            <Button
              onClick={() => {
                form.resetFields()
                setFilters({})
                setOpen(false)
                // setData({})
              }}
              style={{
                width: '100%',
                borderRadius: '8px',
                padding: '16px',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              Cancel
            </Button>
            <Button
              loading={loading}
              type='primary'
              htmlType='submit'
              style={{
                width: '100%',
                borderRadius: '8px',
                padding: '16px',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              Search
            </Button>
          </div>
        </Form>
      </Drawer>
      <div className='report_Container'>
        <div className='container_Header'>
          <div className='report_Heading'>Ledger Beta Report</div>
          <div className='container_Export_Popover'>
            {role !== 'CLIENT' && data?.spIltsreport?.length ? (
              <Popover content={content} placement='bottom' trigger='click'>
                <div style={{ display: 'flex', gap: '10px' }}>
                  <Button>
                    Export All
                    <DownloadOutlined
                      color='#AF0000'
                      style={{ fontSize: '16px' }}
                    />
                  </Button>
                </div>
              </Popover>
            ) : (
              ''
            )}

            <Popover content={content} placement='bottom' trigger='click'>
              <div className='container_Export_Popover'>
                <Button disabled={loading}>
                  Export{' '}
                  <DownloadOutlined
                    color='#AF0000'
                    style={{ fontSize: '16px' }}
                  />
                </Button>
              </div>
            </Popover>

            {role !== 'CLIENT' && (
              <div className='container_Export_Popover'>
                <Button
                  style={{
                    color: '#000000',
                    border: '1px solid #919EAB52',
                    backgroundColor: 'white',
                  }}
                  onClick={showDrawer}
                >
                  Filter{' '}
                  <FilterOutlined
                    color='#000000'
                    style={{ fontSize: '16px' }}
                  />
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className='report_first_Container'>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className='Client_Info_Container'>
              {role === 'CLIENT' && (
                <div className='client_Info'>
                  Client Code:&nbsp;
                  <span>{clientCode}</span>
                </div>
              )}
              <div className='client_Info'>
                Client Name:&nbsp;
                <span>
                  {firstName} {lastName}
                </span>
              </div>
            </div>
          </div>
        </div>

        {role !== 'CLIENT' &&
        data?.spIltsreport &&
        hypothetical_Ledger_Report.length > 1 ? (
          <div className='report-table-wrapper' id='LEDGER-REPORT-PDF'>
            <HypotheticalLedgerTable
              reportData={hypothetical_Ledger_Report}
              columns={columns}
              loading={loading}
            />
          </div>
        ) : (
          ''
        )}
        {role === 'CLIENT' &&
        data?.spIltsreport &&
        hypothetical_Ledger_Report.length > 1 ? (
          <div className='report-table-wrapper' id='LEDGER-REPORT-PDF'>
            <HypotheticalLedgerTable
              reportData={hypothetical_Ledger_Report}
              columns={columns}
              loading={loading}
            />
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  )
}
