import React, { useState, useContext } from 'react'
import { Form, Input, Button, Upload, Tooltip, Row, Col, message } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { AppContext } from 'AppContext'
import pick from 'lodash/pick'
import get from 'lodash/get'
import client from 'apollo'
import { COMPLETE_STEP, UPDATE_KYC } from './graphql/Mutations'
import CustomMeta from 'components/CustomMeta'
import openNotification from '../../utils/Notification'
import {
  SEND_OTP_FOR_REEVALUATE,
  UPDATE_USER,
} from 'modules/Users/graphql/Mutations'
import VerificationModal from './Model/VerificationModal'
import KeyInfo from './KeyInfo'
import FileIcon from 'assets/images/new-images/file-icon.svg'

async function getFilesObject(tempFiles) {
  let files = pick(tempFiles, [
    'panHolderName',
    'panCard',
    'aadharCardFront',
    'passportSize',
    'signature',
    'aadharCardBack',
    'incomeProof',
    'bankStatements',
    'cancelledCheque',
  ])

  if (get(files, 'panCard[0].originFileObj')) {
    files.panCard = get(files, 'panCard[0].originFileObj')
  } else {
    delete files.panCard
  }
  if (get(files, 'aadharCardFront[0].originFileObj')) {
    files.aadharCard = get(files, 'aadharCardFront[0].originFileObj')
  } else {
    delete files.aadharCard
  }
  if (get(files, 'passportSize[0].originFileObj')) {
    files.passportSize = get(files, 'passportSize[0].originFileObj')
  } else {
    delete files.passportSize
  }
  if (get(files, 'signature[0].originFileObj')) {
    files.signature = get(files, 'signature[0].originFileObj')
  } else {
    delete files.signature
  }
  if (get(files, 'cancelledCheque[0].originFileObj')) {
    files.cancelledChequeSnap = get(files, 'cancelledCheque[0].originFileObj')
  } else {
    delete files.cancelledChequeSnap
  }
  if (get(files, 'bankStatements[0].originFileObj')) {
    files.bankStatement = get(files, 'bankStatements[0].originFileObj')
  } else {
    delete files.bankStatement
  }
  if (get(files, 'aadharCardBack[0].originFileObj')) {
    files.aadharCardBack = get(files, 'aadharCardBack[0].originFileObj')
  } else {
    delete files.aadharCardBack
  }
  if (get(files, 'incomeProof[0].originFileObj')) {
    files.incomeProof = get(files, 'incomeProof[0].originFileObj')
  } else {
    delete files.incomeProof
  }
  return files
}

const FormItem = Form.Item

const KycDocNew = ({ next, reEvaluate, newEdit }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [editAccess, setEditAccess] = useState(reEvaluate)
  const [otpModal, setOtpModal] = useState(false)
  const [fileList, setFileList] = useState([])
  const [fileUpload, setFileUpload] = useState(false)
  // const [files, setFile] = useState()
  let {
    dispatch,
    state: {
      currentUser: {
        panCardNo,
        firstName,
        lastName,
        id,
        kyc,
        cvlStatus,
        isReUploadKyc,
      },
      clientSummaryDetails,
    },
  } = useContext(AppContext)

  const normFile = (e) => {
    setFileList([])
    if (Array.isArray(e)) {
      return e
    }
    if (
      e.file.type === 'image/png' ||
      e.file.type === 'image/jpg' ||
      e.file.type === 'image/jpeg' ||
      e.file.type === 'application/pdf'
    ) {
      if (e.file.size / 1024 / 1024 > 1) {
        setFileList([])
        message.error(`Image must smaller than 1MB!`)
        return false
      } else {
        return e && e.fileList
      }
    } else if (e?.file?.status === 'removed') {
      setFileList([])
      message.success(`Document Removed Successfully`)
      return e && e.fileList
    } else {
      setFileList([])
      message.error(`Image Should be png/jpeg`)
      return false
    }
  }

  const customProps = (keyName) => ({
    multiple: false,
    onRemove: (file) => setFileList([]),
    onChange: async (info) => {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList)
      }

      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
      if (info.file.status !== 'removed') {
        dispatch({ type: 'IS_FILE_UPLOAD', data: true })
        setFileUpload(true)
        client
          .mutate({
            mutation: UPDATE_KYC,
            variables: { userId: parseInt(id), [keyName]: info?.file },
          })
          .then((res) => {
            message.success(`${info.file.name} file uploaded successfully`)
            setFileUpload(false)
            dispatch({ type: 'IS_FILE_UPLOAD', data: false })
          })
          .catch((err) => {
            console.log(err)
            setFileUpload(false)
            dispatch({ type: 'IS_FILE_UPLOAD', data: false })
          })
      }
    },
    beforeUpload: (file) => {
      setFileList(file)
      return false
    },
    fileList,
  })

  const editAccessFunc = () => {
    setEditAccess(false)
  }

  const onFinish = async (values) => {
    const files = await getFilesObject(values)
    if (editAccess) {
      onNext(files)
    } else if (reEvaluate) {
      setLoading(true)
      dispatch({ type: 'IS_ACCEPT_LOADING', data: true })
      setOtpModal(true)
      // setFile(values.incomeProof[0])
      client.mutate({ mutation: SEND_OTP_FOR_REEVALUATE }).then((res) => {
        openNotification('success', res?.data?.sendOtpForReEvaluate?.message)
      })
    } else {
      onNext(files)
    }
  }

  const onNext = (files) => {
    setLoading(true)
    dispatch({ type: 'IS_ACCEPT_LOADING', data: true })
    client
      .mutate({
        mutation: UPDATE_KYC,
        variables: {
          userId: parseInt(id),
          panHolderName: files?.panHolderName,
        },
      })
      .then((res) => {
        setLoading(true)
        dispatch({ type: 'IS_ACCEPT_LOADING', data: true })
        client
          .mutate({
            mutation: UPDATE_USER,
            variables: {
              id,
              data: {
                completedSteps:
                  cvlStatus && !isReUploadKyc ? 'KYC_FORM' : 'KYC_DOC',
              },
            },
          })
          .then((response) => {
            const currentIndex = clientSummaryDetails.findIndex(
              (item) => item?.name === 'Kyc Document'
            )
            const updateSummaryUiDetails = {
              name: 'Kyc Document',
              completedSteps: 'SUMMARY',
              isDisplayEdit: isReUploadKyc,
              data: {
                'Aadhaar Card Front': res?.data?.updateKyc?.aadharCard,
                'Aadhaar Card Back': res?.data?.updateKyc?.aadharCardBack,
                'Pan Card': res?.data?.updateKyc?.panCard,
                'Bank Statement': res?.data?.updateKyc?.bankStatement,
                'Cancelled Cheque': res?.data?.updateKyc?.cancelledChequeSnap,
                Signature: res?.data?.updateKyc?.signature,
                'Passport Size Photo': res?.data?.updateKyc?.passportSize,
                'Income Proof': res?.data?.updateKyc?.incomeProof,
              },
            }
            if (currentIndex !== -1) {
              clientSummaryDetails[currentIndex] = updateSummaryUiDetails
            } else {
              clientSummaryDetails.push(updateSummaryUiDetails)
            }
            dispatch({
              type: 'CLIENT_SUMMARY_DETAILS',
              data: clientSummaryDetails,
            })
            client.mutate({
              mutation: COMPLETE_STEP,
              variables: {
                data: {
                  step: 'KYC_DOC',
                  from: 'WEB',
                },
              },
            })
            dispatch({
              type: 'CURRENT_USER',
              data: response.data.updateUser,
            })
            openNotification('success', 'Kyc Document Updated Successfully')
            setLoading(false)
            dispatch({ type: 'IS_ACCEPT_LOADING', data: false })
            if (cvlStatus && !isReUploadKyc) {
              next(2)
            } else {
              next()
            }
          })
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
        dispatch({ type: 'IS_ACCEPT_LOADING', data: false })
      })
      .finally(() => {
        setLoading(false)
        dispatch({ type: 'IS_ACCEPT_LOADING', data: false })
      })
  }

  let obj = {}

  if (kyc?.aadharCard) {
    obj['aadharCardFront'] = [
      {
        uid: `aadharCardFront ${kyc?.id}`,
        name: 'aadhar.png',
        url: kyc?.aadharCard,
      },
    ]
  }
  if (kyc?.aadharCardBack) {
    obj['aadharCardBack'] = [
      {
        uid: `aadharCardBack ${kyc?.id}`,
        name: 'aadhar.png',
        url: kyc?.aadharCardBack,
      },
    ]
  }
  if (kyc?.panCard) {
    obj['panCard'] = [
      {
        uid: `panCard ${kyc?.id}`,
        name: 'panCard.png',
        url: kyc?.panCard,
      },
    ]
  }
  if (kyc?.bankStatement) {
    obj['bankStatements'] = [
      {
        uid: `bankStatements ${kyc?.id}`,
        name: 'bankStatement.png',
        url: kyc?.bankStatement,
      },
    ]
  }
  if (kyc?.cancelledChequeSnap) {
    obj['cancelledCheque'] = [
      {
        uid: `cancelledCheque ${kyc?.id}`,
        name: 'cancelledChequeSnap.png',
        url: kyc?.cancelledChequeSnap[0],
      },
    ]
  }
  if (kyc?.signature) {
    obj['signature'] = [
      {
        uid: `signature ${kyc?.id}`,
        name: 'signature.png',
        url: kyc?.signature,
      },
    ]
  }
  if (kyc?.passportSize) {
    obj['passportSize'] = [
      {
        uid: `passportSize ${kyc?.id}`,
        name: 'passportSize.png',
        url: kyc?.passportSize,
      },
    ]
  }
  if (kyc?.incomeProof) {
    obj['incomeProof'] = [
      {
        uid: `incomeProof ${kyc?.id}`,
        name: 'incomeProof.png',
        url: kyc?.incomeProof,
      },
    ]
  }

  const text = (
    <span>
      ITR Acknowledgement,
      <br />
      Salary slip (3 months latest),
      <br />
      Form-16,
      <br />
      Net worth certificate
    </span>
  )

  return (
    <>
      <div>
        <Row className='row-style'>
          <Col lg={16} sm={24} className='col-left risk-profile-col'>
            <div>
              <CustomMeta title='Risk Profile' />
              <div
                className='gx-app-login-wrap'
                style={{ overflowX: 'hidden', textAlign: 'start' }}
              >
                <CustomMeta title='Kyc Doc' />
                <div className='form-outer-div'>
                  <Row>
                    <Col span={21} style={{ textAlign: 'center' }}></Col>
                    <Col span={3} style={{ textAlign: 'end' }}>
                      {editAccess && (
                        <Button type='primary' onClick={editAccessFunc}>
                          Edit
                        </Button>
                      )}
                    </Col>
                  </Row>
                  <Form
                    form={form}
                    name='register'
                    labelAlign='left'
                    onFinish={onFinish}
                    scrollToFirstError
                    initialValues={{
                      panCardNo: panCardNo || '',
                      panHolderName: `${firstName} ${lastName}` || '',
                      ...obj,
                    }}
                    layout='vertical'
                    className='client-detail-form'
                  >
                    <Row className='client-profile-row'>
                      {!cvlStatus || isReUploadKyc ? (
                        <>
                          {/* <FormItem
                          name='panCardNo'
                          label='PAN Card Number'
                          rules={[
                            {
                              required: true,
                              message: 'Please input your valid pan number!',
                            },
                          ]}
                        >
                          <Input
                            placeholder='PAN Card Number'
                            autoFocus={true}
                            style={{ width: '100%' }}
                            autoComplete='off'
                            className='client-detail-input'
                            disabled
                          />
                        </FormItem> */}
                          <Col
                            className='gutter-row client-column'
                            lg={12}
                            sm={24}
                          >
                            <FormItem
                              name='panHolderName'
                              label='Full Name (As per PAN Card)'
                              rules={[
                                {
                                  required: true,
                                  message: 'PAN Holder Name is required',
                                },
                              ]}
                            >
                              <Input
                                placeholder='PAN Holder Name'
                                autoFocus={true}
                                style={{ width: '100%', margin: '1px' }}
                                autoComplete='off'
                                disabled={!editAccess ? false : true}
                                className='client-detail-input'
                              />
                            </FormItem>
                          </Col>

                          <Col
                            className='gutter-row client-column'
                            lg={12}
                            sm={24}
                          >
                            <FormItem
                              name='aadharCardFront'
                              label='Aadhaar Card Front'
                              valuePropName='fileList'
                              getValueFromEvent={normFile}
                              rules={[
                                {
                                  required: true,
                                  message: 'Aadhaar Card Front is required',
                                },
                              ]}
                              className='upload-document'
                            >
                              <Upload
                                name='aadharCardFront-file'
                                listType='picture'
                                accept='.pdf,.png,.jpg,.jpeg'
                                {...customProps('aadharCard')}
                                className='upload-document-btn'
                              >
                                <Button
                                  disabled={!editAccess ? false : true}
                                  className='upload-doc-btn'
                                >
                                  <img
                                    src={FileIcon}
                                    alt='FileIcon'
                                    style={{ marginRight: '8px' }}
                                  />{' '}
                                  Click or drag a file here to upload
                                </Button>
                              </Upload>
                            </FormItem>
                          </Col>

                          <Col
                            className='gutter-row client-column'
                            lg={12}
                            sm={24}
                          >
                            <FormItem
                              name='aadharCardBack'
                              label='Aadhar Card Back'
                              valuePropName='fileList'
                              getValueFromEvent={normFile}
                              rules={[
                                {
                                  required: true,
                                  message: 'Aadhaar Card Back is required',
                                },
                              ]}
                              className='upload-document'
                            >
                              <Upload
                                name='aadharCardBack-file'
                                listType='picture'
                                {...customProps('aadharCardBack')}
                                accept='.pdf,.png,.jpg,.jpeg'
                                className='upload-document-btn'
                              >
                                <Button
                                  disabled={!editAccess ? false : true}
                                  className='upload-doc-btn'
                                >
                                  <img
                                    src={FileIcon}
                                    alt='FileIcon'
                                    style={{ marginRight: '8px' }}
                                  />{' '}
                                  Click or drag a file here to upload
                                </Button>
                              </Upload>
                            </FormItem>
                          </Col>

                          <Col
                            className='gutter-row client-column'
                            lg={12}
                            sm={24}
                          >
                            <FormItem
                              name='panCard'
                              label='PAN Card'
                              valuePropName='fileList'
                              getValueFromEvent={normFile}
                              rules={[
                                {
                                  required: true,
                                  message: 'PAN Card is required',
                                },
                              ]}
                              className='upload-document'
                            >
                              <Upload
                                name='panCard-file'
                                listType='picture'
                                {...customProps('panCard')}
                                accept='.pdf,.png,.jpg,.jpeg'
                                className='upload-document-btn'
                              >
                                <Button
                                  disabled={!editAccess ? false : true}
                                  className='upload-doc-btn'
                                >
                                  <img
                                    src={FileIcon}
                                    alt='FileIcon'
                                    style={{ marginRight: '8px' }}
                                  />{' '}
                                  Click or drag a file here to upload
                                </Button>
                              </Upload>
                            </FormItem>
                          </Col>

                          <Col
                            className='gutter-row client-column'
                            lg={12}
                            sm={24}
                          >
                            <FormItem
                              name='bankStatements'
                              label='Last 3 Months Bank Statements'
                              valuePropName='fileList'
                              getValueFromEvent={normFile}
                              rules={[
                                {
                                  required: true,
                                  message:
                                    'Last 3 Months Bank Statements is required',
                                },
                              ]}
                              className='upload-document'
                            >
                              <Upload
                                name='bankStatement-file'
                                listType='picture'
                                {...customProps('bankStatement')}
                                accept='.pdf,.png,.jpg,.jpeg'
                                className='upload-document-btn'
                              >
                                <Button
                                  disabled={!editAccess ? false : true}
                                  className='upload-doc-btn'
                                >
                                  <img
                                    src={FileIcon}
                                    alt='FileIcon'
                                    style={{ marginRight: '8px' }}
                                  />{' '}
                                  Click or drag a file here to upload
                                </Button>
                              </Upload>
                            </FormItem>
                          </Col>

                          <Col
                            className='gutter-row client-column'
                            lg={12}
                            sm={24}
                          >
                            <FormItem
                              name='cancelledCheque'
                              label='Cancelled Cheque'
                              valuePropName='fileList'
                              getValueFromEvent={normFile}
                              rules={[
                                {
                                  required: true,
                                  message: 'Cancelled Cheque is required',
                                },
                              ]}
                              className='upload-document'
                            >
                              <Upload
                                name='cancelledCheque-file'
                                listType='picture'
                                {...customProps('cancelledChequeSnap')}
                                accept='.pdf,.png,.jpg,.jpeg'
                                className='upload-document-btn'
                              >
                                <Button
                                  disabled={!editAccess ? false : true}
                                  className='upload-doc-btn'
                                >
                                  <img
                                    src={FileIcon}
                                    alt='FileIcon'
                                    style={{ marginRight: '8px' }}
                                  />{' '}
                                  Click or drag a file here to upload
                                </Button>
                              </Upload>
                            </FormItem>
                          </Col>

                          <Col
                            className='gutter-row client-column'
                            lg={12}
                            sm={24}
                          >
                            <FormItem
                              name='signature'
                              label='Signature'
                              valuePropName='fileList'
                              getValueFromEvent={normFile}
                              rules={[
                                {
                                  required: true,
                                  message: 'Signature is required',
                                },
                              ]}
                              className='upload-document'
                            >
                              <Upload
                                name='signature-file'
                                listType='picture'
                                {...customProps('signature')}
                                accept='.jpeg,.png,.jpg'
                                className='upload-document-btn'
                              >
                                <Button
                                  disabled={!editAccess ? false : true}
                                  className='upload-doc-btn'
                                >
                                  <img
                                    src={FileIcon}
                                    alt='FileIcon'
                                    style={{ marginRight: '8px' }}
                                  />{' '}
                                  Click or drag a file here to upload
                                </Button>
                              </Upload>
                            </FormItem>
                          </Col>

                          <Col
                            className='gutter-row client-column'
                            lg={12}
                            sm={24}
                          >
                            <FormItem
                              name='passportSize'
                              label='Passport Size Photo'
                              valuePropName='fileList'
                              getValueFromEvent={normFile}
                              rules={[
                                {
                                  required: true,
                                  message: 'Passport Size Photo is required',
                                },
                              ]}
                              className='upload-document'
                            >
                              <Upload
                                name='passportSize-file'
                                listType='picture'
                                {...customProps('passportSize')}
                                accept='.jpeg,.png,.jpg'
                                className='upload-document-btn'
                              >
                                <Button
                                  disabled={!editAccess ? false : true}
                                  className='upload-doc-btn'
                                >
                                  <img
                                    src={FileIcon}
                                    alt='FileIcon'
                                    style={{ marginRight: '8px' }}
                                  />{' '}
                                  Click or drag a file here to upload
                                </Button>
                              </Upload>
                            </FormItem>
                          </Col>

                          <Col
                            className='gutter-row client-column'
                            lg={12}
                            sm={24}
                          >
                            <FormItem
                              name='incomeProof'
                              label={
                                <span>
                                  Income Proof&nbsp;{' '}
                                  <Tooltip title={text}>
                                    <QuestionCircleOutlined
                                      style={{ cursor: 'pointer' }}
                                    />
                                  </Tooltip>
                                </span>
                              }
                              valuePropName='fileList'
                              getValueFromEvent={normFile}
                              className='upload-document'
                            >
                              <Upload
                                name='incomeProof-file'
                                listType='picture'
                                accept='.pdf,.png,.jpg,.jpeg'
                                {...customProps('incomeProof')}
                                className='upload-document-btn'
                              >
                                <Button
                                  disabled={!editAccess ? false : true}
                                  className='upload-doc-btn'
                                >
                                  <img
                                    src={FileIcon}
                                    alt='FileIcon'
                                    style={{ marginRight: '8px' }}
                                  />{' '}
                                  Click or drag a file here to upload
                                </Button>
                              </Upload>
                            </FormItem>
                          </Col>
                        </>
                      ) : (
                        <Col
                          className='gutter-row client-column'
                          lg={12}
                          sm={24}
                        >
                          <FormItem
                            name='incomeProof'
                            label={
                              <span>
                                Income Proof&nbsp;{' '}
                                <Tooltip title={text}>
                                  <QuestionCircleOutlined
                                    style={{ cursor: 'pointer' }}
                                  />
                                </Tooltip>
                              </span>
                            }
                            valuePropName='fileList'
                            getValueFromEvent={normFile}
                            className='upload-document'
                          >
                            <Upload
                              name='incomeProof-file'
                              listType='picture'
                              accept='.pdf,.png,.jpg,.jpeg'
                              {...customProps('incomeProof')}
                              className='upload-document-btn'
                            >
                              <Button
                                disabled={!editAccess ? false : true}
                                className='upload-doc-btn'
                              >
                                <img
                                  src={FileIcon}
                                  alt='FileIcon'
                                  style={{ marginRight: '8px' }}
                                />{' '}
                                Click or drag a file here to upload
                              </Button>
                            </Upload>
                          </FormItem>
                        </Col>
                      )}
                    </Row>
                    <div
                      className='verify_next_button'
                      style={{
                        marginRight: '18px',
                      }}
                    >
                      <Button
                        // id='btn-click'
                        disabled={fileUpload}
                        className='yes-next-button'
                        type='primary'
                        loading={loading}
                        htmlType='submit'
                        // disabled={!agree}
                      >
                        Next
                      </Button>
                    </div>
                  </Form>
                  {otpModal && (
                    <VerificationModal visible={otpModal} onNext={onNext} />
                  )}
                </div>
              </div>
            </div>
          </Col>
          <Col lg={8} sm={24} className='col-right risk-profile-col'>
            <KeyInfo newEdit={newEdit} />
          </Col>
        </Row>
      </div>
    </>
  )
}

export default KycDocNew
