import React, { useState, useContext, Fragment } from 'react'
import {
  Table,
  Button,
  Input,
  Space,
  Tag,
  DatePicker,
  Select,
  Modal,
} from 'antd'
import {
  LinkOutlined,
  SearchOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import { useQuery } from '@apollo/react-hooks'
import { NavLink } from 'react-router-dom'
import get from 'lodash/get'
import moment from 'moment'
import { AppContext } from 'AppContext'
import Page404 from 'components/Page404'
import { GET_TICKET } from '../graphql/Queries'
import { getName, getUserNavLinkByObject } from 'utils/User'
import { GET_USERS } from 'modules/Users/graphql/Queries'
import client from 'apollo'
import CloseTicketModal from '../CloseTicketModal'
import { UPDATE_TICKET } from '../graphql/Mutations'
import openNotification from 'utils/Notification'
import { orderBy } from 'lodash'

const { RangePicker } = DatePicker
const { Option } = Select
const { confirm } = Modal

const userField = [
  { text: 'OPEN', value: 'OPEN' },
  { text: 'PENDING', value: 'PENDING' },
  { text: 'CLOSED', value: 'CLOSED' },
]

export default function TabTicketsNew({
  defaultFilters = {},
  setSort,
  setSkip,
  setLimit,
  setFilters,
  limit,
  filters,
  loading,
  error,
  tableData,
  totalCount,
}) {
  const [currentPage, setCurrentPage] = useState(1)
  const [showFormModal, setShowFormModal] = useState(false)
  const [ticketId, setTicketId] = useState(0)
  const {
    state: { isClient, isAssociate, isAdmin, currentUser },
  } = useContext(AppContext)

  let {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useQuery(GET_USERS, {
    variables: {
      where: { role: ['STAFF', 'SALES_EXECUTIVE'], isActive: 'true' },
      limit: 1000,
    },
  })

  if (error || userError) return <Page404 error={error} />

  if (!userLoading) {
    userData = get(userData, 'getUsers.users')
  }

  function handlePagination(page) {
    setSkip((page - 1) * 10)
    setCurrentPage(page)
  }

  function handlePageSizeChange(current, size) {
    setLimit(size)
  }

  function handleSearch(value, confirm, filedName) {
    value = typeof value === 'object' ? value[0] : value
    confirm()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = value
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  function handleReset(clearFilters, filedName) {
    clearFilters()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    if (tempFilters[filedName]) {
      delete tempFilters[filedName]
    }
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  function handleTableChange(pagination, filter, sorter) {
    if (sorter && sorter.field && sorter.order) {
      if (sorter.order === 'descend') {
        setSort({
          field: sorter.field,
          sortingOrder: 'DESC',
        })
        if (filter.status) {
          const tempFilters = JSON.parse(JSON.stringify(filters))
          tempFilters['status'] = filter.status
          setFilters(tempFilters)
        }
        setCurrentPage(1)
      } else {
        setSort({
          field: sorter.field,
          sortingOrder: 'ASC',
        })
        if (filter.status) {
          const tempFilters = JSON.parse(JSON.stringify(filters))
          tempFilters['status'] = filter.status
          setFilters(tempFilters)
        }
        setCurrentPage(1)
      }
    } else if (filter.status) {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['status'] = filter.status
      setFilters(tempFilters)
      setCurrentPage(1)
    } else {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['status'] = ''
      delete tempFilters.status
      setFilters(tempFilters)
      setCurrentPage(1)
    }
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          autoFocus={true}
          value={selectedKeys[0]}
          placeholder={`Search ${dataIndex}`}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys[0], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, dataIndex)}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
  })

  const getColumnDateProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <RangePicker
          format={'DD-MMM-YYYY'}
          onChange={(e) => {
            const from = `from${dataIndex}Date`
            const to = `to${dataIndex}Date`
            let tempFilters
            if (e) {
              confirm()
              tempFilters = JSON.parse(JSON.stringify(filters))
              tempFilters[from] = moment(e[0]).format('YYYY-MM-DD')
              tempFilters[to] = moment(e[1]).format('YYYY-MM-DD')
            } else {
              clearFilters()
              tempFilters = JSON.parse(JSON.stringify(filters))
              delete tempFilters[from]
              delete tempFilters[to]
            }
            setFilters(tempFilters)
            setCurrentPage(1)
          }}
        />
      </div>
    ),
  })
  const assignTicket = (id, data) => {
    const ticketId = data?.id
    client
      .mutate({
        mutation: UPDATE_TICKET,
        variables: { data: { assignUserId: parseInt(id) }, id: ticketId },
      })
      .then((res) => {
        openNotification('success', 'Ticket Assigned Successfully')
      })
      .catch((err) => console.log({ err }))
  }
  const assignUser = (data, record) => {
    if (
      isAdmin &&
      (record?.status === 'OPEN' || record?.status === 'PENDING')
    ) {
      return (
        <Select
          getPopupContainer={(trigger) => trigger.parentElement}
          defaultValue={
            record?.assignUser
              ? `${record?.assignUser?.firstName} ${record?.assignUser?.lastName}`
              : '-'
          }
          loading={userLoading}
          style={{
            width: 120,
          }}
          showSearch
          filterOption={(input, option) =>
            (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
          }
          onSearch={() => console.log('onSearch')}
          onChange={(e) => assignTicket(e, record)}
        >
          {orderBy(
            userData?.map((user) => {
              return { ...user, firstName: user?.firstName?.toLowerCase() }
            }),
            'firstName',
            'asc'
          )?.map((user) => {
            return <Option value={user?.id}>{getName(user)}</Option>
          })}
        </Select>
      )
    } else {
      return isAssociate
        ? getName(data)
        : getUserNavLinkByObject(data, isClient)
    }
  }
  function showConfirm(text, status, id) {
    confirm({
      title: `Do you want to ${text} this ticket?`,
      icon: <ExclamationCircleOutlined />,
      content: `When clicked the OK button, this ticket will be ${text}`,
      onOk() {
        client
          .mutate({
            mutation: UPDATE_TICKET,
            variables: { data: { status }, id },
            refetchQueries: [{ query: GET_TICKET, variables: { id } }],
          })
          .then((res) =>
            openNotification('success', 'Ticket Opened Successfully')
          )
          .catch((err) => console.log(err))
      },
    })
  }

  function closeTicketModal(id) {
    setTicketId(id)
    setShowFormModal(true)
  }

  const changeStatus = (record, ticket) => {
    switch (ticket.status) {
      case 'PENDING':
        return (
          <Button
            key='1'
            type='primary'
            onClick={() => showConfirm('open', 'OPEN', ticket?.id)}
          >
            Open
          </Button>
        )
      case 'OPEN':
        return (
          <Button
            key='1'
            type='primary'
            onClick={() => closeTicketModal(ticket?.id)}
          >
            Close
          </Button>
        )
      default:
        return <Fragment />
    }
  }

  function handleCloseTicket(values, resetForm) {
    const { reason } = values
    const data = { reason, status: 'CLOSED', closeById: currentUser.id }
    client
      .mutate({
        mutation: UPDATE_TICKET,
        variables: { data, id: ticketId },
        refetchQueries: [
          {
            query: GET_TICKET,
            variables: { id: ticketId },
            fetchPolicy: 'network-only',
          },
        ],
      })
      .then((res) => {
        openNotification('success', 'Ticket Closed Successfully')
        setShowFormModal(false)
        resetForm()
      })
      .catch((err) => console.log(err))
  }

  let columns = [
    {
      title: 'Ticket No',
      dataIndex: 'ticketNumber',
      key: 'ticketNumber',
      render: (text, record) => (
        <NavLink to={`/tickets/${record.id}`}>{text}</NavLink>
      ),
      ...getColumnSearchProps('ticketNumber'),
      sorter: (a, b) => a.age - b.age,
      align: 'center',
      width: 150,
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      sorter: (a, b) => a.age - b.age,
      ...getColumnSearchProps('subject'),
      align: 'center',
      width: 150,
    },
    {
      title: 'Users',
      dataIndex: 'generatedBy',
      key: 'generatedBy',
      render: (user) =>
        isAssociate ? getName(user) : getUserNavLinkByObject(user, isClient),
      align: 'center',
      width: 150,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      align: 'center',
      width: 150,
    },
    {
      title: 'Assigned To',
      dataIndex: 'assignUser',
      key: 'assignUser',
      render: (user, record) => assignUser(user, record),
      ...getColumnSearchProps('assignUser'),
      align: 'center',
      width: 150,
    },
    {
      title: 'Attachment',
      dataIndex: 'fileUrl',
      key: 'fileUrl',
      render: (text, record) =>
        record?.fileUrl
          ? text?.map((url, index) => {
              return (
                <>
                  <span>
                    {`Attachment ${index + 1}:`}&nbsp;&nbsp;
                    <a href={`${url}`}>
                      <LinkOutlined />
                    </a>
                  </span>
                  <br />
                </>
              )
            })
          : '-',
      width: 150,
      align: 'center',
    },
    {
      title: 'Closed By',
      dataIndex: 'closedByUser',
      key: 'closedByUser',
      render: (record) => (record ? getName(record) : '-'),

      ...getColumnSearchProps('closedByUser'),
      sorter: (a, b) => a.age - b.age,
      align: 'center',
      width: 150,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={`${status === 'CLOSED' ? 'green' : 'blue'}`}>
          {status === 'CLOSED' ? 'Closed' : 'In Progress'}
        </Tag>
      ),
      filters: userField,
      width: 150,
      sorter: (a, b) => a.age - b.age,
      align: 'center',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date) => moment(date).format('LLL'),
      ...getColumnDateProps('CreatedAt'),
      sorter: (a, b) => a.age - b.age,
      width: 150,
      align: 'center',
    },
    {
      title: 'Closed At',
      dataIndex: 'closedDate',
      key: 'closedDate',
      render: (date) => (date ? moment(date).format('LLL') : '-'),
      ...getColumnDateProps('Closed'),
      sorter: (a, b) => a.age - b.age,
      width: 150,
      align: 'center',
    },
  ]
  if (!isClient) {
    columns.push({
      key: 'ticketStatus',
      render: (user, record) => changeStatus(user, record),
    })
  }

  return (
    <>
      {!isClient && showFormModal && (
        <CloseTicketModal
          visible={showFormModal}
          onCreate={handleCloseTicket}
          onCancel={() => setShowFormModal(false)}
        />
      )}
      <Table
        className='tickets-table'
        loading={loading}
        dataSource={tableData}
        onChange={handleTableChange}
        scroll={{ x: true, y: true }}
        columns={
          isClient
            ? columns.filter((column) => column.title !== 'Client')
            : columns
        }
        pagination={{
          pageSize: limit,
          total: totalCount,
          onChange: handlePagination,
          current: currentPage,
          onShowSizeChange: handlePageSizeChange,
        }}
      />
    </>
  )
}
