import React, { useContext, useEffect, useState } from 'react'
import { Button, Select } from 'antd'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import Container from './Container'
import CreateSectionModal from './CreateSectionModal'
import { useQuery } from '@apollo/react-hooks'
import { CREATE_SECTION } from './graphql/Mutation'
import { GET_PLANS } from 'modules/Plans/graphql/Query'
import { GET_SECTIONS } from 'modules/Verification/graphql/Queries'
import Loader from 'components/loaders/Loader'
import Page404 from 'components/Page404'
import openNotification from 'utils/Notification'
import client from 'apollo'
import { AppContext } from 'AppContext'

const { Option } = Select

const Questionnaires = () => {
  const [showFormModal, setShowFormModal] = useState(false)
  const [planValue, setPlanValue] = useState(null)
  const [userType, setUserType] = useState(false)

  const { data, loading, error } = useQuery(GET_PLANS)
  const {
    state: { isMobile },
  } = useContext(AppContext)

  useEffect(() => {
    if (!loading && data && !planValue) {
      const planId = data?.getPlans[0]?.id
      // const planId = data?.getPlans.find(plan => plan.name.toString() === 'Basic Plan')?.id
      setPlanValue(parseInt(planId))
    }
  }, [data, loading, planValue])

  if (loading || !planValue) return <Loader />

  if (error) return <Page404 error={error} />
  const planName = data?.getPlans.find(
    (plan) => parseInt(plan.id) === planValue
  )?.name

  function handleHideFormModal() {
    setShowFormModal(false)
  }

  function handleCreateSection(values, resetForm) {
    setUserType(undefined)
    client.query({ query: GET_SECTIONS }).then((res) => {
      const data = {
        ...values,
        forNonIndividual: userType,
        index: res.data.getSections.length + 1,
      }

      client
        .mutate({
          mutation: CREATE_SECTION,
          variables: { data },
        })
        .then((res) => {
          setUserType(userType)
          openNotification('success', 'Section Added Successfully')
          handleHideFormModal()
          resetForm()
        })
        .catch((err) => console.log(err))
    })
  }

  function handleChange(value) {
    setPlanValue(value)
  }

  function handleChangeForUserType(value) {
    setUserType(value)
  }

  return (
    <>
      <div className='main_dashboard_div'>
        <div
          style={{
            display: 'flex',
            flexWrap: isMobile ? 'wrap' : 'nowrap',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
          }}
        >
          <div
            style={{
              fontSize: isMobile ? '18px' : '24px',
              fontWeight: '600',
              lineHeight: '26px',
              letterSpacing: '-0.02em',
              textAlign: 'left',
              color: '#181C32',
            }}
          >
            Questionnaires
          </div>
          <Button
            onClick={() => setShowFormModal(true)}
            style={{
              margin: '0',
              padding: isMobile ? '4px 10px' : '8px 16px',
              fontSize: '14px',
              fontWeight: '600',
              lineHeight: '24px',
              textAlign: 'left',
              color: '#AF0000',
              background: '#AF00000D',
              border: '1px solid #AF000080',
              height: isMobile ? '35px' : '40px',
              borderRadius: '8px',
            }}
          >
            Add Section
          </Button>
        </div>
        <div style={{ marginBottom: '15px' }}>
          <Select
          getPopupContainer={(trigger) => trigger.parentElement}
            value={planValue}
            style={{ width: 120 }}
            onChange={handleChange}
          >
            {data &&
              data?.getPlans.map((plan, index) => {
                return (
                  <Option value={parseInt(plan.id)} key={index}>
                    {plan.name}
                  </Option>
                )
              })}
          </Select>
          &nbsp;&nbsp;
          <Select getPopupContainer={(trigger) => trigger.parentElement} value={userType} onChange={handleChangeForUserType}>
            <Option value={false}>Individual</Option>
            <Option value={true}>Non-Individual</Option>
          </Select>
        </div>
        <DndProvider backend={HTML5Backend}>
          <Container
            planId={planValue}
            planName={planName}
            userType={userType}
            setUserType={setUserType}
          />
        </DndProvider>
        {showFormModal && (
          <CreateSectionModal
            visible={showFormModal}
            onCreate={handleCreateSection}
            onCancel={() => handleHideFormModal()}
          />
        )}
      </div>
    </>
  )
}

export default Questionnaires
