import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Button, Modal, Form, Checkbox, Divider } from 'antd'
import {
  GET_DEFAULT_ADMIN_FIELD_SELECTION,
  GET_FIELD_SELECTION,
} from 'modules/Groups/graphql/Queries'
import client from 'apollo'
import { AppContext } from 'AppContext'
import Loader from 'components/loaders/Loader'
import { USER_FIELD_SELECTION } from 'modules/Settings/graphql/Mutations'

function SelectedFieldsModal({ onCancel }) {
  const [loading, setLoading] = useState(false)
  const [selectAllCheck, setSelectAllCheck] = useState()
  const [adminAllowedFields, setAdminAllowedFields] = useState({})
  const [userAllowedFields, setUserAllowedFields] = useState({})

  const {
    state: { currentUser },
  } = useContext(AppContext)

  const getFieldData = useCallback(() => {
    client
      .query({
        query: GET_DEFAULT_ADMIN_FIELD_SELECTION,
        variables: {
          data: {
            role: currentUser?.role,
          },
        },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        // setLoading(false)
        const allowedFields = res?.data?.getAllDefaultFieldSelection
        if (allowedFields[0]) {
          setAdminAllowedFields(allowedFields[0])
        }
      })
      .catch((err) => console.log(err))
  }, [currentUser])

  useEffect(() => {
    getFieldData()
  }, [getFieldData])

  useEffect(() => {
    setLoading(true)
    client
      .query({
        query: GET_FIELD_SELECTION,
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        for (const key in res?.data?.getFieldSelection) {
          setUserAllowedFields((prevStep) => ({
            ...prevStep,
            [key]: res?.data?.getFieldSelection[key],
          }))
        }
        setLoading(false)
      })
      .catch((err) => console.log(err))
  }, [currentUser])

  const updateSelectedFieldModal = (e, key, data) => {
    setSelectAllCheck(false)
    setUserAllowedFields({
      ...userAllowedFields,
      [key]: e.target.checked,
    })
  }

  const handleOk = () => {
    setLoading(true)
    client
      .mutate({
        mutation: USER_FIELD_SELECTION,
        variables: { data: userAllowedFields },
      })
      .then((res) => {
        setLoading(false)
        onCancel()
      })
      .catch((err) => console.log(err))
  }

  const selectAll = (value) => {
    setSelectAllCheck(value)
    for (const item in userAllowedFields) {
      setUserAllowedFields((prevStep) => ({
        ...prevStep,
        [item]: value,
      }))
    }
  }

  return (
    <Modal
      className='feedback_form'
      title='Select Field'
      visible
      onCancel={onCancel}
      footer={[
        <Button
          key='submit'
          type='primary'
          onClick={() => {
            handleOk()
          }}
        >
          Save
        </Button>,
        <Button key='Reset' onClick={(e) => selectAll(true)}>
          Reset
        </Button>,
        <Button key='cancel' onClick={onCancel}>
          Cancel
        </Button>,
      ]}
    >
      {
        <Form
          initialValues={{
            role: currentUser.role,
          }}
        >
          {!loading ? (
            <Checkbox
              style={{ display: 'flex', justifyContent: 'flex-start' }}
              onChange={(e) => selectAll(e.target.checked)}
              name='selectAllcheck'
              checked={selectAllCheck}
            >
              ---SELECT ALL---
            </Checkbox>
          ) : (
            ''
          )}
          <Divider />
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {!loading ? (
              Object.keys(adminAllowedFields).map((key) => {
                if (
                  adminAllowedFields[key] &&
                  key !== 'id' &&
                  key !== 'role' &&
                  key !== 'action' &&
                  key !== '__typename'
                ) {
                  return (
                    <div style={{ width: '50%' }} key={key}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <Checkbox
                          checked={
                            selectAllCheck ? true : userAllowedFields[key]
                          }
                          onChange={(e) => updateSelectedFieldModal(e, key)}
                          loading={loading}
                          style={{ textTransform: 'capitalize' }}
                        >
                          {key.split(/(?=[A-Z])/).join(' ')}{' '}
                        </Checkbox>
                      </div>
                    </div>
                  )
                }
                return null
              })
            ) : (
              <Loader />
            )}
          </div>

          <div className='footer-btn-div'>
            <Button
              className='footer-submit-btn'
              onClick={() => handleOk()}
              type='primary'
            >
              Save
            </Button>
            <Button
              className='footer-cancel-btn'
              onClick={(e) => selectAll(true)}
            >
              {' '}
              Reset{' '}
            </Button>
            <Button className='footer-cancel-btn' onClick={onCancel}>
              {' '}
              Cancel{' '}
            </Button>
          </div>
        </Form>
      }
    </Modal>
  )
}

export default SelectedFieldsModal
