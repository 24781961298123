import React, { useContext, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons'
import { Button, DatePicker, Input, Space, Table } from 'antd'
import moment from 'moment'

import Page404 from 'components/Page404'
import client from 'apollo'
import { getName, transactionStatus } from 'utils/User'
import { GET_ALL_TRANSACTIONS } from './Graphql/Queries'
import { get, isEmpty } from 'lodash'
import { CUSTOM_CSV_DOWNLOAD } from 'modules/Reports/graphql/Queries'
import { AppContext } from 'AppContext'

const { RangePicker } = DatePicker

const Transactions = () => {
  const {
    state: {
      isClient,
      currentUser: { id },
    },
  } = useContext(AppContext)

  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [filters, setFilters] = useState(
    isClient ? { userId: parseInt(id) } : {}
  )
  const [sort, setSort] = useState()
  const [CSVLoading, setCSVLoading] = useState(false)

  const { data, loading, error } = useQuery(GET_ALL_TRANSACTIONS, {
    variables: { where: filters, sort, limit, skip },
    fetchPolicy: 'network-only',
  })

  let totalCount = 0
  if (!loading && get(data, 'getAllTransaction.transactions')) {
    totalCount = data.getAllTransaction?.count
  }

  if (error) return <Page404 />

  const handlePagination = (page) => {
    setSkip((page - 1) * limit)
    setCurrentPage(page)
  }

  const handlePageSizeChange = (current, size) => {
    setLimit(size)
  }

  const handleTableChange = (pagination, filter, sorter) => {
    if (sorter && sorter.field && sorter.order) {
      if (sorter.order === 'descend') {
        setSort({
          field: sorter.field,
          sortingOrder: 'DESC',
        })
        setCurrentPage(1)
      } else {
        setSort({
          field: sorter.field,
          sortingOrder: 'ASC',
        })
        setCurrentPage(1)
      }
    } else if (filter.status) {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['status'] = filter.status
      setFilters(tempFilters)
      setCurrentPage(1)
    } else {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['status'] = ''
      delete tempFilters.status
      setFilters(tempFilters)
      setCurrentPage(1)
    }
  }

  const handleSearch = (value, confirm, filedName) => {
    value = typeof value === 'object' ? value[0] : value
    confirm()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = value
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  const handleReset = (clearFilters, filedName) => {
    clearFilters()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    delete tempFilters[filedName]
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          autoFocus={true}
          value={selectedKeys[0]}
          placeholder={`Search ${dataIndex}`}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys[0], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, dataIndex)}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    // sorter: (a, b) => a.age - b.age,
  })

  const getColumnDateProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      const from = `${dataIndex}From`
      const to = `${dataIndex}To`
      let defaultValue = []
      if (!isEmpty(filters[from])) {
        defaultValue = [moment(filters[from]), moment(filters[to])]
      }
      return (
        <div style={{ padding: 8 }}>
          <RangePicker
            format={'DD-MMM-YYYY'}
            defaultValue={defaultValue}
            allowClear={true}
            onChange={(e) => {
              let tempFilters
              if (e) {
                confirm()
                tempFilters = JSON.parse(JSON.stringify(filters))
                tempFilters[from] = e[0]
                tempFilters[to] = e[1]
              } else {
                clearFilters()
                tempFilters = JSON.parse(JSON.stringify(filters))
                delete tempFilters[from]
                delete tempFilters[to]
              }
              setFilters(tempFilters)
              setCurrentPage(1)
            }}
          />
        </div>
      )
    },
  })

  const columns = [
    {
      title: 'Client Code',
      dataIndex: 'clientCode',
      key: 'client',
      width: '125px',
      render: (text, record) => record?.payer?.clientCode,
      ...getColumnSearchProps('clientCode'),
    },
    {
      title: 'Client Name',
      dataIndex: 'clientName',
      key: 'client',
      width: '125px',
      render: (text, record) => (record?.payer ? getName(record?.payer) : '-'),
      ...getColumnSearchProps('clientName'),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      width: '125px',
      render: (text) => (text ? text : '-'),
    },
    {
      title: 'Client UPI ID',
      dataIndex: 'payerUpiId',
      key: 'payerUpiId',
      width: '125px',
      render: (text) => (text ? text : '-'),
      ...getColumnSearchProps('clientUpiId'),
    },
    {
      title: 'Payment Status',
      dataIndex: 'status',
      key: 'status',
      width: '125px',
      render: (text) => (text ? text : '-'),
      filters: transactionStatus,
    },
    {
      title: 'Payment Date',
      dataIndex: 'paidDate',
      key: 'paidDate',
      width: '125px',
      render: (text) => (text ? text : '-'),
    },
    {
      title: 'Invoice No.',
      dataIndex: 'invoiceNo',
      key: 'invoiceNo',
      width: 150,
      render: (text) => (text ? text : '-'),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '125px',
      render: (text) => (text ? moment(text).format('DD-MMM-YYYY') : '-'),
      ...getColumnDateProps('createdAt'),
    },
    {
      title: 'Client Bank Name',
      dataIndex: 'payerBankName',
      key: 'payerBankName',
      width: '125px',
      render: (text) => (text ? text : '-'),
    },
    {
      title: 'Transaction ID',
      dataIndex: 'transactionId',
      key: 'transactionId',
      width: '125px',
      render: (text) => (text ? text : '-'),
      ...getColumnSearchProps('transactionId'),
    },
    {
      title: 'UPI TXN NO.',
      dataIndex: 'upiTxnNo',
      key: 'upiTxnNo',
      width: '125px',
      render: (text) => (text ? text : '-'),
    },
    {
      title: 'Customer Ref. No.',
      dataIndex: 'cusRefNo',
      key: 'cusRefNo',
      width: '125px',
      render: (text) => (text ? text : '-'),
    },
    {
      title: 'Txn No AMC',
      dataIndex: 'txnNo',
      key: 'txnNo',
      width: '125px',
      render: (text) => (text ? text : '-'),
    },
    {
      title: 'Payment Description',
      dataIndex: 'statusDescription',
      key: 'statusDescription',
      width: '125px',
      render: (text) => (text ? text : '-'),
    },
    {
      title: 'Response Code',
      dataIndex: 'responseCode',
      key: 'responseCode',
      width: '125px',
      render: (text) => (text ? text : '-'),
    },
    {
      title: 'Status',
      dataIndex: 'payType',
      key: 'payType',
      width: '125px',
      render: (text) => (text ? text : '-'),
    },
  ]

  const handleCsvDownload = () => {
    setCSVLoading(true)
    client
      .query({
        query: GET_ALL_TRANSACTIONS,
        variables: { where: { ...filters }, skip: 0, sort, limit: totalCount },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        client
          .query({
            query: CUSTOM_CSV_DOWNLOAD,
            variables: {
              data: {
                data: res.data.getAllTransaction.transactions,

                fields: [
                  {
                    value: 'payer.firstName',
                    label: 'client First Name',
                  },
                  {
                    value: 'payer.lastName',
                    label: 'client Last Name',
                  },
                  { value: 'transactionId', label: 'Transaction Id' },
                  { value: 'upiTxnNo', label: 'UPI TXN No.' },
                  { value: 'cusRefNo', label: 'Customer Ref. No.' },
                  { value: 'txnNo', label: 'Txn No.' },
                  { value: 'amcId', label: 'Amc' },
                  { value: 'amount', label: 'Amount' },
                  { value: 'invoiceNo', label: 'Invoice No' },
                  { value: 'payerUpiId', label: 'Client UPI ID' },
                  {
                    value: 'payerBankName',
                    label: 'Client Bank Name',
                  },
                  { value: 'status', label: 'Payment Status' },
                  { value: 'statusDescription', label: 'Payment Description' },
                  { value: 'payType', label: 'Status' },
                  { value: 'responseCode', label: 'Response Code' },
                  { value: 'createdAt', label: 'Created At' },
                ],
                fileName: 'Transactions',
              },
            },
          })
          .then(
            (res) =>
              get(res, 'data.customCSVDownload') &&
              window.open(get(res, 'data.customCSVDownload'), '_blank')
          )
          .catch((err) => console.log(err))
          .finally(() => {
            setLimit(10)
            setSkip(0)
            setCSVLoading(false)
          })
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setLimit(10)
        setSkip(0)
        setCSVLoading(false)
      })
  }

  return (
    <>
      <div className='main_dashboard_div'>
        {/* {!loading && (
        <PageHeader
          className='box'
          title='Transactions'
          extra={[
            <Button
              key='1'
              type='primary'
              onClick={handleCsvDownload}
              loading={CSVLoading}
            >
              Export Csv
            </Button>,
          ]}
        />
      )} */}

        <div className='agreement_main_div'>
          <div className='agreement_text'>Transactions</div>

          <div style={{ display: 'flex', gap: '10px' }}>
            <Button
              className='export_btn'
              onClick={handleCsvDownload}
              loading={CSVLoading}
            >
              Export Csv
              <DownloadOutlined color='#AF0000' className='icon_style' />
            </Button>
          </div>
        </div>

        <Table
          loading={loading}
          dataSource={data?.getAllTransaction?.transactions}
          onChange={handleTableChange}
          columns={columns}
          pagination={{
            pageSize: limit,
            total: totalCount,
            onChange: handlePagination,
            current: currentPage,
            onShowSizeChange: handlePageSizeChange,
          }}
          style={{ overflow: 'auto' }}
          scroll={{ x: true, y: true }}
        />
      </div>
    </>
  )
}

export default Transactions
