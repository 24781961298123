import React, { useEffect, useState } from 'react'
import { Button, Table, Tabs } from 'antd'
import { uniq } from 'lodash'
import MoreDetailsNew from './MoreDetailsNew'

const PendingAdvisoryIndexNew = ({
  loading,
  onFinish,
  type,
  onReject,
  advisoryData,
  advisoryDate,
  rejectLoading,
  data,
  graphData1,
  isMobile,
  userData,
}) => {
  const [range, setRange] = useState([])
  const [range1, setRange1] = useState([])

  useEffect(() => {
    YAxisData(data)
    YAxisData1(graphData1)
  }, [data, graphData1])

  const YAxisData = (data) => {
    let array = []
    for (const d of data) {
      array.push(+d.pl, +d.nlv)
    }
    array = uniq(array)
    setRange([Math.min(...array) - 10000, Math.max(...array) + 10000])
  }

  const YAxisData1 = (data) => {
    let array = []
    for (const d of data) {
      array.push(+d.pl, +d.nlv)
    }
    array = uniq(array)
    setRange1([Math.min(...array) - 10000, Math.max(...array) + 10000])
  }

  const columns = [
    {
      title: 'Script',
      dataIndex: 'Script',
      key: 'Script',
      width: isMobile && 150,
    },
    {
      title: 'Trade',
      dataIndex: 'Trade',
      key: 'Trade',
      align: 'right',
      width: isMobile && 80,
    },
    {
      title: 'Quantity',
      dataIndex: 'Quantity',
      key: 'Quantity',
      align: 'right',
      width: isMobile && 100,
    },
    {
      title: 'Price',
      dataIndex: 'TradePrice',
      key: 'TradePrice',
      align: 'right',
    },
  ]
  return (
    <>
      <Tabs
        defaultActiveKey='1'
        style={{ marginTop: '16px' }}
        className='trades_tab'
      >
        <Tabs.TabPane tab='Trades' key='1'>
          <div className='advisory_table'>
            <Table
              loading={loading}
              bordered
              columns={columns}
              dataSource={advisoryData?.currentAdvisoryData}
              pagination={false}
              style={{ overflow: 'auto' }}
            />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab='Scenario Analysis' key='2'>
          <MoreDetailsNew
            bookedAdvisory={advisoryData}
            mapData1={data}
            mapData2={graphData1}
            range1={range}
            range2={range1}
            isPending={false}
            isMobile={isMobile}
          />
        </Tabs.TabPane>
      </Tabs>
      <div
        style={{
          display: 'flex',
          justifyContent: `${isMobile ? 'end' : 'center'}`,
          marginTop: '10px',
          marginBottom: `${isMobile ? '10px' : '0px'}`,
        }}
      >
        <Button
          // id='confirm-btn'
          className='confirm-next-button'
          onClick={onFinish}
          loading={loading}
        >
          Accept
        </Button>
        <Button
          // id='cancel-btn'
          // type='primary'
          className='yes-next-button'
          loading={rejectLoading}
          onClick={onReject}
        >
          Reject
        </Button>
      </div>
    </>
  )
}
export default PendingAdvisoryIndexNew
