import React from 'react'
import { Table } from 'antd'
import {
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  CartesianGrid,
  Area,
  Label,
} from 'recharts'

import './pendingAdvisory.css'
import moment from 'moment'
import { convertNumberToCommaSeparate } from 'utils/utilities'
import { maxBy, minBy, sortBy, uniq } from 'lodash'

const MoreDetailsNew = ({
  bookedAdvisory,
  mapData1,
  mapData2,
  range1,
  range2,
  isPending = false,
  isMobile,
}) => {
  const nifty =
    bookedAdvisory?.mapScenario1[0] &&
    Object.keys(bookedAdvisory?.mapScenario1[0])
      .filter((key) => key !== 'Description')
      .map((key, index) => bookedAdvisory?.mapScenario1[0][key])

  const nifty1 =
    bookedAdvisory?.mapScenario2[0] &&
    Object.keys(bookedAdvisory?.mapScenario2[0])
      .filter((key) => key !== 'Description')
      .map((key, index) => bookedAdvisory?.mapScenario2[0][key])

  let data = []
  nifty.forEach((item, key) => {
    let obj = {}
    nifty1.forEach((data, index) => {
      if (item === data) {
        obj.niftyLevel = item
        obj.expectedPL1 = mapData1[key].pl
        obj.expectedPL2 = mapData2[index].pl
        obj.expectedNLV1 = mapData1[key].nlv
        obj.expectedNLV2 = mapData2[index].nlv
      }
    })
    data.push(obj)
    data = sortBy(data, 'niftyLevel')
  })

  const niftyDate1 =
    bookedAdvisory?.mapScenario1[0]?.Description?.split(' ')[
      bookedAdvisory?.mapScenario1[0]?.Description?.split(' ')?.length - 1
    ]
  const niftyDate2 =
    bookedAdvisory?.mapScenario2[0]?.Description?.split(' ')[
      bookedAdvisory?.mapScenario2[0]?.Description?.split(' ')?.length - 1
    ]

  const columns = [
    {
      title: 'NIFTY Levels',
      dataIndex: 'niftyLevel',
      key: 'niftyLevel',
    },
    {
      title: `Scenario Nifty Levels: ${moment(niftyDate1).format(
        'DD-MM-YYYY'
      )}`,
      children: [
        {
          title: 'Expected P&L',
          dataIndex: 'expectedPL1',
          key: 'expectedPL1',
          className: 'text_align',
        },
        {
          title: 'Expected NLV',
          dataIndex: 'expectedNLV1',
          key: 'expectedNLV1',
          className: 'text_align',
        },
      ],
    },
    {
      title: `Scenario Nifty Levels: ${moment(niftyDate2).format(
        'DD-MM-YYYY'
      )}`,
      children: [
        {
          title: 'Expected P&L',
          dataIndex: 'expectedPL2',
          key: 'expectedPL2',
          className: 'text_align',
        },
        {
          title: 'Expected NLV',
          dataIndex: 'expectedNLV2',
          key: 'expectedNLV2',
          className: 'text_align',
        },
      ],
    },
  ]

  const CustomTooltipPl = ({ active, payload, label }) => {
    if (active && payload.length) {
      return (
        <div className='custom-tooltip' style={{ background: '#fff' }}>
          <p style={{ color: 'orange', textAlign: 'center' }}>
            Nifty Levels: {label}
          </p>
          <p style={{ color: 'red' }}>
            {`P&L: ${convertNumberToCommaSeparate(+payload[0].value)}`}
          </p>
        </div>
      )
    }

    return null
  }

  const CustomTooltipNlv = ({ active, payload, label }) => {
    if (active && payload.length) {
      return (
        <div className='custom-tooltip' style={{ background: '#fff' }}>
          <p style={{ color: 'orange', textAlign: 'center' }}>
            Nifty Levels: {label}
          </p>

          <p style={{ color: 'green' }}>{`NLV: ${convertNumberToCommaSeparate(
            +payload[0].value
          )}`}</p>
        </div>
      )
    }

    return null
  }

  const minValue1 = parseInt(
    minBy(mapData1, (item) => parseInt(item?.nifty))?.nifty
  )
  const maxValue1 = parseInt(
    maxBy(mapData1, (item) => parseInt(item?.nifty))?.nifty
  )

  const newMapData1 = [
    { nifty: (minValue1 - 1000).toString(), nlv: null, pl: null },
    ...mapData1,
    { nifty: (maxValue1 + 1000).toString(), nlv: null, pl: null },
  ]

  const newMapData2 = [
    { nifty: (minValue1 - 1000).toString(), nlv: null, pl: null },
    ...mapData2,
    { nifty: (maxValue1 + 1000).toString(), nlv: null, pl: null },
  ]

  const findPLRange = (data) => {
    let array = []
    for (const d of data) {
      array.push(+d.pl)
    }
    array = uniq(array)
    return [Math.min(...array) - 10000, Math.max(...array) + 10000]
  }
  const plRange1 = findPLRange(mapData1)
  const plRange2 = findPLRange(mapData2)

  return (
    <>
      <div className='sub-data-box'>
        <div className='assessment-details-box'>
          <div className='assessment-title'>Current Expected AUA</div>
          <div className='assessment-description'>
            ₹&nbsp;
            {convertNumberToCommaSeparate(
              +bookedAdvisory?.currentExpectedExposure
            )}
          </div>
        </div>
        <div className='assessment-details-box'>
          <div className='assessment-title'>Current Expected NLV</div>
          <div className='assessment-description'>
            ₹&nbsp;
            {convertNumberToCommaSeparate(+bookedAdvisory?.currentExpectedNlv)}
          </div>
        </div>
      </div>

      <div className='advisory_table' style={{ marginTop: '16px' }}>
        <Table
          bordered
          columns={columns}
          dataSource={data}
          pagination={false}
          style={{ overflow: 'auto' }}
        />
      </div>

      <div
        style={{
          marginTop: '16px',
          display: `${isMobile ? 'grid' : 'flex'}`,
          gap: `${isMobile && '10px'}`,
          justifyContent: `${!isMobile && 'space-between'}`,
        }}
      >
        <div
          style={{
            border: '1px solid #85858533',
            borderRadius: '12px',
            width: isMobile ? '100%' : isPending ? '750px' : '550px',
          }}
        >
          <div style={{ marginTop: '24px', marginLeft: '18.5px' }}>
            <span
              style={{
                fontSize: '18px',
                fontWeight: '600',
                lineHeight: '18px',
                letterSpacing: '-0.01em',
                textAlign: 'left',
              }}
            >
              {bookedAdvisory?.mapScenario1[0]?.Description}
            </span>
          </div>
          <ResponsiveContainer width='100%' height={380}>
            <AreaChart
              width={500}
              height={200}
              data={sortBy(newMapData1, 'nifty')}
              syncId='anyId1'
              margin={{ top: 10, right: 10, bottom: 20, left: 60 }}
            >
              <Legend
                verticalAlign='top'
                align='left'
                height={36}
                content={(props) => (
                  <div className='legend1' style={{ marginLeft: '-5px' }}>
                    <div
                      className='legend_dot'
                      style={{ background: '#3E97FF', marginRight: '2.5px' }}
                    ></div>
                    <span
                      style={{
                        fontSize: '13px',
                        lineHeight: '14px',
                        fontWeight: 400,
                        color: '#000',
                        // marginLeft: '5px',
                      }}
                    >
                      Expected P&L at Trading Member
                    </span>
                  </div>
                )}
              />
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='nifty'>
                <Label
                  value='Nifty Levels'
                  position='insideBottom'
                  offset={-5}
                />
              </XAxis>
              <YAxis domain={plRange1}>
                <Label
                  value='Expected P&L at Trading Member'
                  position='left'
                  angle={-90}
                  offset={50}
                  dy={-100}
                />
              </YAxis>
              <Tooltip content={CustomTooltipPl} />
              <Area
                type='monotone'
                dataKey='pl'
                stroke='#3E97FF'
                fill='#3E97FF'
                dot={{ stroke: '#3E97FF', strokeWidth: 16, fill: 'white' }}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>

        <div
          style={{
            border: '1px solid #85858533',
            borderRadius: '12px',
            width: isMobile ? '100%' : isPending ? '750px' : '550px',
          }}
        >
          <div style={{ marginTop: '24px', marginLeft: '18.5px' }}>
            <span
              style={{
                fontSize: '18px',
                fontWeight: '600',
                lineHeight: '18px',
                letterSpacing: '-0.01em',
                textAlign: 'left',
              }}
            >
              {bookedAdvisory?.mapScenario1[0]?.Description}
            </span>
          </div>
          <ResponsiveContainer width='100%' height={380}>
            <AreaChart
              width={500}
              height={200}
              data={sortBy(mapData1, 'nifty')}
              syncId='anyId2'
              margin={{ top: 10, right: 10, bottom: 20, left: 60 }}
            >
              <Legend
                verticalAlign='top'
                align='left'
                height={36}
                content={(props) => (
                  <div className='legend1' style={{ marginLeft: '-10px' }}>
                    <div
                      className='legend_dot'
                      style={{ background: '#50CD89', marginRight: '2.5px' }}
                    ></div>
                    <span
                      style={{
                        fontSize: '13px',
                        lineHeight: '14px',
                        fontWeight: 400,
                        color: '#000',
                        marginLeft: '5px',
                      }}
                    >
                      Expected NLV
                    </span>
                  </div>
                )}
              />
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='nifty'>
                <Label
                  value='Nifty Levels'
                  position='insideBottom'
                  offset={-5}
                />
              </XAxis>
              <YAxis domain={range1}>
                <Label
                  value='Expected NLV'
                  position='left'
                  angle={-90}
                  offset={50}
                  dy={-50}
                />
              </YAxis>
              <Tooltip content={CustomTooltipNlv} />
              <Area
                type='monotone'
                dataKey='nlv'
                stroke='#50CD89'
                fill='#50CD89'
                dot={{ stroke: '#50CD89', strokeWidth: 16, fill: 'white' }}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div
        style={{
          marginTop: '16px',
          display: `${isMobile ? 'grid' : 'flex'}`,
          gap: `${isMobile && '10px'}`,
          justifyContent: `${!isMobile && 'space-between'}`,
        }}
      >
        <div
          style={{
            border: '1px solid #85858533',
            borderRadius: '12px',
            width: isMobile ? '100%' : isPending ? '750px' : '550px',
          }}
        >
          <div style={{ marginTop: '24px', marginLeft: '18.5px' }}>
            <span
              style={{
                fontSize: '18px',
                fontWeight: '600',
                lineHeight: '18px',
                letterSpacing: '-0.01em',
                textAlign: 'left',
              }}
            >
              {bookedAdvisory?.mapScenario2[0]?.Description}
            </span>
          </div>
          <ResponsiveContainer width='100%' height={380}>
            <AreaChart
              width={500}
              height={200}
              data={sortBy(newMapData2, 'nifty')}
              syncId='anyId3'
              margin={{ top: 10, right: 10, bottom: 20, left: 60 }}
            >
              <Legend
                verticalAlign='top'
                align='left'
                height={36}
                content={(props) => (
                  <div className='legend1' style={{ marginLeft: '-10px' }}>
                    <div
                      className='legend_dot'
                      style={{ background: '#3E97FF', marginRight: '2.5px' }}
                    ></div>
                    <span
                      style={{
                        fontSize: '13px',
                        lineHeight: '14px',
                        fontWeight: 400,
                        color: '#000',
                        marginLeft: '5px',
                      }}
                    >
                      Expected P&L at Trading Member
                    </span>
                  </div>
                )}
              />
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='nifty'>
                <Label
                  value='Nifty Levels'
                  position='insideBottom'
                  offset={-5}
                />
              </XAxis>
              <YAxis domain={plRange2}>
                <Label
                  value='Expected P&L at Trading Member'
                  position='left'
                  angle={-90}
                  offset={50}
                  dy={-100}
                />
              </YAxis>
              <Tooltip content={CustomTooltipPl} />
              <Area
                type='monotone'
                dataKey='pl'
                stroke='#3E97FF'
                fill='#3E97FF'
                dot={{ stroke: '#3E97FF', strokeWidth: 16, fill: 'white' }}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>

        <div
          style={{
            border: '1px solid #85858533',
            borderRadius: '12px',
            width: isMobile ? '100%' : isPending ? '750px' : '550px',
          }}
        >
          <div style={{ marginTop: '24px', marginLeft: '18.5px' }}>
            <span
              style={{
                fontSize: '18px',
                fontWeight: '600',
                lineHeight: '18px',
                letterSpacing: '-0.01em',
                textAlign: 'left',
              }}
            >
              {bookedAdvisory?.mapScenario2[0]?.Description}
            </span>
          </div>
          <ResponsiveContainer width='100%' height={380}>
            <AreaChart
              width={500}
              height={200}
              data={sortBy(mapData2, 'nifty')}
              syncId='anyId4'
              margin={{ top: 10, right: 10, bottom: 20, left: 60 }}
            >
              <Legend
                verticalAlign='top'
                align='left'
                height={36}
                content={(props) => (
                  <div className='legend1' style={{ marginLeft: '-10px' }}>
                    <div
                      className='legend_dot'
                      style={{ background: '#50CD89', marginRight: '2.5px' }}
                    ></div>
                    <span
                      style={{
                        fontSize: '13px',
                        lineHeight: '14px',
                        fontWeight: 400,
                        color: '#000',
                        marginLeft: '5px',
                      }}
                    >
                      Expected NLV
                    </span>
                  </div>
                )}
              />
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='nifty'>
                <Label
                  value='Nifty Levels'
                  position='insideBottom'
                  offset={-5}
                />
              </XAxis>
              <YAxis domain={range1}>
                <Label
                  value='Expected NLV'
                  position='left'
                  angle={-90}
                  offset={50}
                  dy={-50}
                />
              </YAxis>
              <Tooltip content={CustomTooltipNlv} />
              <Area
                type='monotone'
                dataKey='nlv'
                stroke='#50CD89'
                fill='#50CD89'
                dot={{ stroke: '#50CD89', strokeWidth: 16, fill: 'white' }}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    </>
  )
}
export default MoreDetailsNew
