import React, { useContext, useEffect, useState } from 'react'
import { Button, Popover, Table } from 'antd'
import { CSVLink } from 'react-csv'
import { DownloadOutlined } from '@ant-design/icons'
import { convertNumberToCommaSeparate } from 'utils/utilities'
import PdfIcon from 'assets/images/new-images/pdficon.svg'
import ExcelIcon from 'assets/images/new-images/excelIcon.svg'
import {
  GET_STOCK_REPORT_DATA,
  GET_TRADER_EQ_REPORT,
} from 'modules/Reports/graphql/Queries'
import client from 'apollo'
import { get } from 'lodash'
import { AppContext } from 'AppContext'

export default function ({ reportData, clientData, id, StrategyCode }) {

  const {
    state: { currentReport, isMobile },
  } = useContext(AppContext)
  const { ClientCode, ClientName } = clientData

  const [exportDataCsv, setExportDataCsv] = useState([])
  const [loading, setLoading] = useState(false)

  const dataForExportCSV = exportDataCsv?.map((item) => {
    return {
      script: item?.script,
      qty: item?.qty,
      rate: item?.rate,
      entrydate: item?.entrydate,
      ClientCode: item?.ClientCode,
    }
  })

  useEffect(() => {
    setLoading(true)
    client
      .query({
        query: GET_TRADER_EQ_REPORT,
        variables: { where: { clientCode: ClientCode?.split('ILTS')[0] } },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        setExportDataCsv(res?.data?.spTradeReportEQ)
        setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const columns = [
    {
      title: 'Script',
      dataIndex: 'Script',
      key: 'Script',
      width: 140,
      className: 'report-text',
    },
    {
      title: 'Quantity',
      dataIndex: 'TotalQty',
      key: 'TotalQty',
      width: 110,
      className: 'report-numeric',
      render: (record) => convertNumberToCommaSeparate(record),
    },
    {
      title: 'Trade Rate',
      dataIndex: 'TradedRate',
      key: 'TradedRate',
      width: 110,
      className: 'report-numeric',
      render: (record) => convertNumberToCommaSeparate(record, true),
    },
    {
      title: 'CMP',
      dataIndex: 'CMP',
      key: 'CMP',
      width: 110,
      className: 'report-numeric',
      render: (record) => convertNumberToCommaSeparate(record, true),
    },
    {
      title: 'Value',
      dataIndex: 'Value',
      key: 'Value',
      width: 110,
      className: 'report-numeric',
      render: (record) => convertNumberToCommaSeparate(record),
    },
    {
      title: 'Profit / Loss',
      dataIndex: 'Pnl',
      key: 'Pnl',
      width: 110,
      className: 'report-numeric',
      render: (record) => convertNumberToCommaSeparate(record),
    },
  ]

  const data = reportData.map((report, key) => {
    return { key, ...report }
  })

  const handleDownloadPdf = () => {
    client
      .query({
        query: GET_STOCK_REPORT_DATA,
        variables: { where: { id: parseInt(id), strategyCode: StrategyCode } },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        get(res, 'data.getStockReport') &&
          window.open(get(res, 'data.getStockReport'), '_blank')
      })
      .catch((err) => console.log(err))
  }

  const content = (
    <div style={{ width: '100px' }}>
      <div
        onClick={handleDownloadPdf}
        style={{
          marginBottom: `${isMobile ? '0px' : '10px'}`,
          fontSize: '14px',
          fontWeight: '600',
          lineHeight: '24px',
          textAlign: 'left',
          color: ' #000',
          cursor: 'pointer',
        }}
      >
        <img src={PdfIcon} style={{ marginRight: '10px' }} alt='pdf' /> Pdf
      </div>

      <CSVLink
        style={{ paddingRight: 0 }}
        data={reportData}
        className='csv-btn'
        filename={`Stock Holding Report ${ClientCode}.csv`}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          style={{
            marginBottom: `${isMobile ? '0px' : '10px'}`,
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '24px',
            textAlign: 'left',
            color: ' #000',
            cursor: 'pointer',
          }}
        >
          <img src={ExcelIcon} style={{ marginRight: '10px' }} alt='csv' />{' '}
          Excel
        </div>
      </CSVLink>
    </div>
  )

  return (
    <>
      <div className='report_first_Container'>
        <div className='report_Inner_Container'>
          <div className='Client_Info_Container'>
            <div className='client_Info'>
              Client Code:&nbsp;
              <span>{ClientCode}</span>
            </div>
            <div className='client_Info'>
              Client Name:&nbsp;
              <span>{ClientName}</span>
            </div>
          </div>
          <div style={{ display: 'flex', gap: '10px' }}>
            {currentReport.includes('Stock Report All Export As Button') &&
              dataForExportCSV && (
                <CSVLink
                  data={dataForExportCSV}
                  className='ExportAS'
                  style={{ marginRight: '10px' }}
                  filename={`Stock Holding Report ${ClientCode}`}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Button
                    type='primary'
                    style={{
                      margin: '0',
                      padding: '4px 8px',
                      fontSize: '13px',
                      fontWeight: '600',
                      lineHeight: '15px',
                      textAlign: 'left',
                      color: '#AF0000',
                      background: '#AF00000D',
                      border: '1px solid #AF000080',
                      height: '30px',
                      borderRadius: '8px',
                    }}
                    disabled={loading}
                  >
                    Export Trades
                  </Button>
                </CSVLink>
              )}
            <Popover content={content} placement='bottom' trigger='click'>
              <Button
                style={{
                  margin: '0',
                  padding: '5px 16px',
                  fontSize: '14px',
                  fontWeight: '600',
                  lineHeight: '15px',
                  textAlign: 'left',
                  color: '#AF0000',
                  background: '#AF00000D',
                  border: '1px solid #AF000080',
                  height: '30px',
                  borderRadius: '8px',
                }}
              >
                Export{' '}
                <DownloadOutlined
                  color='#AF0000'
                  style={{ fontSize: '16px' }}
                />
              </Button>
            </Popover>
          </div>
        </div>
      </div>
      <Table
        className='stock-options'
        dataSource={data}
        columns={columns}
        bordered
        style={{ overflow: 'auto', fontSize: '12px' }}
        // scroll={{ x: true, y: 240 }}
        pagination={{ pageSize: 1000, hideOnSinglePage: true }}
      />
    </>
  )
}
