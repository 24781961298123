import React, { Fragment, useState, useContext } from 'react'
import { Button, Tabs, notification, Popover } from 'antd'
import get from 'lodash/get'

import client from 'apollo'
import Widget from 'components/Widget'
import { AppContext } from 'AppContext'
import CustomMeta from 'components/CustomMeta'
import { CREATE_TICKET } from './graphql/Mutations'
import CreateTicketModal from './CreateTicketModal'
import { useQuery } from '@apollo/react-hooks'
import { GET_TICKETS } from './graphql/Queries'
import { CUSTOM_CSV_DOWNLOAD } from 'modules/Reports/graphql/Queries'
import TabTicketsNew from './TabTickets/TabTicketsNew'
import ExcelIcon from 'assets/images/new-images/excelIcon.svg'

const TabPane = Tabs.TabPane

export default function () {
  const {
    state: {
      isClient,
      isAdmin,
      currentUser: { id },
      isMobile,
    },
  } = useContext(AppContext)
  const [showFormModal, setShowFormModal] = useState(false)
  const [showFormLoading, setShowFormLoading] = useState(false)

  const [sort, setSort] = useState()
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [filters, setFilters] = useState(
    !isClient && !isAdmin ? { assignUserId: parseInt(id) } : {}
  )
  const [CSVLoading, setCSVLoading] = useState(false)

  let tableData = []

  let variables = { where: filters, limit, skip, sort }
  const { data, loading, error } = useQuery(GET_TICKETS, {
    variables,
    fetchPolicy: 'network-only',
  })

  let totalCount = 0
  if (!loading && get(data, 'getTickets.tickets')) {
    totalCount = data.getTickets.count
    tableData = data.getTickets.tickets.map((ticket, key) => ({
      key: key.toString(),
      ...ticket,
    }))
  }

  const changeTab = (key, tab) => {
    if (!isClient && !isAdmin) {
      if (key === '1') {
        setFilters({ assignUserId: parseInt(id) })
      } else if (key === '2') {
        setFilters({ generatedById: parseInt(id) })
      } else {
        setFilters({ assignUserId: parseInt(id) })
      }
    }
  }

  function handleCreateTicket(values, resetForm) {
    setShowFormLoading(true)
    let files = []
    if (values?.fileUrl) {
      values.fileUrl.forEach((file) => {
        files.push(file.originFileObj)
      })
      values.fileUrl = files
    } else {
      delete values.fileUrl
    }

    client
      .mutate({
        mutation: CREATE_TICKET,
        variables: { ...values },
      })
      .then((res) => {
        notification.success({
          duration: 2.5,
          message: (
            <>
              Ticket created Successfully
              <br />
              {`Note Your Ticket Number.:${res?.data?.createTicket?.ticketNumber}`}
            </>
          ),
        })
        setShowFormModal(false)
        resetForm()
      })
      .catch((err) => console.log(err))
      .finally(() => setShowFormLoading(false))
  }

  const handleCsvDownload = () => {
    setCSVLoading(true)
    client
      .query({
        query: GET_TICKETS,
        variables: { where: { ...filters }, skip: 0, sort, limit: totalCount },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        client
          .query({
            query: CUSTOM_CSV_DOWNLOAD,
            variables: {
              data: {
                data: res.data.getTickets.tickets,

                fields: [
                  {
                    value: 'generatedBy.firstName',
                    label: 'User First Name',
                  },
                  {
                    value: 'generatedBy.lastName',
                    label: 'User Last Name',
                  },
                  { value: 'ticketNumber', label: 'Ticket Number' },
                  { value: 'subject', label: 'Subject' },
                  { value: 'status', label: 'Status' },
                  {
                    value: 'description',
                    label: 'Description',
                  },
                  {
                    value: 'assignUser.firstName',
                    label: 'Assigned User First Name',
                  },
                  {
                    value: 'assignUser.lastName',
                    label: 'Assigned User Last Name',
                  },
                  { value: 'fileUrl', label: 'Attachment' },
                  {
                    value: 'closedByUser.firstName',
                    label: 'Closed By User First Name',
                  },
                  {
                    value: 'closedByUser.lastName',
                    label: 'Closed By User Last Name',
                  },
                  { value: 'createdAt', label: 'Created At' },
                  { value: 'closedDate', label: 'Closed At' },
                ],
                fileName: 'Tickets',
              },
            },
          })
          .then(
            (res) =>
              get(res, 'data.customCSVDownload') &&
              window.open(get(res, 'data.customCSVDownload'), '_blank')
          )
          .catch((err) => console.log(err))
          .finally(() => {
            setLimit(10)
            setSkip(0)
            setCSVLoading(false)
          })
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setLimit(10)
        setSkip(0)
        setCSVLoading(false)
      })
  }

  const content = (
    <div style={{ width: '100px' }}>
      <div
        style={
          CSVLoading
            ? { cursor: 'progress' }
            : {
                fontSize: '14px',
                fontWeight: '600',
                lineHeight: '24px',
                textAlign: 'left',
                color: ' #000',
                cursor: 'pointer',
              }
        }
        onClick={handleCsvDownload}
      >
        <img src={ExcelIcon} style={{ marginRight: '10px' }} alt='excel' />{' '}
        Excel
      </div>
    </div>
  )

  return (
    <Fragment>
      <CustomMeta title='Tickets' />
      <div className='main_dashboard_div'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '16px',
            width: '100%',
          }}
        >
          <div
            style={{
              fontSize: isMobile ? '18px' : '24px',
              fontWeight: '600',
              lineHeight: '26px',
              letterSpacing: '-0.02em',
              textAlign: 'left',
              color: '#181C32',
            }}
          >
            Tickets
          </div>

          <div style={{ display: 'flex', gap: '10px' }}>
            {isAdmin ? (
              <Popover content={content} placement='bottom' trigger='click'>
                <Button
                  style={{
                    margin: '0',
                    padding: '8px 16px',
                    fontSize: '14px',
                    fontWeight: '600',
                    lineHeight: '24px',
                    textAlign: 'left',
                    color: '#AF0000',
                    background: '#AF00000D',
                    border: '1px solid #AF00000D',
                    height: '40px',
                    borderRadius: '8px',
                  }}
                >
                  Export Csv
                </Button>
              </Popover>
            ) : (
              <Button
                style={{
                  margin: '0',
                  padding: '8px 16px',
                  fontSize: '14px',
                  fontWeight: '600',
                  lineHeight: '24px',
                  textAlign: 'left',
                  color: '#FFFFFF',
                  background: '#AF0000',
                  border: '1px solid #AF0000',
                  height: '40px',
                  borderRadius: '8px',
                }}
                onClick={() => setShowFormModal(true)}
              >
                Generate Ticket{' '}
              </Button>
            )}
          </div>
        </div>

        {!isAdmin && showFormModal && (
          <CreateTicketModal
            visible={showFormModal}
            loading={showFormLoading}
            onCreate={handleCreateTicket}
            onCancel={() => setShowFormModal(false)}
            fromFaq={false}
          />
        )}
        {isClient || isAdmin ? (
          <>
            <TabTicketsNew
              setSort={setSort}
              setSkip={setSkip}
              setLimit={setLimit}
              setFilters={setFilters}
              limit={limit}
              filters={filters}
              data={data}
              loading={loading}
              error={error}
              tableData={tableData}
              totalCount={totalCount}
            />
          </>
        ) : (
          <Widget
            title='&nbsp;'
            styleName='gx-card-tabs gx-card-tabs-right gx-card-profile'
          >
            <Tabs defaultActiveKey='1' onChange={changeTab}>
              <TabPane tab='Assigned Tickets' key='1'>
                <div className='gx-mb-2'>
                  <TabTicketsNew
                    defaultFilters={{
                      assignUserId: parseInt(id),
                    }}
                    setSort={setSort}
                    setSkip={setSkip}
                    setLimit={setLimit}
                    setFilters={setFilters}
                    limit={limit}
                    filters={filters}
                    data={data}
                    loading={loading}
                    error={error}
                    tableData={tableData}
                    totalCount={totalCount}
                  />
                </div>
              </TabPane>
              <TabPane tab='Generated Tickets' key='2'>
                <div className='gx-mb-2'>
                  <TabTicketsNew
                    defaultFilters={{
                      generatedById: parseInt(id),
                    }}
                    setSort={setSort}
                    setSkip={setSkip}
                    setLimit={setLimit}
                    setFilters={setFilters}
                    limit={limit}
                    filters={filters}
                    data={data}
                    loading={loading}
                    error={error}
                    tableData={tableData}
                    totalCount={totalCount}
                  />
                </div>
              </TabPane>
            </Tabs>
          </Widget>
        )}
      </div>
    </Fragment>
  )
}
