import { AppContext } from 'AppContext'
import React, { useContext, useEffect, useState } from 'react'

import { useQuery } from '@apollo/react-hooks'
import { GET_BOOKING_ADVISORY } from '../graphql/Queries'
import moment from 'moment'
import EditPencilIcon from 'assets/images/new-images/edit-pencil-icon.svg'
import DeleteDustbinIcon from 'assets/images/new-images/delete-dustbin-icon.svg'

import './BookAdvisory.css'
import Loader from 'components/loaders/Loader'
import Page404 from 'components/Page404'
import { Button, DatePicker, Modal, Space, Table } from 'antd'
import { uniq } from 'lodash'
import client from 'apollo'
import { UPDATE_BOOK_ADVISORY } from '../graphql/Mutations'
import { UPDATE_USER } from 'modules/Users/graphql/Mutations'
import openNotification from 'utils/Notification'
import CustomMeta from 'components/CustomMeta'
import calendarIcon from 'assets/images/new-images/calendar.svg'
import { convertNumberToCommaSeparate } from 'utils/utilities'
import AutoRefresh from 'utils/AutoRefresh'

const BookedAdvisorySlot = ({
  mandateId,
  amcAmount,
  dateData,
  setAdvisoryId,
  userData,
}) => {
  const {
    dispatch,
    state: { currentUser, isMobile },
  } = useContext(AppContext)

  const [deleteModal, setDeleteModal] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)
  const [isChangeDate, setIsChangeDate] = useState(false)
  const [date, setDate] = useState()
  const [tableData, setTableData] = useState([])
  const [totalAmount, setTotalAmount] = useState(amcAmount)

  const { data, loading, error } = useQuery(GET_BOOKING_ADVISORY, {
    variables: {
      where: {
        userId: parseInt(currentUser?.id),
        isClientLogin: false,
        isClientAcceptAdvisory: false,
        isAdvisoryBooked: true,
        isCancelAdvisory: false,
        advisoryType: 'New',
      },
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (data?.getBookAdvisory) {
      setAdvisoryId(data?.getBookAdvisory?.id)
      setTableData([
        {
          clientCode: data?.getBookAdvisory?.clientId?.clientCode,
          clientName: `${data?.getBookAdvisory?.clientId?.firstName} ${data?.getBookAdvisory?.clientId?.lastName}`,
          advisoryDate: moment(data?.getBookAdvisory?.advisoryDate).format(
            'DD/MM/YYYY'
          ),
        },
      ])

      if (data?.getBookAdvisory?.clientId?.eMandateAmcAmount) {
        setTotalAmount(data?.getBookAdvisory?.clientId?.eMandateAmcAmount)
      } else {
        if (currentUser?.stateId === 4030) {
          const cGst = data?.getBookAdvisory?.amcAmount * (9 / 100)
          const totalGst = Math.round(cGst) * 2
          setTotalAmount(data?.getBookAdvisory?.amcAmount + totalGst)
        } else {
          const totalGst = data?.getBookAdvisory?.amcAmount * (18 / 100)
          setTotalAmount(
            data?.getBookAdvisory?.amcAmount + Math.round(totalGst)
          )
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser.stateId, data])
  let array = []
  const disabledDate = (current) => {
    const isWeekend = current.day() === 0 || current.day() === 6
    const holiday = dateData.some((date) => moment(date).isSame(current, 'day'))
    const isToday = current.isSame(moment(), 'day')
    const isSelected = current.isSame(
      moment(data?.getBookAdvisory?.advisoryDate),
      'day'
    )

    if (moment(current) >= moment() && array.length < 4) {
      if (!isWeekend && !holiday && !isToday && !isSelected) {
        array.push(moment(current).format('DD/MM'))
        array = uniq(array)
      }
    }

    return !array?.includes(moment(current).format('DD/MM'))
  }

  const handleDateChange = (e) => {
    setDate(moment(e))
  }

  const onFinish = (isDeleted) => {
    setBtnLoading(true)
    let updatedData = { userId: currentUser?.id }
    if (isDeleted) {
      updatedData = {
        ...updatedData,
        isCancelAdvisory: true,
        advisoryCancelBy: currentUser?.id,
      }
    } else {
      updatedData = { ...updatedData, advisoryDate: moment(date).format() }
    }

    client
      .mutate({
        mutation: UPDATE_BOOK_ADVISORY,
        variables: {
          data: { ...updatedData },
          where: { id: parseInt(data?.getBookAdvisory?.id) },
        },
      })
      .then((res) => {
        if (isDeleted) {
          client
            .mutate({
              mutation: UPDATE_USER,
              variables: {
                data: { isBookingAdvisoryDateSelected: false },
                id: currentUser?.id,
              },
            })
            .then((response) => {
              dispatch({
                type: 'CURRENT_USER',
                data: response?.data?.updateUser,
              })
              setBtnLoading(false)
              setDeleteModal(false)
              openNotification(
                'success',
                'Advisory slot cancelled successfully'
              )
            })
            .catch((err) => {
              console.log(err)
              setBtnLoading(false)
            })
        } else {
          setBtnLoading(false)
          setIsChangeDate(false)
          openNotification('success', 'Advisory slot updated successfully')
        }
      })
      .catch((err) => {
        console.log(err)
        setBtnLoading(false)
      })
  }

  const columns = [
    {
      title: 'Client Code',
      dataIndex: 'clientCode',
      key: 'clientCode',
    },
    {
      title: 'Client Name',
      dataIndex: 'clientName',
      key: 'clientName',
      width: !isMobile && 400,
    },
    {
      title: 'Advisory Date',
      dataIndex: 'advisoryDate',
      key: 'advisoryDate',
    },
    {
      title: 'Actions',
      key: 'action',
      width: isMobile && 80,
      render: (_, record) => (
        <Space size='middle'>
          <div
            onClick={() => setIsChangeDate(true)}
            style={{ cursor: 'pointer' }}
          >
            <img src={EditPencilIcon} alt='EditPencilIcon' />
          </div>
          <div
            onClick={() => setDeleteModal(true)}
            style={{ cursor: 'pointer' }}
          >
            <img src={DeleteDustbinIcon} alt='DeleteDustbinIcon' />
          </div>
        </Space>
      ),
    },
  ]

  if (loading) return <Loader />
  if (error) return <Page404 />

  return (
    <>
      <div>
        <CustomMeta title='Your Booked Advisory Slot' />
        <div>
          <div>
            {(mandateId || currentUser?.umrnNumber) && (
              <>
                {!currentUser?.isNewAuaAdvisoryGenerate ? (
                  <div className='modal_note_text'>
                    <p>
                      Congratulations ! You have received a 5% discount. The
                      Finideas advisory fee of Rs.
                      {convertNumberToCommaSeparate(Math.round(totalAmount))}
                      /- will be debited through the advisory mandate within 48
                      hours.
                    </p>
                  </div>
                ) : (
                  <div className='modal_note_text'>
                    <p>
                      Congratulations ! The Finideas advisory fee of Rs.
                      {convertNumberToCommaSeparate(Math.round(totalAmount))}
                      /- will be debited through the advisory mandate within 48
                      hours.
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
          <div className='advisory_table'>
            <Table
              loading={loading}
              bordered
              columns={columns}
              dataSource={tableData}
              pagination={false}
              style={{ overflow: 'scroll' }}
            />
          </div>

          <div className='booking_slot_note_text'>
            Kindly login to Finideas App or Website on your selected Advisory
            slot day.
          </div>
        </div>
      </div>

      {!loading && deleteModal && (
        <Modal
          className='book_advisory_modal'
          visible={deleteModal}
          footer={null}
          centered
        >
          <div className='modal_text'>Do you want cancel advisory slot?</div>
          <div className='book_advisory_btn'>
            <Button
              className='advisory_cancel_btn'
              onClick={() => setDeleteModal(false)}
              disabled={btnLoading}
            >
              Cancel
            </Button>
            <Button
              className='advisory_verify_btn'
              type='primary'
              onClick={() => onFinish(true)}
              loading={btnLoading}
            >
              Confirm
            </Button>
          </div>
        </Modal>
      )}

      {!loading && isChangeDate && (
        <Modal
          className='book_advisory_modal'
          visible={isChangeDate}
          footer={null}
          centered
        >
          <div className='edit_advisory_date_div'>
            <div className='edit_advisory_heading'>
              Do you want to change book advisory Slot?
            </div>
            <div className='calendar_text'>
              <div className='edit_advisory_description'>
                Kindly select Slot for advisory booking
                <DatePicker
                  style={{
                    borderRadius: '8px',
                    marginTop: '5px',
                    height: '40px',
                  }}
                  getPopupContainer={trigger => trigger.parentElement}
                  className='client-detail-date-picker'
                  suffixIcon={<img src={calendarIcon} alt='calendarIcon' />}
                  disabledDate={disabledDate}
                  onChange={handleDateChange}
                  format={'DD-MM-YYYY'}
                  value={date}
                  allowClear={false}
                  dropdownClassName='custom-dropdown-class'
                />
              </div>
            </div>
          </div>
          <div className='book_advisory_btn'>
            <Button
              className='advisory_cancel_btn'
              onClick={() => setIsChangeDate(false)}
              disabled={btnLoading}
            >
              Cancel
            </Button>
            <Button
              className='advisory_verify_btn'
              type='primary'
              loading={btnLoading}
              onClick={() => onFinish(false)}
            >
              Confirm
            </Button>
          </div>
        </Modal>
      )}

      <AutoRefresh />
    </>
  )
}
export default BookedAdvisorySlot
