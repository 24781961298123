import React, { useContext, useEffect, useState } from 'react'
import { configure, HotKeys } from 'react-hotkeys'
import { Layout, Alert } from 'antd'
import _get from 'lodash/get'
import moment from 'moment'

import { AppContext } from 'AppContext'
import CustomSider from './SiderNew'
import './layout.css'

configure({
  ignoreEventsCondition: (event) => {
    return false
  },
})

const keyMap = {
  SEARCH_FIRST_NAME_NODE: ['ctrl+shift+f', 'cmd+shift+f'],
  SEARCH_LAST_NAME_NODE: ['ctrl+shift+l', 'cmd+shift+l'],
}

export default function LayoutWrapper(props) {
  const {
    location: { pathname },
  } = props

  const {
    state: {
      currentUser,
      currentUser: { disableDate, isDisableDate, completedSteps },
      isClient,
      isMobile,
    },
  } = useContext(AppContext)

  const [collapsed, setCollapsed] = useState(
    (isClient && completedSteps !== 'DONE') || isMobile ? true : false
  )

  useEffect(() => {
    if (isClient && completedSteps === 'DONE' && !isMobile) {
      setCollapsed(false)
    }
  }, [isClient, completedSteps, isMobile])

  let showAlert = false
  if (isDisableDate && disableDate) {
    const date = moment(disableDate).add(1, 'days')
    const beforeDate = moment(disableDate).subtract(4, 'days')
    const isBetween = moment().isBetween(beforeDate, date)
    if (isBetween) {
      showAlert = true
    }
  }

  function searchFirstName() {
    if (
      (pathname === '/users' || pathname === '/clients') &&
      _get(
        document.getElementsByClassName(
          'ant-table-filter-trigger ant-dropdown-trigger'
        ),
        '[0]'
      )
    ) {
      document
        .getElementsByClassName(
          'ant-table-filter-trigger ant-dropdown-trigger'
        )[0]
        .click()
    }
  }

  function searchLastName() {
    if (
      (pathname === '/users' || pathname === '/clients') &&
      _get(
        document.getElementsByClassName(
          'ant-table-filter-trigger ant-dropdown-trigger'
        ),
        '[1]'
      )
    ) {
      document
        .getElementsByClassName(
          'ant-table-filter-trigger ant-dropdown-trigger'
        )[1]
        .click()
    }
  }

  const handlers = {
    SEARCH_FIRST_NAME_NODE: searchFirstName,
    SEARCH_LAST_NAME_NODE: searchLastName,
  }

  return (
    <HotKeys keyMap={keyMap} handlers={handlers}>
      {showAlert && (
        <Alert
          type='warning'
          showIcon={false}
          closable={false}
          style={{
            marginBottom: 0,
            textAlign: 'center',
          }}
          message={
            <div>
              Your account will be disable after{' '}
              <b>{moment(disableDate).format('MMMM Do YYYY')}</b>.
            </div>
          }
        />
      )}
      <Layout>
        <CustomSider
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          pathname={pathname}
          currentUser={currentUser}
        />
        {/* <Layout className={`site-layout ${showAlert ? 'with-alert' : ''}`}> */}
        {/* <div class="row notificationBar">
          <span class="notificationMsg">
            You are on new dashboard, access old site on the url <a href="#pricing-plans">backoffice.finideas.com</a> or <a href="#pricing-plans">click here</a>
          </span>
        </div> */}
        {/* <CustomHeader
            collapsed={collapsed}
            toggle={() => setCollapsed(!collapsed)}
            drawerToggle={() => setVisibleDrawer(!visibleDrawer)}
          />
          <Drawer
            placement='left'
            closable={false}
            visible={visibleDrawer}
            className='layout-drawer'
            onClose={() => setVisibleDrawer(!visibleDrawer)}
            title={
              <NavLink to='/' className='nav-text'>
                <img alt='Finideas' src={LogoWhite} />
              </NavLink>
            }
          >
            <div className='menu-wrapper'>
              <Menu pathname={pathname} setVisibleDrawer={setVisibleDrawer} />
            </div>
            <p className='version-print'>V {version}</p>
          </Drawer>

          <Content>
            <div
              className={`content-wrapper  ${
                isAdmin ? 'admin-content-wrapper' : ''
              }`}
            >
              <MainRoutes />
            </div>
          </Content>
          {!isAdmin && <Footer />}
        </Layout> */}
      </Layout>
    </HotKeys>
  )
}
