import React, { useState, useContext, Fragment } from 'react'
import { MenuOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Dropdown, Menu, Modal, Tag } from 'antd'
import get from 'lodash/get'

import client from 'apollo'
import { AppContext } from 'AppContext'
import Page404 from 'components/Page404'
// import TicketDetails from './TicketDetails'
import { useQuery } from '@apollo/react-hooks'
import Loader from 'components/loaders/Loader'
// import CustomMeta from 'components/CustomMeta'
import { GET_TICKET } from './graphql/Queries'
import openNotification from 'utils/Notification'
import CloseTicketModal from './CloseTicketModal'
import { UPDATE_TICKET } from './graphql/Mutations'
import TicketDetailsNew from './TicketDetails/TicketDetailsNew'
import ticketBackIcon from 'assets/images/new-images/ticket-back-icon.svg'

const { confirm } = Modal

export default function TicketNew(props) {
  let ticket = {}
  let clientDetails = {}
  const [visibleMenu, setVisibleMenu] = useState(false)
  const [showFormModal, setShowFormModal] = useState(false)
  const [showFormLoading, setShowFormLoading] = useState(false)

  const {
    history,
    match: {
      params: { ticketId },
    },
  } = props
  const {
    state: {
      currentReport,
      isClient,
      currentUser: { id },
      isMobile,
    },
  } = useContext(AppContext)
  const { data, loading, error } = useQuery(GET_TICKET, {
    variables: { id: ticketId },
    fetchPolicy: 'network-only',
  })

  if (loading) return <Loader />
  if (error) return <Page404 error={error} />

  if (!loading && get(data, 'getTicket')) {
    ticket = data.getTicket
    clientDetails = data.getTicket.generatedBy
  }
  const selfGenerated = ticket.generatedBy.id === id
  function handleCloseTicket(values, resetForm) {
    setShowFormLoading(true)
    const { reason } = values
    const data = { reason, status: 'CLOSED', closeById: id }
    client
      .mutate({
        mutation: UPDATE_TICKET,
        variables: { data, id: ticketId },
        refetchQueries: [
          {
            query: GET_TICKET,
            variables: { id: ticketId },
            fetchPolicy: 'network-only',
          },
        ],
      })
      .then((res) => {
        setShowFormLoading(false)
        openNotification('success', 'Ticket Closed Successfully')
        setShowFormModal(false)
        resetForm()
      })
      .catch((err) => console.log(err))
  }

  function showConfirm(text, status) {
    confirm({
      title: `Do you want to ${text} this ticket?`,
      icon: <ExclamationCircleOutlined />,
      content: `When clicked the OK button, this ticket will be ${text}`,
      onOk() {
        Modal.destroyAll()
        if (text === 'closed') setShowFormModal(true)
        else {
          client
            .mutate({
              mutation: UPDATE_TICKET,
              variables: {
                data: { status, closeById: text === 'closed' ? id : null },
                id: ticketId,
              },
              refetchQueries: [
                { query: GET_TICKET, variables: { id: ticketId } },
              ],
            })
            .then((res) =>
              openNotification('success', 'Ticket Opened Successfully')
            )
            .catch((err) => console.log(err))
        }
      },
    })
  }

  function renderStatusButton() {
    if (isClient || selfGenerated || !currentReport.includes('Update Ticket'))
      return <Fragment />
    // if (window.innerWidth >= 991) {
    //   switch (ticket.status) {
    //     case 'PENDING':
    //       return (
    //         <Button
    //           key='1'
    //           type='primary'
    //           onClick={() => showConfirm('open', 'OPEN')}
    //         >
    //           Open
    //         </Button>
    //       )
    //     case 'OPEN':
    //       return (
    //         <Button
    //           key='1'
    //           type='primary'
    //           onClick={() => setShowFormModal(true)}
    //         >
    //           Close
    //         </Button>
    //       )
    //     default:
    //       return <Fragment />
    //   }
    // } else {
    switch (ticket.status) {
      case 'PENDING':
        return (
          <Menu.Item
            key='1'
            onClick={() => {
              showConfirm('open', 'OPEN')
              setVisibleMenu(!visibleMenu)
            }}
          >
            Open Ticket
          </Menu.Item>
        )
      case 'OPEN':
        return (
          <Menu.Item
            key='1'
            onClick={() => {
              showConfirm('closed', 'CLOSED')
              setVisibleMenu(!visibleMenu)
            }}
          >
            Close Ticket
          </Menu.Item>
        )
      default:
        return (
          <Menu.Item
            key='1'
            onClick={() => {
              showConfirm('re-open', 'OPEN')
              setVisibleMenu(!visibleMenu)
            }}
          >
            Re-Open Ticket
          </Menu.Item>
        )
    }
    // }
  }

  const menu = <Menu>{renderStatusButton()}</Menu>

  return (
    <>
      <div className='main_dashboard_div'>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div
            style={{
              fontSize: '24px',
              fontWeight: '600',
              lineHeight: '26px',
              letterSpacing: '-0.02em',
              color: '#000000',
            }}
          >
            <img
              src={ticketBackIcon}
              alt='ticketBackIcon'
              onClick={() => history.goBack()}
              style={{ cursor: 'pointer' }}
            />{' '}
            <span
              style={{
                position: 'relative',
                top: '4px',
                fontSize: isMobile ? '18px' : '24px',
                lineHeight: isMobile ? '0px' : '20px',
              }}
            >{`#${ticket.ticketNumber}`}</span>
          </div>
          <div style={{ display: 'flex', gap: '10px', justifyContent: 'end' }}>
            <Tag
              style={{
                marginRight: '0',
                height: isMobile ? '25px' : '36px',
                padding: isMobile ? '2px' : '5px',
                fontSize: isMobile ? '10px' : '12px',
                // paddingLeft: '5px',
                // paddingRight: '5px',
              }}
              color={ticket.status === 'CLOSED' ? 'green' : 'blue'}
            >
              {ticket.status === 'CLOSED' ? 'Closed' : 'In Progress'}
            </Tag>
            {!isClient && !selfGenerated && ticket.status !== 'CLOSED' && (
              <Dropdown
                onVisibleChange={() => setVisibleMenu(false)}
                key='0'
                overlay={menu}
                onClick={() => setVisibleMenu(!visibleMenu)}
                visible={visibleMenu}
              >
                <Button>
                  <MenuOutlined />
                </Button>
              </Dropdown>
            )}
          </div>
        </div>
        {!isClient && showFormModal && (
          <CloseTicketModal
            visible={showFormModal}
            onCreate={handleCloseTicket}
            onCancel={() => setShowFormModal(false)}
            loading={showFormLoading}
          />
        )}
        <TicketDetailsNew details={clientDetails} ticket={ticket} />
      </div>
    </>
  )
}
