import React, { useState, useEffect } from 'react'
import {
  Modal,
  Form,
  Input,
  Select,
  Switch,
  Row,
  Col,
  Button,
  Upload,
  DatePicker,
  Alert,
} from 'antd'
import { useQuery } from '@apollo/react-hooks'
import get from 'lodash/get'

import { GET_ALL_CLIENT, USER_PUBLIC } from '../../Users/graphql/Queries'
import moment from 'moment'
import _, { orderBy, uniqBy } from 'lodash'
import client from 'apollo'
import { UploadOutlined } from '@ant-design/icons'
import ReactQuill from 'react-quill'
import { GET_REMINDERS } from 'modules/Reminder/graphql/query'
import { GET_CC_EMAIL } from 'modules/Settings/graphql/Queries'

const { Option } = Select

const CollectionCreateForm = ({
  visible,
  onCreate,
  onUpdate,
  onCancel,
  isEdit,
  selectedNotification,
  loader,
}) => {
  const formItemLayout = {
    labelCol: { xs: { span: 24 }, sm: { span: 24 } },
    wrapperCol: { xs: { span: 24 }, sm: { span: 24 } },
  }
  const [form] = Form.useForm()
  const [usersData, setUsersData] = useState([])
  const [userLoading, setUserLoading] = useState(false)
  const [userDetailSelect, setUserDetailSelect] = useState()
  const [clientCodeForCCMail, setClientCodeForCCMail] = useState()
  const [ccMail, setCcMail] = useState(
    selectedNotification?.ccMailIds ? selectedNotification?.ccMailIds : []
  )
  const [toMail, setToMail] = useState([])
  const [reminderWhatsappStatus, setReminderWhatsappStatus] = useState(true)
  const [templateHtmlMandate, setTemplateHtmlMandate] = useState('')
  const [isOpenScheduleDate, setIsOpenScheduleDate] = useState(false)
  const [selectedScheduleDate, setSelectedScheduleDate] = useState()
  const [mailDraftType, setMailDraftType] = useState('')
  const [selectMailType, setSelectMailType] = useState()
  const [fileList, setFileList] = useState(
    selectedNotification?.attachment
      ? selectedNotification.attachment.map((data, index) => {
          return {
            uid: index,
            name: 'document.png',
            url: data,
          }
        })
      : []
  )
  const onChangeCheckBox = (checked) => {
    console.log(ccMail)
    setReminderWhatsappStatus(checked === true ? true : false)
  }

  let userEmails = []
  const { data: userData, loading } = useQuery(USER_PUBLIC, {
    fetchPolicy: 'network-only',
  })

  if (!loading && get(userData, 'getUsersPublic.users')) {
    userData.getUsersPublic.users?.length &&
      userData.getUsersPublic.users.forEach((item, key) => {
        userEmails.push({
          key,
          label: item?.email,
          value: item?.email,
        })
      })
  }

  const { data, loading: reminderLoading } = useQuery(GET_REMINDERS, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    setUserLoading(true)
    client
      .query({
        query: GET_ALL_CLIENT,
        variables: {
          where: {},
          limit: 10000,
        },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        res.data.getClientUsers.users.map((user) => {
          return usersData.push(user)
        })
        setUsersData(_.uniqBy(usersData, 'email'))
        setUserLoading(false)
      })
      .catch((err) => console.log({ err }))
      .finally(() => setUserLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function resetForm() {
    form.resetFields()
  }

  const formDataReset = () => {
    form.resetFields()
  }

  const handleSelectClientChange = (e) => {
    const data = usersData.filter((item) => (e === item?.id ? item : ''))
    setClientCodeForCCMail(data[0].clientCode)
    setUserDetailSelect({
      name: `${data[0].firstName} ${data[0].lastName}`,
      email: data[0].email,
    })
    if (!e.length) {
      formDataReset()
    }
  }

  useEffect(() => {
    if (clientCodeForCCMail) {
      client
        .query({
          query: GET_CC_EMAIL,
          variables: { clientCode: clientCodeForCCMail },
        })
        .then((res) => {
          setCcMail(res?.data?.getCCEmails?.HKeyEmail?.split(','))
        })
    }
  }, [clientCodeForCCMail, userDetailSelect])

  const handleEditInputChange = (e) => {
    const array = e.map((email) => {
      return {
        email,
      }
    })

    setCcMail(array)
  }
  const handleEditInputToMail = (e) => {
    const array = e.map((email) => {
      return {
        email,
      }
    })

    setToMail(array)
  }

  const normFile = (e) => {
    setFileList([])
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const customProps = {
    multiple: false,
    onRemove: (file) => setFileList([]),
    beforeUpload: (file) => {
      setFileList(file)
      return false
    },
    fileList,
  }

  const handleValueMandate = (content) => {
    setTemplateHtmlMandate(content)
  }

  const onChangeScheduleDate = (checked) => {
    setIsOpenScheduleDate(checked)
  }

  const selectScheduleDate = (date) => {
    setSelectedScheduleDate(moment(date).format('YYYY-MM-DD'))
  }

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote', 'code-block'],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ direction: 'rtl' }], // text direction

      [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],

      ['clean'], // remove formatting button
    ],

    keyboard: {
      bindings: {
        'list autofill': {
          prefix: /^\s*()$/,
        },
      },
    },
  }

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'color',
    'background',
    'image',
    'video',
    'align',
    'direction',
    'code-block',
    'formula',
  ]

  const handleSelectMailType = (e) => {
    setMailDraftType(e)
    const value =
      !reminderLoading &&
      data?.getReminders.filter((item, key) => {
        return item.name === e
      })

    selectMailType &&
      setTemplateHtmlMandate(
        selectMailType === 'mandate'
          ? value[0]?.mandateTemplate
          : value[0]?.nonMandateTemplate
      )
  }
  const handleChange = (e) => {
    setSelectMailType(e)
    const value =
      !reminderLoading &&
      data?.getReminders.filter((item, key) => {
        return item.name === mailDraftType
      })

    setTemplateHtmlMandate(
      e === 'mandate' ? value[0]?.mandateTemplate : value[0]?.nonMandateTemplate
    )
  }
  return (
    <Modal
      maskClosable={false}
      visible={visible}
      width={800}
      title={isEdit ? 'Edit Notification' : 'Add Notification'}
      okText={isEdit ? 'Update Notification' : 'Send Notification'}
      cancelText='Cancel'
      onCancel={() => {
        resetForm()
        onCancel()
      }}
      okButtonProps={{
        loading: loader,
        disabled: templateHtmlMandate.includes(
          '{{Amc Due Date}}',
          '{{Invoice Link}}'
        ),
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            const data = {
              ...values,
              toMailIds: toMail?.length
                ? toMail.map((item) => {
                    return item?.email
                  })
                : userDetailSelect?.email,
              ccMailIds:
                ccMail?.length &&
                ccMail.map((item) => {
                  return item
                }),
              description:
                templateHtmlMandate || selectedNotification?.description,
              draftTemplate: 'mandate',
              isWhatsappNotification: reminderWhatsappStatus,
              date: selectedScheduleDate,
            }
            if (isEdit) {
              onUpdate(data, resetForm)
            } else {
              onCreate(data, resetForm)
            }
          })
          .catch((info) => console.log('Validate Failed:', info))
      }}
    >
      <Form
        form={form}
        layout='vertical'
        name='form_in_modal'
        {...formItemLayout}
        initialValues={{
          modifier: 'public',
          ...selectedNotification,
          date: get(selectedNotification, 'date')
            ? moment(selectedNotification.date)
            : undefined,
          attachment: fileList,
        }}
      >
        <Row style={{ display: 'flex', flexDirection: 'unset' }}>
          <Col xs={12} sm={12} md={12} xl={12} lg={12}>
            <Form.Item
              name='receiverIds'
              label='Select Users'
              rules={[{ required: true, message: 'Please select users!' }]}
              hasFeedback
            >
              <Select
                placeholder='Select Client'
                getPopupContainer={(trigger) => trigger.parentElement}
                showSearch
                allowClear
                optionFilterProp='children'
                loading={userLoading}
                filterOption={true}
                onChange={(e) => handleSelectClientChange(e)}
              >
                {!userLoading &&
                  usersData?.length &&
                  orderBy(usersData, 'id', 'desc').map((user, key) => {
                    return (
                      <>
                        <Option key={key} value={user.id}>
                          {user.clientCode}-{' '}
                          {`${user.firstName || ''} ${user.lastName || ''}`}
                        </Option>
                      </>
                    )
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} xl={12} lg={12}>
            <Form.Item label='Client Name'>
              <Input
                placeholder='Client Name'
                value={
                  userDetailSelect?.name
                    ? userDetailSelect?.name
                    : selectedNotification
                    ? `${selectedNotification?.receivers[0]?.firstName} ${selectedNotification?.receivers[0]?.lastName}`
                    : ''
                }
                disabled
              />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} xl={12} lg={12}>
            <Form.Item label='To Mail'>
              <Select
                mode='tags'
                getPopupContainer={(trigger) => trigger.parentElement}
                allowClear
                placeholder='Please Select To Mail'
                onChange={(e) => handleEditInputToMail(e)}
                options={uniqBy(userEmails, 'value')}
                value={
                  toMail?.length || userDetailSelect?.email
                    ? toMail?.length
                      ? toMail.map((item) => {
                          return item?.email
                        })
                      : userDetailSelect?.email
                    : selectedNotification?.toMailIds.map((mailIds) => mailIds)
                }
              />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} xl={12} lg={12}>
            <Form.Item label='CC Mail'>
              <Select
                mode='tags'
                allowClear
                getPopupContainer={(trigger) => trigger.parentElement}
                placeholder='Please Select CC Mail'
                onChange={(e) => handleEditInputChange(e)}
                options={uniqBy(userEmails, 'value')}
                // value={ccMail.map((item) => {
                //   return item
                // })}
              />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} xl={12} lg={12}>
            <Form.Item name='title' label='Subject Name'>
              <Input placeholder='Enter Subject Name' />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} xl={12} lg={12}>
            <Form.Item label='Mail Draft Type'>
              <Select
                disabled={isEdit ? true : false}
                loading={reminderLoading}
                getPopupContainer={(trigger) => trigger.parentElement}
                placeholder='Select Mail draft type'
                onChange={(e) => {
                  handleSelectMailType(e)
                }}
              >
                {!reminderLoading &&
                  data?.getReminders.map((item, key) => {
                    return (
                      <>
                        <Option key={key} value={item.name}>
                          {item.name}
                        </Option>
                      </>
                    )
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} xl={12} lg={12}>
            <Form.Item name='draftTemplate' label='Mail Draft Select'>
              <Select
                disabled={mailDraftType ? false : true}
                getPopupContainer={(trigger) => trigger.parentElement}
                onChange={(e) => handleChange(e)}
                placeholder='Select Mail Type'
                options={[
                  {
                    value: 'mandate',
                    label: 'Mandate',
                  },
                  {
                    value: 'nonMandate',
                    label: 'Non Mandate',
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} xl={12} lg={12}>
            <Form.Item
              name='isWhatsappNotification'
              label='Whatsapp Notification'
            >
              <Switch
                checked={reminderWhatsappStatus}
                onChange={onChangeCheckBox}
                disabled
              />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} xl={12} lg={12}>
            <Form.Item
              name='attachment'
              label='Attachment'
              valuePropName='fileList'
              getValueFromEvent={normFile}
            >
              <Upload
                name='attachment'
                listType='text'
                {...customProps}
                accept='.jpeg,.png,.jpg,.pdf,.docx,.doc'
              >
                <Button>
                  <UploadOutlined /> Click to upload
                </Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} xl={12} lg={12}>
            <Form.Item label='Schedule Date Select'>
              <Switch
                checked={selectedNotification?.date ? true : isOpenScheduleDate}
                onChange={onChangeScheduleDate}
                disabled={selectedNotification?.date ? true : false}
              />
            </Form.Item>
          </Col>
          {(selectedNotification?.date || isOpenScheduleDate) && (
            <Col xs={12} sm={12} md={12} xl={12} lg={12}>
              <Form.Item name='date' label='Schedule Date'>
                <DatePicker
                  style={{
                    width: '100%',
                  }}
                  getPopupContainer={trigger => trigger.parentElement}
                  inputReadOnly={true}
                  onChange={selectScheduleDate}
                  placeholder='Select Schedule Date'
                  disabledDate={(current) => {
                    return (
                      current && moment(current).format() < moment().format()
                    )
                  }}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Alert
          message='You can use {{First name}} variables for the dynamic value. It is case sensitive'
          type='warning'
          showIcon
        />
        <ReactQuill
          className='reminderQuill'
          theme='snow'
          value={templateHtmlMandate || selectedNotification?.description || ''}
          onChange={handleValueMandate}
          modules={modules}
          formats={formats}
        />
        <br />
        {templateHtmlMandate.includes(
          '{{Amc Due Date}}',
          '{{Invoice Link}}'
        ) && (
          <Alert
            message='You can not use {{Amc Due Date}}, {{Invoice Link}} variables for the dynamic value'
            type='error'
            showIcon
          />
        )}

        <div className='footer-btn-div'>
          <Button
            className='footer-cancel-btn'
            onClick={() => {
              resetForm()
              onCancel()
            }}
          >
            Cancel
          </Button>
          <Button
            className='footer-submit-btn'
            key='submit'
            type='primary'
            loading={loader}
            disabled={templateHtmlMandate.includes(
              '{{Amc Due Date}}',
              '{{Invoice Link}}'
            )}
            onClick={() => {
              form
                .validateFields()
                .then((values) => {
                  const data = {
                    ...values,
                    toMailIds: toMail?.length
                      ? toMail.map((item) => {
                          return item?.email
                        })
                      : userDetailSelect?.email,
                    ccMailIds:
                      ccMail?.length &&
                      ccMail.map((item) => {
                        return item
                      }),
                    description:
                      templateHtmlMandate || selectedNotification?.description,
                    draftTemplate: 'mandate',
                    isWhatsappNotification: reminderWhatsappStatus,
                    date: selectedScheduleDate,
                  }
                  if (isEdit) {
                    onUpdate(data, resetForm)
                  } else {
                    onCreate(data, resetForm)
                  }
                })
                .catch((info) => console.log('Validate Failed:', info))
            }}
          >
            {isEdit ? 'Update Notification' : 'Send Notification'}
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

const CreateNotificationModal = (props) => {
  return (
    <div>
      <CollectionCreateForm {...props} />
    </div>
  )
}

export default CreateNotificationModal
