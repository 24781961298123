import { useQuery } from '@apollo/react-hooks'
import { Button, Checkbox } from 'antd'
import client from 'apollo'
import { AppContext } from 'AppContext'
import { GET_CURRENT_USER } from 'auth/graphql/Queries'
import Loader from 'components/loaders/Loader'
import React, { useContext, useEffect, useState } from 'react'
import { UPDATE_BUTTON_SETTING } from '../graphql/Mutations'
import openNotification from 'utils/Notification'

const PayNowButtonVisibility = () => {
  const {
    state: { isMobile },
  } = useContext(AppContext)

  const CheckboxGroup = Checkbox.Group
  const plainOptions = ['E-Mandate', 'Non-Mandate']
  const [checkedList, setCheckedList] = useState([])
  const [dataLoading, setDataLoading] = useState(false)

  const { data, loading } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: 'network-only',
  })

  const eMandate = data?.currentUser?.payNowSetting?.isMandate
  const nonMandate = data?.currentUser?.payNowSetting?.isNonMandate

  useEffect(() => {
    const updatedCheckedList = []

    if (eMandate) updatedCheckedList.push('E-Mandate')
    if (nonMandate) updatedCheckedList.push('Non-Mandate')

    setCheckedList(updatedCheckedList)
  }, [eMandate, nonMandate])

  if (loading) {
    return <Loader />
  }

  const onChange = (list) => {
    setCheckedList(list)
  }

  const updateData = () => {
    let isMandate = checkedList.includes('E-Mandate') ? true : false
    let isNonMandate = checkedList.includes('Non-Mandate') ? true : false
    setDataLoading(true)
    client
      .mutate({
        mutation: UPDATE_BUTTON_SETTING,
        variables: {
          data: {
            isMandate,
            isNonMandate,
          },
          where: {
            id: 1,
          },
        },
      })
      .then((res) => {
        console.log(res)
        setDataLoading(false)
        openNotification('success', 'Setting updated successfully')
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <div className='main_dashboard_div'>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        <div
          style={{
            fontSize: isMobile ? '18px' : '24px',
            fontWeight: '600',
            lineHeight: '26px',
            letterSpacing: '-0.02em',
            textAlign: 'left',
            color: '#181C32',
          }}
        >
          Pay now button setting
        </div>
      </div>
      <div>
        <CheckboxGroup
          options={plainOptions}
          value={checkedList}
          onChange={onChange}
        />
      </div>
      <div
        style={{
          marginTop: '20px',
        }}
      >
        <Button
          type='primary'
          htmlType='submit'
          onClick={updateData}
          loading={dataLoading === true ? <Loader /> : ''}
        >
          Save
        </Button>
      </div>
    </div>
  )
}

export default PayNowButtonVisibility
