import React, { useEffect, useState } from 'react'
import { Modal, Form, Button, Input, Row, Col } from 'antd'
import client from 'apollo'
import { UPDATE_RISK_PROFILE_SCORE } from './graphql/Mutation'
import { GET_RISK_PROFILE_SCORE } from './graphql/Queries'

const CreateRiskProfileScoreSetting = ({
  isModalOpen,
  setOpenModal,
  setModalLoading,
  modalLoading,
  data,
  handleCancel,
}) => {
  const [form] = Form.useForm()
  const [lowRiskMin, setLowRiskMin] = useState(data?.lowRisk?.minScore || 0)
  const [lowRiskMax, setLowRiskMax] = useState(data?.lowRisk?.maxScore || 0)
  const [moderateRiskMin, setModerateRiskMin] = useState(
    data?.moderateRisk?.minScore || 0
  )
  const [moderateRiskMax, setModerateRiskMax] = useState(
    data?.moderateRisk?.maxScore || 0
  )
  const [highRiskMin, setHighRiskMin] = useState(data?.highRisk?.minScore || 0)
  const [highRiskMax, setHighRiskMax] = useState(data?.highRisk?.maxScore || 0)

  useEffect(() => {
    setLowRiskMax((prevMax) =>
      moderateRiskMin !== undefined ? moderateRiskMin - 1 : prevMax
    )
  }, [moderateRiskMin])

  useEffect(() => {
    setModerateRiskMin((prevMin) =>
      lowRiskMax !== undefined ? lowRiskMax + 1 : prevMin
    )
    setModerateRiskMax((prevMax) =>
      highRiskMin !== undefined ? highRiskMin - 1 : prevMax
    )
  }, [lowRiskMax, highRiskMin])

  useEffect(() => {
    setHighRiskMin((prevMin) =>
      moderateRiskMax !== undefined ? moderateRiskMax + 1 : prevMin
    )
  }, [moderateRiskMax])

  const handleUpdateRange = () => {
    setModalLoading(true)
    const updatedData = {
      lowRisk: JSON.stringify({ minScore: lowRiskMin, maxScore: lowRiskMax }),
      moderateRisk: JSON.stringify({
        minScore: moderateRiskMin,
        maxScore: moderateRiskMax,
      }),
      highRisk: JSON.stringify({
        minScore: highRiskMin,
        maxScore: highRiskMax,
      }),
    }
    client
      .mutate({
        mutation: UPDATE_RISK_PROFILE_SCORE,
        variables: { data: updatedData, where: { id: data?.id } },
        refetchQueries: [
          {
            query: GET_RISK_PROFILE_SCORE,
          },
        ],
      })
      .then((res) => {
        setModalLoading(false)
        setOpenModal(false)
      })
      .catch((err) => {
        console.log(err)
        setModalLoading(false)
      })
  }

  return (
    <>
      <Modal
        className='faqs-modals'
        visible={isModalOpen}
        onCancel={handleCancel}
        title='Update Risk Profile Score Setting'
      >
        <Form
          form={form}
          layout='vertical'
          name='form_in_modal'
          onFinish={handleUpdateRange}
        >
          <Row gutter={16}>
            <Col className='gutter-row'>
              <Form.Item label='Low Risk Profile Min Score'>
                <Input
                  type='number'
                  onChange={(e) => setLowRiskMin(+e.target.value)}
                  value={lowRiskMin}
                />
              </Form.Item>
            </Col>
            <Col className='gutter-row'>
              <Form.Item label='Low Risk Profile Max Score'>
                <Input
                  type='number'
                  value={lowRiskMax}
                  onChange={(e) => setLowRiskMax(+e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col className='gutter-row'>
              <Form.Item label='Moderate Risk Profile Min Score'>
                <Input
                  type='number'
                  value={moderateRiskMin}
                  onChange={(e) => setModerateRiskMin(+e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col className='gutter-row'>
              <Form.Item label='Moderate Risk Profile Max Score'>
                <Input
                  type='number'
                  value={moderateRiskMax}
                  onChange={(e) => setModerateRiskMax(+e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col className='gutter-row'>
              <Form.Item label='High Risk Profile Min Score'>
                <Input
                  type='number'
                  value={highRiskMin}
                  onChange={(e) => setHighRiskMin(+e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col className='gutter-row'>
              <Form.Item label='High Risk Profile Max Score'>
                <Input
                  type='number'
                  value={highRiskMax}
                  onChange={(e) => setHighRiskMax(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button type='primary' htmlType='submit' loading={modalLoading}>
              Submit Risk Profile Score Range
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
export default CreateRiskProfileScoreSetting
