import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import {
  GET_DEFAULT_ADMIN_FIELD_SELECTION,
  GET_ROLES,
} from 'modules/Groups/graphql/Queries'
import './index.css'
import { Divider } from 'antd'
import client from 'apollo'
import { Button, Checkbox, Form, Select } from 'antd'
import Loader from 'components/loaders/Loader'
import openNotification from 'utils/Notification'
import { AppContext } from 'AppContext'
import { UPDATE_DEFAULT_ADMIN_FIELDS } from '../graphql/Mutations'
const { Option } = Select

const formItemLayout = {
  labelCol: { xs: { span: 24 }, sm: { span: 24 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 24 } },
}

function ClientFieldAccess() {
  const [selectedValue, setSelectedValue] = useState('ADMIN')
  const [loading, setLoading] = useState(false)
  const [selectAllCheck, setSelectAllCheck] = useState()
  const [adminAllowedFields, setAdminAllowedFields] = useState({})

  const getFieldData = useCallback(() => {
    client
      .query({
        query: GET_DEFAULT_ADMIN_FIELD_SELECTION,
        variables: {
          data: {
            role: selectedValue,
          },
        },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        setLoading(false)
        const allowedFields = res?.data?.getAllDefaultFieldSelection
        if (allowedFields[0]) {
          setAdminAllowedFields(allowedFields[0])
        }
      })
      .catch((err) => console.log(err))
  }, [selectedValue])

  // useEffect now uses the memoized getFieldData function
  useEffect(() => {
    getFieldData()
  }, [getFieldData])

  const { data: roles } = useQuery(GET_ROLES, {
    fetchPolicy: 'network-only',
  })

  const {
    state: { isMobile },
  } = useContext(AppContext)

  const selectValue = (value, option) => {
    setLoading(true)
    setSelectedValue(value)
  }

  const updateSelectedFieldModal = (e, key, data) => {
    setSelectAllCheck(false)
    setAdminAllowedFields({
      ...adminAllowedFields,
      [key]: e.target.checked,
    })
  }

  const handleOk = () => {
    setLoading(true)
    delete adminAllowedFields.__typename
    delete adminAllowedFields.createdAt
    delete adminAllowedFields.updatedAt
    delete adminAllowedFields.deletedAt
    delete adminAllowedFields.userId
    delete adminAllowedFields.id
    delete adminAllowedFields.role
    delete adminAllowedFields.action

    client
      .mutate({
        mutation: UPDATE_DEFAULT_ADMIN_FIELDS,
        variables: { data: adminAllowedFields, where: { role: selectedValue } },
      })
      .then((res) => {
        setLoading(false)
        openNotification('success', 'Client fields update successfully')
      })
      .catch((err) => {
        setLoading(false)
      })
  }
  const selectAll = (value) => {
    setSelectAllCheck(value)
    for (const item in adminAllowedFields) {
      setAdminAllowedFields((prevStep) => ({
        ...prevStep,
        [item]: value,
      }))
    }
  }
  return (
    <div style={{ height: '100%', overflow: 'auto' }}>
      <div className='main_dashboard_div'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
          }}
        >
          <div
            style={{
              fontSize: isMobile ? '18px' : '24px',
              fontWeight: '600',
              lineHeight: '26px',
              letterSpacing: '-0.02em',
              textAlign: 'left',
              color: '#181C32',
            }}
          >
            Client-Fields
          </div>
        </div>
        <Form {...formItemLayout} className='user-form'>
          <Form.Item label='Select Role'>
            <Select
              value={selectedValue}
              showSearch
              getPopupContainer={(trigger) => trigger.parentElement}
              style={{
                width: 'auto',
                marginBottom: '12px',
                paddingLeft: isMobile ? '0px !important' : '16px !important',
              }}
              placeholder='Option Type'
              optionFilterProp='children'
              onChange={selectValue}
            >
              {roles?.getRoles &&
                Object.values(roles?.getRoles)
                  .map((item) => item.role)
                  .map((data, key) => {
                    return (
                      <Option value={data} key={key}>
                        {data}
                      </Option>
                    )
                  })}
            </Select>
          </Form.Item>
        </Form>

        {selectedValue && (
          <Checkbox
            onChange={(e) => selectAll(e.target.checked)}
            name='selectAllcheck'
            checked={selectAllCheck}
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            ---SELECT ALL---
          </Checkbox>
        )}
        <Divider />
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            fontSize: isMobile ? '12px' : '14px',
          }}
        >
          {!loading ? (
            Object.keys(adminAllowedFields)?.map((key) => {
              if (
                key !== 'createdAt' &&
                key !== 'updatedAt' &&
                key !== 'deletedAt' &&
                key !== 'userId' &&
                key !== 'id' &&
                key !== 'role' &&
                key !== 'action' &&
                key !== '__typename'
              ) {
                return (
                  <div
                    style={{
                      width: isMobile ? '50%' : '25%',
                      display: 'flex',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <div
                      key={key}
                      style={{
                        display: 'flex',
                        padding: isMobile ? '1px' : '2px',
                        margin: isMobile ? '5px' : '2px',
                        alignItems: 'center',
                      }}
                    >
                      <Checkbox
                        checked={adminAllowedFields[key]}
                        key={key}
                        onChange={(e) => updateSelectedFieldModal(e, key)}
                        loading={loading}
                        style={{
                          textTransform: 'capitalize',
                          fontSize: 'medium',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {key.split(/(?=[A-Z])/).join(' ')}
                      </Checkbox>
                    </div>
                  </div>
                )
              } else {
                return null
              }
            })
          ) : (
            <Loader />
          )}
        </div>
        <div style={{ marginTop: '20px', display: 'flex', gap: '10px' }}>
          <Button
            key='submit'
            onClick={handleOk}
            style={{
              margin: '0',
              padding: '8px 16px',
              fontSize: '14px',
              fontWeight: '600',
              lineHeight: '24px',
              textAlign: 'left',
              color: '#AF0000',
              background: '#AF00000D',
              border: '1px solid #AF000080',
              height: '40px',
              borderRadius: '8px',
            }}
          >
            Save
          </Button>
          <Button
            onClick={() => getFieldData()}
            style={{
              margin: '0',
              padding: '8px 16px',
              fontSize: '14px',
              fontWeight: '600',
              lineHeight: '24px',
              textAlign: 'left',
              color: '#000000',
              border: '1px solid #919EAB52',
              height: '40px',
              borderRadius: '8px',
            }}
          >
            Reset
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ClientFieldAccess
