import React, { Fragment, useContext } from 'react'
// import { EditOutlined, MailOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import get from 'lodash/get'

import About from 'components/About'
import { AppContext } from 'AppContext'
import Page404 from 'components/Page404'
import { GET_USER } from './graphql/Queries'
import CustomMeta from 'components/CustomMeta'
import Loader from 'components/loaders/Loader'
import { getName, staffUsersData } from 'utils/User'
import LeadUsersChain from 'modules/Clients/components/LeadUsersChain'
import FilterBackIcon from 'assets/images/new-images/filter-back-button.svg'

export default function (props) {
  let userDetails = {}
  // const [visibleMenu, setVisibleMenu] = useState(false)
  const {
    state: { currentReport, isMobile },
  } = useContext(AppContext)
  const {
    history,
    match: {
      params: { userId },
    },
  } = props
  const { data, loading, error } = useQuery(GET_USER, {
    variables: { id: userId },
    fetchPolicy: 'network-only',
  })

  if (loading) return <Loader />
  if (error) return <Page404 error={error} />

  if (!loading && get(data, 'getUser')) {
    userDetails = data.getUser
  }

  // function handleMenuClick(e) {}

  // const menu = (
  //   <Menu onClick={handleMenuClick}>
  //     {userDetails.email && (
  //       <Menu.Item
  //         key='1'
  //         onClick={() => history.push(`/users/${userId}/${userDetails.email}`)}
  //       >
  //         <MailOutlined /> Mails
  //       </Menu.Item>
  //     )}
  //     {currentReport.includes('Update User') && (
  //       <Menu.Item
  //         key='1'
  //         onClick={() => history.push(`/users/${userId}/edit`)}
  //       >
  //         <EditOutlined /> Edit
  //       </Menu.Item>
  //     )}
  //   </Menu>
  // )

  return (
    <Fragment>
      <CustomMeta title={getName(userDetails)} description='Users' />
      <div className='main_dashboard_div'>
        {/* <PageHeader
          className='box'
          onBack={() => history.goBack()}
          title={getName(userDetails)}
          extra={[
            window.innerWidth >= 991 ? (
              <Fragment key={0}>
                {userDetails.email && (
                  <Button
                    key={1}
                    type='primary'
                    onClick={() =>
                      history.push(`/users/${userId}/${userDetails.email}`)
                    }
                  >
                    Mails
                  </Button>
                )}
                {currentReport.includes('Update User') && (
                  <Button
                    key={2}
                    type='primary'
                    onClick={() => history.push(`/users/${userId}/edit`)}
                  >
                    Edit
                  </Button>
                )}
              </Fragment>
            ) : (
              <Dropdown
                onVisibleChange={() => setVisibleMenu(false)}
                key={0}
                overlay={menu}
                onClick={() => setVisibleMenu(!visibleMenu)}
                visible={visibleMenu}
              >
                <Button key={1}>
                  <MenuOutlined />
                </Button>
              </Dropdown>
            ),
          ]}
        /> */}

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '16px',
            width: '100%',
          }}
        >
          <div
            style={{
              fontSize: '24px',
              fontWeight: '600',
              lineHeight: '26px',
              letterSpacing: '-0.02em',
              textAlign: 'left',
              color: '#181C32',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div
              onClick={() => history.goBack()}
              style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: isMobile ? '18px' : '24px',
              }}
            >
              <img style={{ cursor: 'pointer' }} src={FilterBackIcon} alt='' />{' '}
              &nbsp; {getName(userDetails)}
            </div>
          </div>
          <div style={{ display: 'flex', gap: '10px' }}>
            {userDetails.email && (
              <Button
                key={1}
                style={{
                  margin: '0',
                  padding: '8px 16px',
                  fontSize: '14px',
                  fontWeight: '600',
                  lineHeight: '24px',
                  textAlign: 'left',
                  color: '#AF0000',
                  background: '#AF00000D',
                  border: '1px solid #AF000080',
                  height: '40px',
                  borderRadius: '8px',
                }}
                onClick={() =>
                  history.push(`/users/${userId}/${userDetails.email}`)
                }
              >
                Mails
              </Button>
            )}
            {currentReport.includes('Update User') && (
              <Button
                key={2}
                style={{
                  margin: '0',
                  padding: '8px 16px',
                  fontSize: '14px',
                  fontWeight: '600',
                  lineHeight: '24px',
                  textAlign: 'left',
                  color: '#AF0000',
                  background: '#AF00000D',
                  border: '1px solid #AF000080',
                  height: '40px',
                  borderRadius: '8px',
                }}
                onClick={() => history.push(`/users/${userId}/edit`)}
              >
                Edit
              </Button>
            )}
          </div>
        </div>
        {userDetails.role !== 'ADMIN' && <LeadUsersChain userId={userId} />}
        <About
          details={staffUsersData(userDetails)}
          userId={userDetails.id}
          id={userId}
        />
      </div>
    </Fragment>
  )
}
