import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from 'AppContext'
import Page404 from 'components/Page404'
import Dashboard from 'modules/Dashboard'
import { Switch, Route } from 'react-router-dom'

import Profile from 'modules/Profile'

import Users from 'modules/Users'
import User from 'modules/Users/User'
import EditUser from 'modules/Users/EditUser'
import CreateUser from 'modules/Users/CreateUser'

import Clients from 'modules/Clients'
import Client from 'modules/Clients/Client'
import EditClient from 'modules/Clients/EditClient'
import NiftyEntry from 'modules/Clients/NiftyEntry'
import CreateClient from 'modules/Clients/CreateClient'
import MonthlyReturn from 'modules/Clients/MonthlyReturn'
import ClientPosition from 'modules/Clients/ClientPosition'
import MonthlyExposure from 'modules/Clients/MonthlyExposure'
import SavingOfInterest from 'modules/Clients/SavingOfInterest'
import ClientComparison from 'modules/Clients/ClientComparison'

import Mail from 'modules/Mail'

import Groups from 'modules/Groups'
import Group from 'modules/Groups/Group'

import ScheduleNotification from 'modules/ScheduleNotification'

import TicketsNew from 'modules/Tickets/TicketsNew'
// import Ticket from 'modules/Tickets/Ticket'

import Ilts from 'modules/Reports/ILTS'
import UnHedge from 'modules/Reports/UnHedge'
import PLReport from 'modules/Reports/PLReport'
import Interest from 'modules/Reports/Interest'
import NLVTracker from 'modules/Reports/NLVTracker'
import Performance from 'modules/Reports/Performance'
import NlvMisMatch from 'modules/Reports/NLVMisMatch'
import AverageIndex from 'modules/Reports/AverageIndex'
import LedgerReport from 'modules/Reports/LedgerReport'
import TradeReportEQ from 'modules/Reports/TradeReportEQ'
import TradeReportFO from 'modules/Reports/TradeReportFO'
import InvoiceReport from 'modules/Reports/InvoiceReport'
import DueAmc from 'modules/Reports/DueAmc'
// import UserPerformance from 'modules/Reports/UserPerformance'
import CurrentPosition from 'modules/Reports/CurrentPosition'
import RolloverTrackingReport from 'modules/Reports/RolloverTrackingReport'

import AMC from 'modules/AMC'
import SIP from 'modules/SIP'

// import Revenue from 'modules/Revenue'
import RevenueIncome from 'modules/RevenueIncome'

import Messages from 'modules/Messages'
import Settings from 'modules/Settings'
import Plans from 'modules/Plans'
import RolloverOpportunity from 'modules/Reports/Rolloveropportunity'
import IltsTaxationReport from 'modules/Reports/ILTSTaxationReport'
import IltsShift from 'modules/Reports/ILTSShift'
import Support from 'modules/Support'
import Jbalance from 'modules/Reports/Jbalance'
import PositionEQ from 'modules/Reports/PositionEQ'
import PositionFO from 'modules/Reports/PositionFO'
import Verification from './modules/Verification'
import Questionnaires from 'modules/Questionnaires'
import history from 'CustomHistory'
import Callback from 'auth/Callback'
import Flag from 'modules/Flag'
import SiteSetting from 'modules/Settings/SiteSetting'
import Reevalute from 'modules/Reevalute'
import EditAgreement from 'modules/Settings/EditAgreement'
import MaintenanceStatus from 'modules/Settings/AlertNotification'
import ClientFieldAccess from 'modules/Settings/ClientFieldAccess'
import ClientLog from 'modules/ClientLog'
import GeneralInvoice from 'modules/Settings/GeneralInvoice'
import Invoice from 'modules/Settings/Invoice'
import PendingAdvisory from 'modules/PendingAdvisory'
import InvoiceMaster from 'modules/Settings/InvoiceMaster'
import AmcMailFormat from 'modules/Settings/AMCMailFormat'
import FamilyGroup from 'modules/FamilyGroup'
import TicketMailForMate from 'modules/Settings/TicketMailFormat'
import Transactions from 'modules/Transactions'
import Reminder from 'modules/Reminder'
import MailLog from 'modules/MailLog'
import ComplianceDashboard from 'modules/clientDetails'
import LoginImages from 'modules/LoginImages/Index'
import EditTemplate from 'modules/Settings/EditTemplates'
import BulkAmc from 'modules/Reports/BulkAmc'
import AnalysisExpiryDate from 'modules/AnalysisExpiryDate'
import FAQuestions from 'modules/FAQuestions'
import NSEHoliday from 'modules/NSEHoliday'
import BookAdvisory from 'modules/BookAdvisory'
import AdvisoryTime from 'modules/AdvisoryTime'
import NewAuaAdvisory from 'modules/NewAuaAdvisory'
import PerformanceSummary from 'modules/Reports/PerformanceSummary'
import RiskProfileScore from 'modules/Settings/RiskProfileScore'
import PendingAdvisoryForAssociate from 'modules/PendingAdvisoryForAssociate'
import BulkClientSetting from 'modules/BulkClientReport/BulkClilentSetting'
import MultiClientReport from 'modules/BulkClientReport/MultiClientReport'
import FAQsModelNew from 'modules/Verification/Model/FAQsModalNew'
import TicketNew from 'modules/Tickets/TicketNew'
import PopularBlogs from 'modules/PopularBlogs'
import CompanyDetails from 'modules/CompanyDetails'
import HypotheticalLedger from 'modules/Reports/HypotheticalLedger'
import PayNowButtonVisibility from 'modules/Settings/PayNowButtonVisibility'
import bookAppointment from 'modules/BookAppointment'

export default function MainRoutes() {
  const [loading, setLoading] = useState(true)
  const {
    state: {
      isClient,
      isAssociate,
      isAdmin,
      isStaff,
      currentReport,
      isVerificationDone,
      currentUser: { isShowAddAua },
    },
  } = useContext(AppContext)

  useEffect(() => {
    if (
      isClient &&
      !isVerificationDone &&
      history?.location?.pathname !== '/faqs'
    ) {
      setLoading(false)
      history.push('/verification')
    } else {
      setLoading(false)
    }
  }, [isClient, isVerificationDone, isAdmin])

  if (loading) return <Callback />

  return (
    <Switch>
      <Route exact path='/' component={Dashboard} />
      <Route exact path='/profile' component={Profile} />
      <Route exact path='/faqs' component={FAQsModelNew} />
      <Route
        exact
        path='/profile/edit'
        component={isClient ? EditClient : EditUser}
      />
      {isClient && !isVerificationDone && (
        <Route exact path='/verification' component={Verification} />
      )}
      {isClient && (
        <Route exact path='/book-appointment' component={bookAppointment} />
      )}
      {!(isClient || isAssociate) && (
        <Route exact path='/users' component={Users} />
      )}
      {isAdmin && (
        <Route exact path='/compliance' component={ComplianceDashboard} />
      )}
      {!(isClient || isAssociate) && currentReport.includes('Create User') && (
        <Route exact path='/users/create' component={CreateUser} />
      )}
      {!(isClient || isAssociate) && (
        <Route exact path='/users/:userId' component={User} />
      )}
      {!(isClient || isAssociate) && currentReport.includes('Update User') && (
        <Route exact path='/users/:userId/edit' component={EditUser} />
      )}
      {!isClient && <Route exact path='/clients' component={Clients} />}
      {!isClient && currentReport.includes('Create Client') && (
        <Route exact path='/clients/create' component={CreateClient} />
      )}
      {!isClient && (
        <Route exact path='/clients/:clientId' component={Client} />
      )}
      {!isClient && (
        <Route
          exact
          path='/clients/:clientId/dashboard'
          component={Dashboard}
        />
      )}
      {!isClient && currentReport.includes('Update Client') && (
        <Route exact path='/clients/:clientId/edit' component={EditClient} />
      )}
      {!isClient && (
        <Route
          exact
          path='/clients/:clientId/position/:clientCode'
          component={ClientPosition}
        />
      )}
      {!isClient && (
        <Route
          exact
          path='/clients/:clientId/comparison-of-fund/:clientCode'
          component={ClientComparison}
        />
      )}
      {!isClient && (
        <Route
          exact
          path='/clients/:clientId/saving-of-interest/:clientCode'
          component={SavingOfInterest}
        />
      )}
      {!isClient && (
        <Route
          exact
          path='/clients/:clientId/nifty-entry/:clientCode'
          component={NiftyEntry}
        />
      )}
      {!isClient && (
        <Route
          exact
          path='/clients/:clientId/monthly-return/:clientCode'
          component={MonthlyReturn}
        />
      )}
      {!isClient && (
        <Route
          exact
          path='/clients/:clientId/monthly-exposure/:clientCode/:pnl'
          component={MonthlyExposure}
        />
      )}
      <Route exact path='/clients/:clientId/:email' component={Mail} />
      <Route exact path='/users/:userId/:email' component={Mail} />
      {isAdmin && <Route exact path='/groups' component={Groups} />}
      {isAdmin && (
        <Route
          exact
          path='/schedule-notifications'
          component={ScheduleNotification}
        />
      )}
      {isAdmin && <Route exact path='/groups/:groupId' component={Group} />}
      {isAdmin && <Route exact path='/login-images' component={LoginImages} />}
      {!isClient && (
        <Route exact path='/family-groups' component={FamilyGroup} />
      )}
      {currentReport.includes('view-amc') && (
        <Route exact path='/amc' component={AMC} />
      )}
      {currentReport.includes('view-sip') && (
        <Route exact path='/sip' component={SIP} />
      )}
      {isClient && <Route exact path='/reevalute' component={Reevalute} />}
      {isClient && (
        <Route exact path='/pending-advisory' component={PendingAdvisory} />
      )}
      {/* {!isClient && !isAdmin && <Route exact path='/revenue-income' component={Revenue} />} */}
      {isClient && currentReport.includes('ILTS') && (
        <Route
          exact
          path='/reports/performance/:id/comparison-of-fund/:clientCode'
          component={ClientComparison}
        />
      )}
      <Route
        exact
        path='/clients/:id/position/:clientCode/:id/comparison-of-fund/:clientCode'
        component={ClientComparison}
      />
      {isClient && currentReport.includes('ILTS') && (
        <Route exact path='/reports/performance' component={Ilts} />
      )}
      {isClient && currentReport.includes('Interest') && (
        <Route exact path='/reports/interest' component={Interest} />
      )}
      {currentReport.includes('cost-to-profit') && (
        <Route exact path='/reports/cost-to-profit' component={Performance} />
      )}
      {isClient && currentReport.includes('Average Index') && (
        <Route exact path='/reports/average-index' component={AverageIndex} />
      )}
      {isClient && currentReport.includes('NLV Tracker') && (
        <Route
          exact
          path='/reports/comparison-of-fund'
          component={NLVTracker}
        />
      )}
      {currentReport.includes('NLV Mismatch') && (
        <Route exact path='/reports/nlv-mismatch' component={NlvMisMatch} />
      )}
      {currentReport.includes('Unhedge') && (
        <Route exact path='/reports/unhedge' component={UnHedge} />
      )}
      {isClient && currentReport.includes('Current Position') && (
        <Route
          exact
          path='/reports/current-position'
          component={CurrentPosition}
        />
      )}
      {currentReport.includes('trade-eq') && (
        <Route exact path='/reports/trade-eq' component={TradeReportEQ} />
      )}
      {currentReport.includes('trade-fo') && (
        <Route exact path='/reports/trade-fo' component={TradeReportFO} />
      )}
      {currentReport.includes('ledger') && (
        <Route exact path='/reports/ledger' component={LedgerReport} />
      )}
      {currentReport.includes('Ledger Beta') && (
        <Route
          exact
          path='/reports/ledger-beta'
          component={HypotheticalLedger}
        />
      )}
      {currentReport.includes('pl Change') && (
        <Route exact path='/reports/pl' component={PLReport} />
      )}
      {currentReport.includes('Capital Gain') && (
        <Route
          exact
          path='/reports/ilts-taxation-report'
          component={IltsTaxationReport}
        />
      )}
      {currentReport.includes('Rollover Tracking') && (
        <Route
          exact
          path='/reports/rollover-tracking'
          component={RolloverTrackingReport}
        />
      )}
      {currentReport.includes('RollOver Opportunity') && (
        <Route
          exact
          path='/reports/rollover-opportunity'
          component={RolloverOpportunity}
        />
      )}
      {currentReport.includes('Scenario Analysis') && (
        <Route exact path='/reports/scenario-analysis' component={IltsShift} />
      )}
      <Route exact path='/reports/j-balance' component={Jbalance} />
      <Route exact path='/reports/position-eq' component={PositionEQ} />
      <Route exact path='/reports/position-fo' component={PositionFO} />
      {(isAdmin || isStaff) && (
        <Route exact path='/reports/invoice-report' component={InvoiceReport} />
      )}
      {currentReport.includes('amc-due-report') && (
        <Route exact path='/reports/amcs-due' component={DueAmc} />
      )}
      {isAdmin && <Route exact path='/reports/bulk-amcs' component={BulkAmc} />}
      <Route exact path='/tickets' component={TicketsNew} />
      <Route exact path='/tickets/:ticketId' component={TicketNew} />
      {!isClient && (
        <Route exact path='/revenue-income' component={RevenueIncome} />
      )}
      {(isAdmin || isStaff) && (
        <Route exact path='/messages' component={Messages} />
      )}
      {(isAdmin || isStaff) && (
        <Route exact path='/report-permission' component={Settings} />
      )}
      {(!isAdmin || isStaff) && (
        <Route exact path='/support' component={Support} />
      )}
      {(isAdmin || isStaff) && <Route exact path='/plans' component={Plans} />}
      {(isAdmin || isStaff) && (
        <Route exact path='/questionnaires' component={Questionnaires} />
      )}
      {(isAdmin || isStaff) && <Route exact path='/flags' component={Flag} />}
      {(isAdmin || isStaff) && (
        <Route exact path='/site-setting' component={SiteSetting} />
      )}
      {(isAdmin || isStaff) && (
        <Route exact path='/edit-agreement' component={EditAgreement} />
      )}
      {isAdmin && (
        <Route exact path='/alert-notification' component={MaintenanceStatus} />
      )}
      {(isAdmin || isStaff) && (
        <Route exact path='/maintain' component={SiteSetting} />
      )}
      {isAdmin && (
        <Route exact path='/client-fields' component={ClientFieldAccess} />
      )}
      {(isAdmin || isStaff) && (
        <Route exact path='/agreement-format' component={EditAgreement} />
      )}
      {(isAdmin || isStaff) && (
        <Route exact path='/templates-format' component={EditTemplate} />
      )}
      {(isAdmin || isStaff) && (
        <Route exact path='/amc-mail-format' component={AmcMailFormat} />
      )}
      {isAdmin && <Route exact path='/client-log' component={ClientLog} />}
      {isAdmin && <Route exact path='/invoice' component={Invoice} />}
      {isAdmin && (
        <Route exact path='/general-invoice' component={GeneralInvoice} />
      )}
      {isAdmin && (
        <Route exact path='/invoice-master' component={InvoiceMaster} />
      )}
      {(isAdmin || isStaff) && (
        <Route exact path='/ticket-mail-format' component={TicketMailForMate} />
      )}
      {isAdmin && <Route exact path='/transactions' component={Transactions} />}
      {isAdmin && <Route exact path='/reminder' component={Reminder} />}
      {isAdmin && <Route exact path='/mail-log' component={MailLog} />}
      {isAdmin && <Route exact path='/faq-questions' component={FAQuestions} />}
      {isAdmin && <Route exact path='/nse-holiday' component={NSEHoliday} />}
      {isAdmin && (
        <Route exact path='/book-advisory' component={BookAdvisory} />
      )}
      {isAdmin && (
        <Route exact path='/advisory-working-time' component={AdvisoryTime} />
      )}
      {isClient && isVerificationDone && isShowAddAua && (
        <Route exact path='/add-aua-verification' component={NewAuaAdvisory} />
      )}
      {currentReport.includes('Performance Summary Report') && (
        <Route
          exact
          path='/reports/performance-summary'
          component={PerformanceSummary}
        />
      )}
      {isAdmin && (
        <Route
          exact
          path='/analysis-expiry-date'
          component={AnalysisExpiryDate}
        />
      )}
      {isAdmin && (
        <Route
          exact
          path='/risk-profile-score-setting'
          component={RiskProfileScore}
        />
      )}
      {/* {isAdmin && (
        <Route
          exact
          path='/pay-now-button-setting'
          component={payNowButtonVisibility}
        />
      )} */}
      {isAdmin && (
        <Route
          exact
          path='/pay-now-button-setting'
          component={PayNowButtonVisibility}
        />
      )}
      {isAdmin && (
        <Route
          exact
          path='/bulk-client-setting'
          component={BulkClientSetting}
        />
      )}
      {isAdmin && (
        <Route exact path='/bulk-client-report' component={MultiClientReport} />
      )}
      {!isAdmin && !isClient && (
        <Route
          exact
          path='/pending-advisory-for-associate'
          component={PendingAdvisoryForAssociate}
        />
      )}
      {isAdmin && (
        <Route exact path='/popular-blogs' component={PopularBlogs} />
      )}

      {isAdmin && (
        <Route exact path='/company-summary' component={CompanyDetails} />
      )}
      <Route component={Page404} />
    </Switch>
  )
}
