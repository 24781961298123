import React, { useContext, useState } from 'react'
import { Button, Form, Input, Select } from 'antd'
import { AppContext } from 'AppContext'
import cities from 'utils/cities.json'
import client from 'apollo'
import { COMPLETE_STEP, SUBMIT_FEEDBACK_FORM } from '../graphql/Mutations'
import { UPDATE_USER } from 'modules/Users/graphql/Mutations'
import history from 'CustomHistory'

const { Option } = Select

const Bitrix24Form = ({
  confirmAdvisory,
  isUpdateUser = false,
  setOpenFeedback,
  confirmLoading = false,
}) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const {
    state: { currentUser },
    dispatch,
  } = useContext(AppContext)

  // useEffect(() => {
  //   const { firstName, lastName, email, phone } = currentUser
  //   const script = document.createElement('script')
  //   script.src =
  //     'https://cdn.bitrix24.com/b7363773/crm/form/loader_774.js?' +
  //     ((Date.now() / 180000) | 0)
  //   script.async = true
  //   script.setAttribute('data-b24-form', 'inline/774/7ufafv')
  //   script.setAttribute('data-skip-moving', 'true')

  //   const setDefaultNameValue = () => {
  //     const nameInput = document.querySelector('input[name="name"]')
  //     if (nameInput) {
  //       nameInput.value = firstName + ' ' + lastName
  //       const event = new Event('input', { bubbles: true })
  //       nameInput.dispatchEvent(event)
  //     }
  //   }

  //   const setDefaultEmailValue = () => {
  //     const emailInput = document.querySelector('input[name="email"]')
  //     if (emailInput) {
  //       emailInput.value = email
  //       const event = new Event('input', { bubbles: true })
  //       emailInput.dispatchEvent(event)
  //     }
  //   }

  //   const setDefaultPhoneValue = () => {
  //     const phoneInput = document.querySelector('input[name="phone"]')
  //     if (phoneInput) {
  //       phoneInput.value = `+91${phone}`
  //       const event = new Event('input', { bubbles: true })
  //       phoneInput.dispatchEvent(event)
  //     }
  //   }

  //   const handleFormSubmit = (event) => {
  //     event.preventDefault()
  //     if (isUpdateUser) {
  //       confirmAdvisory()
  //     }
  //     console.log('Form submitted')
  //   }

  //   const observer = new MutationObserver((mutations) => {
  //     const formElement = document.getElementById('form-container')
  //     const inputs = formElement.querySelectorAll('input, select, textarea')
  //     if (inputs.length > 0) {
  //       formElement.addEventListener('submit', handleFormSubmit)
  //       setDefaultNameValue()
  //       setDefaultEmailValue()
  //       setDefaultPhoneValue()
  //       observer.disconnect()
  //     }
  //   })

  //   observer.observe(document.getElementById('form-container'), {
  //     childList: true,
  //     subtree: true,
  //   })

  //   document.getElementById('form-container').appendChild(script)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentUser])

  const onFinish = (values) => {
    setLoading(true)
    client
      .mutate({
        mutation: SUBMIT_FEEDBACK_FORM,
        variables: {
          data: {
            whereDidYouHearAboutUs: values?.whereDidYouHearAboutUs,
            howDidYouOnBoard: values?.howDidYouOnBoard,
            howWasOnBoardingProcess: values?.howWasOnBoardingProcess,
            howWasTheSupportOurTeam: values?.howWasTheSupportOurTeam,
            feedBack: values?.feedBack,
          },
        },
      })
      .then((res) => {
        if (isUpdateUser) {
          let userData = {
            isNewAuaAdvisoryGenerate: false,
            isBookingAdvisoryDateSelected: false,
          }
          if (currentUser?.isNewAuaAdvisoryGenerate) {
            userData.newAuaAdvisoryCompletedSteps = 'DONE'
          } else {
            userData.completedSteps = 'DONE'
          }

          client
            .mutate({
              mutation: UPDATE_USER,
              variables: { data: { ...userData }, id: currentUser?.id },
            })
            .then((res) => {
              client.mutate({
                mutation: COMPLETE_STEP,
                variables: {
                  data: {
                    step: 'DONE',
                    from: 'WEB',
                  },
                },
              })
              dispatch({
                type: 'CURRENT_USER',
                data: res.data.updateUser,
              })
              dispatch({
                type: 'IS_VERIFICATION_DONE',
                data: true,
              })
              history.push('/')
            })
            .catch((err) => {
              console.log(err)
            })
        }

        setLoading(false)
        setOpenFeedback(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
      .finally(() => setLoading(false))
  }
  return (
    <div>
      {/* <div id='form-container'></div> */}

      <Form
        form={form}
        layout='vertical'
        name='form_in_modal'
        onFinish={onFinish}
        initialValues={{
          clientCode: currentUser?.clientCode,
          clientName: currentUser?.firstName + ' ' + currentUser?.lastName,
          email: currentUser?.email,
          city: cities.find((item) => item?.id === currentUser?.cityId)?.name,
        }}
      >
        <Form.Item name='clientCode' label='Client Code'>
          <Input
            disabled
            min={0}
            style={{ width: '100%' }}
            placeholder='Enter Client Code'
          />
        </Form.Item>
        <Form.Item name='clientName' label='Client Name'>
          <Input
            disabled
            min={0}
            style={{ width: '100%' }}
            placeholder='Enter client name'
          />
        </Form.Item>
        <Form.Item name='email' label='Email'>
          <Input
            disabled
            min={0}
            style={{ width: '100%' }}
            placeholder='Enter email'
          />
        </Form.Item>
        <Form.Item name='city' label='City'>
          <Input
            disabled
            min={0}
            style={{ width: '100%' }}
            placeholder='Enter city'
          />
        </Form.Item>

        <Form.Item
          name='whereDidYouHearAboutUs'
          label='Where did you hear about us?'
          rules={[
            {
              required: true,
              message: 'Please select where did you hear about us?',
            },
          ]}
        >
          <Select
            placeholder='Where did you hear about us?'
            className='display-field'
            getPopupContainer={(trigger) => trigger.parentElement}
          >
            <Option value={'4584'}>Google/YouTube Ads</Option>
            <Option value={'4586'}>YouTube Videos</Option>
            <Option value={'4588'}>Google Search</Option>
            <Option value={'4590'}>
              Social Networks (Facebook, Instagram, Twitter, etc.)
            </Option>
            <Option value={'4592'}>Blogs / Articles</Option>
            <Option value={'4594'}>Friends</Option>
            <Option value={'4596'}>Others</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name='howDidYouOnBoard'
          label='How did you onboard?'
          rules={[
            { required: true, message: 'Please select how did you onboard?' },
          ]}
        >
          <Select getPopupContainer={(trigger) => trigger.parentElement} placeholder='How did you onboard?' className='display-field'>
            <Option value={'4598'}>Android App</Option>
            <Option value={'4600'}>iOS App</Option>
            <Option value={'4602'}>Website</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name='howWasOnBoardingProcess'
          label='How was onboarding process?'
          rules={[
            {
              required: true,
              message: 'Please select how was onboarding process?',
            },
          ]}
        >
          <Select
            placeholder='How was onboarding process?'
            className='display-field'
            getPopupContainer={(trigger) => trigger.parentElement}
          >
            <Option value={'4604'}>Excellent</Option>
            <Option value={'4606'}>Good</Option>
            <Option value={'4608'}>could be better</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name='howWasTheSupportOurTeam'
          label='How was the support by our team?'
          rules={[
            {
              required: true,
              message: 'Please select how was the support by our team?',
            },
          ]}
        >
          <Select
            placeholder='How was the support by our team?'
            className='display-field'
            getPopupContainer={(trigger) => trigger.parentElement}
          >
            <Option value={'4610'}>Excellent</Option>
            <Option value={'4612'}>Good</Option>
            <Option value={'4614'}>could be better</Option>
          </Select>
        </Form.Item>

        <Form.Item name='feedBack' label='Feedback/Suggestions'>
          <Input.TextArea rows={5} />
        </Form.Item>

        <Form.Item>
          <div style={{ display: 'flex', gap: '20px' }}>
            <Button
              type='primary'
              htmlType='submit'
              loading={loading}
              className='yes-next-button'
            >
              Submit
            </Button>
            {isUpdateUser ? (
              <Button
                className='resubmit-button'
                htmlType='button'
                onClick={confirmAdvisory}
                loading={confirmLoading}
              >
                Skip
              </Button>
            ) : (
              ''
            )}
          </div>
        </Form.Item>
      </Form>
    </div>
  )
}

export default Bitrix24Form
