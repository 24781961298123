import React, { useContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import get from 'lodash/get'

import { AppContext } from 'AppContext'
import { GET_DASHBOARD_SIP_AMC } from 'modules/Dashboard/graphql/Queries'
import PortfolioNew from '../PortfolioNew'
import '../index.css'

export default function () {
  const {
    state: { selectedGroup, selectedStrategy },
  } = useContext(AppContext)
  const { data, loading } = useQuery(GET_DASHBOARD_SIP_AMC, {
    variables: {
      where: { groupId: selectedGroup, strategyCode: selectedStrategy },
    },
    fetchPolicy: 'network-only',
  })
  if (
    loading ||
    !get(data, 'spDashbordSIPAMC') ||
    !get(data, 'spDashbordSIPAMC')[0] ||
    !get(data, 'spDashbordSIPAMC')[1]
  )
    return <></>

  return (
    <div className='amc_count_div'>
      <div
        style={{
          width: '100%',
        }}
      >
        <PortfolioNew type='AMC' {...get(data, 'spDashbordSIPAMC')[1]} />
      </div>
      <div
        style={{
          width: '100%',
        }}
      >
        <PortfolioNew {...get(data, 'spDashbordSIPAMC')[0]} />
      </div>
    </div>
  )
}
