import React, { Fragment, useContext, useEffect, useState } from 'react'
import {
  Form,
  Input,
  Tooltip,
  Row,
  Col,
  Button,
  DatePicker,
  Select,
  Upload,
} from 'antd'
import { QuestionCircleOutlined, UploadOutlined } from '@ant-design/icons'
import get from 'lodash/get'
import moment from 'moment'

import {
  E_MANDATE_BANK_DATA,
  GET_SYSTEM_USERS,
} from 'modules/Users/graphql/Queries'
import { userRoles, getName } from 'utils/User'
import { AppContext } from 'AppContext'
import client from 'apollo'
import '../users.css'
// import FormItem from 'antd/lib/form/FormItem'

const { Option } = Select

const formItemLayout = {
  labelCol: { xs: { span: 24 }, sm: { span: 24 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 24 } },
}

const tailFormItemLayout = {
  wrapperCol: { xs: { span: 24, offset: 0 }, sm: { span: 16, offset: 8 } },
}

function disabledDate(current) {
  return current && current > moment().endOf('day')
}

const UserForm = (props) => {
  const {
    state: { isClient, isMobile },
  } = useContext(AppContext)
  const { onFinish, initialValues, loading, isUpdate, isProfilePage } = props
  const [users, setUsers] = useState(undefined)
  const [fileList, setFileList] = useState([
    {
      uid: '-1',
      name: 'image.png',
      status: 'done',
      url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    },
  ])
  const [loadingUser, setLoadingUser] = useState(false)
  const [bankNames, setBankNames] = useState([])
  const [role, setRole] = useState(initialValues ? initialValues?.role : '')
  const { state } = useContext(AppContext)
  const [form] = Form.useForm()
  const { isStaff, isAdmin } = state
  const userFilters = userRoles[state.currentUser.role]

  // const [otherMail, setOtherMail] = useState(
  //   initialValues?.otherMailIds
  //     ? initialValues?.otherMailIds?.map((email, key) => {
  //         return {
  //           key,
  //           label: email,
  //           value: email,
  //         }
  //       })
  //     : []
  // )

  // console.log(otherMail)

  // const handleEditInputChange = (e) => {
  //   const array = e.map((email) => {
  //     return {
  //       email,
  //     }
  //   })

  //   setOtherMail(array)
  // }

  useEffect(() => {
    setLoadingUser(true)
    if (isAdmin || isStaff) {
      client
        .query({
          query: GET_SYSTEM_USERS,
          variables: {
            limit: 500,
            where: {},
          },
          fetchPolicy: 'network-only',
        })
        .then((res) => {
          setLoadingUser(false)
          if (get(res, 'data.getSystemUsers.users')) {
            if (isUpdate) {
              setUsers(
                get(res, 'data.getSystemUsers.users').filter(
                  (user) => user.id !== initialValues.id
                )
              )
            } else {
              setUsers(get(res, 'data.getSystemUsers.users'))
            }
          }
        })
        .catch((err) => {
          setLoadingUser(false)
          console.log(err)
          setUsers([])
        })
    }
  }, [initialValues, isAdmin, isStaff, isUpdate])

  useEffect(() => {
    client
      .query({ query: E_MANDATE_BANK_DATA, fetchPolicy: 'network-only' })
      .then((res) => setBankNames(res?.data?.eMandateBankData || []))
  }, [])

  const normFile = (e) => {
    setFileList([])
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const customProps = {
    multiple: true,
    onRemove: (file) => setFileList([]),
    beforeUpload: (file) => {
      setFileList(file)
      return false
    },
    fileList,
  }

  const formFinish = (values) => {
    onFinish({ ...values, role })
  }
  return (
    <Form
      {...formItemLayout}
      form={form}
      name='register'
      onFinish={formFinish}
      initialValues={
        {
          ...initialValues,
          cancelledChequeSnap:
            initialValues?.kyc?.cancelledChequeSnap &&
            initialValues?.kyc?.cancelledChequeSnap?.map((item, key) => {
              return {
                uid: key,
                name: item
                  ?.split('/')
                  [item?.split('/')?.length - 1].replaceAll('%20', ' '),
                status: 'done',
                url: item,
              }
            }),
          associateAgreement:
            initialValues?.kyc?.associateAgreement &&
            initialValues?.kyc?.associateAgreement?.map((item, key) => {
              return {
                uid: key,
                name: item
                  ?.split('/')
                  [item?.split('/')?.length - 1].replaceAll('%20', ' '),
                status: 'done',
                url: item,
              }
            }),
        } || {}
      }
      className='user-form'
      scrollToFirstError
    >
      <Row gutter={16}>
        <Col
          className='gutter-row user_form_fields'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item
            name='firstName'
            label='First Name'
            rules={[
              { required: true, message: 'Please input your First Name!' },
            ]}
          >
            <Input
              style={{ textTransform: 'capitalize' }}
              placeholder='Enter First Name'
            />
          </Form.Item>
        </Col>
        <Col
          className='gutter-row user_form_fields'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item
            name='lastName'
            label='Last Name'
            rules={[
              { required: true, message: 'Please input your Last Name!' },
            ]}
          >
            <Input
              style={{ textTransform: 'capitalize' }}
              placeholder='Enter Last Name'
            />
          </Form.Item>
        </Col>

        <Col
          className='gutter-row user_form_fields'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item
            name='userName'
            autoComplete='new-password'
            rules={[
              { required: true, message: 'Please input your valid username!' },
            ]}
            label={
              <span>
                Username&nbsp;
                <Tooltip title='Choose your unique username for login.'>
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }
          >
            <Input
              autoComplete='new-password'
              placeholder='Enter Username'
              disabled={isProfilePage || isUpdate}
            />
          </Form.Item>
        </Col>

        <Col
          className='gutter-row user_form_fields'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item
            name='email'
            label='E-mail'
            rules={[
              { required: true, message: 'Please input your E-mail!' },
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
                pattern: new RegExp(/^[a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,4}$/),
              },
            ]}
          >
            <Input
              placeholder='Enter Your Email'
              style={{ textTransform: 'lowercase' }}
            />
          </Form.Item>
        </Col>

        {!isUpdate && (
          <Fragment>
            <Col
              className='gutter-row user_form_fields'
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
            >
              <Form.Item
                name='password'
                label='Password'
                autoComplete='new-password'
                rules={[
                  {
                    required: true,
                    message:
                      'Please input your password between 8 to 16 characters!',
                    min: 8,
                    max: 16,
                  },
                ]}
                hasFeedback
              >
                <Input.Password
                  autoComplete='new-password'
                  placeholder='Enter Your Password'
                />
              </Form.Item>
            </Col>

            <Col
              className='gutter-row user_form_fields'
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
            >
              <Form.Item
                name='confirm'
                label='Confirm Password'
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve()
                      }
                      return Promise.reject(
                        'The two passwords that you entered do not match!'
                      )
                    },
                  }),
                ]}
              >
                <Input.Password placeholder='Enter Confirm Password' />
              </Form.Item>
            </Col>
          </Fragment>
        )}
        {!isProfilePage && (
          <Col
            className='gutter-row user_form_fields'
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
          >
            <Form.Item
              name='role'
              label='Choose Role'
              required={!isUpdate}
              validateStatus={!isUpdate && role === '' ? 'error' : ''}
              help={!isUpdate && role === '' ? 'Please choose your Role!' : ''}
            >
              <Select
                getPopupContainer={(trigger) => trigger.parentElement}
                disabled={isUpdate}
                placeholder='Choose Role'
                onChange={(e) => setRole(e)}
              >
                {userFilters.map((role) => (
                  <Option key={role} value={role.value}>
                    {role.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}

        <Col
          className='gutter-row user_form_fields'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item label='Other Mail' name='otherMailIds'>
            <Input
              loading={loadingUser}
              allowClear
              placeholder='Please Select other Mail'
              className='otherMail-input-field'
            />
          </Form.Item>
        </Col>

        <Col
          className='gutter-row user_form_fields'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item
            name='phone'
            label='Phone Number'
            rules={[
              { required: true, message: 'Please input your phone number!' },
            ]}
          >
            <Input
              type='number'
              autoComplete='off'
              style={{ width: '100%' }}
              placeholder='Enter Phone number'
            />
          </Form.Item>
        </Col>

        <Col
          className='gutter-row user_form_fields'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item
            name='dateOfBirth'
            label='Date of Birth'
            rules={[
              { required: true, message: 'Please input your Date of Birth!' },
            ]}
          >
            <DatePicker
              getPopupContainer={(trigger) => trigger.parentElement}
              autoComplete='off'
              format={'DD-MMM-YYYY'}
              disabledDate={disabledDate}
              showToday={false}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>

        {!(isUpdate && initialValues.role === 'ADMIN') && (
          <Col
            className='gutter-row user_form_fields'
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
          >
            <Form.Item name='createdById' label='Select User'>
              <Select
                showSearch
                getPopupContainer={(trigger) => trigger.parentElement}
                loading={!users}
                placeholder='Select user'
                optionFilterProp='children'
                autoComplete='something-new'
              >
                {users &&
                  users.map((user) => (
                    <Option key={user.id} value={user.id}>
                      {getName(user)}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        )}

        <Col
          className='gutter-row user_form_fields'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item name='address' label='User Address'>
            <Input
              type='text'
              autoComplete='off'
              style={{ width: '100%' }}
              placeholder='Enter User Address'
            />
          </Form.Item>
        </Col>

        <Col
          className='gutter-row user_form_fields'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item name='bank' label='Bank name'>
            <Select
              getPopupContainer={(trigger) => trigger.parentElement}
              placeholder='Bank Name'
              showSearch
              className='display-field'
            >
              {bankNames.map((bankName) => (
                <Option key={bankName.id} value={bankName.name}>
                  {bankName.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col
          className='gutter-row user_form_fields'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item name='accountNumber' label='Bank Account Number'>
            <Input
              type='number'
              autoComplete='off'
              style={{ width: '100%' }}
              placeholder='Enter Bank Account Number'
            />
          </Form.Item>
        </Col>
        <Col
          className='gutter-row user_form_fields'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item name='ifscCode' label='Bank IFSC Code'>
            <Input
              type='text'
              autoComplete='off'
              style={{ width: '100%' }}
              placeholder='Enter Bank IFSC Code'
            />
          </Form.Item>
        </Col>
        <Col
          className='gutter-row user_form_fields'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item name='bankAddress' label='Bank Address'>
            <Input
              type='text'
              autoComplete='off'
              style={{ width: '100%' }}
              placeholder='Enter Bank Address'
            />
          </Form.Item>
        </Col>
        <Col
          className='gutter-row user_form_fields'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item name='gstinNumber' label='GSTIN Number'>
            <Input
              type='text'
              autoComplete='off'
              style={{ width: '100%' }}
              placeholder='Enter GSTIN Number'
            />
          </Form.Item>
        </Col>
        <Col
          className='gutter-row user_form_fields'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item name='depositDate' label='Deposit Date'>
            <DatePicker
              autoComplete='off'
              getPopupContainer={(trigger) => trigger.parentElement}
              format={'DD-MMM-YYYY'}
              disabledDate={disabledDate}
              showToday={false}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col
          className='gutter-row user_form_fields'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item name='depositAmount' label='Deposit Amount'>
            <Input
              type='number'
              autoComplete='off'
              style={{ width: '100%' }}
              placeholder='Enter Deposit Amount'
            />
          </Form.Item>
        </Col>
        <Col
          className='gutter-row user_form_fields'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item name={['remark']} label='Remark'>
            <Input disabled={isClient} />
          </Form.Item>
        </Col>
        <Col
          className='gutter-row user_form_fields'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item
            name='cancelledChequeSnap'
            label='cheque/statement'
            valuePropName='fileList'
            getValueFromEvent={normFile}
          >
            <Upload
              listType='picture'
              {...customProps}
              accept='.jpeg,.png,.jpg,.pdf,.docx,.doc'
              fileList={fileList}
            >
              <Button style={{ width: '100%' }}>
                <UploadOutlined /> Click to upload
              </Button>
            </Upload>
          </Form.Item>
        </Col>
        <Col
          className='gutter-row user_form_fields'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item
            name='associateAgreement'
            label='Associate agreement'
            valuePropName='fileList'
            getValueFromEvent={normFile}
          >
            <Upload
              name='associateAgreement'
              listType='picture'
              {...customProps}
              accept='.jpeg,.png,.jpg,.pdf,.docx,.doc'
            >
              <Button style={{ width: '100%' }}>
                <UploadOutlined /> Click to upload
              </Button>
            </Upload>
          </Form.Item>
        </Col>

        {/* <Col className='gutter-row user_form_fields' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='folderPath'
            label='Folder Path'
            rules={[{ required: true, message: 'Please input your Folder Path!', whitespace: true }]}
          >
            <Input />
          </Form.Item>
        </Col> */}
        <Col
          className='gutter-row user_form_fields'
          style={{
            marginBottom: isMobile ? '5vh' : '1vh',
          }}
          span={24}
        >
          <Form.Item {...tailFormItemLayout}>
            <Button type='primary' htmlType='submit' loading={loading}>
              Save
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default UserForm
