import React, { useContext, useState } from 'react'
import { Switch, Form, Row, Col, Input, Button } from 'antd'
import { GET_SITE_SETTING } from '../graphql/Queries'
import { useQuery } from '@apollo/react-hooks'
import client from 'apollo'
import { UPDATE_SITE_SETTING } from '../graphql/Mutations'
import openNotification from 'utils/Notification'
import Loader from 'components/loaders/Loader'
import { AppContext } from 'AppContext'

const formItemLayout = {
  labelCol: { xs: { span: 24 }, sm: { span: 24 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 24 } },
}

const tailFormItemLayout = {
  wrapperCol: { xs: { span: 24, offset: 0 }, sm: { span: 16, offset: 8 } },
}

export default function SiteSetting() {
  const [form] = Form.useForm()
  const [loader, setLoader] = useState(false)
  const {
    state: { isMobile },
  } = useContext(AppContext)

  const { data, loading } = useQuery(GET_SITE_SETTING, {
    fetchPolicy: 'network-only',
  })
  if (loading) return <Loader />

  let siteData
  siteData = data?.getSiteSettings[0]

  function onChange(checked) {
    setLoader(true)
    client
      .mutate({
        mutation: UPDATE_SITE_SETTING,
        variables: {
          data: { status: !siteData?.status, url: siteData?.url },
          where: { id: siteData?.id },
        },
        refetchQueries: [
          { query: GET_SITE_SETTING, fetchPolicy: 'network-only' },
        ],
      })
      .then((res) => {
        setLoader(false)
        openNotification('success', 'Site setting updated successfully ')
      })
      .catch((err) => console.log(err))
  }

  function onFinish(e) {
    setLoader(true)
    client
      .mutate({
        mutation: UPDATE_SITE_SETTING,
        variables: { data: { url: e?.URL }, where: { id: siteData?.id } },
        refetchQueries: [
          { query: GET_SITE_SETTING, fetchPolicy: 'network-only' },
        ],
      })
      .then((res) => {
        setLoader(false)
        openNotification('success', 'Site setting updated successfully ')
      })
      .catch((err) => console.log(err))
  }

  return (
    <>
      <div className='main_dashboard_div'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
          }}
        >
          <div
            style={{
              fontSize: isMobile ? '18px' : '24px',
              fontWeight: '600',
              lineHeight: '26px',
              letterSpacing: '-0.02em',
              textAlign: 'left',
              color: '#181C32',
            }}
          >
            Maintain
          </div>
        </div>
        <Form
          {...formItemLayout}
          form={form}
          name='message'
          onFinish={onFinish}
          className='user-form'
          scrollToFirstError
        >
          <Row gutter={16}>
            <Col sm={24} md={12} lg={6}>
              <Form.Item label='Active' name='isActive'>
                <Switch
                  checked={siteData?.status}
                  onChange={onChange}
                  loading={loader}
                />
              </Form.Item>
            </Col>
            {siteData.status ? (
              <Col sm={24} md={12} lg={18}>
                <Form.Item
                  name='URL'
                  label='Maintenance URL'
                  // rules={[{ required: true, message: 'Please enter url!' }]}
                >
                  <Input
                    placeholder='Enter Maintenance url'
                    defaultValue={siteData?.url}
                  />
                </Form.Item>
              </Col>
            ) : (
              ''
            )}
          </Row>
          <Form.Item {...tailFormItemLayout}>
            <Button type='primary' htmlType='submit' loading={loader}>
              Save
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  )
}
