import React from 'react'
import { Layout, Row, Col } from 'antd'
import '../Support/Support.css'
import FacebookIcon from '../../../src/assets/images/new-images/facebook.svg'
import YoutubeIcon from '../../../src/assets/images/new-images/youtube.svg'
import InstagramIcon from '../../../src/assets/images/new-images/instagram.svg'
import TwitterIcon from '../../../src/assets/images/new-images/twitter.svg'
import LinkedInIcon from '../../../src/assets/images/new-images/LinkedIn.svg'
import { ReactComponent as Phone } from 'assets/images/new-images/phone-call.svg'
import { ReactComponent as Envelope } from 'assets/images/new-images/envelope.svg'

const { Footer } = Layout

const ContactUs = () => (
  <Footer className='contact_footer_div'>
    <Row className='contact_footer_row_1' justify='center'>
      <Col span={24}>
        <h3 className='connect-us'>Connect Us</h3>
        <div className='details_phone_div'>
          <p className='details_phone_text'>
            <Phone />{' '}
            <span className='details_phone_value'>+91 93749 85600</span>
          </p>
          <p className='details_phone_text'>
            <Envelope />{' '}
            <span className='details_phone_value'>info@finideas.com</span>
          </p>
        </div>
      </Col>
    </Row>
    <Row justify='center' gutter={[16, 16]}>
      <Col xs={24} sm={12} md={4}>
        <a
          href='https://www.facebook.com/finideas'
          target='_blank'
          rel='noopener noreferrer'
          className='social-link facebook'
        >
          <img
            className='contact_footer_image'
            src={FacebookIcon}
            alt='Facebook'
          />
        </a>
      </Col>
      <Col xs={24} sm={12} md={4}>
        <a
          href='https://www.youtube.com/@finideas'
          target='_blank'
          rel='noopener noreferrer'
          className='social-link youtube'
        >
          <img
            src={YoutubeIcon}
            alt='Youtube'
            className='contact_footer_image'
          />
        </a>
      </Col>
      <Col xs={24} sm={12} md={4}>
        <a
          href='https://www.instagram.com/finideas'
          target='_blank'
          rel='noopener noreferrer'
          className='social-link instagram'
        >
          <img
            src={InstagramIcon}
            alt='Youtube'
            className='contact_footer_image'
          />
        </a>
      </Col>
      <Col xs={24} sm={12} md={4}>
        <a
          href='https://x.com/finideas'
          target='_blank'
          rel='noopener noreferrer'
          className='social-link twitter'
        >
          <img
            src={TwitterIcon}
            alt='Facebook'
            className='contact_footer_image'
          />
        </a>
      </Col>
      <Col xs={24} sm={12} md={4}>
        <a
          href='https://www.linkedin.com/company/finideas/'
          target='_blank'
          rel='noopener noreferrer'
          className='social-link linkedin'
        >
          <img
            src={LinkedInIcon}
            alt='Facebook'
            className='contact_footer_image'
          />
        </a>
      </Col>
    </Row>
  </Footer>
)

export default ContactUs
