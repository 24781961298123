import React, { Fragment, useContext, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import get from 'lodash/get'
import moment from 'moment'

import client from 'apollo'
import { getName } from 'utils/User'
import { AppContext } from 'AppContext'
import Page404 from 'components/Page404'
import UserForm from './components/UserForm'
import Loader from 'components/loaders/Loader'
import CustomMeta from 'components/CustomMeta'
import openNotification from 'utils/Notification'
import { GET_USER } from '../Users/graphql/Queries'
import { GET_CURRENT_USER } from 'auth/graphql/Queries'
import { UPDATE_KYC, UPDATE_USER } from '../Users/graphql/Mutations'
import { isEmpty } from 'lodash'
import FilterBackIcon from 'assets/images/new-images/filter-back-button.svg'

export default function (props) {
  const { state, dispatch } = useContext(AppContext)
  const {
    state: { isMobile },
  } = useContext(AppContext)
  const [uploadDataLoader, setUploadDataLoader] = useState(false)
  let initialValues = {}
  let {
    history,
    match: {
      path,
      params: { userId },
    },
  } = props
  const isProfilePage = path.includes('profile')
  userId = userId || state.currentUser.id
  const { data, loading, error } = useQuery(GET_USER, {
    variables: { id: userId },
    fetchPolicy: 'network-only',
  })

  if (loading) return <Loader />
  if (error) return <Page404 error={error} />

  if (!loading && get(data, 'getUser')) {
    initialValues = JSON.parse(JSON.stringify(data.getUser))
    initialValues.dateOfBirth = initialValues.dateOfBirth
      ? moment(initialValues.dateOfBirth)
      : undefined
    initialValues.depositDate = initialValues.depositDate
      ? moment(initialValues.depositDate)
      : undefined
    initialValues.amcStartDate = initialValues.amcStartDate
      ? moment(initialValues.amcStartDate)
      : undefined
    initialValues.sipStartDate = initialValues.sipStartDate
      ? moment(initialValues.sipStartDate)
      : undefined
    initialValues.closedDate = initialValues.closedDate
      ? moment(initialValues.closedDate)
      : undefined
    initialValues.createdById = initialValues.createdBy
      ? initialValues.createdBy.id
      : undefined
    initialValues.otherMailIds = initialValues.otherMailIds || []
  }

  // function getFilesObject(tempFiles) {
  //   let files = pick(tempFiles, ['cancelledChequeSnap', 'associateAgreement'])

  //   if (get(files, 'cancelledChequeSnap[0].originFileObj')) {
  //     files.cancelledChequeSnap = get(
  //       files,
  //       'cancelledChequeSnap[0].originFileObj'
  //     )
  //   } else {
  //     delete files.cancelledChequeSnap
  //   }
  //   if (get(files, 'associateAgreement[0].originFileObj')) {
  //     files.associateAgreement = get(
  //       files,
  //       'associateAgreement[0].originFileObj'
  //     )
  //   } else {
  //     delete files.associateAgreement
  //   }
  //   return files
  // }

  function onFinish(values) {
    setUploadDataLoader(true)
    const cancelledChequeValue = values?.cancelledChequeSnap?.map((item) => {
      return item?.url ? item?.url : item?.originFileObj
    })

    const associateAgreementValue = values?.associateAgreement?.map((item) => {
      return item?.url ? item?.url : item?.originFileObj
    })

    const fileValues = {
      cancelledChequeSnap: cancelledChequeValue,
      associateAgreement: associateAgreementValue,
    }

    delete values.cancelledChequeSnap
    delete values.associateAgreement
    delete values.role

    values = {
      ...values,
      firstName:
        values.firstName.charAt(0).toUpperCase() + values.firstName.slice(1),
      lastName:
        values.lastName.charAt(0).toUpperCase() + values.lastName.slice(1),
      email: values.email.toLowerCase(),
      dateOfBirth: moment(values?.dateOfBirth).format('YYYY-MM-DD'),
      depositDate:
        values?.depositDate && moment(values?.depositDate).format('YYYY-MM-DD'),
    }

    const queryVariables = { id: userId, data: values }
    client
      .mutate({ mutation: UPDATE_USER, variables: queryVariables })
      .then((res) => {
        if (isEmpty(fileValues)) {
          openNotification('success', 'User Updated Successfully')
          // setLoading(false)
        } else {
          client
            .mutate({
              mutation: UPDATE_KYC,
              variables: { id: +userId, ...fileValues },
            })
            .then((res) => {
              setUploadDataLoader(false)
              openNotification('success', 'User Updated Successfully')
              history.goBack()
            })
            .catch((err) => console.log(err))
          // .finally(() => setLoading(false))
        }
        if (isProfilePage || userId === state.currentUser.id) {
          client
            .query({ query: GET_CURRENT_USER, fetchPolicy: 'network-only' })
            .then((res) =>
              dispatch({ type: 'CURRENT_USER', data: res.data.currentUser })
            )
            .catch((err) => console.log(err))
        }
      })
      .catch((err) => console.log(err))
  }

  return (
    <Fragment>
      <CustomMeta title={getName(initialValues)} description='Edit User' />
      <div style={{ padding: `${state?.isMobile ? '10px' : '32px 40px'}` }}>
        {/* <PageHeader
          className='box'
          onBack={() => history.goBack()}
          title={isProfilePage ? 'Edit Details' : 'Edit User'}
        /> */}

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '16px',
            width: '100%',
          }}
        >
          <div
            style={{
              fontSize: '24px',
              fontWeight: '600',
              lineHeight: '26px',
              letterSpacing: '-0.02em',
              textAlign: 'left',
              color: '#181C32',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div
              onClick={() => history.goBack()}
              style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: isMobile ? '18px' : '24px',
              }}
            >
              <img style={{ cursor: 'pointer' }} src={FilterBackIcon} alt='' />{' '}
              &nbsp; {isProfilePage ? 'Edit Details' : 'Edit User'}
            </div>
          </div>
        </div>
        <UserForm
          onFinish={onFinish}
          initialValues={initialValues}
          loading={loading || uploadDataLoader}
          isUpdate
          isProfilePage={isProfilePage || state.currentUser.id === userId}
        />
      </div>
    </Fragment>
  )
}
