import React from 'react'
import { Table } from 'antd'
import isEmpty from 'lodash/isEmpty'

export default function PLTable({
  data,
  columns,
  loading,
  rowSelection,
  filters,
}) {
  return (
    <Table
      rowKey='Clientcode'
      rowSelection={
        isEmpty(filters)
          ? {}
          : {
              type: 'checkbox',
              ...rowSelection,
            }
      }
      loading={loading}
      dataSource={data}
      columns={columns}
      className='nlv-tracker-report'
      pagination={{
        pageSize: 5000,
        hideOnSinglePage: true,
        style: { display: 'none' },
      }}
      // scroll={{ x: true, y: 'calc(100vh - 72px - 40px - 207px)' }}
    />
  )
}
