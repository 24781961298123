import gql from 'graphql-tag'

export const GET_PLANS = gql`
  query getPlans {
    getPlans {
      id
      name
      description
      minPoints
      maxPoints
      percentage
      stock
      sip
      pnlOn
      interestFromDebt
      features
      planRiskType
      planRisk
      minRisk
      maxRisk
      tCost
      riskAppetite
    }
  }
`

export const GET_PLAN = gql`
  query getPlan($where: GetPlanInput) {
    getPlan(where: $where) {
      id
      name
      riskAppetite
    }
  }
`

export const GET_USER_PDF = gql`
  query usersPdf($userId: Int) {
    usersPdf(where: { userId: $userId }) {
      id
      type
      pdfUrl
      createdAt
    }
  }
`
