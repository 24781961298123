import React, { useContext, useEffect, useState } from 'react'
import { Button, Switch } from 'antd'
import { Modal, Table, Tooltip } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import {
  EditTwoTone,
  DeleteOutlined,
  ExclamationCircleOutlined,
  MailOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons'
import { get } from 'lodash'
import client from 'apollo'
import './index.css'

import { GET_REMINDERS } from './graphql/query'
import {
  CREATE_REMINDER,
  DELETE_REMINDER,
  SEND_REMINDER,
  UPDATE_REMINDER,
} from './graphql/mutation'
import Page404 from 'components/Page404'
import CustomMeta from 'components/CustomMeta'
import openNotification from 'utils/Notification'
import CreateReminderModal from './CreateReminderModal'
import { GET_MEMBER_NAME } from 'modules/Users/graphql/Queries'
import moment from 'moment'
import { AppContext } from 'AppContext'

const { confirm } = Modal
const Reminder = () => {
  const [showFormModal, setShowFormModal] = useState(false)
  const [selectedReminder, setSelectedReminder] = useState(undefined)
  const [templateHtmlMandate, setTemplateHtmlMandate] = useState('')
  const [templateHtmlNonMandate, setTemplateHtmlNonMandate] = useState('')
  const [loadingReminder, setLoading] = useState(false)
  const [memberName, setMemberName] = useState(undefined)

  useEffect(() => {
    setTemplateHtmlNonMandate(selectedReminder?.nonMandateTemplate)
  }, [selectedReminder])

  useEffect(() => {
    setLoading(true)
    client
      .query({ query: GET_MEMBER_NAME, fetchPolicy: 'network-only' })
      .then((res) => {
        setLoading(false)
        setMemberName(res?.data?.getMemberName)
      })
      .catch((err) => {
        setLoading(false)
      })
  }, [])
  const {
    state: { isMobile },
  } = useContext(AppContext)

  const {
    loading,
    error,
    data: reminderData,
  } = useQuery(GET_REMINDERS, { fetchPolicy: 'network-only' })

  let allReminders
  if (error) return <Page404 error={error} />
  if (!loading && get(reminderData, 'getReminders')) {
    allReminders = reminderData.getReminders.map((reminders, key) => ({
      key: key.toString(),
      ...reminders,
    }))
  }

  const onChange = (checked, record) => {
    const input = {
      status: checked === true ? 'ACTIVE' : 'DE_ACTIVE',
    }
    client
      .mutate({
        mutation: UPDATE_REMINDER,
        variables: { input, where: { id: record?.id } },
        refetchQueries: [{ query: GET_REMINDERS }],
      })
      .then((res) => {
        openNotification('success', 'Reminder Updated Successfully')
      })
      .catch((err) => console.log(err))
  }

  function showConfirm(id) {
    confirm({
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
      title: `Do you want to delete this Reminder?`,
      content: `When clicked the OK button, this reminder will be Deleted`,
      onOk() {
        client
          .mutate({
            mutation: DELETE_REMINDER,
            variables: { input: { id } },
            refetchQueries: [{ query: GET_REMINDERS }],
          })
          .catch((err) => console.log(err))
        openNotification('success', 'Reminder Deleted Successfully')
      },
    })
  }

  function mailSendConfirm(id, isEmail, isWhatsapp) {
    confirm({
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
      title: `Send Mail Reminder?`,
      content: `Are you sure to send reminder on mail`,
      onOk() {
        client
          .mutate({
            mutation: SEND_REMINDER,
            variables: { where: { id: parseInt(id), isEmail, isWhatsapp } },
          })
          .catch((err) => console.log(err))
        openNotification('success', 'Reminder Send Successfully')
      },
    })
  }
  function renderAction(record) {
    return (
      <div className='action-icons'>
        {
          <Switch
            size='small'
            checked={record?.status === 'ACTIVE' ? true : false}
            onClick={(e) => onChange(e, record)}
          />
        }
        &nbsp;&nbsp;&nbsp;
        {
          <Tooltip title='Edit'>
            <EditTwoTone
              onClick={() => {
                setShowFormModal(true)
                setSelectedReminder(record)
                setTemplateHtmlMandate(record?.mandateTemplate)
                setTemplateHtmlNonMandate(record?.nonMandateTemplate)
              }}
            />
          </Tooltip>
        }
        &nbsp;&nbsp;&nbsp;
        {
          <Tooltip title='Delete Plan'>
            <DeleteOutlined onClick={() => showConfirm(record.id)} />
          </Tooltip>
        }
        {record?.amcDueDate && (
          <Tooltip title='Send Reminder On Email'>
            <MailOutlined
              onClick={() => mailSendConfirm(record.id, true, false)}
            />
          </Tooltip>
        )}
        {record?.amcDueDate && (
          <Tooltip title='Send Reminder On WhatsApp'>
            <WhatsAppOutlined
              onClick={() => mailSendConfirm(record.id, false, true)}
            />
          </Tooltip>
        )}
      </div>
    )
  }

  function handleCreatePlan(record) {
    setLoading(true)
    const input = {
      ...record,
      days: +record?.days,
      status: 'ACTIVE',
    }
    client
      .mutate({
        mutation: CREATE_REMINDER,
        variables: { input },
        refetchQueries: [{ query: GET_REMINDERS }],
      })
      .then((res) => {
        setLoading(false)
        openNotification('success', 'Plan Added Successfully')
        handleHideFormModal()
        setTemplateHtmlNonMandate('')
        setTemplateHtmlMandate('')
      })
      .catch((err) => console.log(err))
  }

  function handleUpdatePlan(record) {
    setLoading(true)
    const input = {
      ...record,
      days: +record?.days,
    }
    client
      .mutate({
        mutation: UPDATE_REMINDER,
        variables: { input, where: { id: selectedReminder?.id } },
        refetchQueries: [{ query: GET_REMINDERS }],
      })
      .then((res) => {
        setLoading(false)
        openNotification('success', 'Reminder Updated Successfully')
        handleHideFormModal()
        setTemplateHtmlNonMandate('')
        setTemplateHtmlMandate('')
      })
      .catch((err) => console.log(err))
  }
  function handleHideFormModal() {
    setShowFormModal(false)
    setSelectedReminder(undefined)
  }

  // function createMarkup(__html) {
  //   return { __html }
  // }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Schedule Type',
      dataIndex: 'scheduleType',
      width: isMobile ? '150px' : '200px',
      key: 'scheduleType',
    },
    {
      title: 'Days',
      dataIndex: 'days',
      key: 'days',
    },
    {
      title: 'Whatsapp Send',
      dataIndex: 'isWhatsAppSend',
      width: isMobile ? '150px' : '200px',
      key: 'isWhatsAppSend',
      render: (text, record) => {
        return <div>{record.isWhatsAppSend === true ? 'True' : 'False'}</div>
      },
    },
    {
      title: 'Amc Due Date',
      dataIndex: 'amcDueDate',
      width: isMobile ? '150px' : '200px',
      key: 'amcDueDate',
      render: (text, record) => {
        return record.amcDueDate
          ? moment(record.amcDueDate).format('DD-MMM-YYYY')
          : '-'
      },
    },
    {
      title: 'Selected Members',
      dataIndex: 'amcUserSelectMember',
      key: 'amcUserSelectMember',
      render: (text, record) => {
        const result = memberName?.filter((item) =>
          record?.amcUserSelectMember?.includes(item.ID.toString())
        )
        return result?.map((item, key) => {
          return `${item?.MemberCode}${result.length - 1 === key ? '' : ', '}`
        })
      },
    },
    {
      title: 'User Mandate Type',
      dataIndex: 'amcUserSelectedType',
      width: isMobile ? '150px' : '200px',
      key: 'amcUserSelectedType',
      render: (text, record) => {
        return record?.amcUserSelectedType === 'mandate'
          ? 'Mandate'
          : record?.amcUserSelectedType === 'all'
          ? 'All'
          : record?.amcUserSelectedType === 'nonMandate'
          ? 'NonMandate'
          : '-'
      },
    },
    // {
    //   title: 'Mandate',
    //   dataIndex: 'mandateTemplate',
    //   key: 'mandateTemplate',
    //   width: 200,
    //   render: (text, record) => {
    //     return (
    //       <>
    //         {text ? (
    //           <Tooltip
    //             placement='leftTop'
    //             title={
    //               <div
    //                 className='tooltip_text'
    //                 dangerouslySetInnerHTML={createMarkup(text)}
    //               />
    //             }
    //           >
    //             <span>Click Here To show Reminder</span>
    //           </Tooltip>
    //         ) : (
    //           '-'
    //         )}
    //       </>
    //     )
    //   },
    // },
    // {
    //   title: 'Non Mandate',
    //   dataIndex: 'nonMandateTemplate',
    //   key: 'nonMandateTemplate',
    //   width: 200,
    //   render: (text, record) => {
    //     return (
    //       <>
    //         {text ? (
    //           <Tooltip
    //             placement='leftTop'
    //             title={
    //               <div
    //                 className='tooltip_text'
    //                 dangerouslySetInnerHTML={createMarkup(text)}
    //               />
    //             }
    //           >
    //             <span>Click Here To show Reminder</span>
    //           </Tooltip>
    //         ) : (
    //           '-'
    //         )}
    //       </>
    //     )
    //   },
    // },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => renderAction(record),
    },
  ]

  return (
    <div>
      <CustomMeta title={'Reminder'} description='Reminders' />
      {showFormModal && (
        <CreateReminderModal
          visible={showFormModal}
          onCreate={handleCreatePlan}
          onUpdate={handleUpdatePlan}
          selectedReminder={selectedReminder}
          isEdit={selectedReminder !== undefined}
          onCancel={() => handleHideFormModal()}
          setTemplateHtmlMandate={setTemplateHtmlMandate}
          setTemplateHtmlNonMandate={setTemplateHtmlNonMandate}
          templateHtmlMandate={templateHtmlMandate}
          templateHtmlNonMandate={templateHtmlNonMandate}
          loadingReminder={loadingReminder}
          memberName={memberName}
        />
      )}{' '}
      <div className='main_dashboard_div'>
        <div
          style={{
            display: 'flex',
            flexWrap: isMobile ? 'wrap' : 'nowrap',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
            overflow: 'hidden !important ',
          }}
        >
          <div
            style={{
              fontSize: isMobile ? '18px' : '24px',
              fontWeight: '600',
              lineHeight: '26px',
              letterSpacing: '-0.02em',
              textAlign: 'left',
              color: '#181C32',
            }}
          >
            Reminders
          </div>
          <Button
            loading={loadingReminder}
            onClick={() => setShowFormModal(true)}
            style={{
              margin: '0',
              padding: isMobile ? '4px 10px' : '8px 16px',
              fontSize: '14px',
              fontWeight: '600',
              lineHeight: '24px',
              textAlign: 'left',
              color: '#AF0000',
              background: '#AF00000D',
              border: '1px solid #AF000080',
              height: isMobile ? '35px' : '40px',
              borderRadius: '8px',
            }}
          >
            Add Reminder
          </Button>
        </div>

        <Table
          style={{
            height: isMobile ? '77.5vh' : '100%',
          }}
          loading={loading}
          columns={columns}
          dataSource={allReminders}
        />
      </div>
    </div>
  )
}

export default Reminder
