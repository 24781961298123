import React, { useState, useContext } from 'react'
import {
  EditTwoTone,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import { Tooltip, Table, Select, Modal } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { NavLink } from 'react-router-dom'
import get from 'lodash/get'
import moment from 'moment'

import { UPDATE_SIP, DELETE_SIP } from 'modules/Users/graphql/Mutations'
import { GET_SIP } from 'modules/Users/graphql/Queries'
import openNotification from 'utils/Notification'
import CreateSIPModal from 'modules/SIP/Modal'
import Page404 from 'components/Page404'
import { AppContext } from 'AppContext'
import { getName } from 'utils/User'
import client from 'apollo'

const { confirm } = Modal
const { Option } = Select
const months = [
  'All',
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export default function () {
  let tableData = []
  const [sort, setSort] = useState()
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [isUpdate, setIsUpdate] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const {
    state: {
      isClient,
      currentReport,
      currentUser: { id },
      isMobile,
    },
  } = useContext(AppContext)
  const [filters, setFilters] = useState(
    isClient ? { clientId: parseInt(id) } : {}
  )
  const [updateData, setUpdateData] = useState(undefined)
  const [showFormModal, setShowFormModal] = useState(false)
  const [showFormLoading, setShowFormLoading] = useState(false)

  const { data, loading, error } = useQuery(GET_SIP, {
    variables: { ...filters, skip, limit, sort },
    fetchPolicy: 'network-only',
  })

  if (error) return <Page404 error={error} />

  let totalCount = 0
  if (!loading && get(data, 'getAllSips.sip')) {
    totalCount = data.getAllSips.count
    tableData = data.getAllSips.sip.map((user, key) => ({
      key: key.toString(),
      ...user,
    }))
  }

  function handleTableChange(pagination, filter, sorter) {
    if (sorter && sorter.field && sorter.order) {
      if (sorter.order === 'descend') {
        setSort({
          field: sorter.field,
          sortingOrder: 'DESC',
        })
        setCurrentPage(1)
      } else {
        setSort({
          field: sorter.field,
          sortingOrder: 'ASC',
        })
        setCurrentPage(1)
      }
    } else if (filter.role) {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['role'] = filter.role
      setFilters(tempFilters)
      setCurrentPage(1)
    } else {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['role'] = ''
      delete tempFilters.role
      setFilters(tempFilters)
      setCurrentPage(1)
    }
  }

  function handlePagination(page) {
    setSkip((page - 1) * limit)
    setCurrentPage(page)
  }

  function handlePageSizeChange(current, size) {
    setLimit(size)
  }

  function handleUpdate(values, resetForm) {
    const dateMoment = moment(values.date)
    const date = `${moment().format('YYYY')}-${months.findIndex(
      (m) => m === values.whichMonth
    )}-${dateMoment.format('D')}`
    values.startDate = moment(new Date(date)).add(1, 'days')
    values.endDate = moment(new Date(date)).add(1, 'months')
    delete values.date
    setShowFormLoading(true)
    client
      .mutate({
        mutation: UPDATE_SIP,
        variables: { data: values, id: updateData.id },
        refetchQueries: [
          {
            query: GET_SIP,
            variables: { ...filters, skip, limit },
            fetchPolicy: 'network-only',
          },
        ],
      })
      .then((res) => {
        openNotification('success', 'SIP Updated Successfully')
        setShowFormModal(false)
        setIsUpdate(false)
        setUpdateData(undefined)
        resetForm()
      })
      .catch((err) => console.log(err))
      .finally(() => setShowFormLoading(false))
  }

  function showConfirm(id) {
    confirm({
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
      title: `Do you want to delete this SIP?`,
      content: `When clicked the OK button, this SIP will be Deleted`,
      onOk() {
        client
          .mutate({
            mutation: DELETE_SIP,
            variables: { id },
            refetchQueries: [
              { query: GET_SIP, variables: { ...filters, skip, limit, sort } },
            ],
          })
          .catch((err) => console.log(err))
      },
    })
  }

  function renderAction(record) {
    return (
      <div className='action-icons'>
        {currentReport.includes('Update SIP') && (
          <Tooltip title='Edit'>
            <EditTwoTone
              onClick={() => {
                setIsUpdate(true)
                setUpdateData(record)
                setShowFormModal(true)
              }}
            />
          </Tooltip>
        )}
        {currentReport.includes('Delete SIP') && (
          <Tooltip title='Delete SIP'>
            <DeleteOutlined onClick={() => showConfirm(record.id)} />
          </Tooltip>
        )}
      </div>
    )
  }

  function handleSearch(value, confirm, filedName) {
    value = typeof value === 'object' ? value[0] : value
    confirm()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = value
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  function handleReset(clearFilters, filedName) {
    clearFilters()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    delete tempFilters[filedName]
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  // const getColumnSearchProps = dataIndex => ({
  //   filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => (
  //     <div style={{ padding: 8 }}>
  //       <DatePicker
  //         picker='year'
  //         autoFocus={true}
  //         onChange={e => {
  //           if (e) {
  //             handleSearch(parseInt(e.format('YYYY')), confirm, dataIndex)
  //           } else {
  //             handleReset(clearFilters, dataIndex)
  //           }
  //         }}
  //       />
  //     </div>
  //   ),
  // })

  const getColumnDropDownProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Select
          placeholder='Select Month'
          getPopupContainer={(trigger) => trigger.parentElement}
          style={{ minWidth: 120 }}
          onChange={(e) => {
            if (e === 'All') {
              handleReset(clearFilters, dataIndex)
            } else {
              handleSearch(e, confirm, dataIndex)
            }
          }}
        >
          {months.map((month) => (
            <Option key={month} value={month}>
              {month}
            </Option>
          ))}
        </Select>
      </div>
    ),
  })

  const columns = [
    {
      title: 'Client',
      dataIndex: 'client',
      key: 'client',
      render: (text, record) => (
        <NavLink to={`/clients/${record.client.id}`}>
          {getName(record.client)}
        </NavLink>
      ),
      width: isMobile && 150,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      sorter: (a, b) => a.age - b.age,
      width: isMobile ? 100 : 150,
    },
    {
      title: 'Month',
      dataIndex: 'whichMonth',
      key: 'whichMonth',
      ...getColumnDropDownProps('whichMonth'),
      sorter: (a, b) => a.age - b.age,
    },
    {
      title: 'Payment Type',
      dataIndex: 'paymentType',
      key: 'paymentType',
      sorter: (a, b) => a.age - b.age,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date) => moment(date).format('Do MMMM YYYY'),
      sorter: (a, b) => a.age - b.age,
      width: isMobile && 150,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => renderAction(record),
      width: isMobile && 100,
    },
  ]

  return (
    <>
      {showFormModal && (
        <CreateSIPModal
          data={updateData}
          isUpdate={isUpdate}
          visible={showFormModal}
          onUpdate={handleUpdate}
          loading={showFormLoading}
          onCancel={() => {
            setShowFormModal(false)
            setUpdateData(undefined)
            setIsUpdate(false)
          }}
        />
      )}

      <div className='main_dashboard_div'>
        <div className='agreement_main_div'>
          <div className='agreement_text'>SIPs</div>
        </div>
        <Table
          loading={loading}
          expandable={{
            expandedRowRender: (record) => (
              <p style={{ margin: 0 }}>{record.description}</p>
            ),
            rowExpandable: (record) => record.name !== 'Not Expandable',
          }}
          dataSource={tableData}
          onChange={handleTableChange}
          scroll={{ x: true, y: true }}
          columns={isClient ? columns.slice(0, columns.length - 1) : columns}
          pagination={{
            pageSize: limit,
            total: totalCount,
            onChange: handlePagination,
            current: currentPage,
            onShowSizeChange: handlePageSizeChange,
          }}
        />
      </div>
    </>
  )
}
