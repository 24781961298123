import get from 'lodash/get'
import { useQuery } from '@apollo/react-hooks'
import React, { useState, useEffect, useContext } from 'react'
import { Table, Button, Modal, Tooltip, Form, Upload, Spin } from 'antd'
import { ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons'
import { PlusOutlined } from '@ant-design/icons'

import client from 'apollo'
import Page404 from 'components/Page404'
import { GET_IMAGES } from './graphql/Query'
import { CREATE_NEW_IMAGE, DELETE_LOGIN_IMAGE } from './graphql/Mutation'
import openNotification from 'utils/Notification'
import { LoadingOutlined } from '@ant-design/icons'
import { AppContext } from 'AppContext'

const { confirm } = Modal

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 40,
      color: '#000',
    }}
    spin
  />
)

const LoginImages = () => {
  const [form] = Form.useForm()
  const [fileList, setFileList] = useState(undefined)
  const [imageLoading, setImageLoading] = useState(false)
  const [selectedImage, setSelectedImage] = useState(undefined)

  const {
    state: { isMobile },
  } = useContext(AppContext)

  useEffect(() => {
    form
      .validateFields()
      .then((values) => {
        const data = {
          ...values,
        }
        handleCreateImage(data, resetForm)
      })
      .catch((info) => console.log('Validate Failed:', info))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList])

  const { loading, error, data } = useQuery(GET_IMAGES, {
    fetchPolicy: 'network-only',
  })

  let allImages
  if (error) return <Page404 error={error} />
  if (!loading && get(data, 'getAllLoginImages')) {
    allImages = data.getAllLoginImages.map((image, key) => ({
      ...image,
      key,
    }))
  }

  function handleHideFormModal() {
    setSelectedImage(undefined)
  }

  function showConfirm(id) {
    confirm({
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
      title: `Do you want to delete this  image?`,
      content: `When clicked the OK button, this image will be Deleted`,
      onOk() {
        client
          .mutate({
            mutation: DELETE_LOGIN_IMAGE,
            variables: { id: +id },
            refetchQueries: [{ query: GET_IMAGES }],
          })
          .catch((err) => console.log(err))
        openNotification('success', 'Image Deleted Successfully')
      },
    })
  }

  function renderAction(record) {
    return (
      <div className='action-icons'>
        {
          <Tooltip title='Delete Image'>
            <DeleteOutlined onClick={() => showConfirm(record.id)} />
          </Tooltip>
        }
      </div>
    )
  }

  function handleCreateImage(values, resetForm) {
    const data = values.fileUrl[0].originFileObj
    if (data) {
      setImageLoading(true)
    }
    client
      .mutate({
        mutation: CREATE_NEW_IMAGE,
        variables: { file: data },
        refetchQueries: [{ query: GET_IMAGES }],
      })
      .then((res) => {
        openNotification('success', 'Image Added Successfully')
        handleHideFormModal()
        resetForm()
        setImageLoading(false)
      })
      .catch((err) => console.log(err))
  }

  const columns = [
    {
      title: 'No.',
      dataIndex: 'key',
      render: (item) => item + 1,
      key: 'key',
      width: isMobile && 60,
    },
    {
      title: 'image',
      dataIndex: 'imageUrl',
      render: (item) => (
        <img
          style={{
            borderRadius: '10px',
          }}
          height={100}
          width={100}
          src={item}
          alt='img'
        />
      ),
      key: 'imageUrl',
      width: isMobile && 100,
    },
    {
      title: 'Image Name',
      dataIndex: 'imageName',
      render: (item) => item + 1,
      key: 'imageName',
    },
    {
      title: 'Action',
      key: 'action',
      width: '110px',
      render: (text, record) => renderAction(record),
    },
  ]

  function resetForm() {
    form.resetFields()
  }

  const customProps = {
    multiple: false,
    onRemove: (file) => setFileList([]),
    beforeUpload: (file) => {
      setFileList(file)
      return false
    },
    fileList,
  }

  const normFile = (e) => {
    setFileList([])
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  return (
    <>
      <div className='main_dashboard_div'>
        <div className='agreement_main_div'>
          <div className='agreement_text'>Login Images</div>
          {imageLoading ? (
            <div style={{ alignItems: 'end' }}>
              <Spin indicator={antIcon} />
            </div>
          ) : (
            <Form
              form={form}
              layout='vertical'
              name='form_in_modal'
              initialValues={{
                modifier: 'public',
                ...selectedImage,
              }}
              style={{ display: 'flex', alignItems: 'baseline' }}
            >
              <Form.Item
                name='fileUrl'
                valuePropName='fileList'
                getValueFromEvent={normFile}
              >
                <Upload
                  name='attachment'
                  showUploadList={false}
                  listType='text'
                  {...customProps}
                  accept='.jpeg,.png,.jpg'
                >
                  <>
                    <Button className='export_btn'>
                      <PlusOutlined /> Add Login Image
                    </Button>
                  </>
                </Upload>
              </Form.Item>
              {form.getFieldValue('fileUrl') && (
                <Form.Item>
                  <div
                    style={{ marginTop: '8px', color: 'rgba(0, 0, 0, 0.45)' }}
                  >
                    Selected file: {form.getFieldValue('fileUrl')[0].name}
                  </div>
                </Form.Item>
              )}
              <br />
            </Form>
          )}
        </div>
        <Table
          style={{ overflow: 'auto' }}
          columns={columns}
          dataSource={allImages}
          // scroll={{ x: true, y: true }}
        />
      </div>
    </>
  )
}

export default LoginImages
