import React, { useContext, useState } from 'react'
import { Modal, Descriptions, Checkbox, Button, Row, Col } from 'antd'
import get from 'lodash/get'

import { AppContext } from 'AppContext'
import client from 'apollo'
import {
  SEND_OTP_FOR_REEVALUATE,
  UPDATE_USER,
} from 'modules/Users/graphql/Mutations'
import {
  COMPLETE_STEP,
  CONSENT_DATA,
  GIVE_ANSWER,
  RISK_ASSESSMENT_MAIL,
} from '../graphql/Mutations'
import openNotification from 'utils/Notification'
import { orderBy } from 'lodash'
import VerificationModal from './VerificationModal'
import moment from 'moment'
import { GET_PLANS_BASED_POINTS } from 'modules/Questionnaires/graphql/Query'
import { useQuery } from '@apollo/react-hooks'
import Page404 from 'components/Page404'
// import Loader from 'components/loaders/Loader'
import { formatToLakhsOrCrores } from 'utils/utilities'

const VerifyDetailsShow = ({
  visible,
  onCancel,
  formValue,
  next,
  section,
  points,
  reEvaluate,
}) => {
  const [agree, setAgree] = useState(true)
  const [loadings, setLoadings] = useState(false)
  const [otpModal, setOtpModal] = useState(false)
  const [modalClose, setModalClose] = useState(true)
  let {
    dispatch,
    state: { currentUser, clientSummaryDetails, isMobile },
  } = useContext(AppContext)
  const { id } = currentUser

  const {
    // loading,
    error,
    data: planData,
  } = useQuery(GET_PLANS_BASED_POINTS, {
    variables: { points },
    fetchPolicy: 'network-only',
  })

  // if (loading) return <Loader />
  if (error) return <Page404 error={error} />
  const handleAgree = (e) => {
    setAgree(e.target.checked)
  }

  let getAnswers = []
  if (get(formValue, 'answers')) {
    getAnswers = formValue.answers

    let array = []
    getAnswers.forEach((item) => {
      item = {
        ...item,
        planId: +item.planId,
      }

      array.push(item)
    })

    getAnswers = array
  }

  const onNext = () => {
    setLoadings(true)
    const currentIndex = clientSummaryDetails.findIndex(
      (item) => item?.name === 'Risk Profile Assessment'
    )
    let updateSummaryUiData = {
      completedSteps: 'CLIENT_PROFILE_2',
      name: 'Risk Profile Assessment',
      isDisplayEdit: true,
    }

    const findIncomeQuestion = section?.flatMap((section) =>
      section.questions.filter(
        (question) => question.includeInvestmentValidation
      )
    )

    const getIncomeId = findIncomeQuestion?.map((item) => item?.id)
    const getIncomeAnswer = getAnswers?.filter((item) =>
      getIncomeId?.includes(item?.questionId.toString())
    )

    let arrayPoints = []
    let data = {
      'Eligible Plan': planData?.fetchPlansBasedOnPoints
        ?.map((item) => item?.name)
        ?.join(','),
      description: 'Exposure Limit on the basis of',
    }
    findIncomeQuestion.forEach((question) => {
      const selectedOption = getIncomeAnswer?.find(
        (item) => item?.questionId === parseInt(question?.id)
      )?.selectedOptionsId
      const findOption = question?.options.find(
        (item) => parseInt(item?.id) === selectedOption
      )
      if (currentUser?.isPanCardIndividual) {
        if (question?.question?.includes('What is your annual Income')) {
          data['Annual Income'] = formatToLakhsOrCrores(findOption?.value)
          arrayPoints.push(findOption?.value * findOption?.multiplication)
        }
      } else {
        data['Annual Income'] = formatToLakhsOrCrores(
          +currentUser?.annualIncome
        )
        arrayPoints.push(+currentUser?.annualIncome)
      }
      if (question?.question?.includes('Your net worth?')) {
        data['Net Worth'] = formatToLakhsOrCrores(findOption?.value)
        arrayPoints.push(findOption?.value * findOption?.multiplication)
      }
      if (
        question?.question?.includes(
          'Market Value of all Liquid Assets (Deposits, Shares, Mutual funds etc)'
        )
      ) {
        data['All Liquid Assets'] = formatToLakhsOrCrores(findOption?.value)
        arrayPoints.push(findOption?.value * findOption?.multiplication)
      }
    })
    data['Risk Profile Score'] = points
    data['Maximum Exposure'] = formatToLakhsOrCrores(Math.max(...arrayPoints))
    updateSummaryUiData = { ...updateSummaryUiData, data }

    if (currentIndex !== -1) {
      clientSummaryDetails[currentIndex] = updateSummaryUiData
    } else {
      clientSummaryDetails.push(updateSummaryUiData)
    }
    client
      .mutate({
        mutation: GIVE_ANSWER,
        variables: {
          data: {
            answers: getAnswers,
            // annualIncome: formValue.annualIncome.toString(),
          },
        },
      })
      .then(() => {
        client
          .mutate({
            mutation: UPDATE_USER,
            variables: {
              id,
              data: {
                clientScore: points,
              },
            },
          })
          .then(() => {
            client.mutate({ mutation: RISK_ASSESSMENT_MAIL }).then(() => {
              client.mutate({ mutation: CONSENT_DATA }).then(() => {
                client
                  .mutate({
                    mutation: UPDATE_USER,
                    variables: {
                      id,
                      data: {
                        registrationDate: moment().format('YYYY-MM-DD'),
                        completedSteps: 'RISK_PROFILE',
                      },
                    },
                  })
                  .then((res) => {
                    dispatch({
                      type: 'CURRENT_USER',
                      data: res.data.updateUser,
                    })
                    dispatch({
                      type: 'CLIENT_SUMMARY_DETAILS',
                      data: clientSummaryDetails,
                    })
                    client
                      .mutate({
                        mutation: COMPLETE_STEP,
                        variables: {
                          data: {
                            step: 'RISK_PROFILE',
                            from: 'WEB',
                          },
                        },
                      })
                      .then((res) => {
                        openNotification(
                          'success',
                          'Risk Profile Assessment Updated Successfully'
                        )
                        next()
                      })
                  })
                  .catch((err) => console.log(err))
                  .finally(() => setLoadings(false))
              })
            })
          })
      })
      .catch((err) => console.log({ err }))
  }

  const renderItem = (question, answer, isHeading = false) => {
    return (
      <Descriptions.Item
        span={3}
        style={{ backgroundColor: isHeading ? '#f2f2f2' : '' }}
      >
        <Row>
          <Col
            style={{
              padding: `${isMobile ? '0 4px' : '0 16px'}`,
              textWrap: 'balance',
            }}
            span={15}
          >
            {question.question}
          </Col>
          <Col
            style={{
              padding: `${isMobile ? '0 0px' : '0 16px'}`,
              textWrap: 'wrap',
            }}
            span={9}
          >
            {answer}
          </Col>
        </Row>
      </Descriptions.Item>
    )
  }

  const onOk = () => {
    if (reEvaluate) {
      setOtpModal(true)
      client.mutate({ mutation: SEND_OTP_FOR_REEVALUATE }).then((res) => {
        openNotification('success', res?.data?.sendOtpForReEvaluate?.message)
      })
      setModalClose(false)
    } else {
      onNext()
    }
  }

  return (
    <>
      {modalClose ? (
        <Modal
          width={!isMobile ? '60%' : '100%'}
          maskClosable={false}
          visible={visible}
          title={'Verify Your Details'}
          cancelText='Cancel'
          onCancel={() => onCancel()}
        >
          <Descriptions bordered className='question_detail'>
            {renderItem({ question: 'QUESTION(s)' }, 'ANSWER(s)', true)}
            {section.map((section, key) =>
              orderBy(section.questions, 'index').map((question, key) => {
                let answer
                if (question.type === 'TEXT') {
                  answer = getAnswers.find(
                    (value) => value.questionId === parseInt(question.id)
                  )?.answer
                } else if (question.type === 'CHECK_BOX') {
                  answer = getAnswers.find(
                    (value) => value.questionId === parseInt(question.id)
                  )?.selectedOptionsId
                  if (answer)
                    answer = question.options
                      .filter((o) => answer.includes(parseInt(o.id)))
                      .map((o) => o.option)
                      .join(', ')
                } else {
                  answer = getAnswers.find(
                    (value) => value.questionId === parseInt(question.id)
                  )?.selectedOptionsId
                  if (answer)
                    answer = question.options
                      .filter((o) => answer === parseInt(o.id))
                      .map((o) => o.option)
                      .join(', ')
                }

                return renderItem(question, answer)
              })
            )}
          </Descriptions>
          <br />
          <div className='agree-wrapper'>
            <Checkbox onChange={handleAgree} checked={true} />
            <p className='agree-content'>
              I/We hereby declare that the details furnished above are true and
              correct to the best of my/our knowledge and belief and I/We
              undertake to inform you of any changes therein, immediately. In
              case any of the above information is found to be false or untrue
              or misleading or misrepresenting, I am/We are aware that I/We may
              be held liable for it.
            </p>
          </div>
          <div className='footer-btn-div'>
            <Button className='footer-cancel-btn' onClick={() => onCancel()}>
              {' '}
              Cancel{' '}
            </Button>
            <Button
              className='footer-submit-btn'
              onClick={() => onOk()}
              type='primary'
              disabled={!agree}
              loading={loadings}
            >
              Verify
            </Button>
          </div>
        </Modal>
      ) : (
        <Modal
          style={{ display: 'none' }}
          width={'80%'}
          maskClosable={false}
          visible={visible}
          title={'Verify Your Details'}
          cancelText='Cancel'
          footer={
            <>
              <Button onClick={() => onCancel()}>Cancel</Button>
              <Button onClick={() => onOk()} type='primary' disabled={!agree}>
                Next
              </Button>
            </>
          }
          onCancel={() => onCancel()}
        >
          <Descriptions bordered>
            {renderItem({ question: 'QUESTION(s)' }, 'ANSWER(s)', true)}
            {/* {renderItem(
              { question: '1. What is your annual Income?' },
              formValue.annualIncome
            )} */}
            {section.map((section, key) =>
              orderBy(section.questions, 'index').map((question, key) => {
                let answer
                if (question.type === 'TEXT') {
                  answer = getAnswers.find(
                    (value) => value.questionId === parseInt(question.id)
                  )?.answer
                } else if (question.type === 'CHECK_BOX') {
                  answer = getAnswers.find(
                    (value) => value.questionId === parseInt(question.id)
                  )?.selectedOptionsId
                  if (answer)
                    answer = question.options
                      .filter((o) => answer.includes(parseInt(o.id)))
                      .map((o) => o.option)
                      .join(', ')
                } else {
                  answer = getAnswers.find(
                    (value) => value.questionId === parseInt(question.id)
                  )?.selectedOptionsId
                  if (answer)
                    answer = question.options
                      .filter((o) => answer === parseInt(o.id))
                      .map((o) => o.option)
                      .join(', ')
                }

                return renderItem(question, answer)
              })
            )}
          </Descriptions>
          <br />
          <div className='agree-wrapper'>
            <Checkbox onChange={handleAgree} checked={agree} />
            <p className='agree-content'>
              I/We hereby declare that the details furnished above are true and
              correct to the best of my/our knowledge and belief and I/We
              undertake to inform you of any changes therein, immediately. In
              case any of the above information is found to be false or untrue
              or misleading or misrepresenting, I am/We are aware that I/We may
              be held liable for it.
            </p>
          </div>
          <div className='footer-btn-div'>
            <Button className='footer-cancel-btn' onClick={() => onCancel()}>
              {' '}
              Cancel{' '}
            </Button>
            <Button
              className='footer-submit-btn'
              onClick={() => onOk()}
              type='primary'
              disabled={!agree}
              loading={loadings}
            >
              Verify
            </Button>
          </div>
        </Modal>
      )}
      <VerificationModal
        visible={otpModal}
        onCancel={onCancel}
        onNext={onNext}
      />
    </>
  )
}

const RiskProfile = (props) => {
  return (
    <div>
      <VerifyDetailsShow {...props} />
    </div>
  )
}

export default RiskProfile
