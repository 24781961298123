import React, { useContext, useState } from 'react'
import { Form, Input, Select, Button, DatePicker, Radio, Row, Col } from 'antd'
import moment from 'moment'

import CustomMeta from 'components/CustomMeta'
import { AppContext } from 'AppContext'
import countries from 'utils/countries.json'
import cities from 'utils/cities.json'
// import ClientProfileModal2 from './Model/ClientProfile2'
import { UPDATE_USER } from 'modules/Users/graphql/Mutations'
import client from 'apollo'
import openNotification from 'utils/Notification'
import { orderBy } from 'lodash'
import { handleInputChange } from 'utils/utilities'
import {
  COMPLETE_STEP,
  DISCLAIMER_MAIL,
} from 'modules/Verification/graphql/Mutations'
import calendarIcon from 'assets/images/new-images/calendar.svg'
import './index.css'
import ClientProfileModal2 from 'modules/Verification/Model/ClientProfile2'

const FormItem = Form.Item
const { Option } = Select

const ClientDetail = ({ next, reEvaluate, currentUser }) => {
  const [form] = Form.useForm()
  const [showVerifyModal, setShowVerifyModal] = useState(false)
  const { dispatch, state } = useContext(AppContext)
  const { id, plan: plansName } = state.currentUser
  const planName = plansName?.name
  const [editAccess, setEditAccess] = useState(reEvaluate)
  const [formValue, setFormValue] = useState({})
  const dateFormat = 'DD-MMM-YYYY'
  const [loadings, setLoadings] = useState()
  const {
    gender,
    address,
    fatherName,
    nationality,
    residentialStatus,
    sourceOfIncome,
    politicallyExposed,
    countryOfTax,
    dateOfBirth,
    maritalStatus,
    cityId,
    plan,
    // planId,
  } = state.currentUser
  let { clientSummaryDetails } = state

  const onFinish = (values) => {
    values.fatherName =
      values.fatherName.charAt(0).toUpperCase() +
      values.fatherName.slice(1).toLowerCase()
    if (editAccess === true) {
      onNext()
    } else {
      setFormValue({
        ...values,
        dateOfBirth: values && values?.dateOfBirth.format('YYYY-MM-DD'),
        stateId: cities.filter((item) => values.cityId === item?.id)[0]
          ?.state_id,
        countryId: countries.filter(
          (item) => values?.countryOfTax === item?.name
        )[0]?.id,
      })
      setShowVerifyModal(true)
    }
  }

  const handleHideFormModal = () => {
    setShowVerifyModal(false)
  }

  const editAccessFunc = () => {
    setEditAccess(false)
  }

  const onNext = () => {
    setLoadings(true)
    dispatch({ type: 'IS_ACCEPT_LOADING', data: true })
    const currentIndex = clientSummaryDetails.findIndex(
      (item) => item?.name === 'Client Profile'
    )
    const updateSummaryUiDetails = {
      completedSteps: 'CLIENT_PROFILE_1',
      name: 'Client Profile',
      isDisplayEdit: true,
      data: {
        'Date of Birth': moment(dateOfBirth).format('DD/MM/YYYY'),
        Gender: gender,
        Address: address,
        'Source of Income':
          sourceOfIncome[0] +
          sourceOfIncome?.slice(1)?.toLowerCase().split('_').join(' '),
      },
    }
    if (currentIndex !== -1) {
      clientSummaryDetails[currentIndex] = updateSummaryUiDetails
    } else {
      clientSummaryDetails.push(updateSummaryUiDetails)
    }
    const data = {
      completedSteps: 'CLIENT_PROFILE_2',
    }

    client
      .mutate({ mutation: UPDATE_USER, variables: { id, data } })
      .then((res) => {
        client.mutate({ mutation: DISCLAIMER_MAIL })
        client.mutate({
          mutation: COMPLETE_STEP,
          variables: {
            data: {
              step: 'CLIENT_PROFILE_2',
              from: 'WEB',
            },
          },
        })
        dispatch({ type: 'CURRENT_USER', data: res.data.updateUser })
        dispatch({ type: 'CLIENT_SUMMARY_DETAILS', data: clientSummaryDetails })
        openNotification('success', 'Client Profile Updated Successfully')
        dispatch({ type: 'IS_ACCEPT_LOADING', data: false })
        next()
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoadings(false)
        dispatch({ type: 'IS_ACCEPT_LOADING', data: false })
      })
  }

  const disableFutureDates = (current) => {
    return current && current > moment().endOf('day')
  }

  return (
    <div>
      <CustomMeta title='Client Profile 2' />
      <div className='form-outer-div'>
        <Row>
          <Col span={22} style={{ textAlign: 'center' }}></Col>
          <Col span={2}>
            {editAccess && (
              <Button type='primary' onClick={editAccessFunc}>
                Edit
              </Button>
            )}
          </Col>
        </Row>
        <Form
          form={form}
          name='register'
          onFinish={onFinish}
          initialValues={{
            dateOfBirth: dateOfBirth ? moment(dateOfBirth) : '',
            address: address || '',
            fatherName: fatherName || '',
            gender: gender || undefined,
            nationality: nationality || 'Indian',
            residentialStatus: residentialStatus || 'RESIDENT_INDIVIDUAL',
            sourceOfIncome: sourceOfIncome || undefined,
            politicallyExposed: politicallyExposed || 'NO',
            countryOfTax: countryOfTax || 'India',
            maritalStatus: maritalStatus || undefined,
            cityId: cityId || undefined,
            planId: parseInt(plan?.id) || undefined,
          }}
          labelAlign='left'
          scrollToFirstError
          layout='vertical'
          className='client-detail-form'
        >
          <Row className='client-profile-row'>
            <Col className='gutter-row client-column' lg={12} sm={24}>
              <FormItem
                name='dateOfBirth'
                label='Date of Birth'
                rules={[
                  {
                    required: true,
                    message: 'Date Of Birth is required!',
                  },
                ]}
              >
                <DatePicker
                  inputReadOnly
                  getPopupContainer={(trigger) => trigger.parentElement}
                  format={dateFormat}
                  className='client-detail-date-picker'
                  disabled={!editAccess ? false : true}
                  disabledDate={disableFutureDates}
                  suffixIcon={<img src={calendarIcon} alt='calendarIcon' />}
                />
              </FormItem>
            </Col>

            <Col className='gutter-row client-column' lg={12} sm={24}>
              <FormItem
                name='fatherName'
                label='Name of father/spouse'
                rules={[
                  {
                    required: true,
                    message: 'Father/spouse is required!',
                  },
                ]}
              >
                <Input
                  placeholder='Father/Spouse Name'
                  autoFocus
                  className='client-detail-input'
                  disabled={!editAccess ? false : true}
                  onChange={(e) => handleInputChange(form, 'fatherName', e)}
                />
              </FormItem>
            </Col>

            <Col className='gutter-row client-column' lg={12} sm={24}>
              <FormItem
                name='sourceOfIncome'
                label='Source of Income'
                rules={[
                  {
                    required: true,
                    message: 'Source of Income is required!',
                  },
                ]}
              >
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  placeholder='Source of Income'
                  className='display-field'
                  disabled={!editAccess ? false : true}
                >
                  <Option value='SALARY'>Salary</Option>
                  <Option value='BUSINESS_INCOME'>Business Income</Option>
                  <Option value='OTHER'>Others</Option>
                </Select>
              </FormItem>
            </Col>

            <Col className='gutter-row client-column' lg={12} sm={24}>
              <FormItem
                name='nationality'
                label='Nationality'
                rules={[
                  {
                    required: true,
                    message: 'Nationality is required!',
                  },
                ]}
              >
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  placeholder='Nationality'
                  className='display-field'
                  disabled={!editAccess ? false : true}
                >
                  {countries.map((country) => (
                    <Option key={country.id} value={country.nationalityName}>
                      {country.nationalityName}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>

            <Col className='gutter-row client-column' lg={12} sm={24}>
              <FormItem
                name='cityId'
                label='City'
                rules={[
                  {
                    required: true,
                    message: 'City is required!',
                  },
                ]}
              >
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  showSearch
                  allowClear
                  optionFilterProp='children'
                  placeholder='City'
                  className='display-field'
                  disabled={!editAccess ? false : true}
                >
                  {orderBy(cities, 'name').map((city) => (
                    <Option key={city.id} value={city.id}>
                      {city.name}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>

            <Col className='gutter-row client-column' lg={12} sm={24}>
              <FormItem
                name='countryOfTax'
                label='Country of tax'
                rules={[
                  {
                    required: true,
                    message: 'Country of tax is required!',
                  },
                ]}
              >
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  placeholder='Country of tax'
                  className='display-field'
                  disabled={!editAccess ? false : true}
                >
                  {countries.map((country) => (
                    <Option key={country.id} value={country.nationalityName}>
                      {country.nationalityName}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>

            <Col className='gutter-row client-column' lg={12} sm={24}>
              <FormItem
                name='address'
                label='Address'
                rules={[
                  {
                    required: true,
                    message: 'Address is required!',
                  },
                ]}
              >
                <Input.TextArea
                  rows={4}
                  placeholder='Address'
                  autoFocus
                  className='client-detail-textarea'
                  disabled={!editAccess ? false : true}
                  onChange={(e) => handleInputChange(form, 'address', e)}
                />
              </FormItem>
            </Col>

            <Col className='gutter-row client-column' lg={12} sm={24}>
              <FormItem
                name='politicallyExposed'
                label='Politically Expose'
                rules={[
                  {
                    required: true,
                    message: 'Politically Exposed is required!',
                  },
                ]}
              >
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  placeholder='Politically Expose'
                  className='display-field'
                  disabled={!editAccess ? false : true}
                >
                  <Option value='YES'>Yes</Option>
                  <Option value='NO'>No</Option>
                  <Option value='RELATED'>Related</Option>
                </Select>
              </FormItem>
            </Col>
          </Row>
          <div className='client-profile-grid'>
            <FormItem
              name='gender'
              label='Gender'
              rules={[
                {
                  required: true,
                  message: 'Gender is required!',
                },
              ]}
            >
              <Radio.Group
                className='gender-radio-group'
                disabled={!editAccess ? false : true}
              >
                <Radio.Button className='gender-radio-button' value='MALE'>
                  Male
                </Radio.Button>
                <Radio.Button className='gender-radio-button' value='FEMALE'>
                  Female
                </Radio.Button>
                <Radio.Button className='gender-radio-button' value='OTHER'>
                  Other
                </Radio.Button>
              </Radio.Group>
            </FormItem>

            <FormItem
              name='residentialStatus'
              label='Residential Status'
              rules={[
                {
                  required: true,
                  message: 'Residential Status is required!',
                },
              ]}
            >
              <Radio.Group
                className='gender-radio-group'
                disabled={!editAccess ? false : true}
              >
                <Radio.Button
                  className='gender-radio-button'
                  value='RESIDENT_INDIVIDUAL'
                >
                  Resident Individual
                </Radio.Button>
                <Radio.Button
                  className='gender-radio-button'
                  value='NON_RESIDENT_INDIAN'
                >
                  Non Resident Indian
                </Radio.Button>
              </Radio.Group>
            </FormItem>

            <FormItem
              name='maritalStatus'
              label='Marital Status'
              rules={[
                {
                  required: true,
                  message: 'Marital Status is required!',
                },
              ]}
            >
              <Radio.Group
                className='gender-radio-group'
                disabled={!editAccess ? false : true}
              >
                <Radio.Button className='gender-radio-button' value='SINGLE'>
                  Single
                </Radio.Button>
                <Radio.Button className='gender-radio-button' value='MARRIED'>
                  Married
                </Radio.Button>
              </Radio.Group>
            </FormItem>
          </div>

          <div className='verify_next_button'>
            <Button
              // id='btn-click'
              className='yes-next-button'
              type='primary'
              htmlType='submit'
              loading={loadings}
            >
              Verify And Next
            </Button>
          </div>

          {showVerifyModal && (
            <ClientProfileModal2
              next={next}
              formValue={formValue}
              visible={setShowVerifyModal}
              onCancel={() => handleHideFormModal()}
              reEvaluate={reEvaluate}
              planName={planName}
            />
          )}
        </Form>
      </div>
    </div>
  )
}

export default ClientDetail
