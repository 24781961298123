import React, { useContext, useEffect, useState } from 'react'
import {
  DatePicker,
  Form,
  Button,
  Select,
  Table,
  Tooltip,
  Modal,
  Drawer,
} from 'antd'
import JSZip from 'jszip'

import {
  MailOutlined,
  FilterOutlined,
  DownloadOutlined,
} from '@ant-design/icons'
import { useQuery } from '@apollo/react-hooks'
import {
  GENERATE_BULK_PERFORMANCE_REPORT,
  GET_ALL_CLIENT_BY_TYPE,
  GET_BULK_PERFORMANCE_REPORT_SETTING,
} from '../graphql/Queries'
import Loader from 'components/loaders/Loader'
import Page404 from 'components/Page404'
import client from 'apollo'
import { getName } from 'utils/User'
import moment from 'moment'
import pdfImage from '../../../assets/images/pdf-svgrepo-com.svg'
import PerformanceMailSendModal from '../PerformanceMailSendModal'
import { SEND_BULK_PERFORMANCE_REPORT_MAIL } from '../graphql/Mutation'
import openNotification from 'utils/Notification'
import { GET_ILTS_P_REPORT } from 'modules/Reports/graphql/Queries'
import FilterBackButton from 'assets/images/new-images/filter-back-button.svg'
import '../index.css'
import { AppContext } from 'AppContext'

const { Option } = Select

const MultiClientReport = () => {
  const [form] = Form.useForm()

  const {
    state: { isMobile },
  } = useContext(AppContext)

  const [userLoading, setUserLoading] = useState(false)
  const [userError, setUserError] = useState()
  const [clientData, setClientData] = useState([])
  const [performanceLoading, setPerformanceLoading] = useState(false)
  const [clientCode, setClientCode] = useState([])
  const [performanceReport, setPerformanceReport] = useState([])
  const [selectedRow, setSelectedRow] = useState([])
  const [date, setDate] = useState()
  const [openMailModal, setOpenMailModal] = useState(false)
  const [sendMailData, setSendMailData] = useState({})
  const [buttonLoading, setButtonLoading] = useState(false)
  const [downloadLoading, setDownloadLoading] = useState(false)
  const [open, setOpen] = useState(false)

  const { data, loading, error } = useQuery(
    GET_BULK_PERFORMANCE_REPORT_SETTING,
    { fetchPolicy: 'network-only' }
  )

  const getAllClient = (userType) => {
    setUserLoading(true)
    client
      .query({
        query: GET_ALL_CLIENT_BY_TYPE,
        variables: { where: { userType } },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        setClientData(res?.data?.getUserAccordingUserType)
        setUserLoading(false)
      })
      .catch((err) => {
        setUserError(err)
        setUserLoading(false)
      })
      .finally(() => {
        setUserLoading(false)
      })
  }

  useEffect(() => {
    if (data?.getBulkPerformanceReportSetting?.userType) {
      setDate(
        moment(data?.getBulkPerformanceReportSetting?.performanceReportDate)
      )
      getAllClient(data?.getBulkPerformanceReportSetting?.userType)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    data?.getBulkPerformanceReportSetting?.performanceReportDate,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    data?.getBulkPerformanceReportSetting?.userType,
  ])

  if (loading || userLoading) return <Loader />
  if (error || userError) return <Page404 />

  const renderAction = (record) => {
    return (
      <div className='action-icons'>
        <Tooltip title='Send Mail'>
          <MailOutlined onClick={() => handleExport(record, false)} />
        </Tooltip>
      </div>
    )
  }

  const handleExport = (record, isDownload) => {
    client
      .query({
        query: GET_ILTS_P_REPORT,
        variables: {
          where: {
            id: parseInt(record?.clientId),
            date:
              data?.getBulkPerformanceReportSetting?.type === 'AUTO'
                ? ''
                : moment(
                    data?.getBulkPerformanceReportSetting?.performanceReportDate
                  ).format('DD-MMM-YYYY'),
          },
        },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        if (isDownload) {
          if (res?.data?.getIltspreport) {
            window.open(res?.data?.getIltspreport, '_blank')
          } else {
            openNotification(
              'error',
              `Performance report not found for ${record?.clientCode} client code.`
            )
          }
        } else {
          let mailDraft =
            data?.getBulkPerformanceReportSetting?.mailDraft?.replaceAll(
              // eslint-disable-next-line no-template-curly-in-string
              '${clientName}',
              record?.clientName
            )

          let sendObject = {
            ...record,
            date: data?.getBulkPerformanceReportSetting?.performanceReportDate,
            ccMailIds: record?.ccMails
              ? record?.ccMails?.HKeyEmail?.split(',')?.filter(
                  (item) => item !== ''
                )
              : [],
            emailId: record?.ccMails ? record?.ccMails?.email?.split(',') : [],
            mailDraft,
            attachment: res?.data?.getIltspreport,
          }

          setSendMailData(sendObject)
          setOpenMailModal(true)
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setButtonLoading(false))
  }

  function createMarkup(__html) {
    return { __html }
  }

  const rowSelection = {
    selectedRowKeys: selectedRow,
    onSelectAll: (record) => {
      if (record) {
        setSelectedRow(performanceReport.map((item) => item?.id))
      } else {
        setSelectedRow([])
      }
    },
    onSelect: (record) => {
      const isTrue = selectedRow.findIndex((i) => i === record.id)
      if (isTrue > -1) {
        const newValues = selectedRow.filter((i) => i !== record.id)
        setSelectedRow(newValues)
      } else {
        setSelectedRow((oldData) => [...oldData, record.id])
      }
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
  }

  const columns = [
    { title: 'Client Code', dataIndex: 'clientCode', key: 'clientCode' },
    { title: 'Client Name', dataIndex: 'clientName', key: 'clientName' },
    {
      title: 'To Mail Id',
      dataIndex: 'ccMails',
      key: 'ccMails',
      render: (text) =>
        text.email ? (
          <ol>
            {text?.email?.split(',').map((item) => {
              if (item !== '') {
                return <li>{item}</li>
              } else {
                return null
              }
            })}
          </ol>
        ) : (
          '-'
        ),
    },
    {
      title: 'CC Mail Id',
      dataIndex: 'ccMails',
      key: 'ccMails',
      render: (text) =>
        text?.HKeyEmail ? (
          <ol>
            {text?.HKeyEmail?.split(',').map((email) => {
              if (email !== '') {
                return <li>{email}</li>
              } else {
                return null
              }
            })}
          </ol>
        ) : (
          '-'
        ),
    },
    {
      title: 'Mail Draft',
      dataIndex: 'client',
      key: 'client',
      width: isMobile && 120,
      render: (text, record) => {
        return (
          <>
            <Tooltip
              placement='leftTop'
              title={
                <div
                  // className='tooltip_text'
                  dangerouslySetInnerHTML={createMarkup(
                    data?.getBulkPerformanceReportSetting?.mailDraft?.replaceAll(
                      // eslint-disable-next-line no-template-curly-in-string
                      '${clientName}',
                      record?.clientName
                    )
                  )}
                />
              }
            >
              <span>Click Here To show Mail Draft</span>
            </Tooltip>
          </>
        )
      },
    },
    {
      title: 'Attachment',
      width: isMobile && 120,
      render: (text, record) => (
        <img
          src={pdfImage}
          alt=''
          onClick={() => handleExport(record, true)}
          width={25}
          height={30}
          style={{
            cursor: 'pointer',
            marginRight: '10px',
          }}
        />
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => renderAction(record),
      width: '80px',
    },
  ]

  const downloadZipFile = (pdfUrls) => {
    const zip = new JSZip()
    const promises = []

    pdfUrls.forEach((url) => {
      const filename = url.substring(url.lastIndexOf('/') + 1)
      promises.push(
        fetch(url)
          .then((response) => response.blob())
          .then((blob) => zip.file(filename, blob))
      )
    })

    Promise.all(promises)
      .then(() => zip.generateAsync({ type: 'blob' }))
      .then((content) => {
        const zipFilename = 'selected_client_performance_report.zip'
        const url = window.URL.createObjectURL(content)
        const a = document.createElement('a')
        a.href = url
        a.download = zipFilename
        a.click()
        window.URL.revokeObjectURL(url)
      })
      .catch((error) => console.log('Error zipping PDF files:', error))
  }

  const onFinish = (isDownload) => {
    let obj
    if (isDownload) {
      setDownloadLoading(true)
      const clients = performanceReport.filter((item) =>
        selectedRow.includes(item?.id)
      )
      obj = {
        clientCode: clients.map((code) => code?.clientCode),
        date,
        isDownload: true,
      }
    } else {
      setPerformanceLoading(true)
      obj = {
        clientCode: clientCode.map((code) => code?.split('-')[0]),
        date,
        isDownload: false,
      }
    }
    client
      .query({
        query: GENERATE_BULK_PERFORMANCE_REPORT,
        variables: {
          where: obj,
        },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        if (isDownload) {
          let count = 0
          let pdfUrls = []
          let download = false
          res.data.generateBulkPerformanceReport.forEach((item) => {
            if (item?.attachment) {
              pdfUrls.push(item?.attachment)
              download = true
            } else {
              count = count + 1
            }
          })

          if (download) {
            downloadZipFile(pdfUrls)
          }

          if (count > 0) {
            openNotification(
              'error',
              `${count} Report(s) has an error while downloading the performance report`
            )
          }

          setSelectedRow([])
          setClientCode([])
        } else {
          setPerformanceReport(res?.data?.generateBulkPerformanceReport)
        }
        setDownloadLoading(false)
        setPerformanceLoading(false)
        setOpen(false)
      })
      .catch((err) => {
        setPerformanceReport([])
        setPerformanceLoading(false)
        setOpen(false)
      })
  }

  const handlePaste = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData
    const pastedData = clipboardData.getData('Text')?.trim()?.split(',')
    let array = []

    clientData.forEach((user) => {
      let name = `${user.clientCode}-${getName(user)}`

      if (name.split('-')?.length && pastedData.includes(name?.split('-')[0])) {
        array.push(name)
      }
    })
    setClientCode(array)
    if (array.length) event.clipboardData = null
  }

  const handleSelect = (value) => {
    setClientCode(value)
  }

  const handleClear = () => {
    setPerformanceReport([])
    setClientCode([])
    setOpen(false)
  }

  const handleSendEmail = () => {
    setButtonLoading(true)
    let sendData = []
    performanceReport
      .filter((item) => selectedRow.includes(item?.id))
      .forEach((item) => {
        const ccMailIds = item?.ccMails
          ? item?.ccMails?.HKeyEmail?.split(',')
              .map((item) => {
                return { email: item }
              })
              .filter((email) => email?.email !== '')
          : []
        const obj = {
          mailDraft:
            data?.getBulkPerformanceReportSetting?.mailDraft?.replaceAll(
              // eslint-disable-next-line no-template-curly-in-string
              '${clientName}',
              item?.clientName
            ),
          date: data?.getBulkPerformanceReportSetting?.performanceReportDate,
          clientCode: item?.clientCode,
          subject: `Finideas Index Long Term Strategy Performance Report`,
          toMailId: item?.ccMails
            ? item?.ccMails?.email?.split(',').filter((item) => item !== '')
            : [],
          ccMailIds,
          pdfName: `ILTS-PERFORMANCE-REPORT-${item?.clientCode}`,
        }
        sendData.push(obj)
      })
    client
      .mutate({
        mutation: SEND_BULK_PERFORMANCE_REPORT_MAIL,
        variables: {
          data: sendData,
        },
      })
      .then((res) => {
        if (res?.data?.sendBulkPerformanceMailSend) {
          openNotification('success', 'Mail Send Successfully')
        } else {
          openNotification('error', 'Please try again')
        }
        setButtonLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setButtonLoading(false)
      })
      .finally(() => {
        setButtonLoading(false)
      })
  }

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }
  return (
    <>
      <div className='main_dashboard_div'>
        <div className='bulk_setting_text_div'>
          <div className='bulk_setting_text'>Multi Client Report</div>

          <div className='multiple_performance_option'>
            {!performanceLoading && performanceReport.length ? (
              <>
                <Button
                  className='option_btn'
                  disabled={!selectedRow?.length}
                  loading={buttonLoading}
                  onClick={handleSendEmail}
                >
                  Send Mail
                </Button>
                <Button
                  className='option_btn'
                  disabled={!selectedRow?.length}
                  onClick={() => onFinish(true)}
                  loading={downloadLoading}
                >
                  Download
                  <DownloadOutlined
                    color='#AF0000'
                    style={{ fontSize: '16px' }}
                  />
                </Button>
              </>
            ) : (
              ''
            )}

            <Button className='filter_btn' onClick={showDrawer}>
              Filter{' '}
              <FilterOutlined color='#000000' style={{ fontSize: '16px' }} />
            </Button>
          </div>
        </div>

        <Drawer
          title={null}
          headerStyle={{ display: 'none' }}
          closable={false}
          placement='right'
          onClose={onClose}
          visible={open}
          width={472}
        >
          <div
            style={{
              margin: '32px 0 50px 0',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <div onClick={onClose}>
              <img src={FilterBackButton} alt='FilterBackButton' />
            </div>
            <div
              style={{
                fontSize: '24px',
                fontWeight: '700',
                lineHeight: '28px',
                textAlign: 'left',
                color: '#000000',
                marginLeft: '16px',
              }}
            >
              Filter
            </div>
          </div>
          <Form
            form={form}
            name='advanced_search'
            style={{ marginTop: '16px' }}
            layout='vertical'
            className='client-detail-form'
            onFinish={() => onFinish(false)}
          >
            <Form.Item label='Date'>
              <DatePicker getPopupContainer={trigger => trigger.parentElement} style={{ width: '100%' }} disabled value={date} />
            </Form.Item>
            <Form.Item label='Select Clients'>
              <Select
              getPopupContainer={(trigger) => trigger.parentElement}
                placeholder='Please select client'
                showSearch
                optionFilterProp='children'
                mode='multiple'
                onChange={handleSelect}
                onPaste={handlePaste}
                value={clientCode}
              >
                {!userLoading &&
                  clientData &&
                  clientData.map((user, key) => {
                    return (
                      <Option
                        key={key}
                        value={`${user.clientCode}-${getName(user)}`}
                        title={getName(user)}
                      >
                        {user.clientCode}-{getName(user)}
                      </Option>
                    )
                  })}
              </Select>
            </Form.Item>

            <div style={{ display: 'flex', gap: '20px', height: '56px' }}>
              <Button
                onClick={handleClear}
                style={{
                  width: '100%',
                  borderRadius: '8px',
                  padding: '16px',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                Clear All
              </Button>
              <Button
                type='primary'
                htmlType='submit'
                disabled={!clientCode.length}
                loading={performanceLoading}
                style={{
                  width: '100%',
                  borderRadius: '8px',
                  padding: '16px',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                Submit
              </Button>
            </div>
          </Form>
        </Drawer>

        {!performanceLoading && performanceReport.length ? (
          <>
            {/* <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '20px',
              }}
            >
              <Button
                type='primary'
                disabled={!selectedRow?.length}
                onClick={() => onFinish(true)}
                loading={downloadLoading}
              >
                Download
              </Button>
            </div> */}
            <Table
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
              rowKey='id'
              columns={columns}
              dataSource={performanceReport}
              style={{ overflow: 'auto' }}
            />
            {/* <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '20px',
              }}
            >
              <Button
                type='primary'
                disabled={!selectedRow?.length}
                loading={buttonLoading}
                onClick={handleSendEmail}
              >
                Send Mail
              </Button>
            </div> */}
          </>
        ) : (
          <></>
        )}

        {openMailModal && (
          <Modal
            className='feedback_form'
            visible={openMailModal}
            width={1200}
            onCancel={() => setOpenMailModal(false)}
            footer={null}
            title='Performance Mail Body'
          >
            <PerformanceMailSendModal
              data={sendMailData}
              setOpenMailModal={setOpenMailModal}
            />
          </Modal>
        )}
      </div>
    </>
  )
}

export default MultiClientReport
