import { Button, DatePicker, Drawer, Form, Modal, Select, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { GET_MAIL_LOG, GET_MAIL_CONTENT } from './graphql/Queries'
import moment from 'moment'
import {
  LinkOutlined,
  DownloadOutlined,
  FilterOutlined,
} from '@ant-design/icons'
import client from 'apollo'
import { GET_CLIENT_USERS_FOR_STAFF } from 'modules/Users/graphql/Queries'
import { get, orderBy } from 'lodash'
import './index.css'
import { GENERATE_PDF } from 'modules/Reports/graphql/Queries'
import FilterBackButton from 'assets/images/new-images/filter-back-button.svg'

const { Option } = Select
const { RangePicker } = DatePicker

const formItemLayout = {
  labelCol: { xs: { span: 24 }, sm: { span: 24 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 24 } },
}

const MailLog = () => {
  const [formData] = Form.useForm()
  const dateFormat = 'DD-MMM-YYYY'

  const [mailLogData, setMailLogData] = useState([])
  let [usersData, setUsersData] = useState([])
  const [userLoadingClient, setUserLoading] = useState(true)
  const [mailLoadingClient, setMAilLoading] = useState(true)
  const [showTable, setShowTable] = useState(false)
  const [sort, setSort] = useState('desc')
  const [currentPage, setCurrentPage] = useState(1)
  const [mailContent, setMailContent] = useState('')
  const [mailContentLoading, setMailContentLoading] = useState(false)
  const [mailContentPopUP, setMailContentPopUP] = useState(false)
  const [pdfGenerateLoading, setPdfGenerateLoading] = useState(false)
  const [limit, setLimit] = useState(5)
  const [skip, setSkip] = useState(0)
  const [email, setEmail] = useState()
  const [date, setDate] = useState([moment().subtract(29, 'days'), moment()])
  const [totalCount, setTotalCount] = useState()
  const [dateDifference, setDateDifference] = useState(true)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    client
      .query({
        query: GET_CLIENT_USERS_FOR_STAFF,
        variables: {
          where: {},
          limit: 10000,
        },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        res.data.getClientUsersForStaff.users.map((user) => {
          return usersData.push(user)
        })
        setUsersData(usersData)
        setUserLoading(false)
      })
      .catch((err) => console.log({ err }))
      .finally(() => setUserLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const changeFormData = (values) => {
    setMAilLoading(true)
    if (values?.email || email) {
      if (values?.email) {
        setEmail(values?.email)
        setDate(values?.date)
      } else {
        setEmail(email)
        setDate(date)
      }
      client
        .query({
          query: GET_MAIL_LOG,
          variables: {
            where: {
              email: values?.email || email,
              startDate: values?.date
                ? moment(values?.date[0]).format('YYYY-MM-DD')
                : moment(moment().subtract(29, 'days')).format('YYYY-MM-DD'),
              endDate: values?.date
                ? moment(values?.date[1]).format('YYYY-MM-DD')
                : moment().format('YYYY-MM-DD'),
              sort: sort,
              limit: limit,
              skip: skip,
            },
          },
          fetchPolicy: 'network-only',
        })
        .then((res) => {
          setMailLogData(res?.data?.getMailLogs?.transactionalEmails)
          setTotalCount(res?.data?.getMailLogs?.count)
          setMAilLoading(false)
        })
        .catch((err) => console.log({ err }))
        .finally(() => setMAilLoading(false))
    }

    if (values?.email || email) {
      setShowTable(true)
    } else {
      setShowTable(false)
    }
    setOpen(false)
  }
  useEffect(() => {
    changeFormData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip, limit, sort])

  const formDataReset = () => {
    formData.resetFields()
    setShowTable(false)
    setEmail(undefined)
    setDate(undefined)
    setOpen(false)
  }

  const handleTableChange = (pagination, filter, sorter) => {
    if (sorter && sorter.field && sorter.order) {
      if (sorter.order === 'descend') {
        setSort('desc')
        setCurrentPage(1)
      } else {
        setSort('asc')
        setCurrentPage(1)
      }
    }
  }

  const handleClickMailContent = (e, record) => {
    setMailContentLoading(true)
    client
      .query({
        query: GET_MAIL_CONTENT,
        variables: {
          where: {
            uuid: record?.uuid,
          },
        },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        setMailContent(res?.data?.getMailContent?.body)
        setMailContentPopUP(true)
        setMailContentLoading(true)
      })
      .catch((err) => console.log({ err }))
      .finally(() => setMailContentLoading(true))
  }

  const handleOk = () => {
    setMailContentPopUP(false)
  }
  const handleCancel = () => {
    setMailContentPopUP(false)
  }

  function handlePagination(page) {
    setSkip((page - 1) * limit)
    setCurrentPage(page)
  }

  function handlePageSizeChange(current, size) {
    setLimit(size)
  }

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (text, record) => moment(record?.date).format('DD-MMM-YYYY'),
      sorter: (a, b) => a.age - b.age,
    },
    {
      title: 'Mail Send From ',
      dataIndex: 'from',
      key: 'from',
    },
    {
      title: 'Subject ',
      dataIndex: 'subject',
      key: 'subject',
    },
    {
      title: 'Mail Content',
      dataIndex: 'mailContent',
      key: 'mailContent',
      render: (text, record) => (
        <Button
          onClick={(e) => {
            handleClickMailContent(e, record)
          }}
        >
          Show Message
        </Button>
      ),
    },
    {
      title: 'UUID',
      dataIndex: 'uuid',
      key: 'uuid',
      render: (text, record) =>
        record?.uuid ? (
          <a
            href={`https://app-smtp.brevo.com/log#${record?.uuid}`}
            target='_blank'
            rel={'noopener noreferrer'}
          >
            <LinkOutlined />
          </a>
        ) : (
          '-'
        ),
    },
  ]

  function createMarkup(__html) {
    return { __html }
  }

  function handlePdfDownload() {
    setPdfGenerateLoading(true)
    let staticHtml = ``
    staticHtml = `
            <div class="ant-table-wrapper nlv-tracker-report">
              <div class="ant-spin-nested-loading">
                <div class="ant-spin-container">
                  <div class="ant-table">
                    <div class="ant-table-container">
                      <div class="ant-table-content">
                        <table style="table-layout: auto;">
                          <colgroup>
                            <col style="width: 60px; min-width: 60px;">
                            <col>
                            <col>
                            <col>
                            <col>
                            <col>
                            <col>
                            <col>
                            <col>
                            <col>
                            <col>
                            <col>
                            <col>
                            <col>
                            <col style="width: 200px; min-width: 200px;">
                          </colgroup>
                          <thead class="ant-table-thead">
                            <tr>
                              <th colspan="14" class="ant-table-cell report-title main-title">Mail Logs</th>
                            </tr>
                            <tr>
                              <th class="ant-table-cell report-text">Email</th>
                              <th class="ant-table-cell report-text">Date</th>
                              <th class="ant-table-cell report-text">Subject</th>
                              <th class="ant-table-cell report-text">UUID</th>
                            </tr>
                          </thead>
                          <tbody class="ant-table-tbody">`
    staticHtml = `${staticHtml}`
    mailLogData?.length &&
      mailLogData.forEach((data) => {
        const { email, date, subject, uuid } = data

        staticHtml = `${staticHtml}
                            <tr data-row-key="KYP" class="ant-table-row ant-table-row-level-0">
                              <td class="ant-table-cell report-text">${
                                email || 'N/A'
                              } </td>
                              <td class="ant-table-cell report-text">${
                                moment(date).format('DD-MMM-YYYY') || 'N/A'
                              }</td>
                              <td class="ant-table-cell report-text">${
                                subject || 'N/A'
                              }</td>
                              <td class="ant-table-cell report-text">
                              ${
                                uuid
                                  ? `https://app-smtp.brevo.com/log#${uuid}`
                                  : 'N/A'
                              }
                            </td>
                            </tr>`
      })
    staticHtml = `${staticHtml}
            </tbody >
                        </table >
                      </div >
                    </div >
                  </div >
                </div >
              </div >
            </div >
              `
    client
      .query({
        query: GENERATE_PDF,
        variables: { data: { html: staticHtml, type: 'landscape' } },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        setPdfGenerateLoading(false)
        get(res, 'data.generatePDF') &&
          window.open(get(res, 'data.generatePDF'), '_blank')
      })
      .catch((err) => setPdfGenerateLoading(false))
  }
  const [startDate, setStartDate] = useState(
    moment().subtract(29, 'days').format('YYYY-MM-DD')
  )
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'))

  const handleCalendarChange = (dates) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }

  const disabledDate = (current) => {
    const today = moment().startOf('day')

    if (current.isAfter(today)) {
      return true
    }

    if (
      (startDate &&
        (current.diff(startDate, 'days') > 29 ||
          current.diff(endDate, 'days') > 29)) ||
      (endDate &&
        (current.diff(endDate, 'days') > 29 ||
          current.diff(startDate, 'days') > 29))
    ) {
      return true
    }

    return false
  }

  useEffect(() => {
    setDateDifference(
      moment(startDate).add(29, 'days').format('YYYY-MM-DD') >=
        moment(endDate).format('YYYY-MM-DD')
    )
  }, [startDate, endDate])

  const renderExtraFooter = () => {
    return (
      <div>
        {!dateDifference ? (
          <p style={{ color: 'red' }}>
            Maximum difference between startDate and endDate shall not be
            greater than 30 days.
          </p>
        ) : (
          ''
        )}
      </div>
    )
  }

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Modal
        width={800}
        title='Mail Content'
        visible={mailContentPopUP}
        onOk={handleOk}
        onCancel={handleCancel}
        loading={mailContentLoading}
      >
        <div
          className='mailModal'
          dangerouslySetInnerHTML={createMarkup(mailContent)}
        />
      </Modal>
      {/* <PageHeader
        className='box'
        title='Mail Log'
        extra={[
          <Button
            key='1'
            type='primary'
            onClick={handlePdfDownload}
            disabled={mailLogData?.length === 0 ? true : false}
            loading={pdfGenerateLoading}
          >
            Export
          </Button>,
        ]}
      /> */}

      <Drawer
        title={null}
        headerStyle={{ display: 'none' }}
        closable={false}
        placement='right'
        onClose={onClose}
        visible={open}
        width={472}
      >
        <div
          style={{
            margin: '32px 0 50px 0',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <div onClick={onClose}>
            <img src={FilterBackButton} alt='FilterBackButton' />
          </div>
          <div
            style={{
              fontSize: '24px',
              fontWeight: '700',
              lineHeight: '28px',
              textAlign: 'left',
              color: '#000000',
              marginLeft: '16px',
            }}
          >
            Filter
          </div>
        </div>
        <Form
          {...formItemLayout}
          form={formData}
          name='advanced_search'
          style={{ marginTop: '16px' }}
          layout='vertical'
          className='client-detail-form'
          onFinish={changeFormData}
        >
          <>
            <Form.Item
              label='Select Client'
              name='email'
              rules={[{ required: true, message: 'Please Select Client!' }]}
            >
              <Select
                getPopupContainer={(trigger) => trigger.parentElement}
                placeholder='Select Client'
                showSearch
                allowClear
                optionFilterProp='children'
                loading={userLoadingClient}
                filterOption={true}
                onChange={(e) => !e && formDataReset()}
              >
                {!userLoadingClient &&
                  usersData?.length &&
                  orderBy(usersData, 'id', 'desc').map((user, key) => {
                    return (
                      <>
                        <Option key={key} value={user.email}>
                          {user.clientCode}-{' '}
                          {`${user.firstName || ''} ${user.lastName || ''}`}
                        </Option>
                      </>
                    )
                  })}
              </Select>
            </Form.Item>
            <Form.Item label='Date' name='date'>
              <div>
                <RangePicker
                  renderExtraFooter={renderExtraFooter}
                  format={dateFormat}
                  defaultValue={date}
                  onCalendarChange={(e) => {
                    if (!e) {
                      setStartDate(undefined)
                      setEndDate(undefined)
                    } else {
                      handleCalendarChange(e)
                    }
                  }}
                  disabledDate={disabledDate}
                />
                {!dateDifference && (
                  <div className='error'>
                    <span>
                      Maximum difference between startDate and endDate shall not
                      be greater than 30 days.
                    </span>
                  </div>
                )}
              </div>
            </Form.Item>
            <div style={{ display: 'flex', gap: '20px', height: '56px' }}>
              <Button
                htmlType='button'
                onClick={formDataReset}
                style={{
                  width: '100%',
                  borderRadius: '8px',
                  padding: '16px',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                Reset
              </Button>
              <Button
                type='primary'
                htmlType='submit'
                disabled={!dateDifference}
                style={{
                  width: '100%',
                  borderRadius: '8px',
                  padding: '16px',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                Search
              </Button>
            </div>
          </>
        </Form>
      </Drawer>

      <div className='main_dashboard_div'>
        <div className='mail_log_main_div'>
          <div className='mail_log_text_div'>Mail Log</div>

          <div className='mail_log_option_div'>
            <Button
              onClick={handlePdfDownload}
              disabled={mailLogData?.length === 0 ? true : false}
              loading={pdfGenerateLoading}
              className='submit_btn'
            >
              Export Pdf
              <DownloadOutlined
                color='#AF0000'
                className="className='icon_style'"
              />
            </Button>

            <Button className='filter_btn' onClick={showDrawer}>
              Filter{' '}
              <FilterOutlined
                color='#000000'
                className="className='icon_style'"
              />
            </Button>
          </div>
        </div>
        {showTable && (
          <Table
            columns={columns}
            dataSource={mailLogData || []}
            loading={mailLoadingClient}
            onChange={handleTableChange}
            pagination={{
              pageSize: limit,
              total: totalCount,
              onChange: handlePagination,
              current: currentPage,
              onShowSizeChange: handlePageSizeChange,
              hideOnSinglePage: true,
            }}
          />
        )}
      </div>
    </>
  )
}

export default MailLog
