import React from 'react'
import { Row, Table, Col } from 'antd'
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  Area,
} from 'recharts'
import _isEmpty from 'lodash/isEmpty'
import './ILTSShift.css'
// import Chart from 'modules/Dashboard/Components/NLVIndividualReport/Chart'

export default function IltsShiftTable({
  reportData,
  columns,
  loading,
  newLevelData,
}) {
  const data = reportData.map((report, key) => {
    return { key, ...report }
  })
  let pnChartData = []
  let pnOverCharData = []
  let nlvCharData = []
  let overAllPlChartData = []
  let nlvWithIntrestChartData = []

  if (reportData.length !== 0) {
    Object.keys(reportData[1]).forEach((key) => {
      if (key !== 'particulars') {
        let newObj = {}
        newObj['Current Position'] = reportData[1][key]
        newObj[`Shifted to ${newLevelData || ''}`] = reportData[2][key]
        newObj['name'] = reportData[1][key]
        newObj['spotPrice'] = reportData[0][key]
        pnChartData.push(newObj)
      }
    })
    // Object.keys(reportData[2]).forEach((key) => {
    //   if (key !== 'particulars') {
    //     let newObj = {}
    //     newObj['name'] = reportData[0][key]
    //     pnChartData.push(newObj)
    //   }
    // })

    // for (const iterator of pnChartData) {
    //   Object.keys(reportData[1]).forEach((key) => {
    //     if (key !== 'particulars' && iterator.name === key) {
    //       iterator['pl2'] = reportData[2][key]
    //     }
    //   })
    // }
    Object.keys(reportData[3]).forEach((key) => {
      if (key !== 'particulars') {
        let newObj = {}
        newObj['Current Position'] = reportData[3][key]
        newObj[`Shifted to ${newLevelData || ''}`] = reportData[4][key]
        newObj['name'] = reportData[3][key]
        newObj['spotPrice'] = reportData[0][key]
        pnOverCharData.push(newObj)
      }
    })

    // for (let iterator of pnOverCharData) {
    //   Object.keys(reportData[4]).forEach((key) => {
    //     if (key !== 'particulars' && iterator.name === key) {
    //       iterator['pl2'] = reportData[4][key]
    //     }
    //   })
    // }
    Object.keys(reportData[5]).forEach((key) => {
      if (key !== 'particulars') {
        let newObj = {}
        newObj['Current Position'] = reportData[5][key]
        newObj[`Shifted to ${newLevelData || ''}`] = reportData[6][key]
        newObj['name'] = reportData[5][key]
        newObj['spotPrice'] = reportData[0][key]
        nlvCharData.push(newObj)
      }
    })
    // Object.keys(reportData[6]).forEach((key) => {
    //   if (key !== 'particulars') {
    //     let newObj = {}
    //     newObj['name'] = reportData[0][key]
    //     nlvCharData.push(newObj)
    //   }
    // })

    // for (const iterator of nlvCharData) {
    //   Object.keys(reportData[6]).forEach((key) => {
    //     if (key !== 'particulars' && iterator.name === key) {
    //       iterator['pl2'] = reportData[6][key]
    //     }
    //   })
    // }
    Object.keys(reportData[7]).forEach((key) => {
      if (key !== 'particulars') {
        let newObj = {}
        newObj['Current Position'] = reportData[7][key]
        newObj[`Shifted to ${newLevelData || ''}`] = reportData[8][key]
        newObj['name'] = reportData[7][key]
        newObj['spotPrice'] = reportData[0][key]
        overAllPlChartData.push(newObj)
      }
    })
    // Object.keys(reportData[8]).forEach((key) => {
    //   if (key !== 'particulars') {
    //     let newObj = {}
    //     newObj['name'] = reportData[0][key]
    //     overAllPlChartData.push(newObj)
    //   }
    // })

    Object.keys(reportData[9]).forEach((key) => {
      if (key !== 'particulars') {
        let newObj = {}
        newObj['Current Position'] = reportData[9][key]
        newObj[`Shifted to ${newLevelData || ''}`] = reportData[10][key]
        newObj['name'] = reportData[9][key]
        newObj['spotPrice'] = reportData[0][key]
        nlvWithIntrestChartData.push(newObj)
      }
    })
    // Object.keys(reportData[10]).forEach((key) => {
    //   if (key !== 'particulars') {
    //     let newObj = {}
    //     newObj['name'] = reportData[0][key]
    //     nlvWithIntrestChartData.push(newObj)
    //   }
    // })
    for (const iterator of nlvWithIntrestChartData) {
      Object.keys(reportData[10]).forEach((key) => {
        if (key !== 'particulars' && iterator.name === key) {
          iterator['pl2'] = reportData[10][key]
        }
      })
    }
  }

  const getRange = (data) => {
    const openPosition = data?.map((item) => item['Current Position'])
    const shifted = data?.map((item) => item['Shifted to 19000'])
    const mainArray = [...openPosition, ...shifted]

    return [Math.min(...mainArray) - 10000, Math.max(...mainArray) + 10000]
  }
  return (
    <>
      <div className='analysis_graph'>
        <Table
          loading={loading}
          dataSource={data}
          columns={columns}
          className='nlv-tracker-report'
          pagination={{ pageSize: 100, hideOnSinglePage: true }}
          // scroll={{ x: true, y: 'calc(100vh - 72px - 24px - 24px - 36px - 35px)' }}
        />
      </div>
      <Row className='analysis_graph_row' gutter={16}>
        {!_isEmpty(pnChartData) && (
          <Col
            sm={24}
            md={12}
            lg={8}
            className='analysis_graph_col'
            style={{
              marginBottom: '10px',
            }}
          >
            <div className='scenario_analysis_graph'>
              <div className='scenario_analysis_graph_Heading'>
                Change in P&L
              </div>
              <ResponsiveContainer width='100%' height={270}>
                <AreaChart
                  width={350}
                  height={270}
                  data={pnChartData}
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >
                  <defs>
                    <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
                      <stop offset='5%' stopColor='#3E97FF' stopOpacity={0.8} />
                      <stop offset='95%' stopColor='#3E97FF' stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id='colorPv' x1='0' y1='0' x2='0' y2='1'>
                      <stop offset='5%' stopColor='#50CD89' stopOpacity={0.8} />
                      <stop offset='95%' stopColor='#50CD89' stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <XAxis
                    dataKey='spotPrice'
                    label={{
                      value: `Spot Price`,
                      // style: { textAnchor: 'middle' },
                      // angle: -20,
                      position: 'bottom',
                      offset: -10,
                    }}
                  />
                  <YAxis dataKey='name' domain={getRange(pnChartData)} />
                  <CartesianGrid strokeDasharray='3 3' />
                  <Tooltip />
                  <Area
                    type='monotone'
                    dataKey='Current Position'
                    stroke='#50CD89'
                    fillOpacity={1}
                    fill='url(#colorUv)'
                  />
                  <Area
                    type='monotone'
                    dataKey={`Shifted to ${newLevelData || ''}`}
                    stroke='#3E97FF'
                    fillOpacity={1}
                    fill='url(#colorPv)'
                  />
                  <Legend />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </Col>
        )}

        {!_isEmpty(pnOverCharData) && (
          <Col
            sm={24}
            md={12}
            lg={8}
            className='analysis_graph_col'
            style={{
              marginBottom: '10px',
            }}
          >
            <div className='scenario_analysis_graph'>
              <div className='scenario_analysis_graph_Heading'>Overall P&L</div>
              <ResponsiveContainer width='100%' height={270}>
                <AreaChart
                  width={350}
                  height={270}
                  data={pnOverCharData}
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >
                  <defs>
                    <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
                      <stop offset='5%' stopColor='#3E97FF' stopOpacity={0.8} />
                      <stop offset='95%' stopColor='#3E97FF' stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id='colorPv' x1='0' y1='0' x2='0' y2='1'>
                      <stop offset='5%' stopColor='#50CD89' stopOpacity={0.8} />
                      <stop offset='95%' stopColor='#50CD89' stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <XAxis
                    label={{
                      value: `Spot Price`,
                      // style: { textAnchor: 'middle' },
                      // angle: -20,
                      position: 'bottom',
                      offset: -10,
                    }}
                    dataKey='spotPrice'
                  />
                  <YAxis domain={getRange(pnOverCharData)} />
                  <CartesianGrid strokeDasharray='3 3' />
                  <Tooltip />
                  <Area
                    type='monotone'
                    dataKey='Current Position'
                    stroke='#50CD89'
                    fillOpacity={1}
                    fill='url(#colorUv)'
                  />
                  <Area
                    type='monotone'
                    dataKey={`Shifted to ${newLevelData || ''}`}
                    stroke='#3E97FF'
                    fillOpacity={1}
                    fill='url(#colorPv)'
                  />
                  <Legend />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </Col>
        )}

        {!_isEmpty(nlvCharData) && (
          <Col
            sm={24}
            md={12}
            lg={8}
            className='analysis_graph_col'
            style={{
              marginBottom: '10px',
            }}
          >
            <div className='scenario_analysis_graph'>
              <div className='scenario_analysis_graph_Heading'>
                NLV at Current Position
              </div>
              <ResponsiveContainer width='100%' height={270}>
                <AreaChart
                  width={350}
                  height={270}
                  data={nlvCharData}
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >
                  <defs>
                    <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
                      <stop offset='5%' stopColor='#3E97FF' stopOpacity={0.8} />
                      <stop offset='95%' stopColor='#3E97FF' stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id='colorPv' x1='0' y1='0' x2='0' y2='1'>
                      <stop offset='5%' stopColor='#50CD89' stopOpacity={0.8} />
                      <stop offset='95%' stopColor='#50CD89' stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <XAxis
                    label={{
                      value: `Spot Price`,
                      // style: { textAnchor: 'middle' },
                      // angle: -20,
                      position: 'bottom',
                      offset: -10,
                    }}
                    dataKey='spotPrice'
                  />
                  <YAxis domain={getRange(nlvCharData)} />
                  <CartesianGrid strokeDasharray='3 3' />
                  <Tooltip />
                  <Area
                    type='monotone'
                    dataKey='Current Position'
                    stroke='#50CD89'
                    fillOpacity={1}
                    fill='url(#colorUv)'
                  />
                  <Area
                    type='monotone'
                    dataKey={`Shifted to ${newLevelData || ''}`}
                    stroke='#3E97FF'
                    fillOpacity={1}
                    fill='url(#colorPv)'
                  />
                  <Legend />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </Col>
        )}

        {!_isEmpty(overAllPlChartData) && (
          <Col
            sm={24}
            md={12}
            lg={8}
            className='analysis_graph_col'
            style={{
              marginBottom: '10px',
            }}
          >
            <div className='scenario_analysis_graph'>
              <div className='scenario_analysis_graph_Heading'>
                Overall P&L With Interest
              </div>
              <ResponsiveContainer width='100%' height={270}>
                <AreaChart
                  width={350}
                  height={270}
                  data={overAllPlChartData}
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >
                  <defs>
                    <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
                      <stop offset='5%' stopColor='#3E97FF' stopOpacity={0.8} />
                      <stop offset='95%' stopColor='#3E97FF' stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id='colorPv' x1='0' y1='0' x2='0' y2='1'>
                      <stop offset='5%' stopColor='#50CD89' stopOpacity={0.8} />
                      <stop offset='95%' stopColor='#50CD89' stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <XAxis
                    label={{
                      value: `Spot Price`,
                      // style: { textAnchor: 'middle' },
                      // angle: -20,
                      position: 'bottom',
                      offset: -10,
                    }}
                    dataKey='spotPrice'
                  />
                  <YAxis domain={getRange(overAllPlChartData)} />
                  <CartesianGrid strokeDasharray='3 3' />
                  <Tooltip />
                  <Area
                    type='monotone'
                    dataKey='Current Position'
                    stroke='#50CD89'
                    fillOpacity={1}
                    fill='url(#colorUv)'
                  />
                  <Area
                    type='monotone'
                    dataKey={`Shifted to ${newLevelData || ''}`}
                    stroke='#3E97FF'
                    fillOpacity={1}
                    fill='url(#colorPv)'
                  />
                  <Legend />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </Col>
        )}

        {!_isEmpty(nlvWithIntrestChartData) && (
          <Col
            sm={24}
            md={12}
            lg={8}
            className='analysis_graph_col'
            style={{
              marginBottom: '10px',
            }}
          >
            <div className='scenario_analysis_graph'>
              <div className='scenario_analysis_graph_Heading'>
                NLV With Interest
              </div>
              <ResponsiveContainer width='100%' height={270}>
                <AreaChart
                  width={350}
                  height={270}
                  data={nlvWithIntrestChartData}
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >
                  <defs>
                    <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
                      <stop offset='5%' stopColor='#3E97FF' stopOpacity={0.8} />
                      <stop offset='95%' stopColor='#3E97FF' stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id='colorPv' x1='0' y1='0' x2='0' y2='1'>
                      <stop offset='5%' stopColor='#50CD89' stopOpacity={0.8} />
                      <stop offset='95%' stopColor='#50CD89' stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <XAxis
                    label={{
                      value: `Spot Price`,
                      // style: { textAnchor: 'middle' },
                      // angle: -20,
                      position: 'bottom',
                      offset: -10,
                    }}
                    dataKey='spotPrice'
                  />
                  <YAxis domain={getRange(nlvWithIntrestChartData)} />
                  <CartesianGrid strokeDasharray='3 3' />
                  <Tooltip />
                  <Area
                    type='monotone'
                    dataKey='Current Position'
                    stroke='#50CD89'
                    fillOpacity={1}
                    fill='url(#colorUv)'
                  />
                  <Area
                    type='monotone'
                    dataKey={`Shifted to ${newLevelData || ''}`}
                    stroke='#3E97FF'
                    fillOpacity={1}
                    fill='url(#colorPv)'
                  />
                  <Legend />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </Col>
        )}
      </Row>
    </>
  )
}
