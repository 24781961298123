import React, { useState, useContext, useEffect } from 'react'
import {
  Table,
  Tag,
  Tooltip,
  Button,
  Switch,
  Input,
  Space,
  Modal,
  Upload,
  Form,
} from 'antd'
import {
  EditTwoTone,
  SearchOutlined,
  DownloadOutlined,
} from '@ant-design/icons'
import { UploadOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/react-hooks'
import { useJsonToCsv } from 'react-json-csv'
import { NavLink } from 'react-router-dom'
import get from 'lodash/get'

import client from 'apollo'
import { AppContext } from 'AppContext'
import Page404 from 'components/Page404'
import { GET_USERS } from './graphql/Queries'
import CustomMeta from 'components/CustomMeta'
import openNotification from 'utils/Notification'
import { UPDATE_USER } from './graphql/Mutations'
import { IMPORT_USERS } from '../Users/graphql/Mutations'
import { getFirstName, getLastName, userRoles } from 'utils/User'

const { saveAsCsv } = useJsonToCsv()

export default function ({ history }) {
  let tableData = []
  const [sort, setSort] = useState()
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const { state } = useContext(AppContext)
  const { currentReport, isAdmin } = state
  const [filters, setFilters] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const userFilters = userRoles[state.currentUser.role]
  const [switchLoading, setSwitchLoading] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [csv, setCsv] = useState(undefined)
  const [isCsvDownload, setIsCsvDownload] = useState(false)

  const { isMobile } = state

  const { data, loading, error } = useQuery(GET_USERS, {
    variables: { where: filters, limit, skip, sort },
    fetchPolicy: 'network-only',
  })

  if (error) return <Page404 error={error} />
  let totalCount = 0
  if (!loading && get(data, 'getUsers.users')) {
    totalCount = data.getUsers.count
    tableData = data.getUsers.users.map((user, key) => ({
      key: key.toString(),
      ...user,
    }))
  }
  useEffect(() => {
    if (skip === 0 && limit === 500 && !loading && isCsvDownload) {
      const newData = data.getUsers.users
      const fields = {
        cityId: 'cityId',
        id: 'id',
        firstName: 'firstName',
        lastName: 'lastName',
        email: 'email',
        phone: 'phone',
        dateOfBirth: 'dateOfBirth',
        userName: 'userName',
        isActive: 'isActive',
        role: 'role',
        level: 'level',
        ratio: 'ratio',
        sipAmount: 'sipAmount',
        exposure: 'exposure',
        planId: 'planId',
        disableDate: 'disableDate',
        isDisableDate: 'isDisableDate',
        remark: 'remark',
        collateralValue: 'collateralValue',
        address: 'address',
      }
      saveAsCsv({ data: newData, fields, filename: 'Users' })
      setIsCsvDownload(false)
    }
  }, [skip, limit, loading, data, isCsvDownload])

  function handleCsvDownload() {
    setIsCsvDownload(true)
    setLimit(500)
    setSkip(0)
  }

  function handleSwitch(record) {
    setSwitchLoading(record.id)
    const queryVariables = {
      id: record.id,
      data: { isActive: !record.isActive },
    }
    client
      .mutate({
        mutation: UPDATE_USER,
        variables: queryVariables,
        refetchQueries: [
          {
            query: GET_USERS,
            variables: { where: filters, limit, skip, sort },
          },
        ],
      })
      .then((res) => openNotification('success', 'User Updated Successfully'))
      .catch((err) => console.log(err))
      .finally(() => setSwitchLoading(''))
  }

  function renderAction(record) {
    const { id } = record
    return (
      <div className='action-icons'>
        <Tooltip title='Edit'>
          {currentReport.includes('Update User') && (
            <EditTwoTone onClick={() => history.push(`/users/${id}/edit`)} />
          )}
        </Tooltip>
        {currentReport.includes('Disable User') && (
          <Switch
            size='small'
            checked={record.isActive}
            loading={switchLoading === id}
            disabled={state.currentUser.id === record.id}
            onClick={() => handleSwitch(record)}
            defaultChecked={record.isActive}
          />
        )}
      </div>
    )
  }

  function handlePagination(page) {
    setSkip((page - 1) * limit)
    setCurrentPage(page)
  }

  function handlePageSizeChange(current, size) {
    setLimit(size)
  }

  function handleSearch(value, confirm, filedName) {
    value = typeof value === 'object' ? value[0] : value
    confirm()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = value
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  function handleReset(clearFilters, filedName) {
    clearFilters()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = ''
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          autoFocus={true}
          value={selectedKeys[0]}
          placeholder={`Search ${dataIndex}`}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys[0], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, dataIndex)}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    sorter: (a, b) => a.age - b.age,
  })

  function handleTableChange(pagination, filter, sorter) {
    if (sorter && sorter.field && sorter.order) {
      if (sorter.order === 'descend') {
        setSort({
          field: sorter.field,
          sortingOrder: 'DESC',
        })
        setCurrentPage(1)
      } else {
        setSort({
          field: sorter.field,
          sortingOrder: 'ASC',
        })
        setCurrentPage(1)
      }
    }
    if (filter.role) {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['role'] = filter.role
      setFilters(tempFilters)
      setCurrentPage(1)
    } else {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['role'] = ''
      delete tempFilters.role
      setFilters(tempFilters)
      setCurrentPage(1)
    }
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const [form] = Form.useForm()

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        client
          .mutate({
            mutation: IMPORT_USERS,
            variables: { data: { csv: values.csv[0].originFileObj } },
          })
          .then(async (res) => {
            // if (get(res, 'data.importUsers.errorUsers') && get(res, 'data.importUsers.importUsersCount') && get(res, 'data.importUsers.errorUsersCount')) {
            openNotification(
              'success',
              `${res.data.importUsers.importUsersCount} Users import successfully`
            )
            if (res.data.importUsers.errorUsersCount > 0)
              openNotification(
                'error',
                `${res.data.importUsers.errorUsersCount} Users not imported`
              )
            const fields = {
              address: 'address',
              cityId: 'cityId',
              clientCode: 'clientCode',
              countryId: 'countryId',
              createdById: 'createdById',
              dateOfBirth: 'dateOfBirth',
              email: 'email',
              firstName: 'firstName',
              isActive: 'isActive',
              lastName: 'lastName',
              password: 'password',
              phone: 'phone',
              role: 'role',
              stateId: 'stateId',
              strategyCode: 'strategyCode',
              userName: 'userName',
            }

            // converting json to csv
            saveAsCsv({
              data: res.data.importUsers.errorUsers,
              fields,
              filename: 'Error Users',
            })
            // }
          })
          .catch((err) => console.log(err))
      })
      .catch((info) => console.log('Validate Failed:', info))

    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setCsv('')
  }

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e
    }
    if (e && e.fileList && e.fileList.length > 1 && e.file) {
      setCsv([e.file])
      return e && e.fileList
    }
    setCsv(e.fileList)
    return e && e.fileList
  }

  const customCsv = {
    multiple: false,
    onRemove: (file) => setCsv([]),
    beforeUpload: (file) => {
      setCsv(file)
      return false
    },
    csv,
  }

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      render: (text, record) => (
        <NavLink to={`/users/${record.id}`}>{getFirstName(record)}</NavLink>
      ),
      ...getColumnSearchProps('firstName'),
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      render: (text, record) => (
        <NavLink to={`/users/${record.id}`}>{getLastName(record)}</NavLink>
      ),
      ...getColumnSearchProps('lastName'),
    },
    {
      title: 'Username',
      dataIndex: 'userName',
      key: 'userName',
      ...getColumnSearchProps('userName'),
      width: 140,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text) => <a href={`mailto:${text}`}>{text}</a>,
      ...getColumnSearchProps('email'),
      width: 200,
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      render: (text) => <a href={`tel:${text}`}>{text}</a>,
      ...getColumnSearchProps('phone'),
      width: 180,
    },
    {
      title: 'Role',
      key: 'role',
      dataIndex: 'role',
      render: (role) => <Tag className={role}>{role}</Tag>,
      filters: userFilters,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => renderAction(record),
      width: 120,
    },
  ]

  return (
    <>
      <div
        className='main_dashboard_div'
        style={{
          marginBottom: isMobile ? '5vh' : '1vh',
        }}
      >
        <CustomMeta title='Users' />
        {/* <PageHeader
          className='box'
          title='Users'
          extra={[
            currentReport.includes('Create User') && (
              <Button key='1' type='primary' onClick={handleCsvDownload}>
                Export User
              </Button>
            ),
            currentReport.includes('Create User') && (
              <Button key='2' type='primary' onClick={showModal}>
                Import User
              </Button>
            ),
            currentReport.includes('Create User') && (
              <Button
                key='3'
                type='primary'
                onClick={() => history.push('/users/create')}
              >
                Add User
              </Button>
            ),
          ]}
        /> */}

        <div
          style={{
            display: isMobile ? 'grid' : 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
          }}
        >
          <div className='agreement_text'>Users</div>
          <div
            className='option_btn_div'
            style={isMobile ? { marginTop: '10px' } : {}}
          >
            {currentReport.includes('Create User') ? (
              <Button onClick={handleCsvDownload} className='export_btn'>
                Export Users
                <DownloadOutlined
                  color='#AF0000'
                  style={{ fontSize: '16px' }}
                />
              </Button>
            ) : (
              ''
            )}

            {currentReport.includes('Create User') ? (
              <Button onClick={showModal} className='export_btn'>
                Import User
              </Button>
            ) : (
              ''
            )}
            {currentReport.includes('Create User') ? (
              <Button
                onClick={() => history.push('/users/create')}
                className='export_btn'
              >
                Add User
              </Button>
            ) : (
              ''
            )}
          </div>
        </div>
        {isModalVisible && (
          <Modal
            className='feedback_form'
            visible
            title='Import User'
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
              <Button
                key='link'
                href='https://finideas-india.blr1.digitaloceanspaces.com/importClients/importClients/importClients.csv'
                type='secondary'
                loading={loading}
                style={{ marginRight: '35%' }}
              >
                Download demo CSV
              </Button>,
              <Button key='back' onClick={handleCancel}>
                Cancel
              </Button>,
              <Button
                key='submit'
                type='primary'
                loading={loading}
                onClick={handleOk}
              >
                Ok
              </Button>,
            ]}
          >
            <Form form={form} layout='vertical'>
              <Form.Item
                name='csv'
                label='Please select user CSV file'
                valuePropName='csv'
                getValueFromEvent={normFile}
              >
                <Upload
                  name='csv-file'
                  listType='text'
                  {...customCsv}
                  accept='.csv'
                  multiple={false}
                  fileList={csv}
                >
                  <Button>
                    <UploadOutlined /> Click to upload
                  </Button>
                  <u>
                    <p>Instructions</p>
                  </u>
                  <ul>
                    <li>Use Demo CSV to import User.</li>
                    <li>
                      Please make sure User Name and email must be unique.
                    </li>
                    <li>
                      All the sensitive user details are valid like
                      createdById,cityId, stateId, countryId etc.
                    </li>
                  </ul>
                </Upload>
              </Form.Item>
              <div className='footer-btn-div'>
                <Button
                  className='footer-cancel-btn'
                  key='link'
                  href='https://finideas-india.blr1.digitaloceanspaces.com/importClients/importClients/importClients.csv'
                  type='secondary'
                  loading={loading}
                >
                  Download demo CSV
                </Button>
                <Button className='footer-cancel-btn' onClick={handleCancel}>
                  Cancel
                </Button>
                <Button
                  className='footer-submit-btn'
                  key='submit'
                  type='primary'
                  loading={loading}
                  onClick={handleOk}
                >
                  Ok
                </Button>
              </div>
            </Form>
          </Modal>
        )}
        <Table
          loading={loading}
          rowKey='id'
          columns={columns}
          dataSource={tableData}
          onChange={handleTableChange}
          scroll={{ x: true, y: true }}
          pagination={
            isAdmin
              ? {
                  pageSize: limit,
                  total: totalCount,
                  onChange: handlePagination,
                  current: currentPage,
                  onShowSizeChange: handlePageSizeChange,
                }
              : ''
          }
        />
      </div>
    </>
  )
}
