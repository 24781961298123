import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import {
  Button,
  Form,
  Select,
  DatePicker,
  InputNumber,
  Input,
  Drawer,
  Popover,
} from 'antd'

import '../index.css'
import { getName } from 'utils/User'
import Page404 from 'components/Page404'
import IltsShiftTable from './IltsShiftTable'
// import DownloadReport from '../DownloadReport'
import { GET_ILTS_SHIFT, GET_NIFTY } from '../graphql/Queries'
import { GET_CLIENT_USERS_FOR_STAFF } from 'modules/Users/graphql/Queries'
import Loader from 'components/loaders/Loader'
import moment from 'moment'
import client from 'apollo'
import { AppContext } from 'AppContext'
import { orderBy } from 'lodash'
// import { NavLink } from 'react-router-dom/cjs/react-router-dom.min'
import { GET_ANALYSIS_EXPIRY_DATE } from 'modules/AnalysisExpiryDate/graphql/Queries'
import { DownloadOutlined, FilterOutlined } from '@ant-design/icons'
import { exportGraphAsPdf } from 'utils/Graph'
import PdfIcon from 'assets/images/new-images/pdficon.svg'
import ExcelIcon from 'assets/images/new-images/excelIcon.svg'
import FilterBackButton from 'assets/images/new-images/filter-back-button.svg'
import { CSVLink } from 'react-csv'

const { Option } = Select

export default function IltsShift() {
  const [form] = Form.useForm()
  let [usersData, setUsersData] = useState([])
  const [userLoadingClient, setUserLoading] = useState(true)
  const [open, setOpen] = useState(false)

  const {
    state: { currentUser, isClient, isMobile },
  } = useContext(AppContext)

  const [newLevelData, setNewLevelData] = useState(
    !isClient ? undefined : 19000
  )

  const { data: AnalysisExpiryDate, loading: AnalysisExpiryDateLoading } =
    useQuery(GET_ANALYSIS_EXPIRY_DATE, { fetchPolicy: 'network-only' })

  const [filters, setFilters] = useState({
    where: {
      clientCode: !isClient ? '' : `${currentUser?.clientCode}`,
      analysisLevel: 19000,
      analysisExp: '2024-12-26',
      final: new Date().toISOString(),
      step: 3000,
      intRate: 9,
      round: 1000,
    },
  })

  useEffect(() => {
    if (!AnalysisExpiryDateLoading && AnalysisExpiryDate) {
      setFilters({
        where: {
          ...filters.where,
          analysisExp: AnalysisExpiryDate?.getAnalysisExpiryDate[0]?.expiryDate,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AnalysisExpiryDateLoading, AnalysisExpiryDate])

  let year = moment().format('YYYY')
  let month = moment().format('MM')
  let date = moment().format('DD')

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    setUserLoading(true)
    client
      .query({
        query: GET_CLIENT_USERS_FOR_STAFF,
        variables: {
          where: {},
          limit: 10000,
        },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        res.data.getClientUsersForStaff.users.map((user) => {
          return usersData.push(user)
        })
        setUsersData(usersData)
        setUserLoading(false)
      })
      .catch((err) => console.log({ err }))
      .finally(() => setUserLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    data = {},
    loading,
    error,
  } = useQuery(GET_ILTS_SHIFT, {
    variables: filters,
    fetchPolicy: 'network-only',
  })

  let ieltsShift = data?.ieltsShift?.response
  const clientCode = data?.ieltsShift?.clientCode
  const clientName = data?.ieltsShift?.clientName

  const { data: niftyData } = useQuery(GET_NIFTY, {
    fetchPolicy: 'network-only',
  })
  // let selectedClient
  // if (
  //   !userLoading &&
  //   get(userData, 'getUsers.users') &&
  //   get(filters, 'where.clientCode')
  // ) {
  //   selectedClient = get(userData, 'getUsers.users').filter(
  //     (u) => u.clientCode === filters.where.clientCode
  //   )[0]
  // }

  if (userLoadingClient || loading || AnalysisExpiryDateLoading)
    return <Loader />

  const columns = [
    {
      title: 'Particulars',
      dataIndex: 'particulars',
      key: 'particulars',
      className: 'report-text',
    },
    {
      title: 'Level1',
      dataIndex: 'level1',
      key: 'level1',
      className: 'report-numeric',
    },
    {
      title: 'Level2',
      dataIndex: 'level2',
      key: 'level2',
      className: 'report-numeric',
    },
    {
      title: 'Level3',
      dataIndex: 'level3',
      key: 'level3',
      className: 'report-numeric',
    },
    {
      title: 'Level4',
      dataIndex: 'level4',
      key: 'level4',
      className: 'report-numeric',
    },
    {
      title: 'Level5',
      dataIndex: 'level5',
      key: 'level5',
      className: 'report-numeric',
    },
  ]

  const onFinish = (values) => {
    setNewLevelData(values?.analysisLevel)
    const data = {
      ...values,
      analysisExp: moment(values.analysisExp).format('YYYY-MM-DD'),
      final: new Date(moment(values.final).add(330, 'minutes')).toISOString(),
      intRate: values.intRate,
    }
    setFilters({ where: data })
    setOpen(false)
  }

  let csvInput = ieltsShift?.map((obj, index) => {
    const newObj = {}
    for (const key in obj) {
      const newKey = key.charAt(0).toUpperCase() + key.slice(1)
      newObj[newKey] = obj[key]
    }
    return newObj
  })

  const customData = [
    'Client Code:',
    `${clientCode ? clientCode : ''}`,
    '',
    '',
    'Client Name:',
    `${clientName ? clientName : ''}`,
  ]
  const keys = Object.keys(csvInput[0])
  const dataArray = csvInput.map((obj) => keys.map((key) => obj[key]))
  const output = [keys, ...dataArray]

  const csvContent = [
    customData.join(','),
    '',
    output.map((row) => row?.join(',')).join('\n'),
  ].join('\n')

  const handleDownloadPdf = () => {
    exportGraphAsPdf(
      'ieltsShift-report',
      'ieltsShift-REPORT-PDF',
      null,
      true
    ).finally(() => console.log('==== ieltsShift-report download ===='))
  }

  const content = (
    <div style={{ width: '100px' }}>
      <div
        onClick={handleDownloadPdf}
        style={{
          marginBottom: `${isMobile ? '0px' : '10px'}`,
          fontSize: '14px',
          fontWeight: '600',
          lineHeight: '24px',
          textAlign: 'left',
          color: ' #000',
          cursor: 'pointer',
        }}
      >
        <img src={PdfIcon} style={{ marginRight: '10px' }} alt='pdf' /> Pdf
      </div>

      <CSVLink
        style={{ paddingRight: 0 }}
        data={csvContent}
        className='csv-btn'
        filename={`ieltsShift-report`}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          style={{
            marginBottom: `${isMobile ? '0px' : '10px'}`,
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '24px',
            textAlign: 'left',
            color: ' #000',
            cursor: 'pointer',
          }}
        >
          <img src={ExcelIcon} style={{ marginRight: '10px' }} alt='excel' />{' '}
          Excel
        </div>
      </CSVLink>
    </div>
  )

  return (
    <>
      <Drawer
        // title='Filter'
        title={null}
        headerStyle={{ display: 'none' }}
        closable={false}
        placement='right'
        onClose={onClose}
        visible={open}
        width={472}
      >
        <div className='Filter_Form'>
          <div onClick={onClose}>
            <img src={FilterBackButton} alt='FilterBackButton' />
          </div>
          <div className='filter_panel_heading'>Filter</div>
        </div>
        <Form
          form={form}
          onFinish={onFinish}
          name='advanced_search'
          style={{ marginTop: '16px' }}
          layout='vertical'
          initialValues={{
            step: 3000,
            intRate: 9,
            round: 1000,
            analysisLevel:
              niftyData &&
              Math.round(niftyData?.getNifty50[0]?.CMP / 1000, 0) * 1000,
            clientCode: !isClient
              ? usersData[0]?.userName
              : `${currentUser?.clientCode}`,
            analysisExp: AnalysisExpiryDate?.getAnalysisExpiryDate
              ? moment(AnalysisExpiryDate?.getAnalysisExpiryDate[0]?.expiryDate)
              : moment(`${year}-12-26`),
            final: moment(`${year}-${month}-${date}`),
          }}
          className='client-detail-form'
        >
          <div
            style={{
              height: isMobile ? 'calc(100vh - 15rem)' : 'calc(100vh - 12rem)',
              overflowY: 'auto',
              scrollbarWidth: 'none',
            }}
          >
            <Form.Item
              name='clientCode'
              label='User'
              rules={[{ required: true, message: 'Please select users!' }]}
              hasFeedback
            >
              {!isClient ? (
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  allowClear
                  showSearch
                  filterOption={true}
                  optionFilterProp='title'
                  placeholder='Please select users'
                >
                  {!userLoadingClient &&
                    usersData.length &&
                    orderBy(usersData, 'id', 'desc').map((user, key) => {
                      return (
                        user.clientCode && (
                          <>
                            <Option
                              key={key}
                              value={user.clientCode}
                              title={`${user.clientCode} - ${getName(user)}`}
                            >
                              {user.clientCode} - {getName(user)}
                            </Option>
                          </>
                        )
                      )
                    })}
                </Select>
              ) : (
                <Input name='clientCode' readOnly />
              )}
            </Form.Item>
            <Form.Item
              name='analysisLevel'
              label='New Level'
              rules={[
                {
                  required: true,
                  message: 'Please Select Analysis Level!',
                },
              ]}
            >
              <InputNumber
                className='display-field-input-number'
                placeholder='Analysis Level'
                style={{ width: '100%', margin: '0' }}
              />
            </Form.Item>
            <Form.Item
              name='analysisExp'
              label='New Expiry'
              rules={[{ required: true, message: 'Please select date!' }]}
            >
              <DatePicker
                // format={'DD-MMM-YYYY'}
                getPopupContainer={(trigger) => trigger.parentElement}
                autoComplete='off'
                showToday={false}
                style={{ width: '100%', margin: '0' }}
              />
            </Form.Item>
            <Form.Item
              name='final'
              label='Reporting Date'
              rules={[{ required: true, message: 'Please select date!' }]}
            >
              <DatePicker
                // format={'DD-MMM-YYYY'}
                getPopupContainer={(trigger) => trigger.parentElement}
                autoComplete='off'
                showToday={false}
                style={{ width: '100%', margin: '0' }}
              />
            </Form.Item>
            <Form.Item
              name='step'
              label='Interval'
              rules={[{ required: true, message: 'Please Select step!' }]}
            >
              <InputNumber
                className='display-field-input-number'
                placeholder='Step'
                style={{ width: '100%', margin: '0' }}
              />
            </Form.Item>
            <Form.Item
              name='intRate'
              label='Interest'
              rules={[{ required: true, message: 'Please Select intRate!' }]}
            >
              <InputNumber
                className='display-field-input-number'
                formatter={(value) => `${value}%`}
                parser={(value) => value.replace('%', '')}
                style={{ width: '100%', margin: '0' }}
              />
            </Form.Item>
            <Form.Item
              name='round'
              label='Round'
              rules={[{ required: true, message: 'Please select users!' }]}
            >
              <Select
                getPopupContainer={(trigger) => trigger.parentElement}
                className='display-field'
                loading={userLoadingClient}
                optionFilterProp='title'
                placeholder='Please select users'
                style={{ width: '100%', margin: '0' }}
              >
                <Option value={1}>1</Option>
                <Option value={10}>10</Option>
                <Option value={100}>100</Option>
                <Option value={1000}>1000</Option>
                <Option value={10000}>10000</Option>
                <Option value={100000}>Lakh</Option>
                <Option value={1000000}>Million</Option>
                <Option value={10000000}>Crore</Option>
              </Select>
            </Form.Item>
          </div>
          <div className='filter_panel_button'>
            <Button
              onClick={() => {
                form.resetFields()
                setFilters({
                  where: {
                    clientCode: !isClient ? '' : `${currentUser?.clientCode}`,
                    analysisLevel: 19000,
                    analysisExp: AnalysisExpiryDate?.getAnalysisExpiryDate
                      ? AnalysisExpiryDate?.getAnalysisExpiryDate[0]?.expiryDate
                      : '2024-12-26',
                    final: new Date().toISOString(),
                    step: 3000,
                    intRate: 9,
                    round: 1000,
                  },
                })
                setOpen(false)
              }}
            >
              Clear
            </Button>
            <Button loading={loading} type='primary' htmlType='submit'>
              Search Scenario Analysis
            </Button>
          </div>
        </Form>
      </Drawer>
      <div className='report_Container'>
        <div className='container_Header'>
          <div className='report_Heading'> Scenario Analysis</div>
          <div className='container_Export_Popover'>
            <Popover content={content} placement='bottom' trigger='click'>
              <Button>
                Export{' '}
                <DownloadOutlined
                  color='#AF0000'
                  style={{ fontSize: '16px' }}
                />
              </Button>
            </Popover>

            <Button
              style={{
                color: '#000000',
                border: '1px solid #919EAB52',
                backgroundColor: 'white',
              }}
              onClick={showDrawer}
            >
              Filter{' '}
              <FilterOutlined color='#000000' style={{ fontSize: '16px' }} />
            </Button>
          </div>
        </div>

        <div className='report_first_Container'>
          <div className='report_Inner_Container'>
            <div className='Client_Info_Container'>
              {currentUser?.role === 'CLIENT' && (
                <div className='client_Info'>
                  Client Code:&nbsp;
                  <span>{currentUser.clientCode}</span>
                </div>
              )}
              <div className='client_Info'>
                Client Name:&nbsp;
                <span>
                  {`${currentUser.firstName} ${currentUser.lastName}`}
                </span>
              </div>
            </div>
            <div className='sShift_Round_Client_Info'>
              Round:&nbsp;
              <span>{filters.where.round}</span>
            </div>
          </div>
        </div>
        {error ? (
          <Page404 error={error} isShow={false} />
        ) : error ? (
          <Page404 error={error} isShow={false} />
        ) : (
          <div className='report-table-wrapper' id='ieltsShift-REPORT-PDF'>
            <IltsShiftTable
              reportData={ieltsShift || []}
              columns={columns}
              loading={loading}
              newLevelData={newLevelData}
            />
          </div>
        )}
      </div>
    </>
  )
}
