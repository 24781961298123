import React, { useState, useContext, useEffect } from 'react'
import {
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  Area,
} from 'recharts'
import { Select, DatePicker } from 'antd'
import get from 'lodash/get'
import moment from 'moment'

import history from 'CustomHistory'
import { AppContext } from 'AppContext'
import { exportGraphAsImage } from 'utils/Graph'
import ChartError from 'components/Page404/ChartError'
import ChartLoader from 'components/loaders/ChartLoader'
import { GET_NLV_TRACKER_REPORT } from '../../Reports/graphql/Queries'
import client from 'apollo'
import { GET_CLIENT_USERS } from 'modules/Users/graphql/Queries'
import { isEmpty } from 'lodash'
import './index.css'

const Option = Select.Option

export default function ({ clientId, clientCode }) {
  const [selectedMonths, setSelectedMonths] = useState(60)
  const [selectedDate, setSelectedDate] = useState(undefined)
  const {
    state: {
      currentUser: { role },
      isClient,
    },
  } = useContext(AppContext)

  const [data, setData] = useState([])
  const [loadingClient, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [selectClientDropdown, setSelectClient] = useState([])
  const [selectClientByOption, setSelectClientByOption] = useState()
  const [selectedUser, setSelectedUser] = useState({})

  useEffect(() => {
    setLoading(true)
    client
      .query({
        query: GET_CLIENT_USERS,
        variables: { where: {}, skip: 0, limit: 1000000 },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        client
          .query({
            query: GET_NLV_TRACKER_REPORT,
            variables: {
              where: {
                clientCode:
                  role !== 'CLIENT'
                    ? selectClientByOption
                      ? selectClientByOption
                      : res?.data?.getClientUsers?.users[0]?.clientCode
                    : null,
              },
            },
            fetchPolicy: 'network-only',
          })
          .then((res) => {
            setData(res?.data)
            setLoading(false)
          })
          .catch((error) => {
            setError(true)
            setLoading(false)
          })
        setSelectClient(res?.data?.getClientUsers?.users)
        if (isEmpty(selectedUser)) {
          setSelectedUser(res?.data?.getClientUsers?.users[0])
        }
      })
      .catch((error) => setLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role, selectClientByOption])

  if (loadingClient)
    return <ChartLoader title='Comparison of Fund with Index' />
  if (error) return <ChartError title='Comparison of Fund with Index' />
  if (!get(data, 'spNLVTracker.length'))
    return <ChartError title='Comparison of Fund with Index' desc='No Data' />

  const handleChangeClient = (e) => {
    setSelectClientByOption(e)
    if (e !== undefined) {
      setSelectedUser(
        selectClientDropdown?.find((item) => item?.clientCode === e)
      )
    } else {
      setSelectedUser(selectClientDropdown[0])
    }
  }

  const {
    spNLVTracker: [spNLVTracker],
  } = data

  function handleChange(value) {
    setSelectedMonths(value === 'all' ? 60 : value)
    setSelectedDate(undefined)
  }

  const divId = 'export-graph-NLV-Chart'

  function handleExport(value) {
    exportGraphAsImage('NLV-Chart-Report', value, divId)
  }

  const renderData = selectedDate
    ? spNLVTracker
        .map((reportData) => ({
          name: reportData.ReportDate,
          Index: reportData['NIFTY%'],
          NLV: reportData['TotNLV%'],
        }))
        .filter((reportData) =>
          moment(reportData.name).isSameOrAfter(selectedDate)
        )
    : spNLVTracker
        .map((reportData) => ({
          name: reportData.ReportDate,
          Index: reportData['NIFTY%'],
          NLV: reportData['TotNLV%'],
        }))
        .filter((reportData) =>
          moment(reportData.name).isSameOrAfter(
            moment().subtract(selectedMonths, 'months'),
            'months'
          )
        )

  function disabledDate(current) {
    return current && current > moment().endOf('day')
  }

  const renderLegend = (props) => {
    const { payload } = props
    return (
      <ul
        style={{
          listStyleType: 'none',
          margin: 0,
          marginTop: '20px',
          padding: 0,
          display: 'flex',
          justifyContent: 'center',
          gap: '20px',
        }}
      >
        {payload.map((entry, index) => {
          return (
            <>
              <li
                key={`item-${index}`}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 5,
                }}
              >
                <svg width='10' height='10' style={{ marginRight: 5 }}>
                  <circle cx='5' cy='5' r='5' fill={entry.color} />
                </svg>
                <div
                  style={{
                    fontSize: '14px',
                    fontWeight: '600',
                    lineHeight: '16.45px',
                    letterSpacing: '-0.03em',
                    color: '#000000',
                  }}
                >
                  {entry.value}
                </div>
              </li>
            </>
          )
        })}
      </ul>
    )
  }

  return (
    <div className='nlv_chart_div'>
      <div className='nlv_chart_sub_div'>
        <div>
          <div className='nlv_chart_text'>Comparison Of Fund With Index</div>
          <div className='nlv_chart_link_text'>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (!isClient) {
                  history.push(
                    `/clients/${selectedUser?.id}/position/${selectedUser?.clientCode}`
                  )
                } else {
                  history.push('/reports/performance')
                }
              }}
            >
              View Performance
            </span>
          </div>
        </div>

        <div>
          <div className='nlv_chart_option'>
            {role !== 'CLIENT' && (
              <Select
                getPopupContainer={(trigger) => trigger.parentElement}
                placeholder='Select Client'
                showSearch
                allowClear
                className='display-field'
                style={{ margin: '0px 5px' }}
                // defaultValue={selectClientDropdown[0]?.clientCode}
                optionFilterProp='children'
                onChange={(e) => handleChangeClient(e)}
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {!loadingClient &&
                  !error &&
                  selectClientDropdown?.map((ele, index) => {
                    return (
                      <Option value={ele.clientCode} key={index}>
                        {ele.clientCode}
                      </Option>
                    )
                  })}
              </Select>
            )}
            &nbsp;
            <DatePicker
              getPopupContainer={(trigger) => trigger.parentElement}
              popupStyle={{ position: 'absolute' }}
              showToday={false}
              className='client-detail-date-picker nlv_chart_date_picker'
              format={'DD-MMM-YYYY'}
              disabledDate={disabledDate}
              value={selectedDate}
              onChange={(e) => {
                if (e) {
                  setSelectedDate(e)
                  setSelectedMonths('')
                } else {
                  setSelectedDate(undefined)
                  setSelectedMonths(3)
                }
              }}
            />
            &nbsp;
            <Select
              getPopupContainer={(trigger) => trigger.parentElement}
              className='display-field'
              defaultValue='3'
              onChange={handleChange}
              value={
                selectedMonths
                  ? selectedMonths === 60
                    ? 'View All Records'
                    : `Last ${selectedMonths} months`
                  : selectedMonths
              }
            >
              <Option value='' disabled>
                Select Months
              </Option>
              <Option value='3'>Last 3 months</Option>
              <Option value='6'>Last 6 months</Option>
              <Option value='12'>Last 12 months</Option>
              <Option value='all'>View All Records</Option>
            </Select>
            &nbsp;
            <Select
              getPopupContainer={(trigger) => trigger.parentElement}
              className='display-field'
              value='1'
              onChange={handleExport}
            >
              <Option value='1' disabled>
                Export As
              </Option>
              <Option value='png'>PNG</Option>
              <Option value='jpeg'>JPEG</Option>
            </Select>
          </div>
        </div>
      </div>

      <div className='nlv_chart_data_div'>
        <ResponsiveContainer width='100%' height={386} id={divId}>
          <AreaChart
            width={500}
            height={386}
            data={renderData}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <defs>
              <linearGradient id='colorPv' x1='0' y1='0' x2='0' y2='1'>
                <stop offset='5%' stopColor='#50CD89' stopOpacity={0.8} />
                <stop offset='95%' stopColor='#50CD89' stopOpacity={0} />
              </linearGradient>
              <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
                <stop offset='5%' stopColor='#3E97FF' stopOpacity={0.8} />
                <stop offset='95%' stopColor='#3E97FF' stopOpacity={0} />
              </linearGradient>
            </defs>
            <YAxis />
            <CartesianGrid strokeDasharray='3 3' />
            <Tooltip />
            <Area
              type='monotone'
              dataKey='Index'
              stroke='#50CD89'
              fillOpacity={1}
              fill='url(#colorPv)'
            />
            <Area
              type='monotone'
              dataKey='NLV'
              stroke='#3E97FF'
              fillOpacity={1}
              fill='url(#colorUv)'
            />
            {/* <Legend /> */}
            <Legend
              layout='horizontal'
              horizontalAlign='middle'
              align='center'
              content={renderLegend}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}
