import { Button, Form, DatePicker, InputNumber, Drawer, Popover } from 'antd'
import React, { useState, useContext, useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { get, isEmpty } from 'lodash'
import moment from 'moment'
import client from 'apollo'
import { DownloadOutlined, FilterOutlined } from '@ant-design/icons'
import PdfIcon from 'assets/images/new-images/pdficon.svg'
import ExcelIcon from 'assets/images/new-images/excelIcon.svg'
import '../index.css'
import PLTable from './PLTable'
import NoData from 'components/NoData'
import { AppContext } from 'AppContext'
import Page404 from 'components/Page404'
import Loader from 'components/loaders/Loader'
import openNotification from 'utils/Notification'
import { SEND_PNL_MAIL } from '../graphql/Mutations'
import {
  GET_NIFTY,
  GET_PL_CHANGE_REPORT,
  PL_CHANGE_HISTORY_SEARCH,
} from '../graphql/Queries'
import { CSVLink } from 'react-csv'
import { exportGraphAsPdf } from 'utils/Graph'
import EmailIcon from 'assets/images/new-images/email-icon.svg'
import FilterBackButton from 'assets/images/new-images/filter-back-button.svg'

// const { RangePicker } = DatePicker

export default function PLReport() {
  const {
    state: { isClient, currentUser, isMobile },
  } = useContext(AppContext)
  const [form] = Form.useForm()
  const [selectedRow, setSelectedRow] = useState([])
  const [dateState, setDateState] = useState(moment())
  // const [dateFilters, setDateFilters] = useState({})
  const [filters, setFilters] = useState(
    isClient ? { clientCode: currentUser.clientCode } : {}
  )
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})
  const [error, setError] = useState()
  const [open, setOpen] = useState(false)

  const { data: plChangeHistory, loading: plChangeLoading } = useQuery(
    PL_CHANGE_HISTORY_SEARCH,
    {
      fetchPolicy: 'network-only',
    }
  )

  const { data: niftyData, loading: niftyLoading } = useQuery(GET_NIFTY, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (
      !isEmpty(filters) &&
      !plChangeLoading &&
      !niftyLoading &&
      plChangeHistory &&
      niftyData
    ) {
      setLoading(true)
      client
        .query({
          query: GET_PL_CHANGE_REPORT,
          variables: { where: filters },
          fetchPolicy: 'network-only',
        })
        .then((res) => {
          setData(res?.data)
          setLoading(false)
        })
        .catch((err) => {
          setError(err)
          setLoading(false)
          console.log(err)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  const dateFormat = 'DD-MMM-YYYY'

  let year = moment().format('YYYY')

  const initialValues = {
    analysis: plChangeHistory?.plChangeHistorySearch.analysis
      ? moment(plChangeHistory?.plChangeHistorySearch.analysis)
      : moment().format(),
    final: plChangeHistory?.plChangeHistorySearch.final
      ? moment(plChangeHistory?.plChangeHistorySearch.final)
      : moment(`${year}-12-31`, 'YYYY-MM-DD'),
    l1: Math.round(niftyData?.getNifty50[0]?.CMP / 1000, 0) * 1000 - 3000,
    l2: Math.round(niftyData?.getNifty50[0]?.CMP / 1000, 0) * 1000,
    l3: Math.round(niftyData?.getNifty50[0]?.CMP / 1000, 0) * 1000 + 3000,
    interest: 0,
    change: 0,
    vol: 0,
  }

  useEffect(() => {
    if (!plChangeLoading && !niftyLoading && plChangeHistory && niftyData) {
      setFilters(initialValues)
    }

    setDateState(moment(plChangeHistory?.plChangeHistorySearch.analysis))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plChangeLoading, plChangeHistory, niftyData, niftyLoading])

  if (loading) return <Loader />
  if (!get(data, 'plChange')) return <NoData />

  const { plChange } = data

  const columns = [
    {
      title: 'Client Name',
      dataIndex: 'ClientName',
      key: 'ClientName',
      className: 'report-text',
    },
    {
      title: 'Client Code',
      dataIndex: 'Clientcode',
      key: 'Clientcode',
      className: 'report-text',
    },
    {
      title: 'FPL1',
      dataIndex: 'FPL1',
      key: 'FPL1',
      className: 'report-numeric',
    },
    {
      title: 'FPL2',
      dataIndex: 'FPL2',
      key: 'FPL2',
      className: 'report-numeric',
    },
    {
      title: 'FPL3',
      dataIndex: 'FPL3',
      key: 'FPL3',
      className: 'report-numeric',
    },
    {
      title: 'Interest',
      dataIndex: 'Interest',
      key: 'Interest',
      width: isMobile && 100,
      className: 'report-numeric',
    },
    {
      title: 'Lossperc',
      dataIndex: 'Lossperc',
      key: 'Lossperc',
      width: isMobile && 100,
      className: 'report-text',
      render: (text) => (text ? text.toFixed(2) : text),
    },
    {
      title: 'PnlWithInt',
      dataIndex: 'PnlWithInt',
      width: isMobile && 110,
      key: 'PnlWithInt',
      className: 'report-numeric',
    },
    {
      title: 'PnlWoInt',
      dataIndex: 'PnlWoInt',
      key: 'PnlWoInt',
      width: isMobile && 100,
      className: 'report-numeric',
    },
    {
      title: 'TotExp',
      dataIndex: 'TotExp',
      key: 'TotExp',
      className: 'report-text',
    },
  ]

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  const onFinish = (values) => {
    setFilters(values)
    setOpen(false)
  }

  let reportData = plChange.map((report, key) => {
    return { key, ...report }
  })

  const handleSendMail = () => {
    let data = reportData.filter((d) => selectedRow.includes(d.Clientcode))
    data = data.map((d) => {
      return {
        clientCode: d.Clientcode,
        firstName: d.ClientName,
        lastName: d.ClientName,
        email: d.email || '',
        dateOfAnalysis: filters?.analysis,
        currentExposure: d.TotExp,
        currentNLV: d.NLV,
        currentPl: d.PnlWithInt,
        niftyLevel1: filters?.l1,
        niftyLevel2: filters?.l2,
        niftyLevel3: filters?.l3,
        expectedChange1: d.FPL1,
        expectedChange2: d.FPL2,
        expectedChange3: d.FPL3,
        expectedNlv1: d.NLV + d.FPL1,
        expectedNlv2: d.NLV + d.FPL2,
        expectedNlv3: d.NLV + d.FPL3,
      }
    })
    client
      .mutate({
        mutation: SEND_PNL_MAIL,
        variables: { data },
      })
      .then((res) => openNotification('success', 'Mail Sent Successfully'))
      .catch((err) => console.log(err))
  }

  const rowSelection = {
    selectedRowKeys: selectedRow,
    onSelectAll: (record) => {
      setSelectedRow(record ? reportData.map((r) => r.Clientcode) : [])
    },

    onSelect: (record) => {
      const isTrue = selectedRow.findIndex((i) => i === record.Clientcode)
      if (isTrue > -1) {
        const newValues = selectedRow.filter((i) => i !== record.Clientcode)
        setSelectedRow(newValues)
      } else {
        setSelectedRow((oldData) => [...oldData, record.Clientcode])
      }
    },
  }
  const handleDownloadPdf = () => {
    let filteredData = reportData.filter((r) =>
      selectedRow.includes(r.Clientcode)
    )

    if (filteredData?.length === 0) {
      filteredData = reportData
    }

    let staticHtml = ``
    if (get(data, 'plChange')) {
      staticHtml = `
<div class="ant-table-wrapper nlv-tracker-report">
  <div class="ant-spin-nested-loading">
    <div class="ant-spin-container">
      <div class="ant-table">
        <div class="ant-table-container">
          <div class="ant-table-content">
            <table style="table-layout: auto;">
              <colgroup>
                <col style="width: 60px; min-width: 60px;">
                <col>
                <col>
                <col>
                <col>
                <col>
                <col>
                <col>
                <col>
                <col>
                <col>
                <col>
                <col>
                <col>
                <col style="width: 200px; min-width: 200px;">
              </colgroup>
              <thead class="ant-table-thead">
                <tr>
                  <th colspan="14" class="ant-table-cell report-title main-title">Change in P&amp;L</th>
                </tr>
                <tr>
                  <th class="ant-table-cell report-text">ClientName</th>
                  <th class="ant-table-cell report-text">Client Code</th>
                  <th class="ant-table-cell report-numeric">FPL1</th>
                  <th class="ant-table-cell report-numeric">FPL2</th>
                  <th class="ant-table-cell report-numeric">FPL3</th>
                  <th class="ant-table-cell report-numeric">Interest</th>
                  <th class="ant-table-cell report-text">Lossperc</th>
                  <th class="ant-table-cell report-numeric">PnlWithInt</th>
                  <th class="ant-table-cell report-numeric">PnlWoInt</th>
                  <th class="ant-table-cell report-text">TotExp</th>
                  <th class="ant-table-cell report-text">TradeFO</th>
                  <th class="ant-table-cell report-text">TradeFQ</th>
                  <th class="ant-table-cell report-text">NLVMismatch</th>
                  <th class="ant-table-cell report-text ant-table-column-has-sorters">
                    <div class="ant-table-filter-column"><span class="ant-table-filter-column-title">
                        <div class="ant-table-column-sorters-with-tooltip">
                          <div class="ant-table-column-sorters"><span>Last mail send</span><span
                              class="ant-table-column-sorter ant-table-column-sorter-full"><span
                                class="ant-table-column-sorter-inner"><span role="img" aria-label="caret-up"
                                  class="anticon anticon-caret-up ant-table-column-sorter-up"><svg
                                    viewBox="0 0 1024 1024" focusable="false" data-icon="caret-up" width="1em"
                                    height="1em" fill="currentColor" aria-hidden="true">
                                    <path
                                      d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z">
                                    </path>
                                  </svg></span><span role="img" aria-label="caret-down"
                                  class="anticon anticon-caret-down ant-table-column-sorter-down"><svg
                                    viewBox="0 0 1024 1024" focusable="false" data-icon="caret-down" width="1em"
                                    height="1em" fill="currentColor" aria-hidden="true">
                                    <path
                                      d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z">
                                    </path>
                                  </svg></span></span></span></div>
                        </div>
                      </span><span class="ant-table-filter-trigger-container"><span role="button" tabindex="-1"
                          class="ant-table-filter-trigger ant-dropdown-trigger"><span role="img" aria-label="filter"
                            class="anticon anticon-filter"><svg viewBox="64 64 896 896" focusable="false"
                              data-icon="filter" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                              <path
                                d="M349 838c0 17.7 14.2 32 31.8 32h262.4c17.6 0 31.8-14.3 31.8-32V642H349v196zm531.1-684H143.9c-24.5 0-39.8 26.7-27.5 48l221.3 376h348.8l221.3-376c12.1-21.3-3.2-48-27.7-48z">
                              </path>
                            </svg></span></span></span></div>
                  </th>
                </tr>
              </thead>
              <tbody class="ant-table-tbody">`
      staticHtml = `${staticHtml}`
      filteredData.forEach((data) => {
        const {
          ClientName,
          Clientcode,
          FPL1,
          FPL2,
          FPL3,
          Interest,
          Lossperc,
          PnlWithInt,
          PnlWoInt,
          TotExp,
        } = data
        staticHtml = `${staticHtml}
                <tr data-row-key="KYP" class="ant-table-row ant-table-row-level-0">
                  <td class="ant-table-cell report-text">${ClientName} </td>
                  <td class="ant-table-cell report-text">${Clientcode}</td>
                  <td class="ant-table-cell report-numeric">${FPL1}</td>
                  <td class="ant-table-cell report-numeric">${FPL2}</td>
                  <td class="ant-table-cell report-numeric">${FPL3}</td>
                  <td class="ant-table-cell report-numeric">${Interest}</td>
                  <td class="ant-table-cell report-text">${Lossperc}</td>
                  <td class="ant-table-cell report-numeric">${PnlWithInt}</td>
                  <td class="ant-table-cell report-numeric">${PnlWoInt}</td>
                  <td class="ant-table-cell report-text">${TotExp}</td>
                  <td class="ant-table-cell report-text"></td>
                  <td class="ant-table-cell report-text"></td>
                  <td class="ant-table-cell report-text"></td>
                  <td class="ant-table-cell report-text"></td>
                </tr>`
      })
      staticHtml = `${staticHtml}
              </tbody >
            </table >
          </div >
        </div >
      </div >
    </div >
  </div >
</div >
      `
    }
    exportGraphAsPdf(
      'PL-change-report',
      'PL-CHANGE-REPORT-PDF',
      staticHtml,
      true
    ).finally(() => console.log('=== PL-change-report download ===='))
  }

  const content = (
    <div style={{ width: '100px' }}>
      <div
        onClick={handleDownloadPdf}
        style={{
          marginBottom: `${isMobile ? '0px' : '10px'}`,
          fontSize: '14px',
          fontWeight: '600',
          lineHeight: '24px',
          textAlign: 'left',
          color: ' #000',
          cursor: 'pointer',
        }}
      >
        <img src={PdfIcon} style={{ marginRight: '10px' }} alt='pdf' /> Pdf
      </div>
      <CSVLink
        style={{ paddingRight: 0 }}
        data={
          selectedRow?.length
            ? plChange.filter((item) => selectedRow.includes(item.Clientcode))
            : plChange
        }
        className='csv-btn'
        filename={`PL-change-report`}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          style={{
            marginBottom: `${isMobile ? '0px' : '10px'}`,
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '24px',
            textAlign: 'left',
            color: ' #000',
            cursor: 'pointer',
          }}
        >
          <img src={ExcelIcon} style={{ marginRight: '10px' }} alt='excel' />{' '}
          Excel
        </div>
      </CSVLink>

      <div
        disabled={isEmpty(selectedRow)}
        onClick={handleSendMail}
        style={{
          marginBottom: `${isMobile ? '0px' : '10px'}`,
          fontSize: '14px',
          fontWeight: '600',
          lineHeight: '24px',
          textAlign: 'left',
          color: ' #000',
          cursor: 'pointer',
        }}
      >
        <img src={EmailIcon} style={{ marginRight: '10px' }} alt='main' /> Mail
      </div>
    </div>
  )

  return (
    <>
      <Drawer
        // title='Filter'
        title={null}
        headerStyle={{ display: 'none' }}
        closable={false}
        placement='right'
        onClose={onClose}
        visible={open}
        width={472}
      >
        <div className='Filter_Form'>
          <div onClick={onClose}>
            <img src={FilterBackButton} alt='FilterBackButton' />
          </div>
          <div className='filter_panel_heading'>Filter</div>
        </div>
        <Form
          form={form}
          onFinish={onFinish}
          name='advanced_search'
          style={{ marginTop: '16px', height: '100%' }}
          layout='vertical'
          className='client-detail-form'
          initialValues={initialValues}
        >
          <div
            style={{
              height: isMobile ? 'calc(100vh - 11rem)' : 'calc(100vh - 12rem)',
              overflowY: 'auto',
              scrollbarWidth: 'none',
            }}
          >
            <Form.Item
              rules={[{ required: true, message: 'Analysis is required' }]}
              name='analysis'
              label='From Date'
            >
              <DatePicker
                autoComplete='off'
                getPopupContainer={(trigger) => trigger.parentElement}
                showToday={false}
                format={dateFormat}
                // style={{ width: '100%' }}
                onChange={(e) => setDateState(e)}
              />
            </Form.Item>
            <Form.Item
              name='final'
              label='To Date'
              rules={[{ required: true, message: 'Final date is required' }]}
            >
              <DatePicker
                autoComplete='off'
                getPopupContainer={(trigger) => trigger.parentElement}
                format={dateFormat}
                // style={{ width: '100%' }}
                disabledDate={(current) => {
                  return (
                    current &&
                    moment(current).format() < moment(dateState).format()
                  )
                }}
              />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: 'Level 1 is required' }]}
              name='l1'
              label='Nifty-Level1'
            >
              <InputNumber
                style={{ width: '100%', margin: '0' }}
                placeholder='Enter Level1'
              />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: 'Level 2 is required' }]}
              name='l2'
              label='Nifty-Level2'
            >
              <InputNumber
                placeholder='Enter Level2'
                style={{ width: '100%', margin: '0' }}
              />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: 'Level 3 is required' }]}
              name='l3'
              label='Nifty-Level3'
            >
              <InputNumber
                placeholder='Enter Level3'
                style={{ width: '100%', margin: '0' }}
              />
            </Form.Item>
            <Form.Item name='interest' label='Interest'>
              <InputNumber
                placeholder='Enter Interest'
                style={{ width: '100%', margin: '0' }}
              />
            </Form.Item>
            <Form.Item name='vol' label='Volatility'>
              <InputNumber
                placeholder='Enter Volatility'
                style={{ width: '100%', margin: '0' }}
              />
            </Form.Item>
            <Form.Item name='change' label='Due Time'>
              <InputNumber
                placeholder='Enter Change'
                style={{ width: '100%', margin: '0' }}
              />
            </Form.Item>
          </div>
          <div className='filter_panel_button' style={{}}>
            <Button
              onClick={() => {
                form.resetFields()
                setFilters({})
                setOpen(false)
              }}
            >
              Cancel
            </Button>
            <Button
              loading={loading}
              type='primary'
              htmlType='submit'
              style={{
                width: '100%',
                borderRadius: '8px',
                padding: '10px',
                height: `${isMobile ? 'auto' : '100%'}`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              Generate Report
            </Button>
          </div>
        </Form>
      </Drawer>

      <div className='report_Container'>
        <div className='container_Header'>
          <div className='report_Heading'>Change in P & L</div>
          <div className='container_Export_Popover'>
            <Popover content={content} placement='bottom' trigger='click'>
              <Button>
                Export{' '}
                <DownloadOutlined
                  color='#AF0000'
                  style={{ fontSize: '16px' }}
                />
              </Button>
            </Popover>

            <Button
              style={{
                color: '#000000',
                border: '1px solid #919EAB52',
                backgroundColor: 'white',
              }}
              onClick={showDrawer}
            >
              Filter{' '}
              <FilterOutlined color='#000000' style={{ fontSize: '16px' }} />
            </Button>
          </div>
        </div>
        {error ? (
          <Page404 error={error} isShow={false} />
        ) : (
          !isEmpty(filters) && (
            <div
              className='report-table-wrapper'
              id='PL-CHANGE-REPORT-PDF'
              style={{ marginTop: '16px' }}
            >
              <PLTable
                filters={filters}
                data={reportData}
                columns={columns}
                loading={loading}
                reportData={plChange}
                rowSelection={rowSelection}
              />
            </div>
          )
        )}
      </div>

      <br />
      <br />
      {/* <Row>
        <Col span={20}>
          <Form
            form={form}
            onFinish={onFinish}
            name='advanced_search'
            initialValues={initialValues}
          >
            <Row>
              <Col
                xs={24}
                sm={24}
                md={8}
                lg={8}
                xl={8}
                style={{ padding: '0 3rem' }}
              >
                <Form.Item
                  rules={[{ required: true }]}
                  name='analysis'
                  label='From Date'
                >
                  <DatePicker
                    autoComplete='off'
                    showToday={false}
                    format={dateFormat}
                    style={{ width: '100%' }}
                    onChange={(e) => setDateState(e)}
                  />
                </Form.Item>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={8}
                lg={8}
                xl={8}
                style={{ padding: '0 3rem' }}
              >
                <Form.Item
                  name='final'
                  label='To Date'
                  rules={[{ required: true }]}
                >
                  <DatePicker
                    autoComplete='off'
                    format={dateFormat}
                    style={{ width: '100%' }}
                    disabledDate={(current) => {
                      return (
                        current &&
                        moment(current).format() < moment(dateState).format()
                      )
                    }}
                  />
                </Form.Item>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={8}
                lg={8}
                xl={8}
                style={{ padding: '0 3rem' }}
              ></Col>
            </Row>

            <Row>
              <Col
                xs={24}
                sm={24}
                md={8}
                lg={8}
                xl={8}
                style={{ padding: '0 3rem' }}
              >
                <Form.Item
                  rules={[{ required: true, message: 'Level 1 is required' }]}
                  name='l1'
                  label='Nifty-Level1'
                >
                  <InputNumber
                    placeholder='Enter Level1'
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={8}
                lg={8}
                xl={8}
                style={{ padding: '0 3rem' }}
              >
                <Form.Item
                  rules={[{ required: true, message: 'Level 2 is required' }]}
                  name='l2'
                  label='Nifty-Level2'
                >
                  <InputNumber
                    placeholder='Enter Level2'
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={8}
                lg={8}
                xl={8}
                style={{ padding: '0 3rem' }}
              >
                <Form.Item
                  rules={[{ required: true, message: 'Level 3 is required' }]}
                  name='l3'
                  label='Nifty-Level3'
                >
                  <InputNumber
                    placeholder='Enter Level3'
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col
                xs={24}
                sm={24}
                md={8}
                lg={8}
                xl={8}
                style={{ padding: '0 3rem' }}
              >
                <Form.Item name='interest' label='Interest'>
                  <InputNumber
                    placeholder='Enter Interest'
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={8}
                lg={8}
                xl={8}
                style={{ padding: '0 3rem' }}
              >
                <Form.Item name='vol' label='Volatility'>
                  <InputNumber
                    placeholder='Enter Volatility'
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={8}
                lg={8}
                xl={8}
                style={{ padding: '0 3rem' }}
              >
                <Form.Item name='change' label='Due Time'>
                  <InputNumber
                    placeholder='Enter Change'
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={24} style={{ textAlign: 'center' }}>
                <Button type='primary' htmlType='submit'>
                  Generate Report
                </Button>
                <Button
                  onClick={() => {
                    form.resetFields()
                    setFilters({})
                  }}
                >
                  Clear
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row> */}
      {/* {error ? (
        <Page404 error={error} isShow={false} />
      ) : (
        !isEmpty(filters) && (
          <div className='report-table-wrapper' id='PL-CHANGE-REPORT-PDF'>
            <PLTable
              filters={filters}
              data={reportData}
              columns={columns}
              loading={loading}
              reportData={plChange}
              rowSelection={rowSelection}
            />
          </div>
        )
      )} */}
    </>
  )
}
