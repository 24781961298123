import { AppContext } from 'AppContext'
import { Button, Col, Form, Row, Select } from 'antd'
import client from 'apollo'
import CustomMeta from 'components/CustomMeta'
import { UPDATE_USER } from 'modules/Users/graphql/Mutations'
import React, { useContext, useEffect, useState } from 'react'
import { COMPLETE_STEP } from './graphql/Mutations'
import openNotification from 'utils/Notification'
import { GET_IMAGES } from 'modules/LoginImages/graphql/Query'
import Loader from 'components/loaders/Loader'
import { useQuery } from '@apollo/react-hooks'
import Page404 from 'components/Page404'
import { random } from 'lodash'

const FormItem = Form.Item

const SelectTypeFormNew = ({ next }) => {
  const [form] = Form.useForm()
  const { dispatch, state } = useContext(AppContext)
  const [loading, setLoadings] = useState(false)
  const [imageUrl, setImageUrl] = useState('')
  const onFinish = (values) => {
    setLoadings(true)
    dispatch({ type: 'IS_ACCEPT_LOADING', data: true })
    let isPanCardIndividual
    if (values?.entityStatus === 'Individual') {
      isPanCardIndividual = true
    } else {
      isPanCardIndividual = false
    }
    const { id } = state.currentUser

    client
      .mutate({
        mutation: UPDATE_USER,
        variables: {
          id,
          data: {
            entityStatus: !isPanCardIndividual
              ? values.entityStatus
              : undefined,
            isPanCardIndividual: isPanCardIndividual,
            completedSteps: 'SELECT_USER_TYPE',
          },
        },
      })
      .then((res) => {
        client.mutate({
          mutation: COMPLETE_STEP,
          variables: {
            data: {
              step: 'SELECT_USER_TYPE',
              from: 'WEB',
            },
          },
        })
        dispatch({ type: 'CURRENT_USER', data: res.data.updateUser })
        openNotification('success', 'User type Updated Successfully')
        setLoadings(false)
        dispatch({ type: 'IS_ACCEPT_LOADING', data: false })
        next()
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoadings(false)
        dispatch({ type: 'IS_ACCEPT_LOADING', data: false })
      })
  }

  const {
    loading: imageLoading,
    error,
    data,
  } = useQuery(GET_IMAGES, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (!loading) {
      setImageUrl(
        data?.getAllLoginImages[random(0, data?.getAllLoginImages?.length - 1)]
          .imageUrl
      )
    }
  }, [data, loading])

  if (error) return <Page404 error={error} />
  if (imageLoading) return <Loader />

  return (
    <>
      <Row className='row-style'>
        <Col sm={24} lg={16} className='col-left disclaimer-col'>
          <div>
            <CustomMeta title='Select User Type' />
            <div className='gx-app-login-content verification'>
              <Form
                // {...formItemLayout}
                form={form}
                name='register'
                onFinish={onFinish}
                labelAlign='left'
                scrollToFirstError
                layout='vertical'
                className='client-detail-form'
              >
                <div>
                  <FormItem
                    name='entityStatus'
                    label='Type of Investor'
                    rules={[
                      { required: true, message: 'Select Type of Investor' },
                    ]}
                  >
                    <Select
                      placeholder='Select Type of Investor'
                      className='display-field'
                      options={[
                        {
                          value: 'Individual',
                          label: 'Individual',
                        },
                        {
                          value: 'HUF',
                          label: 'HUF',
                        },
                        {
                          value: 'LLP',
                          label: 'LLP',
                        },
                        {
                          value: 'partnershipFirm',
                          label: 'Partnership Firm',
                        },
                        {
                          value: 'Corporate',
                          label: 'Corporate',
                        },
                        {
                          value: 'Trust',
                          label: 'Trust',
                        },
                      ]}
                    />
                  </FormItem>
                </div>
                <div className='verify_next_button'>
                  <Button
                    // id='btn-click'
                    type='primary'
                    className='yes-next-button'
                    htmlType='submit'
                    loading={loading}
                  >
                    Verify And Next
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </Col>
        <Col span={8} className='col-right disclaimer-col disclaimer-col-image'>
          <div
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src={imageUrl} alt='img' />
          </div>
        </Col>
      </Row>
    </>
  )
}

export default SelectTypeFormNew
