import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Checkbox, Col, Row } from 'antd'
import ReactSpeedometer from 'react-d3-speedometer'
import { AppContext } from 'AppContext'
import { GET_KYC, GET_USER_ANSWERS } from './graphql/Queries'
import Loader from 'components/loaders/Loader'
import client from 'apollo'
import { UPDATE_USER } from 'modules/Users/graphql/Mutations'
import { COMPLETE_STEP, SENDING_ASSESSMENT_MAILS } from './graphql/Mutations'
import { useQuery } from '@apollo/react-hooks'
import { GET_PLANS_BASED_POINTS } from 'modules/Questionnaires/graphql/Query'
import { GET_RISK_PROFILE_SCORE } from 'modules/Settings/RiskProfileScore/graphql/Queries'
import KeyInfo from './KeyInfo'
import CustomMeta from 'components/CustomMeta'
import CalendarIcon from 'assets/images/new-images/calendar.svg'
import ReSubmitIcon from 'assets/images/new-images/resubmit-icon.svg'
import moment from 'moment'

const SummaryNew = ({ back, next, reEvaluate, newEdit }) => {
  let {
    dispatch,
    state: { currentUser, clientSummaryDetails, isMobile },
  } = useContext(AppContext)
  const { isPanCardIndividual } = currentUser
  const [checkBox, setCheckBox] = useState(true)
  const [loading, setLoading] = useState(false)
  const [nextLoading, setNextLoading] = useState(false)
  const [dataLoading, setDataLoading] = useState(true)
  const [riskAppetite, setRiskAppetite] = useState('')
  const [riskProfile, setRiskProfile] = useState()

  const { data, loading: planLoading } = useQuery(GET_PLANS_BASED_POINTS, {
    variables: { points: currentUser?.clientScore },
    fetchPolicy: 'network-only',
  })

  const { data: riskProfileScoreRange, loading: rangeLoading } = useQuery(
    GET_RISK_PROFILE_SCORE,
    { fetchPolicy: 'network-only' }
  )
  const divRef = useRef(null)

  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      })
      window.scrollBy(0, -50)
    }
  }, [])

  useEffect(() => {
    client
      .query({
        query: GET_USER_ANSWERS,
        variables: { where: { planId: parseInt(currentUser?.plan?.id) } },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        if (
          currentUser?.clientScore <=
            riskProfileScoreRange?.getRiskProfile?.lowRisk?.maxScore ||
          (currentUser?.clientScore >=
            riskProfileScoreRange?.getRiskProfile?.moderateRisk?.minScore &&
            currentUser?.clientScore <=
              riskProfileScoreRange?.getRiskProfile?.moderateRisk?.maxScore)
        ) {
          setRiskProfile('Moderate')
        } else if (
          currentUser?.clientScore >=
          riskProfileScoreRange?.getRiskProfile?.highRisk?.minScore
        ) {
          setRiskProfile('High')
        }
        setDataLoading(false)
      })
      .catch((err) => console.log(err))
      .finally(() => setDataLoading(false))
  }, [currentUser, riskProfileScoreRange])

  useEffect(() => {
    if (!planLoading && data?.fetchPlansBasedOnPoints && riskProfile) {
      const findPlan = data?.fetchPlansBasedOnPoints?.find((item) =>
        item?.planRiskType?.includes(riskProfile)
      )
      if (findPlan) {
        setRiskAppetite(findPlan?.riskAppetite)
      } else {
        setRiskAppetite('Averse: Avoidance of risk and uncertainty.')
      }
    }
  }, [planLoading, data, riskProfile])

  if (dataLoading && riskProfile && planLoading) return <Loader />

  let range = []
  let planArray = []
  if (!planLoading) {
    data.fetchPlansBasedOnPoints.forEach((plan) => {
      planArray.push(plan.name.trim())
    })
  }

  if (!rangeLoading && riskProfileScoreRange?.getRiskProfile) {
    range.push(
      riskProfileScoreRange?.getRiskProfile?.lowRisk?.minScore,
      riskProfileScoreRange?.getRiskProfile?.moderateRisk?.minScore,
      riskProfileScoreRange?.getRiskProfile?.highRisk?.minScore,
      riskProfileScoreRange?.getRiskProfile?.highRisk?.maxScore
    )
  }

  const onChange = (e) => {
    setCheckBox(e.target.checked)
  }

  const reSubmit = () => {
    setLoading(true)
    dispatch({ type: 'IS_RESUBMIT_LOADING', data: true })
    client
      .mutate({
        mutation: UPDATE_USER,
        variables: {
          id: currentUser?.id,
          data: { completedSteps: 'CLIENT_PROFILE_2' },
        },
      })
      .then((res) => {
        setLoading(false)
        dispatch({ type: 'IS_RESUBMIT_LOADING', data: false })
        dispatch({ type: 'CURRENT_USER', data: res.data.updateUser })
        back()
      })
      .catch((err) => {
        console.log({ err })
      })
      .finally(() => {
        setLoading(false)
        dispatch({ type: 'IS_RESUBMIT_LOADING', data: false })
      })
  }

  const onNext = () => {
    setNextLoading(true)
    dispatch({ type: 'IS_ACCEPT_LOADING', data: true })
    isPanCardIndividual
      ? client
          .mutate({ mutation: SENDING_ASSESSMENT_MAILS })
          .then((res) => {
            client
              .mutate({
                mutation: UPDATE_USER,
                variables: {
                  id: currentUser?.id,
                  data: {
                    completedSteps: isPanCardIndividual
                      ? reEvaluate && currentUser?.cvlStatus
                        ? 'KYC_FORM'
                        : currentUser?.cvlStatus
                        ? 'KYC_FORM'
                        : 'SUMMARY'
                      : reEvaluate && currentUser?.cvlStatus
                      ? 'KYC_FORM'
                      : 'SUMMARY',
                  },
                },
              })
              .then((res) => {
                setNextLoading(false)
                dispatch({ type: 'IS_ACCEPT_LOADING', data: false })
                if (
                  res?.data?.updateUser?.completedSteps !== 'SUMMARY' &&
                  currentUser?.isPanCardIndividual
                ) {
                  client
                    .query({
                      query: GET_KYC,
                      variables: {
                        userId: parseInt(res?.data?.updateUser?.id),
                      },
                      fetchPolicy: 'network-only',
                    })
                    .then((response) => {
                      if (response?.data?.kyc?.aadharCard) {
                        const currentIndex = clientSummaryDetails.findIndex(
                          (item) => item?.name === 'Kyc Document'
                        )
                        const updateSummaryUiDetails = {
                          name: 'Kyc Document',
                          completedSteps: 'SUMMARY',
                          isDisplayEdit: currentUser?.isReUploadKyc,
                          data: {
                            'Aadhaar Card Front': response?.data?.kyc
                              ?.aadharCard
                              ? response?.data?.kyc?.aadharCard
                              : null,
                            'Aadhaar Card Back': response?.data?.kyc
                              ?.aadharCardBack
                              ? response?.data?.kyc?.aadharCardBack
                              : null,
                            'Pan Card': response?.data?.kyc?.panCard
                              ? response?.data?.kyc?.panCard
                              : null,
                            'Bank Statement': response?.data?.kyc?.bankStatement
                              ? response?.data?.kyc?.bankStatement
                              : null,
                            'Cancelled Cheque': response?.data?.kyc
                              ?.cancelledChequeSnap
                              ? response?.data?.kyc?.cancelledChequeSnap
                              : null,
                            Signature: response?.data?.kyc?.signature
                              ? response?.data?.kyc?.signature
                              : null,
                            'Passport Size Photo': response?.data?.kyc
                              ?.passportSize
                              ? response?.data?.kyc?.passportSize
                              : null,
                            'Income Proof': response?.data?.kyc?.incomeProof
                              ? response?.data?.kyc?.incomeProof
                              : null,
                          },
                        }
                        if (currentIndex !== -1) {
                          clientSummaryDetails[currentIndex] =
                            updateSummaryUiDetails
                        } else {
                          clientSummaryDetails.push(updateSummaryUiDetails)
                        }
                        dispatch({
                          type: 'CLIENT_SUMMARY_DETAILS',
                          data: clientSummaryDetails,
                        })
                      }
                    })
                    .catch((err) => {
                      console.log(err)
                    })
                }
                dispatch({ type: 'CURRENT_USER', data: res.data.updateUser })
                client
                  .mutate({
                    mutation: COMPLETE_STEP,
                    variables: {
                      data: {
                        step: 'SUMMARY',
                        from: 'WEB',
                      },
                    },
                  })
                  .then((res) => {
                    next()
                  })
              })
          })
          .catch((err) => {
            console.log({ err })
          })
          .finally(() => {
            setNextLoading(false)
            dispatch({ type: 'IS_ACCEPT_LOADING', data: false })
          })
      : client
          .mutate({
            mutation: UPDATE_USER,
            variables: {
              id: currentUser?.id,
              data: {
                completedSteps: isPanCardIndividual
                  ? reEvaluate && currentUser?.cvlStatus
                    ? 'KYC_FORM'
                    : currentUser?.cvlStatus
                    ? 'KYC_FORM'
                    : 'SUMMARY'
                  : reEvaluate && currentUser?.cvlStatus
                  ? 'KYC_FORM'
                  : 'SUMMARY',
              },
            },
          })
          .then((res) => {
            setNextLoading(false)
            dispatch({ type: 'IS_ACCEPT_LOADING', data: false })
            dispatch({ type: 'CURRENT_USER', data: res.data.updateUser })
            client
              .mutate({
                mutation: COMPLETE_STEP,
                variables: {
                  data: {
                    step: 'SUMMARY',
                    from: 'WEB',
                  },
                },
              })
              .then((res) => {
                next()
              })
          })
          .catch((err) => {
            console.log({ err })
          })
          .finally(() => {
            setNextLoading(false)
            dispatch({ type: 'IS_ACCEPT_LOADING', data: false })
          })
  }

  return (
    <div ref={divRef}>
      <Row className='row-style'>
        <Col lg={16} sm={24} className='col-left risk-profile-col'>
          <div ref={divRef}>
            <CustomMeta title='Risk Profile' />
            <div ref={divRef}>
              {riskProfile === 'Moderate' ? (
                <div className='summary-title-box'>
                  <div className='summary-title'>
                    <span>What is Moderate risk?</span>
                  </div>
                  <div>
                    <span className='summary-description'>
                      As a Moderate Investor your portfolio should have a bias
                      towards capital growth and a little need for income. You
                      are prepared to accept a higher degree of volatility and
                      risk. Your primary concern is accumulating assets over the
                      medium to long terms.
                    </span>
                  </div>
                </div>
              ) : riskProfile === 'High' ? (
                <div className='summary-title-box'>
                  <div className='summary-title'>
                    <span>What is High risk?</span>
                  </div>
                  <div>
                    <span className='summary-description'>
                      As a High Risk Investor you are ready to take higher risk
                      expecting greater returns. This is a result of your urge
                      to get more income and capital growth. You are well placed
                      to recover from unforeseen market downturns either because
                      you have time on your side or access to capital returns.
                    </span>
                  </div>
                </div>
              ) : riskProfile === 'Low' ? (
                <div className='summary-title-box'>
                  <div className='summary-title'>
                    <span>What is Low risk?</span>
                  </div>
                  <br />
                  <div>
                    <span className='summary-description'>
                      As a Low risk Investor your investment portfolio is
                      focused on Preservation of capital and current income.
                      Your Primary concern is capital protection and avoiding
                      high risk investment.
                    </span>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
            <div className='summary-title-box'>
              <div className='sub-box'>
                <div className='sub-box-title'>Suitability Assessment</div>
                <div className='sub-box-date'>
                  <img src={CalendarIcon} alt='' />{' '}
                  {moment().format('Do MMMM YYYY')}
                </div>
              </div>
              <div className='assessment-details'>
                <div className='detail-data'>
                  <div className='assessment-details-box'>
                    <div className='assessment-title'>Investment Objective</div>
                    <div className='assessment-description'>
                      Long Term Capital Growth with calculated Risk
                    </div>
                  </div>
                  <div className='sub-data-box'>
                    <div className='assessment-details-box'>
                      <div className='assessment-title'>Eligible Plan</div>
                      <div className='assessment-plan-display'>
                        {planArray?.map((plan) => (
                          <div className='assessment-description' key={plan}>
                            {plan?.trim()}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className='sub-data-box'>
                    <div className='assessment-details-box'>
                      <div className='assessment-title'>Risk Profile</div>
                      <div className='assessment-description'>
                        {riskProfile}
                      </div>
                    </div>
                    <div className='assessment-details-box'>
                      <div className='assessment-title'>Risk Appetite</div>
                      <div className='assessment-description'>
                        {riskAppetite}
                      </div>
                    </div>
                    <div className='assessment-details-box'>
                      <div className='assessment-title'>Time Horizon</div>
                      <div className='assessment-description'>5-10 Years</div>
                    </div>
                  </div>
                </div>
                <div className='chart-block'>
                  {range.length > 0 && (
                    <div>
                      <div>
                        <ReactSpeedometer
                          width={272}
                          height={168}
                          segments={3}
                          maxValue={93}
                          value={currentUser?.clientScore}
                          needleHeightRatio={0.7}
                          needleTransitionDuration={3333}
                          needleTransition='easeElastic'
                          needleColor={'#000'}
                          customSegmentStops={range}
                          customSegmentLabels={[
                            { position: 'INSIDE', color: '#fff' },
                            { position: 'INSIDE', color: '#fff' },
                            { position: 'INSIDE', color: '#fff' },
                          ]}
                          segmentColors={['#d3b53d', '#d7872e', '#31697b']}
                        />
                      </div>
                      <span className='chart-title-user-name'>
                        Risk Assessment of{' '}
                        <span>
                          {`${currentUser?.firstName} ${currentUser?.lastName}`}
                        </span>
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className='summary_risk_profile'>
                  <div>
                    <div className='agree-wrapper'>
                      <Checkbox onChange={onChange} checked={checkBox} />
                      <p
                        className='agree-content'
                        style={{ fontSize: isMobile ? '14px' : '17px' }}
                      >
                        I have been explained all the products with detailed
                        features and benefits by the counsellor and I confirm
                        that I wish to subscribe to this product with my own
                        consent.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                className='resubmit-button'
                loading={loading}
                onClick={reSubmit}
              >
                Re-Submit Risk Profile &nbsp;
                <img
                  src={ReSubmitIcon}
                  alt='ReSubmitIcon'
                  style={{ width: `${isMobile ? '20px' : '100%'}` }}
                />
              </Button>
              <Button
                className='yes-next-button'
                loading={nextLoading}
                type='primary'
                onClick={onNext}
                disabled={!checkBox}
              >
                Next
              </Button>
            </div>
          </div>
        </Col>
        <Col lg={8} sm={24} className='col-right risk-profile-col'>
          <KeyInfo newEdit={newEdit} />
        </Col>
      </Row>
    </div>
  )
}

export default SummaryNew
