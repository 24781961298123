import React, { Fragment, useState, useContext } from 'react'
// import {
//   // MenuOutlined,
//   EditOutlined,
//   EyeOutlined,
//   MailOutlined,
// } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import get from 'lodash/get'

import { getName } from 'utils/User'
import About from 'components/About'
import Steps from './components/Steps'
import { AppContext } from 'AppContext'
import Page404 from 'components/Page404'
import CustomMeta from 'components/CustomMeta'
import Loader from 'components/loaders/Loader'
import { useQuery } from '@apollo/react-hooks'
import { GET_USER } from '../Users/graphql/Queries'
import LeadUsersChain from './components/LeadUsersChain'
import { SYNC_SIGNED_PDF } from 'modules/Users/graphql/Mutations'
import client from 'apollo'
import openNotification from 'utils/Notification'
import moment from 'moment'
import FilterBackIcon from 'assets/images/new-images/filter-back-button.svg'

export default function Client(props) {
  let clientDetails = {}
  // const [visibleMenu, setVisibleMenu] = useState(false)
  const [loadingSync, setLoadingSync] = useState(false)

  const {
    history,
    match: {
      params: { clientId },
    },
  } = props
  const {
    state: {
      isAdmin,
      isStaff,
      isMasterAssociate,
      isClient,
      currentReport,
      isPanCardIndividual,
      isMobile,
    },
  } = useContext(AppContext)
  const { data, loading, error } = useQuery(GET_USER, {
    variables: { id: clientId },
    fetchPolicy: 'network-only',
  })

  if (loading) return <Loader />
  if (error) return <Page404 error={error} />

  if (!loading && get(data, 'getUser')) {
    clientDetails = data.getUser
  }

  const handleSyncSignedPdf = () => {
    setLoadingSync(true)
    client
      .mutate({
        mutation: SYNC_SIGNED_PDF,
        variables: { userId: clientId },
        refetchQueries: [
          {
            query: GET_USER,
            variables: { id: clientId },
            fetchPolicy: 'network-only',
          },
        ],
      })
      .then((res) => {
        openNotification(
          res?.data?.syncSignedPDF?.status,
          res?.data?.syncSignedPDF?.message
        )
        setLoadingSync(false)
      })
      .catch((err) => {
        setLoadingSync(false)
        openNotification('error', err?.message || 'something went wrong')
      })
  }

  // const menu = (
  //   <Menu>
  //     {clientDetails.email && (
  //       <Menu.Item
  //         key='1'
  //         onClick={() =>
  //           history.push(`/clients/${clientId}/${clientDetails.email}`)
  //         }
  //       >
  //         <MailOutlined /> Mails
  //       </Menu.Item>
  //     )}
  //     {clientDetails.clientCode && (
  //       <Menu.Item
  //         key='2'
  //         onClick={() =>
  //           history.push(
  //             `/clients/${clientId}/position/${clientDetails.clientCode}`
  //           )
  //         }
  //       >
  //         <EyeOutlined /> View Position
  //       </Menu.Item>
  //     )}
  //     {currentReport.includes('Update Client') && (
  //       <Menu.Item
  //         key='3'
  //         onClick={() => history.push(`/clients/${clientId}/edit`)}
  //       >
  //         <EditOutlined /> Edit
  //       </Menu.Item>
  //     )}
  //   </Menu>
  // )

  return (
    <Fragment>
      <CustomMeta title={getName(clientDetails)} description='Clients' />
      <div
        className='main_dashboard_div'
        style={{
          marginBottom: isMobile ? '5vh' : '1px',
        }}
      >
        {/* <PageHeader
          className='box'
          onBack={() => history.goBack()}
          title={getName(clientDetails)}
          extra={[
            window.innerWidth >= 991 ? (
              <Fragment key='0'>
                {isAdmin && (
                  <Tooltip
                    // visible={clientDetails?.kyc?.syncOn}
                    placement='topLeft'
                    title={
                      clientDetails?.kyc?.syncOn
                        ? `Last Sync On : ${moment(
                            clientDetails?.kyc?.syncOn
                          ).format('DD, MMM-YY hh:mm')}`
                        : 'Not synced yet'
                    }
                  >
                    <Button
                      loading={loadingSync}
                      key='4'
                      type='primary'
                      onClick={handleSyncSignedPdf}
                    >
                      Sync Signed KYC & Agreement
                    </Button>
                  </Tooltip>
                )}

                {clientDetails.email && (
                  <Button
                    key='1'
                    type='primary'
                    onClick={() =>
                      history.push(
                        `/clients/${clientId}/${clientDetails.email}`
                      )
                    }
                  >
                    Mails
                  </Button>
                )}
                {clientDetails.clientCode && (
                  <Button
                    key='2'
                    type='primary'
                    onClick={() =>
                      history.push(
                        `/clients/${clientId}/position/${clientDetails.clientCode}`
                      )
                    }
                  >
                    View Position
                  </Button>
                )}
                {currentReport.includes('Update Client') && (
                  <Button
                    key='3'
                    type='primary'
                    onClick={() => history.push(`/clients/${clientId}/edit`)}
                  >
                    Edit
                  </Button>
                )}
              </Fragment>
            ) : (
              <Dropdown
                onVisibleChange={() => setVisibleMenu(false)}
                key='0'
                overlay={menu}
                onClick={() => setVisibleMenu(!visibleMenu)}
                visible={visibleMenu}
              >
                <Button>
                  <MenuOutlined />
                </Button>
              </Dropdown>
            ),
          ]}
        /> */}

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '16px',
            width: '100%',
            flexWrap: isMobile ? 'wrap' : 'nowrap',
            gap: isMobile ? '20px' : '5px',
          }}
        >
          <div
            style={{
              fontSize: isMobile ? '18px' : '24px',
              fontWeight: '600',
              lineHeight: '26px',
              letterSpacing: '-0.02em',
              textAlign: 'left',
              color: '#181C32',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div
              onClick={() => history.goBack()}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <img style={{ cursor: 'pointer' }} src={FilterBackIcon} alt='' />{' '}
              &nbsp; {getName(clientDetails)}
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              gap: '10px',
              flexWrap: isMobile ? 'wrap' : 'nowrap',
            }}
          >
            {isAdmin ? (
              <Tooltip
                // visible={clientDetails?.kyc?.syncOn}
                placement='topLeft'
                title={
                  clientDetails?.kyc?.syncOn
                    ? `Last Sync On : ${moment(
                        clientDetails?.kyc?.syncOn
                      ).format('DD, MMM-YY hh:mm')}`
                    : 'Not synced yet'
                }
              >
                <Button
                  loading={loadingSync}
                  key='4'
                  style={{
                    margin: '0',
                    padding: isMobile ? '4px 8px' : '8px 16px',
                    fontSize: isMobile ? '13px' : '14px',
                    fontWeight: '600',
                    lineHeight: '24px',
                    textAlign: 'left',
                    color: '#AF0000',
                    background: '#AF00000D',
                    border: '1px solid #AF000080',
                    height: '40px',
                    borderRadius: '8px',
                  }}
                  onClick={handleSyncSignedPdf}
                >
                  Sync Signed KYC & Agreement
                </Button>
              </Tooltip>
            ) : (
              <></>
            )}

            {clientDetails.email ? (
              <Button
                key='1'
                s
                style={{
                  margin: '0',
                  padding: isMobile ? '4px 8px' : '8px 16px',
                  fontSize: isMobile ? '13px' : '14px',
                  fontWeight: '600',
                  lineHeight: '24px',
                  textAlign: 'left',
                  color: '#AF0000',
                  background: '#AF00000D',
                  border: '1px solid #AF000080',
                  height: '40px',
                  borderRadius: '8px',
                }}
                onClick={() =>
                  history.push(`/clients/${clientId}/${clientDetails.email}`)
                }
              >
                Mails
              </Button>
            ) : (
              <></>
            )}
            {clientDetails.clientCode ? (
              <Button
                key='2'
                style={{
                  margin: '0',
                  padding: isMobile ? '4px 8px' : '8px 16px',
                  fontSize: isMobile ? '13px' : '14px',
                  fontWeight: '600',
                  lineHeight: '24px',
                  textAlign: 'left',
                  color: '#AF0000',
                  background: '#AF00000D',
                  border: '1px solid #AF000080',
                  height: '40px',
                  borderRadius: '8px',
                }}
                onClick={() =>
                  history.push(
                    `/clients/${clientId}/position/${clientDetails.clientCode}`
                  )
                }
              >
                View Position
              </Button>
            ) : (
              <></>
            )}
            {currentReport.includes('Update Client') ? (
              <Button
                style={{
                  margin: '0',
                  padding: isMobile ? '4px 8px' : '8px 16px',
                  fontSize: isMobile ? '13px' : '14px',
                  fontWeight: '600',
                  lineHeight: '24px',
                  textAlign: 'left',
                  color: '#AF0000',
                  background: '#AF00000D',
                  border: '1px solid #AF000080',
                  height: '40px',
                  borderRadius: '8px',
                }}
                onClick={() => history.push(`/clients/${clientId}/edit`)}
              >
                Edit
              </Button>
            ) : (
              <></>
            )}
          </div>
        </div>

        {(isAdmin || isStaff || isMasterAssociate) && (
          <Steps
            clientId={clientId}
            completedStep={clientDetails.completedSteps}
            isPanCardIndividual={isPanCardIndividual}
          />
        )}
        {!isClient && <LeadUsersChain userId={clientId} />}
        <About details={clientDetails} id={clientId} />
      </div>
    </Fragment>
  )
}
