import React, { useContext, useEffect, useState } from 'react'
import {
  Button,
  DatePicker,
  InputNumber,
  Modal,
  Tag,
  Tooltip as AntTooltip,
  Table,
  Tabs,
} from 'antd'
import {
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  ArrowLeftOutlined,
} from '@ant-design/icons'

import client from 'apollo'

import { cloneDeep, isEmpty, uniq } from 'lodash'
import {
  UPDATE_ADVISORY_STATUS,
  UPDATE_ADVISORY_VALUE,
} from './graphql/Mutation'
import openNotification from 'utils/Notification'
import moment from 'moment'
import PendingAdvisoryModel from './PendingAdvisoryModel'
import ScenarioPendingAdvisoryIndex from 'modules/Verification/PendingAdvisory/ScenarioPendingAdvisoryIndex'
import {
  SEND_PENDING_ADVISORY_TERMS_MAIL,
  UPDATE_BOOK_ADVISORY,
} from 'modules/Verification/graphql/Mutations'
import { AppContext } from 'AppContext'
import { ConfirmAdvisoryModalNew } from 'modules/Verification/PendingAdvisory/ConfirmAdvisoryModalNew'
import MoreDetailsNew from 'modules/Verification/PendingAdvisory/MoreDetailsNew'
import { GET_BOOKING_ADVISORY } from 'modules/Verification/graphql/Queries'
import TermsConditionNew from 'modules/Verification/PendingAdvisory/TermsConditionNew'
import { useQuery } from '@apollo/react-hooks'

export default function PendingAdvisoryTableNew({
  advisoryData,
  refetch,
  setDateForAdvisory,
  dateForAdvisory,
  allData,
  mapData,
  range,
  accept,
  setAccept,
  bookedAdvisoryId,
  userData,
  isPending,
  setOpenAdvisory,
}) {
  const data = cloneDeep(advisoryData)
  const { confirm } = Modal
  const [errorKeys, setErrorKeys] = useState([])
  const [openTableInModal, setOpenTableInModal] = useState(false)
  const [newData, setNewData] = useState()
  const [confirmModal, setConfirmModal] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)

  const {
    dispatch,
    state: { isMobile },
  } = useContext(AppContext)

  const { data: getBookAdvisoryData } = useQuery(GET_BOOKING_ADVISORY, {
    variables: {
      where: { id: parseInt(bookedAdvisoryId), userId: parseInt(userData?.id) },
    },
  })

  useEffect(() => {
    if (data?.length) {
      data.forEach((d, key) => {
        if (newData?.length && newData[key]) {
          d.TradePrice = newData[key].TradePrice
          d.TradedQuantity = newData[key].TradedQuantity
        } else {
          d.TradedQuantity = +d.Quantity
        }
      })
    }
  }, [data, newData])

  const columns = [
    {
      title: 'Pending Advisory',
      className: 'report-title main-title main_div_title',
      children: [
        {
          title: (
            <div className='hidden-client-data'>
              {advisoryData?.length ? advisoryData[0]?.AdvisoryType : ''}
              <span className='hidden-client-code'></span>
            </div>
          ),
          className: 'hidden-client-data-th',
          children: [
            // {
            //   title: 'Trade Type',
            //   dataIndex: 'TradeType',
            //   key: 'TradeType',
            //   className: 'report-text average-index-column-align',
            // },
            {
              title: 'Script',
              dataIndex: 'Script',
              key: 'Script',
              className: '',
            },
            {
              title: 'Trade',
              dataIndex: 'Trade',
              key: 'Trade',
              className: 'text_align',
              width: '15%',
            },
            {
              title: 'Quantity',
              dataIndex: 'Quantity',
              key: 'Quantity',
              className: 'text_align',
              width: '15%',
            },
          ],
        },
        {
          title: (
            <div className='hidden-client-data'>
              {advisoryData.length
                ? new Date(advisoryData[0]?.AdvisoryDate)
                    .toISOString()
                    .replace('Z', '')
                    .replace('T', ' ')
                : ''}
              <span className='hidden-client-code'></span>
            </div>
          ),
          className: 'hidden-client-data-th',
          children: [
            {
              title: 'Traded Quantity',
              dataIndex: 'TradedQuantity',
              key: 'TradedQuantity',
              className: 'text_align',
              width: '15%',
            },
            {
              title: 'Price',
              dataIndex: 'TradePrice',
              key: 'TradePrice',
              className: 'text_align',
              width: '15%',
            },
          ],
        },
      ],
    },
  ]

  const showConfirm = () => {
    let flag = true
    let isEQFlag = true
    data.forEach((data) => {
      const isEQ =
        data?.Script.split(' ')[data?.Script?.split(' ')?.length - 1] === 'EQ'
      if (
        data.TradedQuantity !== 0 &&
        (data.TradePrice <= 0 || typeof data?.TradePrice !== 'number') &&
        !isEQ
      ) {
        flag = false
      }
      if (
        (data.TradedQuantity === 0 || data?.TradedQuantity === null) &&
        !isEQ
      ) {
        // data.TradePrice = 0
        flag = false
      }
      if (data?.TradePrice === null || typeof data?.TradePrice != 'number') {
        flag = false
        isEQFlag = false
      }
    })

    flag
      ? setOpenTableInModal(true)
      : confirm({
          title: `${
            isEQFlag
              ? 'Traded Quantity and Trade Price must be greater than 0'
              : "Traded Quantity and Trade Price Can't Be Empty"
          }`,
          icon: <ExclamationCircleOutlined />,
        })
  }

  const handleCancel = () => {
    setOpenTableInModal(false)
  }

  const handleOnOk = () => {
    if (data) {
      const statusData = data.map((d) => {
        return {
          advisorId: parseInt(d.AdvisorID),
          clientCode: d.ClientCode,
          status: 'Accept',
        }
      })
      client
        .mutate({
          mutation: UPDATE_ADVISORY_STATUS,
          variables: { data: statusData },
        })
        .then(() => {
          data.forEach((d) => {
            delete d.high
            delete d.low
            delete d.MktPrice
            // delete d.Symbol
            d.AdvisorID = parseInt(d.AdvisorID)
            if (!d.Strike) {
              d.Strike = ''
            } else {
              d.Strike = JSON.stringify(d.Strike)
            }
            if (d.AdvisoryType) {
              delete d.AdvisoryType
            }
            if (d.Price) {
              delete d.Price
            }
            if (d.AdvisoryDate) {
              delete d.AdvisoryDate
            }
            if (d.Quantity) {
              delete d.Quantity
            }
          })
          client
            .mutate({
              mutation: UPDATE_ADVISORY_VALUE,
              variables: { data },
            })
            .then((data) => {
              data?.data?.updateAdvisoryValue === true
                ? openNotification('success', 'Accepted Successfully')
                : openNotification('error', 'Something went wrong')

              client
                .mutate({
                  mutation: UPDATE_BOOK_ADVISORY,
                  variables: {
                    data: {
                      userId: userData?.id,
                      isClientAcceptAdvisory: true,
                      advisoryAcceptDateAndTime: moment().format(),
                    },
                    where: { id: parseInt(bookedAdvisoryId) },
                  },
                })
                .then((res) => {
                  setOpenTableInModal(false)
                  setConfirmModal(true)
                })
                .catch((err) => console.log(err))
            })
        })
    } else {
      setAccept(false)
      setOpenTableInModal(false)
    }
  }

  const handleAccept = (isReject = false) => {
    let modal
    modal = confirm({
      icon: <ExclamationCircleOutlined />,
      centered: true,
      okText: isReject ? 'Yes' : 'Accept',
      cancelText: isReject ? 'No' : 'Cancel',
      okButtonProps: { style: { display: 'none' } },
      cancelButtonProps: { style: { display: 'none' } },
      content: (
        <div>
          <div className='modal_text'>
            {isReject
              ? 'Do You Want To Discard The Received Advisory?'
              : 'Kindly Update The Traded Quantity And Price?'}
          </div>
          <div className='book_advisory_btn'>
            <Button
              className='advisory_cancel_btn'
              onClick={() => modal.destroy()}
              disabled={false}
            >
              {isReject ? 'No' : 'Cancel'}
            </Button>
            <Button
              className='advisory_verify_btn'
              type='primary'
              onClick={() => onOk()}
              loading={false}
            >
              {isReject ? 'Yes' : 'Accept'}
            </Button>
          </div>
        </div>
      ),
    })

    const onOk = () => {
      if (isReject) {
        const statusData = data.map((d) => {
          return {
            advisorId: parseInt(d.AdvisorID),
            clientCode: d.ClientCode,
            status: 'Reject',
          }
        })
        client
          .mutate({
            mutation: UPDATE_ADVISORY_STATUS,
            variables: { data: statusData },
          })
          .then((res) => {
            res?.data?.updateAdvisoryStatus === true
              ? openNotification('info', 'Rejected Successfully')
              : openNotification('error', 'Something went wrong')

            client
              .mutate({
                mutation: UPDATE_BOOK_ADVISORY,
                variables: {
                  data: {
                    isCancelAdvisory: true,
                    advisoryCancelBy: userData?.id,
                    isPendingAdvisoryAccepted: true,
                    userId: userData?.id,
                  },
                  where: { id: parseInt(bookedAdvisoryId) },
                },
              })
              .then((res) => {
                modal.destroy()
                refetch(true, true)
              })
              .catch((err) => console.log(err))
          })
      } else {
        modal.destroy()
        dispatch({ type: 'IS_ACCEPT_LOADING', data: true })
        client
          .mutate({
            mutation: UPDATE_BOOK_ADVISORY,
            variables: {
              data: { isPendingAdvisoryAccepted: true, userId: userData?.id },
              where: { id: parseInt(bookedAdvisoryId) },
            },
          })
          .then((res) => {
            dispatch({
              type: 'SHOW_FOOTER_BTN',
              data: { isAdvisoryAccept: true, isAdvisoryReject: true },
            })
            dispatch({ type: 'IS_ACCEPT_LOADING', data: false })
            setAccept(true)
          })
          .catch((err) => {
            dispatch({ type: 'IS_ACCEPT_LOADING', data: false })
            console.log(err)
          })
      }
    }
  }

  const handleGetPendingAdvisory = (e) => {
    setDateForAdvisory(
      moment(e).format('DD-MMM-YYYY') !== 'Invalid date'
        ? moment(e).format('DD-MMM-YYYY')
        : ''
    )
  }

  const onFinish = () => {
    handleAccept()
  }
  const onReject = () => {
    handleAccept(true)
  }

  const confirmAdvisory = () => {
    setAccept(false)
    refetch(true, true)
  }

  const selectedDateColumns = [
    {
      title: 'Script',
      dataIndex: 'Script',
      key: 'Script',
    },
    {
      title: 'Trade',
      dataIndex: 'Trade',
      key: 'Trade',
    },
    {
      title: 'Quantity',
      dataIndex: 'Quantity',
      key: 'Quantity',
    },
    {
      title: 'Traded Quantity',
      dataIndex: 'Quantity',
      key: 'Quantity',
      render: (text, record, key) => {
        const { Quantity } = record
        return !accept ? (
          <>
            <span>{Quantity}</span>
          </>
        ) : (
          <InputNumber
            defaultValue={accept && Quantity}
            onChange={(value) => {
              data[key].TradedQuantity = value
              setNewData(data)
            }}
          />
        )
      },
    },
    {
      title: 'Trade Price',
      dataIndex: 'TradePrice',
      key: 'TradePrice',

      render: (text, _, key) => {
        return !accept ? (
          <>
            <span>{data[key].TradePrice}</span>
          </>
        ) : (
          <>
            <div className='advisory_input'>
              <InputNumber
                type='number'
                defaultValue={
                  newData?.length && newData[key] && newData[key].TradePrice > 0
                    ? newData[key].TradePrice
                    : undefined
                }
                onChange={(value) => {
                  if (!isNaN(data[key]?.low) && !isNaN(data[key]?.high)) {
                    if (
                      (value < data[key].low || value > data[key].high) &&
                      !(
                        data[key]?.Script.split(' ')[
                          data[key]?.Script?.split(' ')?.length - 1
                        ] === 'EQ'
                      )
                    ) {
                      setErrorKeys(uniq([...errorKeys, key]))
                    } else {
                      setErrorKeys(errorKeys?.filter((item) => item !== key))
                    }
                  }
                  data[key].TradePrice = value
                  setNewData(data)
                }}
              />

              <div>
                {errorKeys?.includes(key) &&
                  !isNaN(data[key].low) &&
                  !isNaN(data[key].high) &&
                  !(
                    data[key]?.Script.split(' ')[
                      data[key]?.Script?.split(' ')?.length - 1
                    ] === 'EQ'
                  ) && (
                    <>
                      <div className='advisory_error'>
                        Price range is {data[key].low} - {data[key].high}
                      </div>

                      <div style={{ marginTop: '10px' }}>
                        <AntTooltip
                          placement='left'
                          visible={
                            errorKeys?.includes(key) &&
                            !isNaN(data[key].low) &&
                            !isNaN(data[key].high)
                          }
                          title={`Price range is ${data[key].low} - ${data[key].high}`}
                        >
                          <InfoCircleOutlined className='error_info_icon' />
                        </AntTooltip>
                      </div>
                    </>
                  )}
              </div>
            </div>
          </>
        )
      },
    },
  ]

  const newColumns = [
    {
      title: 'Script',
      dataIndex: 'Script',
      key: 'Script',
    },
    {
      title: 'Trade',
      dataIndex: 'Trade',
      key: 'Trade',
    },
    {
      title: 'Quantity',
      dataIndex: 'Quantity',
      key: 'Quantity',
    },
    {
      title: 'Price',
      dataIndex: 'TradePrice',
      key: 'TradePrice',
      render: (text, record) =>
        record?.TradePrice ? record?.TradePrice : record?.Price,
    },
  ]

  const sendTermsMail = () => {
    setBtnLoading(true)
    dispatch({ type: 'IS_ACCEPT_LOADING', data: true })
    const data = {
      ...allData?.termPara,
      name: userData?.firstName + ' ' + userData?.lastName,
      email: userData?.email,
      advisoryId: parseInt(bookedAdvisoryId),
    }

    client
      .mutate({
        mutation: SEND_PENDING_ADVISORY_TERMS_MAIL,
        variables: { data: { ...data } },
        refetchQueries: [
          {
            query: GET_BOOKING_ADVISORY,
            variables: {
              where: {
                userId: parseInt(userData?.id),
                id: parseInt(bookedAdvisoryId),
              },
            },
            fetchPolicy: 'network-only',
          },
        ],
      })
      .then((res) => {
        setBtnLoading(false)
        dispatch({ type: 'IS_ACCEPT_LOADING', data: false })
        dispatch({
          type: 'SHOW_FOOTER_BTN',
          data: { isPendingAccept: true, isPendingReject: true },
        })
      })
      .catch((err) => {
        setBtnLoading(false)
        dispatch({ type: 'IS_ACCEPT_LOADING', data: false })
        console.log(err)
      })
  }

  const disabledDate = (current) => {
    return current && current > moment().endOf('day')
  }
  return (
    <>
      <div className='main_display_div pending_advisory_div'>
        {advisoryData?.length ? (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: isMobile ? 'wrap' : 'nowrap',
              }}
            >
              <div
                style={{
                  fontSize: '24px',
                  fontWeight: '600',
                  lineHeight: '26px',
                  letterSpacing: '-0.02em',
                  textAlign: 'left',
                  color: '#181C32',
                }}
              >
                Index Long Term Strategy
              </div>
              <div
                style={{
                  // display: `${isMobile && 'inline-flex'}`,
                  marginTop: `${isMobile ? '20px' : '0px'}`,
                  display: 'inline-flex',
                  flexDirection: 'row',
                  gap: '5px',
                }}
              >
                <div className='pending_advisory_title'>
                  {moment(allData?.createdAt)
                    .subtract(330, 'minute')
                    .format('DD-MM-YYYY')}
                </div>
                <div
                  className='pending_advisory_title'
                  style={{
                    textAlign: `${isMobile ? 'start' : 'end'}`,
                  }}
                >
                  {moment(allData?.createdAt)
                    .subtract(330, 'minute')
                    .format('HH:mm:ss')}
                </div>
              </div>
            </div>
            <div className='pending_advisory_container'>
              {(advisoryData[0]?.AdvisoryType === 'Shifting' ||
                advisoryData[0]?.AdvisoryType === 'Rollover') && (
                // ||
                // advisoryData[0]?.AdvisoryType === 'Rollover' || (
                <div
                  style={{
                    border: '1px solid #F1F1F2',
                    boxShadow: '0px 0px 15px 0px #0000000D',
                    padding: `${isMobile ? '20px' : '17.5px 32px'}`,
                    borderRadius: ' 14px',
                    marginTop: isMobile ? '15px' : '24px',
                    width: isMobile ? '100%' : '50%',
                  }}
                >
                  <div
                    style={{
                      display: `${isMobile ? 'block' : 'flex'}`,
                      justifyContent: 'space-between',
                    }}
                  >
                    <div
                      style={{
                        display: `${isMobile ? 'grid' : 'flex'}`,
                        gap: `${isMobile ? '5px' : '32px'}`,
                      }}
                    >
                      <div
                        style={{
                          fontSize: '16px',
                          fontWeight: '600',
                          lineHeight: '16.45px',
                          textAlign: 'left',
                          color: '#af0000',
                          marginBottom: '8px',
                        }}
                      >
                        <div
                          style={{
                            marginBottom: '10px',
                          }}
                        >
                          Please Follow This Steps Before Trading:
                        </div>

                        <span
                          style={{
                            fontSize: '14px',
                            fontWeight: '600',
                            lineHeight: '18.8px',
                            letterSpacing: '-0.01em',
                            textAlign: 'left',
                            color: '#000000',
                          }}
                        >
                          1. Match your open position in the trading account
                          with the "Current Open Position" . <br />
                          2. After taking trades match your closing position
                          with "Open position after Execution". <br />
                          3. In case of any discrepancy, create a ticket in
                          Finideas BackOffice for support.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div
                style={{
                  border: '1px solid #F1F1F2',
                  boxShadow: '0px 0px 15px 0px #0000000D',
                  padding: `${isMobile ? '20px' : '17.5px 32px'}`,
                  borderRadius: ' 14px',
                  marginTop: isMobile ? '15px' : '24px',
                  width: isMobile ? '100%' : '50%',
                }}
              >
                <div
                  style={{
                    display: `${isMobile ? 'block' : 'flex'}`,
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    style={{
                      display: `${isMobile ? 'grid' : 'flex'}`,
                      gap: `${isMobile ? '5px' : '10px'}`,
                      flexDirection: 'column',
                    }}
                  >
                    <div
                      style={{
                        fontSize: '14px',
                        fontWeight: '600',
                        lineHeight: '16.45px',
                        textAlign: 'left',
                        color: '#A1A5B7',
                      }}
                    >
                      Client Code:&nbsp;
                      <span
                        style={{
                          fontSize: '16px',
                          fontWeight: '600',
                          lineHeight: '18.8px',
                          letterSpacing: '-0.01em',
                          textAlign: 'left',
                          color: '#000000',
                        }}
                      >
                        {userData?.clientCode}
                      </span>
                    </div>
                    <div
                      style={{
                        fontSize: '14px',
                        fontWeight: '600',
                        lineHeight: '16.45px',
                        textAlign: 'left',
                        color: '#A1A5B7',
                      }}
                    >
                      Client Name:&nbsp;
                      <span
                        style={{
                          fontSize: '16px',
                          fontWeight: '600',
                          lineHeight: '18.8px',
                          letterSpacing: '-0.01em',
                          textAlign: 'left',
                          color: '#000000',
                        }}
                      >
                        {userData?.firstName + ' ' + userData?.lastName}
                      </span>
                    </div>
                    <div
                      style={{
                        fontSize: '14px',
                        fontWeight: '600',
                        lineHeight: '16.45px',
                        textAlign: 'left',
                        color: '#A1A5B7',
                      }}
                    >
                      Advisory Type:&nbsp;
                      <span
                        style={{
                          fontSize: '16px',
                          fontWeight: '600',
                          lineHeight: '18.8px',
                          letterSpacing: '-0.01em',
                          textAlign: 'left',
                          color: '#000000',
                        }}
                      >
                        {advisoryData?.length
                          ? advisoryData[0]?.AdvisoryType === 'New'
                            ? 'Entry'
                            : advisoryData[0]?.AdvisoryType
                          : ''}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        {advisoryData?.length ? (
          <>
            {!accept ? (
              <>
                {advisoryData[0]?.AdvisoryType === 'Scenario' ||
                advisoryData[0]?.AdvisoryType === 'Shifting' ||
                advisoryData[0]?.AdvisoryType === 'Rollover' ? (
                  <ScenarioPendingAdvisoryIndex
                    loading={false}
                    onFinish={onFinish}
                    data={allData}
                    onReject={onReject}
                    rejectLoading={false}
                  />
                ) : advisoryData[0]?.AdvisoryType === 'New' ? (
                  <>
                    {!getBookAdvisoryData?.getBookAdvisory
                      ?.isTermAndServiceAccepted ? (
                      <TermsConditionNew
                        data={{
                          getPendingBookingAdvisory: allData?.termPara,
                        }}
                        loading={btnLoading}
                        onFinish={sendTermsMail}
                      />
                    ) : (
                      <Tabs
                        defaultActiveKey='1'
                        style={{ marginTop: '16px' }}
                        className='trades_tab'
                      >
                        <Tabs.TabPane tab='Trades' key='1'>
                          <div className='advisory_table'>
                            <Table
                              bordered
                              columns={newColumns}
                              dataSource={data}
                              pagination={false}
                              style={{ overflow: 'auto' }}
                            />
                          </div>

                          <div
                            className='verify_next_button'
                            style={{
                              marginRight: '18px',
                              marginTop: '10px',
                            }}
                          >
                            <Button
                              // id='confirm-btn'
                              className='confirm-next-button'
                              onClick={onFinish}
                              loading={false}
                            >
                              Accept
                            </Button>
                            <Button
                              className='yes-next-button'
                              // id='cancel-btn'
                              // type='primary'
                              loading={false}
                              onClick={onReject}
                            >
                              Reject
                            </Button>
                          </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab='Scenario Analysis' key='2'>
                          <MoreDetailsNew
                            bookedAdvisory={allData}
                            mapData1={mapData?.mapData1}
                            mapData2={mapData?.mapData2}
                            range1={range?.range1}
                            range2={range?.range2}
                            userData={userData}
                            isPending={true}
                            isMobile={isMobile}
                          />
                        </Tabs.TabPane>
                      </Tabs>
                    )}
                  </>
                ) : (
                  <>
                    <div>
                      <div
                        className='advisory_table'
                        style={{ marginTop: '16px' }}
                      >
                        <Table
                          bordered
                          columns={newColumns}
                          dataSource={data}
                          pagination={false}
                          style={{ overflow: 'auto' }}
                        />
                      </div>
                      <div
                        className='verify_next_button'
                        style={{
                          marginRight: '18px',
                          marginTop: '10px',
                        }}
                      >
                        <Button
                          // id='confirm-btn'
                          className='confirm-next-button'
                          onClick={onFinish}
                          loading={false}
                        >
                          Accept
                        </Button>
                        <Button
                          className='yes-next-button'
                          // id='cancel-btn'
                          // type='primary'
                          loading={false}
                          onClick={onReject}
                        >
                          Reject
                        </Button>
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <div
                  style={{
                    display: 'grid',
                    justifyContent: `${isMobile ? 'normal' : 'flex-end'}`,
                    marginTop: '16px',
                  }}
                >
                  <div
                    style={{
                      textAlign: `${isMobile ? 'flex-start' : 'flex-end'}`,
                    }}
                  >
                    <b>Select Trade Date</b>
                  </div>

                  <div>
                    <DatePicker
                      className='date_picker_new'
                      getPopupContainer={(trigger) => trigger.parentElement}
                      onChange={(e) => handleGetPendingAdvisory(e)}
                      value={dateForAdvisory ? moment(dateForAdvisory) : ''}
                      disabledDate={disabledDate}
                      format={'DD-MM-YYYY'}
                    />
                    {(!dateForAdvisory ||
                      dateForAdvisory === 'Invalid date') && (
                      <div>
                        <Tag
                          color='red'
                          style={{
                            marginTop: '5px',
                            display: 'flex',
                            justifyContent: 'center',
                            marginRight: 0,
                          }}
                        >
                          Please Select Date
                        </Tag>
                      </div>
                    )}
                  </div>
                </div>

                <div className='advisory_table' style={{ marginTop: '10px' }}>
                  <Table
                    bordered
                    columns={selectedDateColumns}
                    dataSource={advisoryData}
                    pagination={false}
                    style={{ overflow: 'auto' }}
                  />
                </div>

                <div
                  className='verify_next_button'
                  style={{
                    marginRight: '18px',
                    marginTop: '10px',
                    marginBottom: isMobile ? '50px' : '10px',
                  }}
                >
                  <Button
                    // id='confirm-btn'
                    type='primary'
                    className='confirm-next-button'
                    disabled={
                      !dateForAdvisory ||
                      dateForAdvisory === 'Invalid date' ||
                      dateForAdvisory === undefined ||
                      errorKeys?.length
                        ? true
                        : false
                    }
                    onClick={showConfirm}
                  >
                    {'Submit'}
                  </Button>
                  <Button
                    onClick={() => setAccept(false)}
                    className='yes-next-button'
                    // id='cancel-btn'
                  >
                    {'Cancel'}
                  </Button>
                </div>
              </>
            )}
          </>
        ) : (
          <div>
            {isPending && (
              <div
                onClick={() => setOpenAdvisory(false)}
                className='back_icon_for_advisory'
              >
                <ArrowLeftOutlined />
                <div style={{ marginLeft: '10px' }}>Back to Advisory List</div>
              </div>
            )}
            <div
              style={{
                color: '#000',
                marginTop: '300px',
                fontSize: '25px',
                fontWeight: '500',
                textAlign: 'center',
              }}
            >
              No Pending Advisory Found
            </div>
          </div>
        )}

        {openTableInModal && !isEmpty(data) && (
          <PendingAdvisoryModel
            visible={setOpenTableInModal}
            onCancel={() => handleCancel()}
            onOk={() => handleOnOk()}
            columns={columns}
            data={data}
          />
        )}

        {/* {openMoreDetailsModal && (
          <MoreDetails
            isOpenModal={openMoreDetailsModal}
            closeModal={closeModal}
            bookedAdvisory={allData}
            advisoryDate={allData?.createdAt}
            mapData1={mapData?.mapData1}
            mapData2={mapData?.mapData2}
            range1={range?.range1}
            range2={range?.range2}
            userData={userData}
          />
        )} */}

        {confirmModal && (
          <ConfirmAdvisoryModalNew
            visible={confirmModal}
            onOk={confirmAdvisory}
            confirmLoading={false}
          />
        )}
      </div>
    </>
  )
}
