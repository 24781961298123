import React from 'react'
import { Table } from 'antd'

export default function ({ reportData, columns, loading }) {
  const data = reportData?.map((report, key) => {
    return {
      key,
      ...report,
    }
  })

  return (
    <Table
      loading={loading}
      columns={columns}
      dataSource={data}
      className='nlv-tracker-report'
      pagination={{ pageSize: 10000, hideOnSinglePage: true }}
    />
  )
}
