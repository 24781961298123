import React, { useContext, useEffect, useState } from 'react'
import {
  Button,
  Input,
  Space,
  DatePicker,
  Select,
  Form,
  Popover,
  Drawer,
} from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/react-hooks'
import LedgerTable from './LedgerTable'
// import DownloadReport from '../DownloadReport'
import { GET_LEDGER_REPORT } from '../graphql/Queries'
import '../index.css'
import { GET_CLIENT_USERS_FOR_STAFF } from 'modules/Users/graphql/Queries'
import { AppContext } from 'AppContext'
import { getName } from 'utils/User'
import { isEmpty } from 'lodash'
import { DownloadOutlined, FilterOutlined } from '@ant-design/icons'
import ExcelIcon from 'assets/images/new-images/excelIcon.svg'
import FilterBackButton from 'assets/images/new-images/filter-back-button.svg'
import { CSVLink } from 'react-csv'
import './index.css'

const { RangePicker } = DatePicker
const { Option } = Select

export default function () {
  const {
    state: {
      currentUser: { clientCode, firstName, lastName, role },
    },
  } = useContext(AppContext)
  const [form] = Form.useForm()
  const [filters, setFilters] = useState({})
  const [data, setData] = useState()
  const [open, setOpen] = useState(false)

  const { data: allClient, loading: userLoading } = useQuery(
    GET_CLIENT_USERS_FOR_STAFF,
    {
      variables: { where: {}, limit: 10000 },
      fetchPolicy: 'network-only',
    }
  )

  const { data: allData, loading } = useQuery(GET_LEDGER_REPORT, {
    variables: { where: filters },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (!isEmpty(filters) && !loading) {
      setData(allData)
    }
  }, [allData, loading, filters])

  const onFinish = (values) => {
    setFilters(() => {
      return { ...filters, clientCode: values?.clientCode }
    })
    setOpen(false)
  }

  function handleSearch(value, confirm, filedName) {
    value = typeof value === 'object' ? value[0] : value
    confirm()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = value
    setFilters(tempFilters)
  }

  function handleReset(clearFilters, filedName) {
    clearFilters()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = ''
    setFilters(tempFilters)
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          autoFocus={true}
          value={selectedKeys[0]}
          placeholder={`Search ${dataIndex}`}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys[0], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, dataIndex)}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    sorter: (a, b) => a.age - b.age,
  })

  const getColumnDateProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <RangePicker
          format={'DD-MMM-YYYY'}
          onChange={(e) => {
            const from = `fromDate`
            const to = `toDate`
            let tempFilters
            if (e) {
              confirm()
              tempFilters = JSON.parse(JSON.stringify(filters))
              tempFilters[from] = e[0]
              tempFilters[to] = e[1]
            } else {
              clearFilters()
              tempFilters = JSON.parse(JSON.stringify(filters))
              delete tempFilters[from]
              delete tempFilters[to]
            }
            setFilters(tempFilters)
          }}
        />
      </div>
    ),
  })
  const {
    state: { isMobile },
  } = useContext(AppContext)

  const columns = [
    {
      title: 'Entry Date',
      dataIndex: 'Entrydate',
      key: 'Entrydate',
      className: 'report-text',
      align: 'center',
      width: isMobile && 150,
      ...getColumnDateProps('entrydate'),
    },
    {
      title: 'Type',
      dataIndex: 'Type',
      key: 'Type',
      className: 'report-text',
    },
    {
      title: 'Remarks',
      dataIndex: 'remark',
      key: 'remark',
      className: 'report-text',
      width: '15%',
    },
    {
      title: 'DR Amount',
      dataIndex: 'DR_Amount',
      key: 'DR_Amount',
      className: 'report-numeric',
    },
    {
      title: 'CR Amount',
      dataIndex: 'CR_Amount',
      key: 'CR_Amount',
      className: 'report-numeric',
    },
    {
      title: 'Strategy Code',
      dataIndex: 'StrategyCode',
      key: 'StrategyCode',
      width: isMobile && 150,
      className: 'report-text',
      ...getColumnSearchProps('strategyCode'),
    },
    {
      title: 'Client Code',
      dataIndex: 'ClientCode',
      key: 'ClientCode',
      className: 'report-text',
      ...getColumnSearchProps('clientCode'),
    },
    {
      title: 'Member Code',
      dataIndex: 'membercode',
      key: 'membercode',
      className: 'report-text',
      ...getColumnSearchProps('memberCode'),
    },
  ]

  const contentAll = (
    <div style={{ width: '100px' }}>
      <CSVLink
        style={{ paddingRight: 0 }}
        data={allData?.spLedgerReport}
        className='csv-btn'
        filename={`LEDGER-report`}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          style={{
            marginBottom: `${isMobile ? '0px' : '10px'}`,
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '24px',
            textAlign: 'left',
            color: ' #000',
            cursor: 'pointer',
          }}
        >
          <img src={ExcelIcon} style={{ marginRight: '10px' }} alt='csv' />{' '}
          Excel
        </div>
      </CSVLink>
    </div>
  )

  const content = (
    <div style={{ width: '100px' }}>
      <CSVLink
        style={{ paddingRight: 0 }}
        data={
          data?.spLedgerReport && data?.spLedgerReport.length
            ? data?.spLedgerReport
            : allData?.spLedgerReport
        }
        className='csv-btn'
        filename={`LEDGER-report`}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          style={{
            marginBottom: `${isMobile ? '0px' : '10px'}`,
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '24px',
            textAlign: 'left',
            color: ' #000',
            cursor: 'pointer',
          }}
        >
          <img src={ExcelIcon} style={{ marginRight: '10px' }} alt='csv' />{' '}
          Excel
        </div>
      </CSVLink>
    </div>
  )

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  return (
    <>
      {/* {role !== 'CLIENT' && data?.spLedgerReport?.length ? (
        <DownloadReport
          data={data?.spLedgerReport}
          fileName='LEDGER-report'
          id='LEDGER-REPORT-PDF'
          onlyCSV={true}
        />
      ) : (
        ''
        // allData?.spLedgerReport && (
        //   <DownloadReport
        //     data={allData?.spLedgerReport}
        //     fileName='LEDGER-report'
        //     id='LEDGER-REPORT-PDF'
        //     onlyCSV={true}
        //   />
        // )
      )}

      {role !== 'CLIENT' && allData?.spLedgerReport?.length ? (
        <DownloadReport
          data={allData?.spLedgerReport}
          fileName='LEDGER-report'
          id='TRADER-EQ-REPORT-PDF'
          // staticHtml={staticHtml}
          onlyCSV={true}
          getAllData={true}
        />
      ) : (
        ''
      )} */}

      <Drawer
        title={null}
        headerStyle={{ display: 'none' }}
        closable={false}
        placement='right'
        onClose={onClose}
        visible={open}
        width={472}
      >
        <div
          style={{
            margin: '32px 0 50px 0',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <div onClick={onClose}>
            <img src={FilterBackButton} alt='FilterBackButton' />
          </div>
          <div
            style={{
              fontSize: '24px',
              fontWeight: '700',
              lineHeight: '28px',
              textAlign: 'left',
              color: '#000000',
              marginLeft: '16px',
            }}
          >
            Filter
          </div>
        </div>
        <Form
          form={form}
          onFinish={onFinish}
          name='advanced_search'
          style={{ marginTop: '16px' }}
          layout='vertical'
          className='client-detail-form'
        >
          <div
            style={{
              height: isMobile ? 'calc(100vh - 15rem)' : 'calc(100vh - 12rem)',
              overflowY: 'auto',
            }}
          >
            <Form.Item
              name='clientCode'
              label='Select Client Code'
              rules={[{ required: true, message: 'Please select clientCode!' }]}
              hasFeedback
            >
              <Select
                style={{ width: '100%' }}
                getPopupContainer={(trigger) => trigger.parentElement}
                className='display-field'
                showSearch
                loading={userLoading}
                placeholder='Select Client Code'
                optionFilterProp='children'
                filterOption={(input, option) =>
                  (option?.children[0] ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase()) ||
                  (option?.children[2] ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {!userLoading &&
                  allClient &&
                  allClient?.getClientUsersForStaff?.users.map((user, key) => {
                    return (
                      <Option
                        key={key}
                        value={user.clientCode}
                        title={getName(user)}
                      >
                        {user.clientCode}-{getName(user)}
                      </Option>
                    )
                  })}
              </Select>
            </Form.Item>
          </div>

          <div style={{ display: 'flex', gap: '20px', height: '56px' }}>
            <Button
              onClick={() => {
                form.resetFields()
                setFilters({})
                setOpen(false)
                setData({})
              }}
              style={{
                width: '100%',
                borderRadius: '8px',
                padding: '16px',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              Cancel
            </Button>
            <Button
              loading={loading}
              type='primary'
              htmlType='submit'
              style={{
                width: '100%',
                borderRadius: '8px',
                padding: '16px',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              Search
            </Button>
          </div>
        </Form>
      </Drawer>

      <div className='report_Container' >
        <div className='container_Header'>
          <div className='report_Heading'>Ledger Report</div>
          <div className='container_Export_Popover'>
            {role !== 'CLIENT' && allData?.spLedgerReport?.length ? (
              <Popover content={contentAll} placement='bottom' trigger='click'>
                <div style={{ display: 'flex', gap: '10px' }}>
                  <Button>
                    Export All
                    <DownloadOutlined
                      color='#AF0000'
                      style={{ fontSize: '16px' }}
                    />
                  </Button>
                </div>
              </Popover>
            ) : (
              ''
            )}

            <Popover content={content} placement='bottom' trigger='click'>
              <div className='container_Export_Popover'>
                <Button disabled={loading}>
                  Export{' '}
                  <DownloadOutlined
                    color='#AF0000'
                    style={{ fontSize: '16px' }}
                  />
                </Button>
              </div>
            </Popover>

            {role !== 'CLIENT' && (
              <div className='container_Export_Popover'>
                <Button
                  style={{
                    color: '#000000',
                    border: '1px solid #919EAB52',
                    backgroundColor: 'white',
                  }}
                  onClick={showDrawer}
                >
                  Filter{' '}
                  <FilterOutlined
                    color='#000000'
                    style={{ fontSize: '16px' }}
                  />
                </Button>
              </div>
            )}
          </div>
        </div>

        <div className='report_first_Container'>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className='Client_Info_Container'>
              {role === 'CLIENT' && (
                <div className='client_Info'>
                  Client Code:&nbsp;
                  <span>{clientCode}</span>
                </div>
              )}
              <div className='client_Info'>
                Client Name:&nbsp;
                <span>
                  {firstName} {lastName}
                </span>
              </div>
            </div>
          </div>
        </div>

        {role !== 'CLIENT' && data?.spLedgerReport ? (
          <div className='report-table-wrapper' id='LEDGER-REPORT-PDF'>
            <LedgerTable
              reportData={data?.spLedgerReport}
              columns={columns}
              loading={loading}
            />
          </div>
        ) : (
          ''
        )}

        {role === 'CLIENT' ? (
          <div className='report-table-wrapper' id='LEDGER-REPORT-PDF'>
            <LedgerTable
              reportData={allData?.spLedgerReport}
              columns={columns}
              loading={loading}
            />
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  )
}
