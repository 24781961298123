import React, { useContext } from 'react'
import { Col, Row, Tabs } from 'antd'
import { ReactComponent as File } from 'assets/images/new-images/file.svg'
import { ReactComponent as Marker } from 'assets/images/new-images/marker.svg'
import { ReactComponent as AccountDetails } from 'assets/images/new-images/account-details-icon.svg'
import { ReactComponent as UserDetails } from 'assets/images/new-images/userDetails.svg'

import Description from './Description'
import { convertNumberToCommaSeparate } from 'utils/utilities'
import { AppContext } from 'AppContext'
const TabPane = Tabs.TabPane

export default function TicketDetailsNew(props) {
  const {
    state: { isMobile },
  } = useContext(AppContext)
  const { details, ticket } = props
  const renderDetailsSection = (title, icon, details) => (
    <div className='user-details-section'>
      <h3>
        <span className='icon'>{icon}</span>
        <span
          style={{
            fontSize: '18px',
            fontWeight: '600',
            lineHeight: '26px',
            letterSpacing: '-0.02em',
            color: '#AF0000',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {title}
        </span>
      </h3>
      <Row className='detail-row'>
        {details.map((detail, index) => {
          return (
            <Col key={index} className='detail-item'>
              <span>{detail.label}</span>
              <p>
                {detail.label === 'Initial Exposure'
                  ? convertNumberToCommaSeparate(detail.value)
                  : detail.label === 'Phone Number'
                  ? `+91${detail.value}`
                  : detail.value || '-'}
              </p>
            </Col>
          )
        })}
      </Row>
    </div>
  )

  return (
    <div className='tab-details'>
      <Tabs defaultActiveKey={'2'}>
        <TabPane tab='Summary' key='0'>
          <div className='gx-mb-2 tab-child'>
            <Row>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Description {...ticket} />
              </Col>
            </Row>
          </div>
        </TabPane>
        {/* )} */}
        <TabPane tab='User Details' key='2'>
          <div
            className='gx-mb-2 tab-child'
            style={{
              marginBottom: isMobile ? '5vh' : '1px',
            }}
          >
            {renderDetailsSection('User Details', <UserDetails />, [
              { label: 'User Name', value: details.userName },
              { label: 'Client Code', value: details.clientCode },
              { label: 'Strategy Code', value: details.strategyCode },
              { label: 'Key ID', value: details.keyId },
            ])}
            {renderDetailsSection('Personal Details', <File />, [
              { label: 'First Name', value: details.firstName },
              { label: 'Last Name', value: details.lastName },
              { label: 'Date of Birth', value: details.dob },
              { label: 'Email Address', value: details.email },
              { label: 'Phone Number', value: details.phone },
              { label: 'Mother Name', value: details.motherName },
              { label: 'Father Name', value: details.fatherName },
            ])}
            {renderDetailsSection('Address', <Marker />, [
              { label: 'Address', value: details.address },
              { label: 'City', value: details.city },
              { label: 'State', value: details.state },
              { label: 'Country', value: details.country },
            ])}
            {renderDetailsSection('Account Details', <AccountDetails />, [
              { label: 'Fund Received', value: details.fundReceived },
              { label: 'AMC Start Date', value: details.amcStartDate },
              { label: 'SIP Start Date', value: details.sipStartDate },
              { label: 'Closed Date', value: details.closedDate },
              { label: 'Initial Exposure', value: details.initialExposure },
            ])}
          </div>
        </TabPane>
      </Tabs>
    </div>
  )
}
