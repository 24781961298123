import React, { useEffect, useState } from 'react'
import { Form, Row, Col, Input, Select, Button } from 'antd'
import { USER_PUBLIC } from 'modules/Users/graphql/Queries'
import client from 'apollo'
import { SEND_BULK_PERFORMANCE_REPORT_MAIL } from '../graphql/Mutation'
import { get, uniqBy } from 'lodash'
import { useQuery } from '@apollo/react-hooks'
import openNotification from 'utils/Notification'
import ReactQuill from 'react-quill'

const formItemLayout = {
  labelCol: { xs: { span: 24 }, sm: { span: 24 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 24 } },
}
const PerformanceMailSendModal = ({ data, setOpenMailModal }) => {
  const { mailDraft, ccMailIds, emailId, clientCode, date, attachment } = data

  const [toMailOnChange, setToMailOnChange] = useState(
    emailId ? (typeof emailId === 'object' ? emailId : [emailId]) : []
  )
  const [receiveMailTemplate, setReceiveMailTemplate] = useState(mailDraft)
  const [ccMail, setCcMail] = useState(
    ccMailIds
      ? ccMailIds
          ?.map((item) => {
            return { email: item }
          })
          ?.filter((item) => item?.email !== '')
      : []
  )
  const [subjectForMail, setSubjectForMail] = useState('')
  const [buttonLoading, setButtonLoading] = useState(false)

  let userEmails = []
  const { data: userData, loading: userLoading } = useQuery(USER_PUBLIC, {
    fetchPolicy: 'network-only',
  })

  let clientName
  if (!userLoading && get(userData, 'getUsersPublic.users')) {
    userData.getUsersPublic.users.forEach((item, key) => {
      if (item.clientCode === clientCode) {
        clientName = `${item?.firstName} ${item?.lastName}`
      }
      userEmails.push({
        key,
        label: item?.email,
        value: item?.email,
      })
    })
  }
  useEffect(() => {
    setSubjectForMail(`Finideas Index Long Term Strategy Performance Report`)
  }, [clientName])

  const handleEditInputChange = (e) => {
    const array = e.map((email) => {
      return {
        email,
      }
    })

    console.log(array)
    setCcMail(array)
  }

  const handleToMailChange = (e) => {
    setToMailOnChange(e)
  }

  const handleMailSend = () => {
    setButtonLoading(true)
    const sendData = {
      clientCode: data?.clientCode,
      subject: subjectForMail,
      toMailId: toMailOnChange,
      ccMailIds: ccMail,
      pdfName: `ILTS-PERFORMANCE-REPORT-${clientCode}`,
      date,
      mailDraft,
      attachedPdf: attachment,
    }
    client
      .mutate({
        mutation: SEND_BULK_PERFORMANCE_REPORT_MAIL,
        variables: { data: [sendData] },
      })
      .then((res) => {
        if (res?.data?.sendBulkPerformanceMailSend) {
          setOpenMailModal(false)
          openNotification('success', 'Mail Send Successfully')
        } else {
          openNotification('error', 'Please try again')
        }
        setButtonLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setButtonLoading(false)
      })
      .finally(() => setButtonLoading(false))
  }

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],

      [{ header: 1 }, { header: 2 }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ direction: 'rtl' }],

      [{ size: ['small', false, 'large', 'huge'] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ align: [] }],

      ['clean'],
    ],

    keyboard: {
      bindings: {
        'list autofill': {
          prefix: /^\s*()$/,
        },
      },
    },
  }

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'color',
    'background',
    'image',
    'video',
    'align',
    'direction',
    'code-block',
    'formula',
  ]

  return (
    <>
      <Form
        {...formItemLayout}
        layout='vertical'
        initialValues={{
          emailId: emailId,
          ccMails: ccMailIds,
        }}
      >
        <Row>
          <Col className='gutter-row' xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item label='Subject'>
              <Input
                value={subjectForMail}
                placeholder='Enter Subject'
                onChange={(e) => setSubjectForMail(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' xs={24} sm={24} md={10} lg={10} xl={10}>
            <Form.Item label='To Mail' name='emailId'>
              <Select
                getPopupContainer={(trigger) => trigger.parentElement}
                mode='tags'
                allowClear
                style={{ width: '100%' }}
                placeholder='Please select'
                onChange={(e) => handleToMailChange(e)}
                options={uniqBy(userEmails, 'value')}
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' xs={24} sm={24} md={10} lg={10} xl={10}>
            <Form.Item label='CC Mail' name='ccMails'>
              <Select
                getPopupContainer={(trigger) => trigger.parentElement}
                mode='tags'
                allowClear
                style={{ width: '100%' }}
                placeholder='Please select'
                onChange={(e) => handleEditInputChange(e)}
                options={uniqBy(userEmails, 'value')}
              />
            </Form.Item>
          </Col>

          <Col className='gutter-row' xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item>
              <ReactQuill
                theme='snow'
                value={receiveMailTemplate}
                onChange={(value) => {
                  setReceiveMailTemplate(value)
                }}
                modules={modules}
                formats={formats}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button
            type='primary'
            onClick={emailId || toMailOnChange ? handleMailSend : ''}
            style={{ marginTop: '20px', marginRight: '10px' }}
            loading={buttonLoading}
            disabled={ccMail?.length === 0 || toMailOnChange?.length === 0}
          >
            Send Mail
          </Button>
          <Button
            type='primary'
            style={{ marginTop: '20px' }}
            onClick={() => {
              setOpenMailModal(false)
            }}
          >
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}
export default PerformanceMailSendModal
