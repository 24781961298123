import React, { useContext } from 'react'
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts'
import { useQuery } from '@apollo/react-hooks'
import _get from 'lodash/get'

import { GET_PROFIT_LOSS_CHART } from '../../graphql/Queries'
// import ChartLoader from 'components/loaders/ChartLoader'
import ChartError from 'components/Page404/ChartError'
import Loader from 'components/loaders/Loader'
import { AppContext } from 'AppContext'
import Widget from 'components/Widget'
import '../index.css'

const COLORS = [
  '#50CD89',
  '#3E97FF',
  '#AF0000',
  '#0088FE',
  '#00C49F',
  '#FFBB28',
  '#FF8042',
]

export default function () {
  const {
    state: { selectedGroup, isMobile },
  } = useContext(AppContext)
  const { data, loading, error } = useQuery(GET_PROFIT_LOSS_CHART, {
    variables: { groupId: selectedGroup },
    fetchPolicy: 'network-only',
  })

  if (error) return <ChartError title='Profit Loss Report' />

  const isEmpty =
    !_get(data, 'profitLossPieChart') ||
    (!_get(data, 'profitLossPieChart[0].Profit') &&
      !_get(data, 'profitLossPieChart[0].Loss'))

  let chartData
  if (!isEmpty) {
    chartData = [
      { name: 'Profit', value: _get(data, 'profitLossPieChart')[0].Profit },
      { name: 'Loss', value: _get(data, 'profitLossPieChart')[0].Loss },
    ]
  }

  const renderLegend = (props) => {
    const { payload } = props
    return (
      <ul className='performance_chart_legend_ul'>
        {payload.map((entry, index) => {
          return (
            <>
              <li className='performance_chart_legend_li' key={`item-${index}`}>
                <div style={{ display: 'flex' }}>
                  <div>
                    <svg width='10' height='10' style={{ marginRight: 5 }}>
                      <circle cx='5' cy='5' r='5' fill={entry.color} />
                    </svg>
                  </div>
                  <div className='performance_chart_legend_text'>
                    {entry.value}
                  </div>
                </div>
              </li>
            </>
          )
        })}
      </ul>
    )
  }

  const RADIAN = Math.PI / 180
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
      <text
        x={x}
        y={y}
        fill='white'
        textAnchor={x > cx ? 'middle' : 'middle'}
        dominantBaseline='central'
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    )
  }

  return (
    <Widget
      title={
        <h2 className='h4 gx-text-capitalize gx-mb-0'>Profit Loss Report</h2>
      }
      styleName='gx-text-center'
    >
      {loading ? (
        <div className='performance_loading'>
          <Loader />
        </div>
      ) : isEmpty ? (
        <h2 className='h4 gx-text-capitalize gx-mb-0 performance_loading'>
          No Data
        </h2>
      ) : (
        <ResponsiveContainer
          width='100%'
          height={isMobile ? 340 : 390}
          className='pie-chart-wrapper'
        >
          <PieChart>
            <Pie
              data={chartData}
              label={renderCustomizedLabel}
              cx='50%'
              cy='50%'
              innerRadius={isMobile ? 50 : 80}
              outerRadius={isMobile ? 100 : 150}
              labelLine={false}
              fill='#8884d8'
              dataKey='value'
            >
              {chartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Legend
              layout='vertical'
              verticalAlign='bottom'
              align='center'
              content={renderLegend}
            />
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      )}
    </Widget>
  )
}
