import React from 'react'
import { Modal, Button } from 'antd'
import Success from '../../../assets/images/new-images/pana.svg'
import './pendingAdvisory.css'

export const ConfirmAdvisoryModalNew = ({ visible, onOk, confirmLoading }) => {
  return (
    <>
      <Modal
        visible={visible}
        footer={null}
        maskClosable={false}
        className='book_advisory_modal'
        centered
      >
        <div>
          <div className='confirm_img'>
            <img src={Success} alt='success' />
          </div>
          <div className='confirm_text_div_new'>
            <div className='confirm_text_second_div'>
              <span className='confirm_text'>
                Your trades have been successfully uploaded and you will be able
                to see your Performance report within 48 hours.
              </span>
            </div>
          </div>
          <div className='confirm_btn_div'>
            <Button
              style={{
                background: '#13806C',
                color: '#fff',
                height: '40px',
                borderRadius: '8px',
              }}
              onClick={() => onOk()}
              loading={confirmLoading}
            >
              Done
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}
