import { Button, Checkbox, Modal, Table } from 'antd'
import React, { useState } from 'react'

const PendingAdvisoryModel = ({ visible, onCancel, onOk, data, columns }) => {
  const [agree, setAgree] = useState(true)

  const handleAgree = (e) => {
    setAgree(e.target.checked)
  }

  return (
    <div>
      <Modal
        // width={'80%'}
        maskClosable={false}
        visible={visible}
        title={
          <div
            style={{
              padding: '5px !important',
            }}
          >
            Verify Your Details
          </div>
        }
        cancelText='Cancel'
        className='pending_advisory_model'
        footer={
          <>
            <Button onClick={() => onCancel()}>Cancel</Button>
            <Button onClick={() => onOk()} type='primary' disabled={!agree}>
              Submit
            </Button>
          </>
        }
        onCancel={() => onCancel()}
      >
        <Table
          columns={columns}
          dataSource={data}
          bordered
          pagination={{ pageSize: 1000, hideOnSinglePage: true }}
          className='advisory_update_table'
          style={{ overflow: 'auto' }}
        />
        <br />
        <div className='agree-wrapper'>
          <Checkbox onChange={handleAgree} checked={agree} />
          <p className='agree-content'>
            I/We hereby declare that the details furnished above are true and
            correct to the best of my/our knowledge and belief and I/We
            undertake to inform you of any changes therein, immediately. In case
            any of the above information is found to be false or untrue or
            misleading or misrepresenting, I am/We are aware that I/We may be
            held liable for it.
          </p>
        </div>
        <div className='footer-btn-div'>
          <Button className='footer-cancel-btn' onClick={() => onCancel()}>
            {' '}
            Cancel{' '}
          </Button>
          <Button
            className='footer-submit-btn'
            onClick={() => onOk()}
            type='primary'
            disabled={!agree}
          >
            Verify
          </Button>
        </div>
      </Modal>
    </div>
  )
}

export default PendingAdvisoryModel
