import React from 'react'
import { Table } from 'antd'

export default function ({ reportData, ClientCode, firstName, lastName }) {
  const columns = [
    {
      title: 'Entry Date',
      dataIndex: 'entrydate',
      key: 'entrydate',
      width: 120,
      className: 'report-text average-index-column-align',
    },
    {
      title: 'Type',
      dataIndex: 'Type',
      key: 'Type',
      className: 'report-numeric interest-column-align',
      render: (text) => (text ? text : '-'),
    },
    {
      title: 'Index Level',
      dataIndex: 'ClosingIndexValue',
      key: 'ClosingIndexValue',
      className: 'report-numeric interest-column-align',
      render: (text) => (text ? text : '-'),
    },
    {
      title: 'Quantity',
      dataIndex: 'Qty',
      key: 'Qty',
      className: 'report-numeric interest-column-align',
      render: (text) => (text ? text : '-'),
    },
    {
      title: 'Value',
      dataIndex: 'Value',
      key: 'Value',
      className: 'report-numeric interest-column-align',
      render: (text) => (text ? text : '-'),
    },
  ]
  const data = reportData.map((report, key) => {
    const round = {
      ClosingIndexValue: report.ClosingIndexValue,
      Qty: report.Qty,
      Value: report.Value,
    }
    return { key, ...report, ...round }
  })

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        bordered
        className='report-table-wrapper'
        pagination={{ pageSize: 1000, hideOnSinglePage: true }}
        // scroll={{ x: true, y: 'calc(100vh - 24px - 24px - 72px - 36px - 35px)' }}
      />
    </>
  )
}
