import React, { useContext } from 'react'
import { Row, Col, Typography } from 'antd'
import SupportIcon from '../../../src/assets/images/new-images/support.png'
import { AppContext } from 'AppContext'
import '../Support/Support.css'
import ContactUs from './ContactUs'
import { ReactComponent as UserDetails } from 'assets/images/new-images/userDetails.svg'
import { ReactComponent as Location } from 'assets/images/new-images/location.svg'
import { ReactComponent as Clock } from 'assets/images/new-images/clock.svg'
import { ReactComponent as Phone } from 'assets/images/new-images/phone-call.svg'
import { ReactComponent as Envelope } from 'assets/images/new-images/envelope.svg'

const { Title, Text } = Typography

export default function DetailsNew() {
  const {
    state: { currentUser },
  } = useContext(AppContext)

  return (
    <div className='support'>
      <Row justify='center' className='top-section'>
        <Row gutter={[16, 16]} align='middle'>
          <Col xs={24} md={12}>
            <img
              className='support_image'
              src={SupportIcon}
              alt='24/7 Service'
            />
          </Col>
          <Col xs={24} md={12}>
            <div style={{ marginBottom: '24px' }}>
              <div className='support_user_details'>
                <UserDetails />
                <Title level={4} style={{ marginBottom: '0px' }}>
                  {currentUser?.createdBy &&
                    currentUser?.createdBy.firstName +
                      ' ' +
                      currentUser?.createdBy.lastName}
                </Title>
              </div>
              <div className='details_text_div'>
                <Text>
                  <Phone />{' '}
                  <span className='details_phone_value'>
                    {currentUser?.createdBy && currentUser.createdBy.phone
                      ? currentUser.createdBy.phone
                      : '-'}
                  </span>
                </Text>
                <Text>
                  <Envelope />{' '}
                  <span className='details_phone_value'>
                    {currentUser?.createdBy && currentUser.createdBy.email
                      ? currentUser.createdBy.email
                      : '-'}
                  </span>
                </Text>
              </div>
            </div>

            <div style={{ marginBottom: '24px' }}>
              <div className='support_user_details'>
                <Location />
                <Title level={4} style={{ marginBottom: '0px' }}>
                  Location
                </Title>
              </div>
              <div style={{ textAlign: 'left' }}>
                <Text>
                  <span className='details_phone_value'>
                    1002, Luxuria Business Hub, Near VR Mall, Gaurav Path Road,
                    Surat - 395007
                  </span>
                </Text>
              </div>
            </div>

            <div className='support_user_details'>
              <Clock />
              <Title level={4} style={{ marginBottom: '0px' }}>
                Working Hours
              </Title>
            </div>
            <div style={{ textAlign: 'left' }}>
              <div>
                <Text>
                  <span className='details_phone_value'>
                    9:00 AM - 6:00 PM (IST) Monday – Friday
                  </span>
                </Text>
              </div>
              <div>
                <Text>
                  {' '}
                  <span className='details_phone_value'>
                    10:00 AM - 4:00 PM (IST) Saturday
                  </span>
                </Text>
              </div>
            </div>
          </Col>
        </Row>
      </Row>
      <ContactUs />
    </div>
  )
}
