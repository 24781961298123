import { Button, Form, Modal, Upload } from 'antd'
import React, { useState } from 'react'
import { CREATE_USERS_PDF } from './graphql/mutation'
import { GET_USER_PDF } from 'modules/Plans/graphql/Query'
import { UPDATE_USER } from 'modules/Users/graphql/Mutations'
import openNotification from 'utils/Notification'
import client from 'apollo'

const UploadFileModal = ({
  type,
  notify,
  updateField,
  id,
  setIsModalVisible,
  importBulkAmcsModalClose,
  uploadFileValues,
  setUploadFileValues,
}) => {
  const [form] = Form.useForm()
  const [fileList, setFileList] = useState([])
  const [uploadFileLoader, setUploadFileLoader] = useState(false)

  const normFile = (e) => {
    setFileList([])
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const customProps = {
    multiple: true,
    onRemove: (file) => setFileList([]),
    beforeUpload: (file) => {
      setFileList(file)
      return false
    },
    fileList,
  }

  const onFinish = () => {
    setUploadFileLoader(true)
    const data = {
      pdfUrl: uploadFileValues?.fileList,
      type: type,
      userId: parseInt(id),
    }

    client
      .mutate({
        mutation: CREATE_USERS_PDF,
        variables: { data },
        refetchQueries: [
          {
            query: GET_USER_PDF,
            variables: { userId: parseInt(id) },
            fetchPolicy: 'network-only',
          },
        ],
      })
      .then((res) => {
        setUploadFileLoader(true)
        let data = {}
        data[updateField] = true
        const queryVariables = {
          id: +id,
          data,
        }
        client
          .mutate({
            mutation: UPDATE_USER,
            variables: queryVariables,
          })
          .then((res) => {
            setUploadFileLoader(false)
          })
          .catch((err) => console.log(err))
        setFileList([])
        setIsModalVisible(false)
        openNotification('success', `${notify} Pdf Successfully Uploaded`)
      })
      .catch((error) => {
        setUploadFileLoader(false)
        openNotification('error', 'Something Went Wrong')
      })
  }

  return (
    <div>
      <Modal
        className='feedback_form'
        title={`Upload ${notify}`}
        visible
        onCancel={importBulkAmcsModalClose}
        footer={[
          <Button
            key='submit'
            loading={uploadFileLoader}
            type='primary'
            onClick={onFinish}
          >
            Upload
          </Button>,
        ]}
      >
        <Form form={form} layout='vertical'>
          <Form.Item
            name='csv'
            label={`Please select ${notify} file`}
            valuePropName='csv'
            getValueFromEvent={normFile}
          >
            <Upload
              listType='text'
              {...customProps}
              accept='.pdf,.docx,.doc'
              fileList={uploadFileValues?.fileList}
              onChange={(values) => setUploadFileValues(values)}
            >
              <Button>Click to upload</Button>
            </Upload>
          </Form.Item>
          <div>
            <Button
              key='submit'
              loading={uploadFileLoader}
              type='primary'
              onClick={onFinish}
              className='footer-submit-btn'
            >
              Upload
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default UploadFileModal
