import {
  Button,
  Form,
  InputNumber,
  Checkbox,
  Select,
  Input,
  Popover,
  Drawer,
} from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { FilterOutlined, DownloadOutlined } from '@ant-design/icons'
import React, { useContext, useEffect, useState } from 'react'

import { getName } from 'utils/User'
import Page404 from 'components/Page404'
// import DownloadReport from '../DownloadReport'
import { GET_ROLL_OVER_REPORT } from '../graphql/Queries'
import RolloverTrackingTable from './RolloverTrackingTable'
import { GET_CLIENT_USERS_FOR_STAFF } from 'modules/Users/graphql/Queries'
import '../index.css'
import client from 'apollo'
import { orderBy } from 'lodash'
import { AppContext } from 'AppContext'
import FilterBackButton from 'assets/images/new-images/filter-back-button.svg'
import PdfIcon from 'assets/images/new-images/pdficon.svg'
import ExcelIcon from 'assets/images/new-images/excelIcon.svg'
import { exportGraphAsPdf } from 'utils/Graph'
import { CSVLink } from 'react-csv'
const { Option } = Select

export default function RolloverTrackingReport() {
  const [form] = Form.useForm()
  const [filters, setFilters] = useState({
    where: {
      allClients: 0,
      clientCode: '',
      detailed: 1,
      diff: 20,
      diff1: 10,
      diff2: 5,
      onlyShift: 1,
    },
  })
  const [columnName, setColumnName] = useState()
  let [usersData, setUsersData] = useState([])
  const [userLoading, setUserLoading] = useState(true)
  const [open, setOpen] = useState(false)
  const {
    state: { currentUser, isClient, isMobile },
  } = useContext(AppContext)

  const {
    data = {},
    loading,
    error,
  } = useQuery(GET_ROLL_OVER_REPORT, {
    variables: filters,
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    setUserLoading(true)
    client
      .query({
        query: GET_CLIENT_USERS_FOR_STAFF,
        variables: {
          where: {},
          limit: 10000,
        },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        res.data.getClientUsersForStaff.users.map((user) => {
          return usersData.push(user)
        })
        setUsersData(usersData)
        setUserLoading(false)
      })
      .catch((err) => console.log({ err }))
      .finally(() => setUserLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { rollOver = [] } = data

  const newColumns = [
    {
      title: 'Select User',
      dataIndex: 'clientCode',
      key: 'clientCode',
      className: 'report-text',
    },
    {
      title: columnName?.diff ? `${columnName?.diff}%` : 'DIff',
      dataIndex: 'diff',
      key: 'diff',
      className: 'report-numeric',
    },
    {
      title: columnName?.diff1 ? `${columnName?.diff1}%` : 'DIff1',
      dataIndex: 'diff1',
      key: 'diff1',
      className: 'report-numeric',
    },
    {
      title: columnName?.diff2 ? `${columnName?.diff2}%` : 'DIff2',
      dataIndex: 'diff2',
      key: 'diff2',
      className: 'report-numeric',
    },
    {
      title: 'Detailed',
      dataIndex: 'detailed',
      key: 'detailed',
      className: 'report-numeric',
    },
    {
      title: 'All Clients',
      dataIndex: 'allClients',
      key: 'allClients',
      className: 'report-numeric',
    },
    {
      title: 'Only shift',
      dataIndex: 'onlyShift',
      key: 'onlyShift',
      width: isMobile && 90,
      className: 'report-numeric',
    },
  ]

  const columns = [
    {
      title: 'Rollover Tracking',
      className: 'report-title main-title',
      children: [
        {
          title: 'Script',
          dataIndex: 'Script',
          key: 'script',
          className: 'report-text',
        },
        {
          title: '5%',
          dataIndex: 'perc5',
          key: 'perc5',
          className: 'report-numeric',
        },
        {
          title: '10%',
          dataIndex: 'perc10',
          key: 'perc10',
          className: 'report-numeric',
        },
        {
          title: '20%',
          dataIndex: 'perc20',
          key: 'perc20',
          className: 'report-numeric',
        },
      ],
    },
  ]

  const columns2 = [
    {
      title: 'cmp',
      dataIndex: 'cmp',
      key: 'cmp',
      className: 'report-numeric',
    },
    {
      title: 'leve',
      dataIndex: 'leve',
      key: 'leve',
      className: 'report-numeric',
    },
    {
      title: 'option_type',
      dataIndex: 'option_type',
      key: 'option_type',
      className: 'report-numeric',
    },
    {
      title: 'shift1',
      dataIndex: 'shift1',
      key: 'shift1',
      className: 'report-numeric',
    },
    {
      title: 'strike_price',
      dataIndex: 'strike_price',
      key: 'strike_price',
      className: 'report-numeric',
    },
  ]

  const onFinish = (values) => {
    setColumnName(values)
    if (!values.allClients) {
      values.allClients = 0
    } else {
      values.allClients = 1
    }

    if (!values.onlyShift) {
      values.onlyShift = 0
    } else {
      values.onlyShift = 1
    }

    if (!values.detailed) {
      values.detailed = 0
    } else {
      values.detailed = 1
    }

    setFilters({
      where: values,
    })
    setOpen(false)
  }

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  const ClientShift =
    filters?.where?.allClients && filters?.where?.onlyShift ? columns2 : columns

  const handleDownloadPdf = () => {
    exportGraphAsPdf(
      'ROLLOVER-TRACKING-report',
      'ROLLOVER-TRACKING-REPORT-PDF',
      null,
      true
    ).finally(() =>
      console.log(' ====== ROLLOVER-TRACKING-report downloaded ====== ')
    )
  }
  const content = (
    <div style={{ width: '100px' }}>
      <div
        onClick={handleDownloadPdf}
        style={{
          marginBottom: `${isMobile ? '0px' : '10px'}`,
          fontSize: '14px',
          fontWeight: '600',
          lineHeight: '24px',
          textAlign: 'left',
          color: ' #000',
          cursor: 'pointer',
        }}
      >
        <img src={PdfIcon} style={{ marginRight: '10px' }} alt='pdf' /> PDF
      </div>

      <CSVLink
        style={{ paddingRight: 0 }}
        data={data.rollOver}
        className='csv-btn'
        filename={`ROLLOVER-TRACKING-report`}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          style={{
            marginBottom: `${isMobile ? '0px' : '10px'}`,
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '24px',
            textAlign: 'left',
            color: ' #000',
            cursor: 'pointer',
          }}
        >
          <img src={ExcelIcon} style={{ marginRight: '10px' }} alt='excel' />{' '}
          Excel
        </div>
      </CSVLink>
    </div>
  )

  return (
    <>
      {/* {!error && rollOver && (
        <DownloadReport
          data={data.rollOver}
          fileName='ROLLOVER-TRACKING-report'
          id='ROLLOVER-TRACKING-REPORT-PDF'
        />
      )} */}
      <Drawer
        title={null}
        headerStyle={{ display: 'none' }}
        closable={false}
        placement='right'
        onClose={onClose}
        visible={open}
        width={472}
      >
        <div className='Filter_Form'>
          <div onClick={onClose}>
            <img src={FilterBackButton} alt='FilterBackButton' />
          </div>
          <div className='filter_panel_heading'>Filter</div>
        </div>
        <Form
          name='advanced_search'
          style={{ marginTop: '16px' }}
          layout='vertical'
          className='client-detail-form'
          form={form}
          onFinish={onFinish}
          initialValues={{
            diff: 20,
            diff1: 10,
            diff2: 5,
            onlyShift: true,
            detailed: true,
            clientCode: !isClient
              ? usersData[0]?.userName
              : `${currentUser?.clientCode}-${currentUser?.firstName} ${currentUser?.lastName}`,
          }}
        >
          <div className='filter_Form_div_Container'>
            <Form.Item
              hasFeedback
              name='clientCode'
              label='Select Users'
              rules={[{ required: true, message: 'Please select users!' }]}
            >
              {!isClient ? (
                <Select
                  allowClear
                  getPopupContainer={(trigger) => trigger.parentElement}
                  showSearch
                  filterOption={true}
                  optionFilterProp='title'
                  placeholder='Please select users'
                  loading={userLoading}
                >
                  {!userLoading &&
                    usersData.length &&
                    orderBy(usersData, 'id', 'desc').map((user, key) => {
                      return (
                        user.clientCode && (
                          <>
                            <Option
                              key={key}
                              value={user.clientCode}
                              title={`${user.clientCode} - ${getName(user)}`}
                            >
                              {user.clientCode} - {getName(user)}
                            </Option>
                          </>
                        )
                      )
                    })}
                </Select>
              ) : (
                <Input name='clientCode' readOnly />
              )}
            </Form.Item>

            {/* <Col xs={24} sm={24} md={6} lg={6} xl={6} style={{ padding: '0 3rem' }}></Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6} style={{ padding: '0 3rem' }}></Col> */}

            <Form.Item
              name='diff'
              label='Diff'
              rules={[{ required: true, message: 'Please Select Diff!' }]}
            >
              <InputNumber
                formatter={(value) => `${value}%`}
                placeholder='Enter Diff'
                style={{ width: '100%' }}
              />
            </Form.Item>

            <Form.Item
              name='diff1'
              label='Diff1'
              rules={[{ required: true, message: 'Please Select Diff1!' }]}
            >
              <InputNumber
                formatter={(value) => `${value}%`}
                placeholder='Enter Diff1'
                style={{ width: '100%' }}
              />
            </Form.Item>

            <Form.Item
              name='diff2'
              label='Diff2'
              rules={[{ required: true, message: 'Please Select Diff2!' }]}
            >
              <InputNumber
                formatter={(value) => `${value}%`}
                placeholder='Enter Diff2'
                style={{ width: '100%' }}
              />
            </Form.Item>
            <div style={{ position: 'relative', height: '45px' }}>
              <Form.Item
                valuePropName='checked'
                name='detailed'
                label='Detailed'
                className='form_Checkbox_label'
              >
                <div
                  style={{ position: 'absolute', bottom: '41px', left: '80px' }}
                >
                  <Checkbox />
                </div>
              </Form.Item>
            </div>
            <div style={{ position: 'relative', height: '45px' }}>
              <Form.Item
                valuePropName='checked'
                name='allClients'
                label='All Clients'
              >
                <div
                  style={{ position: 'absolute', bottom: '41px', left: '80px' }}
                >
                  <Checkbox />
                </div>
              </Form.Item>
            </div>
            <div style={{ position: 'relative', height: '45px' }}>
              <Form.Item
                valuePropName='checked'
                name='onlyShift'
                label='Only Shift'
              >
                <div
                  style={{ position: 'absolute', bottom: '41px', left: '80px' }}
                >
                  <Checkbox />
                </div>
              </Form.Item>
            </div>
          </div>
          <div className='filter_panel_button'>
            <Button
              onClick={() => {
                form.resetFields()
                setFilters({
                  where: {
                    allClients: 0,
                    clientCode: '',
                    detailed: 1,
                    diff: 20,
                    diff1: 10,
                    diff2: 5,
                    onlyShift: 1,
                  },
                })
              }}
            >
              Cancel
            </Button>
            <Button loading={loading} type='primary' htmlType='submit'>
              Search
            </Button>
          </div>
        </Form>
      </Drawer>
      {error ? (
        <Page404 error={error} isShow={false} />
      ) : (
        <div className='report_Container'>
          <div className='container_Header'>
            <div className='report_Heading'>Rollover Tracking</div>

            <div className='container_Export_Popover'>
              {!error && data.rollOver ? (
                <Popover content={content} placement='bottom' trigger='click'>
                  <div className='container_Export_Popover'>
                    <Button>
                      Export{' '}
                      <DownloadOutlined
                        color='#AF0000'
                        style={{ fontSize: '16px' }}
                      />
                    </Button>
                  </div>
                </Popover>
              ) : (
                ''
              )}

              <Button
                style={{
                  color: '#000000',
                  border: '1px solid #919EAB52',
                  backgroundColor: 'white',
                }}
                onClick={showDrawer}
              >
                Filter{' '}
                <FilterOutlined color='#000000' style={{ fontSize: '16px' }} />
              </Button>
            </div>
          </div>

          <div
            className='report-table-wrapper'
            id='ROLLOVER-TRACKING-REPORT-PDF'
          >
            <RolloverTrackingTable
              columns={newColumns}
              reportData={filters?.where ? [filters?.where] : []}
            />
            <RolloverTrackingTable
              reportData={rollOver || []}
              columns={ClientShift}
              loading={loading}
            />
          </div>
        </div>
      )}
    </>
  )
}
