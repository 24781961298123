import { Button, Form, Input, Select, Drawer, Popover } from 'antd'
import React, { useState, useContext, useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { get, isEmpty } from 'lodash'
import _map from 'lodash/map'
import client from 'apollo'
import { FilterOutlined, DownloadOutlined } from '@ant-design/icons'

import '../index.css'
import NoData from 'components/NoData'
import { AppContext } from 'AppContext'
import Page404 from 'components/Page404'
import Loader from 'components/loaders/Loader'
import history from 'CustomHistory'
import { GET_STRATEGY_LIST } from 'modules/Settings/graphql/Queries'
import { SP_CURRENT_POSITIONEQ } from '../graphql/Queries'
// import DownloadReport from '../DownloadReport'
import PositionEQTable from './PositionEQTable'
import FilterBackButton from 'assets/images/new-images/filter-back-button.svg'
import PdfIcon from 'assets/images/new-images/pdficon.svg'
import ExcelIcon from 'assets/images/new-images/excelIcon.svg'
import { exportGraphAsPdf } from 'utils/Graph'
import { CSVLink } from 'react-csv'
import { GET_CLIENT_USERS_FOR_STAFF } from 'modules/Users/graphql/Queries'
import { getName } from 'utils/User'

const { Option } = Select

export default function () {
  const [form] = Form.useForm()
  const [strategyList, setStrategyList] = useState([])
  const [open, setOpen] = useState(false)
  const {
    state: {
      isClient,
      isStaff,
      isAdmin,
      currentUser: { clientCode },
      isMobile,
    },
    dispatch,
  } = useContext(AppContext)
  const [filters, setFilters] = useState({
    clientCode: isAdmin || isStaff ? '' : clientCode,
    strategy: '',
    script: '',
  })
  const showFilters = get(history, 'location.pathname') === '/' && !isClient
  const [selectedClientName, setSelectedClientName] = useState('')
  useEffect(() => {
    dispatch({ type: 'SELECTED_GROUP', data: undefined })
    dispatch({ type: 'SELECTED_STRATEGY', data: 'ILTS' })
  }, [dispatch, showFilters])
  const { data: allClient, loading: userLoading } = useQuery(
    GET_CLIENT_USERS_FOR_STAFF,
    {
      variables: { where: {}, limit: 10000 },
      fetchPolicy: 'network-only',
    }
  )
  useEffect(() => {
    client
      .query({ query: GET_STRATEGY_LIST, fetchPolicy: 'network-only' })
      .then((res) => {
        setStrategyList(res.data.getStrategyList)
      })
  }, [])

  const { data, loading, error } = useQuery(SP_CURRENT_POSITIONEQ, {
    variables: { where: filters },
  })

  if (loading) return <Loader />
  if (error) return <Page404 error={error} />
  if (!get(data, 'spCurrentPositionEQ')) return <NoData />

  let currentPositionEQBalanceData
  const { spCurrentPositionEQ } = data
  currentPositionEQBalanceData = spCurrentPositionEQ.map((EqBalance) => {
    return {
      ...EqBalance,
      qty: Math.round(EqBalance.qty),
      Value: Math.round(EqBalance.Value),
      NetPrice: Math.round(EqBalance.NetPrice),
    }
  })

  const columns = [
    {
      title: 'EQ Position Report',
      className: 'report-title main-title',
      children: [
        {
          title: 'Client Code',
          dataIndex: 'ClientCode',
          key: 'ClientCode',
          width: isMobile && 80,
          className: 'report-text',
        },
        {
          title: 'Strategy Code',
          dataIndex: 'StrategyCode',
          key: 'StrategyCode',
          width: isMobile && 100,
          className: 'report-numeric',
        },
        {
          title: 'Script',
          dataIndex: 'script',
          key: 'script',
          width: isMobile ? 150 : '15%',
          className: 'report-numeric',
        },
        {
          title: 'qty',
          dataIndex: 'qty',
          key: 'qty',
          width: isMobile ? 100 : '15%',
          className: 'report-numeric',
        },
        {
          title: 'Value',
          dataIndex: 'Value',
          key: 'Value',
          width: '20%',
          className: 'report-numeric',
        },
        {
          title: 'Net Price',
          dataIndex: 'NetPrice',
          key: 'NetPrice',
          width: isMobile && 100,
          className: 'report-numeric',
        },
      ],
    },
  ]

  const onFinish = (values) => {
    setFilters({
      ...values,
      clientCode: selectedClientName,
      strategy: values.strategy ? values.strategy : '',
      script: values.script ? values.script : '',
    })
    setOpen(false)
  }

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  const handleDownloadPdf = () => {
    exportGraphAsPdf(
      'current-positionEQ',
      'SP_CURRENT_POSITIONFO_PDF',
      null,
      true
    ).finally(() =>
      console.log(' ====== current-positionEQ downloaded ====== ')
    )
  }
  const content = (
    <div style={{ width: '100px' }}>
      <div
        onClick={handleDownloadPdf}
        style={{
          marginBottom: `${isMobile ? '0px' : '10px'}`,
          fontSize: '14px',
          fontWeight: '600',
          lineHeight: '24px',
          textAlign: 'left',
          color: ' #000',
          cursor: 'pointer',
        }}
      >
        <img src={PdfIcon} style={{ marginRight: '10px' }} alt='pdf' /> PDF
      </div>

      <CSVLink
        style={{ paddingRight: 0 }}
        data={currentPositionEQBalanceData}
        className='csv-btn'
        filename={`current-positionEQ`}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          style={{
            marginBottom: `${isMobile ? '0px' : '10px'}`,
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '24px',
            textAlign: 'left',
            color: ' #000',
            cursor: 'pointer',
          }}
        >
          <img src={ExcelIcon} style={{ marginRight: '10px' }} alt='excel' />{' '}
          Excel
        </div>
      </CSVLink>
    </div>
  )

  return (
    <>
      {/* <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          margin: 0,
        }}
      >
        {!error && (
          <DownloadReport
            data={currentPositionEQBalanceData}
            fileName='current-positionEQ'
            id='SP_CURRENT_POSITIONFO_PDF'
          />
        )}
      </div> */}

      <Drawer
        title={null}
        headerStyle={{ display: 'none' }}
        closable={false}
        placement='right'
        onClose={onClose}
        visible={open}
        width={472}
      >
        <div className='Filter_Form'>
          <div onClick={onClose}>
            <img src={FilterBackButton} alt='FilterBackButton' />
          </div>
          <div className='filter_panel_heading'>Filter</div>
        </div>

        <Form
          form={form}
          onFinish={onFinish}
          name='advanced_search'
          style={{ marginTop: '16px' }}
          layout='vertical'
          className='client-detail-form'
        >
          <div className='filter_Form_div_Container'>
            <Form.Item
              name='clientCode'
              label='Select Client Code'
              rules={[{ required: true, message: 'Please select clientCode!' }]}
              hasFeedback
            >
              <Select
                style={{ width: '100%' }}
                getPopupContainer={(trigger) => trigger.parentElement}
                className='display-field'
                showSearch
                loading={userLoading}
                placeholder='Select Client Code'
                optionFilterProp='children'
                filterOption={(input, option) =>
                  (option?.children[0] ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase()) ||
                  (option?.children[2] ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onChange={(value, option) => {
                  setSelectedClientName(value)
                }}
              >
                {!userLoading &&
                  allClient &&
                  allClient?.getClientUsersForStaff?.users.map((user, key) => {
                    return (
                      <Option
                        key={key}
                        value={user?.clientCode}
                        title={getName(user)}
                      >
                        {user?.clientCode}-{getName(user)}
                      </Option>
                    )
                  })}
              </Select>
            </Form.Item>

            <Form.Item name='script' label='script'>
              <Input placeholder='Enter script' style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item name='strategy' label='strategy'>
              <Select
                // style={{ width: 80 }}
                getPopupContainer={(trigger) => trigger.parentElement}
                className='gx-mr-3 gx-select-sm'
                onChange={(e) =>
                  dispatch({ type: 'SELECTED_STRATEGY', data: e })
                }
              >
                {_map(strategyList, (strategy, key) => {
                  const { StrategyName, StrategyCode } = strategy
                  return (
                    <Option value={StrategyCode} key={key}>
                      {StrategyName}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
          </div>
          <div className='filter_panel_button'>
            <Button
              onClick={(values) => {
                form.resetFields()
                setFilters({})
                setOpen(false)
              }}
            >
              Clear
            </Button>
            <Button type='primary' htmlType='submit'>
              Search
            </Button>
          </div>
        </Form>
      </Drawer>

      {error ? (
        <Page404 error={error} isShow={false} />
      ) : (
        <div className='report_Container'>
          <div className='container_Header'>
            <div className='report_Heading'>Position EQ</div>

            <div className='container_Export_Popover'>
              {!error ? (
                <Popover content={content} placement='bottom' trigger='click'>
                  <div className='container_Export_Popover'>
                    <Button>
                      Export{' '}
                      <DownloadOutlined
                        color='#AF0000'
                        style={{ fontSize: '16px' }}
                      />
                    </Button>
                  </div>
                </Popover>
              ) : (
                ''
              )}

              <Button
                style={{
                  color: '#000000',
                  border: '1px solid #919EAB52',
                  backgroundColor: 'white',
                }}
                onClick={showDrawer}
              >
                Filter{' '}
                <FilterOutlined color='#000000' style={{ fontSize: '16px' }} />
              </Button>
            </div>
          </div>
          {!isEmpty(filters) && (
            <div
              className='report-table-wrapper'
              id='SP_CURRENT_POSITIONFO_PDF'
            >
              <PositionEQTable
                filters={filters}
                data={currentPositionEQBalanceData}
                columns={columns}
                loading={loading}
              />
            </div>
          )}
        </div>
      )}
    </>
  )
}
