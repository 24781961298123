import React, { Fragment, useContext, useEffect, useState } from 'react'
import LogoutIcon from 'assets/images/new-images/logout.svg'
import {
  Breadcrumb,
  Button,
  Col,
  Layout,
  Popover,
  Row,
  Select,
  Tag,
} from 'antd'
import { AppContext } from 'AppContext'
import {
  EyeOutlined,
  QuestionCircleOutlined,
  UserOutlined,
} from '@ant-design/icons'
import HomeNewIcon from 'assets/images/new-images/homeIcon.svg'
import client from 'apollo'
import _omit from 'lodash/omit'
import _map from 'lodash/map'
import _get from 'lodash/get'
// import NotificationIcon from 'assets/images/new-images/notification.svg'

import { GET_STRATEGY_LIST } from 'modules/Settings/graphql/Queries'
import { GET_GROUPS } from 'modules/Groups/graphql/Queries'
import history from 'CustomHistory'
import openNotification from 'utils/Notification'
import { CHANGE_PASSWORD } from 'modules/Users/graphql/Mutations'
import ChangePassword from 'components/ChangePassword'

const { Header } = Layout
const { Option } = Select

const HeaderNew = () => {
  const [groups, setGroups] = useState([])
  const [strategyList, setStrategyList] = useState([])
  const [visibleMenu, setVisibleMenu] = useState(false)
  const [visible, setVisible] = useState(false)
  const {
    dispatch,
    state: {
      showFAQScreen,
      currentUser,
      clientRiskScore,
      MaintenanceStatus: { type, message, isActive },
      isClient,
      isMobile,
    },
  } = useContext(AppContext)

  const IndividualSteps = {
    SELECT_USER_TYPE: 'Client Basic Details',
    LOGIN_CREATED: 'Select User Type',
    CLIENT_PROFILE_1: 'Client Profile',
    CLIENT_PROFILE_2: 'Risk Profile Assessment',
    RISK_PROFILE: 'Summary',
    SUMMARY: 'Kyc Document',
    KYC_DOC: 'Kyc E-Sign',
    KYC_FORM: 'Investment Planning',
    INVESTMENT_PLANNING: 'Agreement Sign',
    AGREEMENT_SIGN: 'E-Mandate Registration',
    E_MANDATE: 'Congratulations !!!!',
    CONGRATULATION: 'Pending Advisory',
    DONE: 'Dashboard',
  }

  const nonIndividualSteps = {
    LOGIN_CREATED: 'Select User Type',
    SELECT_USER_TYPE: 'Client Basic Details',
    CLIENT_PROFILE_1: 'Client Profile',
    CLIENT_PROFILE_2: 'Risk Profile Assessment',
    RISK_PROFILE: 'Summary',
    SUMMARY: 'Investment Planning',
    INVESTMENT_PLANNING: 'Courier Instruction',
    DONE: 'Dashboard',
  }

  const messageStyle = {
    textAlign: 'center',
    fontSize: '15px',
    marginTop: '10px',
    backgroundColor: '#fff',
    height: '20',
  }
  if (type === 'SUCCESS') {
    messageStyle.color = '#5b8c00'
  } else if (type === 'WARNING') {
    messageStyle.color = '#fa8c16'
  } else if (type === 'INFO') {
    messageStyle.color = '#1890ff'
  } else if (type === 'DANGER') {
    messageStyle.color = '#f5222d'
  }

  const showFilters = _get(history, 'location.pathname') === '/' && !isClient

  useEffect(() => {
    dispatch({ type: 'SELECTED_GROUP', data: undefined })
    dispatch({ type: 'SELECTED_STRATEGY', data: 'ILTS' })
  }, [dispatch, showFilters])

  useEffect(() => {
    if (showFilters) {
      client
        .query({ query: GET_STRATEGY_LIST, fetchPolicy: 'network-only' })
        .then((res) => {
          if (_get(res, 'data.getStrategyList')) {
            setStrategyList(_get(res, 'data.getStrategyList'))
          }
        })
    }
  }, [showFilters])

  useEffect(() => {
    if (showFilters) {
      client
        .query({
          query: GET_GROUPS,
          variables: { limit: 300 },
          fetchPolicy: 'network-only',
        })
        .then((res) => {
          if (_get(res, 'data.getGroups.groups')) {
            setGroups(_get(res, 'data.getGroups.groups'))
          }
        })
    }
  }, [showFilters])

  function returnGroups() {
    return _map(groups, (group, key) => {
      const { id, name } = group
      return (
        <Option value={id} key={key}>
          {name}
        </Option>
      )
    })
  }

  const userMenuOptions = (
    <ul className='gx-user-popover'>
      <li style={{ display: 'flex', gap: '10px' }}>
        <UserOutlined
          style={{
            fontSize: '20px',
            display: `${isMobile ? 'flex' : 'none'}`,
            gap: '10px',
          }}
        />
        <div className='header-new-user-info-mobile'>
          <div className='header-new-user-name'>
            {currentUser?.role === 'ADMIN'
              ? currentUser?.firstName
              : !currentUser?.isPanCardIndividual &&
                currentUser?.nameOfApplicant
              ? currentUser?.nameOfApplicant
              : currentUser?.firstName && currentUser?.lastName
              ? `${currentUser?.firstName} ${currentUser?.lastName}`
              : ''}
          </div>
          {currentUser?.clientCode ? (
            <div className='header-new-user-code'>
              {currentUser?.clientCode}
            </div>
          ) : (
            <div className='header-new-user-code'>
              {currentUser?.role?.split('_')?.join(' ')}
            </div>
          )}
        </div>
      </li>
      <hr
        style={{ margin: '5px 0', display: `${isMobile ? 'flex' : 'none'}` }}
      />
      <li
        style={{ display: 'flex', gap: '10px' }}
        onClick={() => {
          setVisibleMenu(false)
          history.push('/profile')
        }}
      >
        <i className='icon icon-avatar' style={{ fontSize: '20px' }}></i>
        <span>Profile</span>
      </li>
      <li
        style={{ display: 'flex', gap: '10px' }}
        onClick={() => {
          setVisibleMenu(false)
          handleVisible()
        }}
      >
        <EyeOutlined style={{ fontSize: '20px' }} /> Change Password
      </li>
      {currentUser?.role === 'CLIENT' ? (
        <li
          style={{ display: `${isMobile ? 'flex' : 'none'}`, gap: '10px' }}
          onClick={() => {
            if (currentUser?.completedSteps !== 'DONE') {
              dispatch({ type: 'SHOW_FAQ_SCREEN', data: true })
            } else {
              history.push('/faqs')
            }
          }}
        >
          <QuestionCircleOutlined
            style={{ fontSize: '20px', height: '26px' }}
          />
          Help
        </li>
      ) : (
        ''
      )}
      <li
        style={{ display: `${isMobile ? 'flex' : 'none'}`, gap: '10px' }}
        onClick={() => (window.location.href = '/logout')}
      >
        <img src={LogoutIcon} style={{ height: '22px' }} alt='LogoutIcon' />{' '}
        Logout
      </li>
    </ul>
  )

  function handleVisible() {
    setVisible(!visible)
  }

  function handleChangePassword(values, resetForm) {
    const queryVariables = _omit(values, 'confirm')
    client
      .mutate({ mutation: CHANGE_PASSWORD, variables: queryVariables })
      .then((res) => {
        openNotification('success', 'Password Changed Successfully')
        handleVisible()
        resetForm()
        history.push('/logout')
      })
      .catch((err) => console.log(err))
  }

  return (
    <Fragment>
      <Header
        className='header-new'
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            height: `${isMobile ? 'auto' : '100%'}`,
            alignItems: 'center',
            width: '100%',
          }}
        >
          {isClient && currentUser?.completedSteps !== 'DONE' ? (
            <div
              className={`${
                showFAQScreen
                  ? 'header-new-text-with-tag-description'
                  : 'header-new-text-with-tag'
              }`}
            >
              <div className='header-new-title'>
                {currentUser?.completedSteps === 'DONE'
                  ? window?.location?.pathname.replace('/', '')
                  : currentUser?.completedSteps === null
                  ? 'Disclaimer'
                  : showFAQScreen
                  ? 'FAQ'
                  : currentUser?.completedSteps === 'E_MANDATE' &&
                    currentUser?.isBookingAdvisoryDateSelected
                  ? 'Booked Advisory Slot'
                  : currentUser?.isPanCardIndividual
                  ? IndividualSteps[currentUser?.completedSteps]
                  : nonIndividualSteps[currentUser?.completedSteps]}
              </div>
              {currentUser?.completedSteps === 'E_MANDATE' && showFAQScreen && (
                <div className='header-new-subtitle'>
                  Read some of he top queries asked by our customers
                </div>
              )}
              {currentUser?.completedSteps === 'CLIENT_PROFILE_2' && (
                <div className='user-score-status'>
                  <Tag
                    defaultValue={clientRiskScore}
                    color='red'
                    className='red-tag'
                  >
                    <div className='risk-score-div'>
                      <span className='risk-score-text'>Risk Score </span>
                      <span className='risk-score-total'>
                        {clientRiskScore}
                      </span>
                    </div>
                  </Tag>
                  <Tag
                    className={`${
                      currentUser?.cvlStatus ? 'green-tag' : 'red-tag'
                    }`}
                    // style={{
                    //   padding: '16px',
                    // }}
                  >
                    <div className='risk-score-div'>
                      <span
                        className={`${
                          currentUser?.cvlStatus
                            ? 'risk-score-text-green'
                            : 'risk-score-text'
                        }`}
                      >
                        PAN KYC Status
                      </span>{' '}
                      <span
                        className={`${
                          currentUser?.cvlStatus
                            ? 'risk-score-total-green'
                            : 'risk-score-total'
                        }`}
                      >
                        ({currentUser?.cvlStatus ? 'YES' : 'NO'})
                      </span>
                    </div>
                  </Tag>
                </div>
              )}
            </div>
          ) : (
            <Breadcrumb
              separator='>'
              style={{ display: 'flex', flexWrap: 'wrap' }}
            >
              <Breadcrumb.Item>
                <img src={HomeNewIcon} alt='' />
              </Breadcrumb.Item>
              {window.location.pathname === '/' ? (
                <Breadcrumb.Item
                  href=''
                  style={{
                    textTransform: 'capitalize',
                    color: '#3F4254',
                    fontSize: `${isMobile ? '12px' : '14px'}`,
                    fontWeight: '600',
                    lineHeight: '14px',
                    textAlign: 'left',
                  }}
                >
                  Dashboard
                </Breadcrumb.Item>
              ) : (
                window.location.pathname.split('/')?.map((item) => {
                  return (
                    <Breadcrumb.Item
                      href=''
                      style={{
                        textTransform: 'capitalize',
                        color: '#3F4254',
                        fontSize: `${isMobile ? '12px' : '14px'}`,
                        fontWeight: '600',
                        lineHeight: '14px',
                        textAlign: 'left',
                      }}
                    >
                      {item}
                    </Breadcrumb.Item>
                  )
                })
              )}
            </Breadcrumb>
          )}
          <div className='header-new-info'>
            {currentUser?.role !== 'CLIENT' ? (
              <div
                style={{
                  display: `${isMobile ? 'none' : 'flex'}`,
                  gap: '10px',
                }}
              >
                {showFilters && (
                  <Select
                    getPopupContainer={(trigger) => trigger.parentElement}
                    placeholder='Select Client'
                    className='display-field'
                    onChange={(value) =>
                      dispatch({
                        type: 'SELECTED_GROUP',
                        data: value ? parseInt(value) : undefined,
                      })
                    }
                    style={{ width: 133 }}
                  >
                    <Option value=''>All Clients</Option>
                    {returnGroups()}
                  </Select>
                )}
                {showFilters && (
                  <Select
                    getPopupContainer={(trigger) => trigger.parentElement}
                    defaultValue='1005'
                    style={{ width: 94 }}
                    className='display-field'
                    onChange={(e) =>
                      dispatch({ type: 'SELECTED_STRATEGY', data: e })
                    }
                  >
                    {_map(strategyList, (strategy, key) => {
                      const { StrategyName, StrategyCode } = strategy
                      return (
                        <Option value={StrategyCode} key={key}>
                          {StrategyName}
                        </Option>
                      )
                    })}
                  </Select>
                )}
              </div>
            ) : (
              <div className='faqs-icon-div'>
                <Button
                  style={{ display: `${isMobile ? 'none' : 'flex'}` }}
                  className='faq-btn'
                  onClick={() => {
                    if (currentUser?.completedSteps !== 'DONE') {
                      dispatch({ type: 'SHOW_FAQ_SCREEN', data: true })
                    } else {
                      history.push('/faqs')
                    }
                  }}
                >
                  Help&nbsp;
                </Button>
              </div>
            )}
            <Popover
              placement='bottomRight'
              onVisibleChange={() => setVisibleMenu(!visibleMenu)}
              visible={visibleMenu}
              content={userMenuOptions}
              trigger='click'
              className='header-popover'
            >
              <div className='header-new-user-avatar-container'>
                <div className='header-new-user-avatar'>
                  {currentUser?.role === 'ADMIN'
                    ? currentUser?.firstName[0]
                    : !currentUser?.isPanCardIndividual &&
                      currentUser?.nameOfApplicant?.length
                    ? currentUser?.nameOfApplicant?.split(' ')?.length > 1
                      ? currentUser?.nameOfApplicant[0] +
                        currentUser?.nameOfApplicant?.split(' ')[1][0]
                      : currentUser?.nameOfApplicant[0]
                    : currentUser?.firstName && currentUser?.lastName
                    ? currentUser?.firstName[0] + currentUser?.lastName[0]
                    : '-'}
                </div>
                <div className='header-new-user-info'>
                  <div className='header-new-user-name'>
                    {currentUser?.role === 'ADMIN'
                      ? currentUser?.firstName
                      : !currentUser?.isPanCardIndividual &&
                        currentUser?.nameOfApplicant
                      ? currentUser?.nameOfApplicant
                      : currentUser?.firstName && currentUser?.lastName
                      ? `${currentUser?.firstName} ${currentUser?.lastName}`
                      : ''}
                  </div>
                  {currentUser?.clientCode ? (
                    <div className='header-new-user-code'>
                      {currentUser?.clientCode}
                    </div>
                  ) : (
                    <div className='header-new-user-code'>
                      {currentUser?.role?.split('_')?.join(' ')}
                    </div>
                  )}
                </div>
                <i
                  className='icon icon-chevron-down gx-fs-xxs'
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '15px',
                    color: '#000',
                  }}
                />
              </div>
            </Popover>
            <div
              style={{ display: `${isMobile ? 'none' : 'flex'}` }}
              className='header-new-logout-container'
              onClick={() => (window.location.href = '/logout')}
            >
              <img src={LogoutIcon} alt='LogoutIcon' />
              <div className='header-new-logout-text'>Logout</div>
            </div>
          </div>
        </div>

        {currentUser?.completedSteps === 'CLIENT_PROFILE_2' && (
          <div className='user-score-status-mobile'>
            <Tag defaultValue={clientRiskScore} color='red' className='red-tag'>
              <div className='risk-score-div'>
                <span className='risk-score-text'>Risk Score </span>
                <span className='risk-score-total'>{clientRiskScore}</span>
              </div>
            </Tag>
            <Tag
              className={`${currentUser?.cvlStatus ? 'green-tag' : 'red-tag'}`}
              // style={{
              //   padding: '16px',
              // }}
            >
              <div className='risk-score-div'>
                <span
                  className={`${
                    currentUser?.cvlStatus
                      ? 'risk-score-text-green'
                      : 'risk-score-text'
                  }`}
                >
                  PAN KYC Status
                </span>{' '}
                <span
                  className={`${
                    currentUser?.cvlStatus
                      ? 'risk-score-total-green'
                      : 'risk-score-total'
                  }`}
                >
                  ({currentUser?.cvlStatus ? 'YES' : 'NO'})
                </span>
              </div>
            </Tag>
          </div>
        )}

        {currentUser?.role !== 'CLIENT' && (
          <div
            style={{
              display: `${isMobile ? 'flex' : 'none'}`,
              marginTop: '10px',
              gap: '20px',
            }}
          >
            {showFilters && (
              <Select
                getPopupContainer={(trigger) => trigger.parentElement}
                placeholder='Select Client'
                className='display-field'
                onChange={(value) =>
                  dispatch({
                    type: 'SELECTED_GROUP',
                    data: value ? parseInt(value) : undefined,
                  })
                }
                style={{ width: 133 }}
              >
                <Option value=''>All Clients</Option>
                {returnGroups()}
              </Select>
            )}
            {showFilters && (
              <Select
                getPopupContainer={(trigger) => trigger.parentElement}
                defaultValue='1005'
                style={{ width: 94 }}
                className='display-field'
                onChange={(e) =>
                  dispatch({ type: 'SELECTED_STRATEGY', data: e })
                }
              >
                {_map(strategyList, (strategy, key) => {
                  const { StrategyName, StrategyCode } = strategy
                  return (
                    <Option value={StrategyCode} key={key}>
                      {StrategyName}
                    </Option>
                  )
                })}
              </Select>
            )}
          </div>
        )}
      </Header>
      {visible && (
        <ChangePassword
          visible={visible}
          onCreate={handleChangePassword}
          onCancel={handleVisible}
        />
      )}
      {isClient && isActive && (
        <Row gutter={24}>
          <Col span={24}>
            <div style={messageStyle}>{message}</div>
          </Col>
        </Row>
      )}
    </Fragment>
  )
}

export default HeaderNew
