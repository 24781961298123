import get from 'lodash/get'
import { useQuery } from '@apollo/react-hooks'
import React, { useState, useContext } from 'react'
import { Table, Button, Modal, Tooltip } from 'antd'
import {
  EditTwoTone,
  ExclamationCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons'

import client from 'apollo'
import { AppContext } from 'AppContext'
import Page404 from 'components/Page404'
import { GET_PLANS } from './graphql/Query'
import CreatePlanModal from './CreatePlanModal'
import { UPDATE_PLAN } from './graphql/Mutation'
import openNotification from 'utils/Notification'
import { CREATE_PLAN, DELETE_PLAN } from './graphql/Mutation'

const { confirm } = Modal

const Plans = () => {
  const [showFormModal, setShowFormModal] = useState(false)
  const {
    state: { currentReport, isMobile },
  } = useContext(AppContext)
  const [selectedPlan, setSelectedPlan] = useState(undefined)

  const { loading, error, data } = useQuery(GET_PLANS, {
    fetchPolicy: 'network-only',
  })

  let allPlans
  if (error) return <Page404 error={error} />
  if (!loading && get(data, 'getPlans')) {
    allPlans = data.getPlans.map((plan, key) => ({
      key: key.toString(),
      ...plan,
    }))
  }

  function handleUpdatePlan(values, resetForm) {
    const data = values
    const { id } = selectedPlan
    client
      .mutate({
        mutation: UPDATE_PLAN,
        variables: { data, id },
        refetchQueries: [{ query: GET_PLANS }],
      })
      .then((res) => {
        openNotification('success', 'Plan Updated Successfully')
        handleHideFormModal()
        resetForm()
      })
      .catch((err) => console.log(err))
  }

  function handleHideFormModal() {
    setShowFormModal(false)
    setSelectedPlan(undefined)
  }

  function showConfirm(id) {
    confirm({
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
      title: `Do you want to delete this plan?`,
      content: `When clicked the OK button, this plan will be Deleted`,
      onOk() {
        client
          .mutate({
            mutation: DELETE_PLAN,
            variables: { id },
            refetchQueries: [{ query: GET_PLANS }],
          })
          .catch((err) => console.log(err))
        openNotification('success', 'Plan Deleted Successfully')
      },
    })
  }

  function renderAction(record) {
    return (
      <div className='action-icons'>
        {currentReport.includes('Update Plan') && (
          <Tooltip title='Edit'>
            <EditTwoTone
              onClick={() => {
                setShowFormModal(true)
                setSelectedPlan(record)
              }}
            />
          </Tooltip>
        )}
        {
          // currentReport.includes('Delete Plan') &&
          <Tooltip title='Delete Plan'>
            <DeleteOutlined onClick={() => showConfirm(record.id)} />
          </Tooltip>
        }
      </div>
    )
  }

  function handleCreatePlan(values, resetForm) {
    const data = values
    client
      .mutate({
        mutation: CREATE_PLAN,
        variables: { data },
        refetchQueries: [{ query: GET_PLANS }],
      })
      .then((res) => {
        openNotification('success', 'Plan Added Successfully')
        handleHideFormModal()
        resetForm()
      })
      .catch((err) => console.log(err))
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 50,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 170,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '110px',
    },
    {
      title: 'MinPoints',
      dataIndex: 'minPoints',
      key: 'minPoints',
      width: '110px',
    },
    {
      title: 'MaxPoints',
      dataIndex: 'maxPoints',
      key: 'maxPoints',
      width: '110px',
    },
    {
      title: 'Percentage',
      dataIndex: 'percentage',
      key: 'percentage',
      width: '110px',
    },
    {
      title: 'Stock',
      dataIndex: 'stock',
      key: 'stock',
      width: '110px',
    },
    {
      title: 'Sip',
      dataIndex: 'sip',
      key: 'sip',
      width: '110px',
    },
    {
      title: 'Pnl On',
      dataIndex: 'pnlOn',
      key: 'pnlOn',
      width: '110px',
    },
    {
      title: 'Interest From Debt',
      dataIndex: 'interestFromDebt',
      key: 'interestFromDebt',
      width: '160px',
    },
    {
      title: 'Features',
      dataIndex: 'features',
      key: 'features',
      width: '110px',
    },
    {
      title: 'Plan Risk Type',
      dataIndex: 'planRiskType',
      key: 'planRiskType',
      width: '130px',
    },
    {
      title: 'Plan Risk',
      dataIndex: 'planRisk',
      key: 'planRisk',
      width: '400px',
    },
    {
      title: 'Min Risk',
      dataIndex: 'minRisk',
      key: 'minRisk',
      width: '110px',
    },
    {
      title: 'Max Risk',
      dataIndex: 'maxRisk',
      key: 'maxRisk',
      width: '110px',
    },
    {
      title: 'T Cost',
      dataIndex: 'tCost',
      key: 'tCost',
      width: '110px',
    },
    {
      title: 'Risk Appetite',
      dataIndex: 'riskAppetite',
      key: 'riskAppetite',
      width: '200px',
    },
    {
      title: 'Action',
      key: 'action',
      width: '110px',
      render: (text, record) => renderAction(record),
    },
  ]

  return (
    <>
      {showFormModal && (
        <CreatePlanModal
          visible={showFormModal}
          onCreate={handleCreatePlan}
          onUpdate={handleUpdatePlan}
          selectedPlan={selectedPlan}
          isEdit={selectedPlan !== undefined}
          onCancel={() => handleHideFormModal()}
        />
      )}
      <div className='main_dashboard_div'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
          }}
        >
          <div
            style={{
              fontSize: isMobile ? '18px' : '24px',
              fontWeight: '600',
              lineHeight: '26px',
              letterSpacing: '-0.02em',
              textAlign: 'left',
              color: '#181C32',
            }}
          >
            Plans
          </div>
          <div style={{ display: 'flex', gap: '10px' }}>
            {currentReport?.includes('Create Plan') ? (
              <Button
                onClick={() => setShowFormModal(true)}
                style={{
                  margin: '0',
                  padding: isMobile ? '4px 10px' : '8px 16px',
                  fontSize: '14px',
                  fontWeight: '600',
                  lineHeight: '24px',
                  textAlign: 'left',
                  color: '#AF0000',
                  background: '#AF00000D',
                  border: '1px solid #AF000080',
                  height: isMobile ? '35px' : '40px',
                  borderRadius: '8px',
                }}
              >
                Add Plan
              </Button>
            ) : (
              ''
            )}
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={allPlans}
          scroll={{ x: true, y: true }}
        />
      </div>
    </>
  )
}

export default Plans
