import gql from 'graphql-tag'

export const GET_ALL_FIELD_FOR_LOG = gql`
  query getAllFieldsForLog {
    getAllFieldsForLog
  }
`

export const EXPORT_CLIENT_LOGS = gql`
  query exportLogs(
    $where: LogWhereUniqueInput
    $skip: Int
    $limit: Int
    $sort: SortInput
  ) {
    exportLogs(where: $where, skip: $skip, limit: $limit, sort: $sort)
  }
`

export const GET_ALL_LOGS = gql`
  query getAllLogs(
    $where: LogWhereUniqueInput!
    $skip: Int
    $limit: Int
    $sort: SortInput
  ) {
    getAllLogs(where: $where, skip: $skip, limit: $limit, sort: $sort) {
      log {
        id
        client {
          id
          firstName
          lastName
          email
        }
        fieldName
        oldData
        newData
        createdAt
        updatedAt
        updatedByUser {
          userName
          firstName
          lastName
          role
        }
      }
      count
      total
    }
  }
`
