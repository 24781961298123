import React from 'react'
import { Modal, Form, Input, Button } from 'antd'

const CollectionCreateForm = ({
  visible,
  onCreate,
  onUpdate,
  onCancel,
  isEdit,
  section = {},
}) => {
  const [form] = Form.useForm()

  function resetForm() {
    form.resetFields()
  }

  return (
    <Modal
      maskClosable={false}
      visible={visible}
      title={isEdit ? 'Edit Section' : 'Add New Section'}
      okText={isEdit ? 'Update Section' : 'Add Section'}
      cancelText='Cancel'
      onCancel={() => {
        resetForm()
        onCancel()
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            const data = {
              ...values,
            }
            if (isEdit) {
              onUpdate(data, resetForm)
            } else {
              onCreate(data, resetForm)
            }
          })
          .catch((info) => console.log('Validate Failed:', info))
      }}
    >
      <Form
        form={form}
        layout='vertical'
        name='form_in_modal'
        initialValues={{
          ...section,
          modifier: 'public',
        }}
      >
        <Form.Item
          name='section'
          label='Section'
          rules={[
            { required: true, message: 'Section is require', whitespace: true },
          ]}
          hasFeedback
        >
          <Input />
        </Form.Item>
        <div className='footer-btn-div'>
          <Button
            className='footer-cancel-btn'
            onClick={() => {
              resetForm()
              onCancel()
            }}
          >
            Cancel
          </Button>
          <Button
            className='footer-submit-btn'
            onClick={() => {
              form
                .validateFields()
                .then((values) => {
                  const data = {
                    ...values,
                  }
                  if (isEdit) {
                    onUpdate(data, resetForm)
                  } else {
                    onCreate(data, resetForm)
                  }
                })
                .catch((info) => console.log('Validate Failed:', info))
            }}
            type='primary'
          >
            {isEdit ? 'Update Section' : 'Add Section'}
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

const CreateSectionModal = (props) => {
  return (
    <div>
      <CollectionCreateForm {...props} />
    </div>
  )
}

export default CreateSectionModal
