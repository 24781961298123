import React, { useContext } from 'react'
import { Button, Progress } from 'antd'
import './index.css' // Assuming this file contains global styles
import history from 'CustomHistory'
import { AppContext } from 'AppContext'

const PortfolioNew = (props) => {
  const {
    type = 'SIP',
    Completed,
    CompletedPer,
    Pending,
    PendingPer,
    isClient,
  } = props
  const {
    state: { isMobile },
  } = useContext(AppContext)

  return (
    <div
      className='portfolio-new-container'
      style={{
        padding: isMobile ? '10px' : '30px',
      }}
    >
      <div className='portfolio-summary-header'>
        <div className='summary-title'>
          <div className='summary-title-text'>{type} Summary</div>
          <div className='summary-total-amount'>
            ₹{props[`Total${type}`] ? Math.round(props[`Total${type}`]) : 0}
          </div>
          <div className='summary-label'>Total Amount</div>
        </div>
        <div className='view-all-button'>
          <Button
            className='view-all-btn'
            onClick={() =>
              history.push(`${isClient ? '/' : ''}${type.toLowerCase()}`)
            }
          >
            View All
          </Button>
        </div>
      </div>

      <div className='summary-item'>
        <div className='summary-item-details'>
          <div className='summary-item-title'>
            <span className='paid-label'>Paid |</span> ₹{Completed || 0}
          </div>
          <div className='summary-item-percentage paid-percentage'>
            {CompletedPer ? `${CompletedPer.toFixed(2)}%` : `0%`}
          </div>
        </div>
        <div className='summary-progress-success'>
          <Progress
            percent={CompletedPer ? CompletedPer.toFixed(2) : 0}
            showInfo={false}
          />
        </div>
      </div>

      <div className='summary-item'>
        <div className='summary-item-details'>
          <div className='summary-item-title'>
            <span className='pending-label'>Pending |</span> ₹{Pending || 0}
          </div>
          <div className='summary-item-percentage pending-percentage'>
            {PendingPer ? `${PendingPer.toFixed(2)}%` : `0%`}
          </div>
        </div>
        <div className='summary-progress-primary'>
          <Progress
            percent={PendingPer ? PendingPer.toFixed(2) : 0}
            showInfo={false}
          />
        </div>
      </div>
    </div>
  )
}

export default PortfolioNew
