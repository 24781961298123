import React, { useContext } from 'react'
import { AppContext } from 'AppContext'
import { Button, Col, Row } from 'antd'
import KeyInfo from './KeyInfo'
import CongratulationsImage from 'assets/images/new-images/congratulations.png'

export default function CourierPageNew({ newEdit }) {
  const { state , isMobile } = useContext(AppContext)
  const { kyc } = state?.currentUser

  return (
    <>
      <Row className='row-style'>
        <Col lg={16} sm={24} className='col-left risk-profile-col'>
          <div className='congratulations_page'>
            <div className='hand-shake-image'>
              <img
                src={CongratulationsImage}
                alt='HandShakeImage'
                width={350}
                height={200}
              />
            </div>

            <div className='congratulations_sub_div'>
              Kindly Courier all these documents signed and stamped to the below
              address:
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div
                style={{
                  display: 'grid',
                  justifyContent: `${isMobile ? 'start': 'center'}`,
                  marginTop: '16px',
                  width: '370px',
                }}
              >
                <div
                  style={{
                    fontSize: `${isMobile ? '16px' :'20px'}`,
                    fontWeight: '700',
                    lineHeight: '23.5px',
                    textAlign: `${isMobile ? 'start': 'center'}`,
                    color: '#000',
                  }}
                >
                  Finideas Investment Advisor Pvt. Ltd.
                </div>
                <div
                  style={{
                    fontSize: `${isMobile ? '16px' :'20px'}`,
                    fontWeight: '700',
                    lineHeight: '23.5px',
                    textAlign: `${isMobile ? 'start': 'center'}`,
                    color: '#000',
                    marginTop: '15px',
                  }}
                >
                  1002, Luxuria Business Hub, Near VR Mall, Gaurav Path Road,
                  Surat - 395007
                </div>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '15px',
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '18px',
                color: '#AF0000',
              }}
            >
              If You forgot to download your agreement, Download from here
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: `${isMobile ? 'end': 'center'}`,
                marginTop: '16px',
              }}
            >
              <Button
                type='primary'
                style={{ borderRadius: '8px' }}
                onClick={() =>
                  window.open(
                    kyc?.agreementForNonIndividual &&
                      kyc?.agreementForNonIndividual,
                    '_blank'
                  )
                }
              >
                Download Document
              </Button>
            </div>
          </div>
        </Col>
        <Col lg={8} sm={24} className='col-right risk-profile-col'>
          <KeyInfo newEdit={newEdit} />
        </Col>
      </Row>
    </>
  )
}
