import React from 'react'
import get from 'lodash/get'
import { userDocuments } from 'utils/User'
import AboutItem from 'components/About/AboutItem'

export default function (props) {
  const { subject, description, fileUrl, reason, status, Overview } = props
  let documentList
  if (fileUrl) {
    documentList = userDocuments(fileUrl)
  }

  return (
    <div>
      {reason && status === 'CLOSED' && !Overview ? (
        <>
          <span className='ant-card-head-title gx-mb-2'>Closing Summary</span>
          <p>{reason}</p>
        </>
      ) : (
        <>
          <span className='ant-card-head-title gx-mb-2'>{subject}</span>
          <p>{description}</p>
          {fileUrl && get(documentList, 'length') && (
            <AboutItem {...documentList[0]} title='Attachment' />
          )}
        </>
      )}
    </div>
  )
}
