import React, { useContext, useEffect, useState } from 'react'
import { Progress, Steps } from 'antd'
import client from 'apollo'
import { useHistory } from 'react-router-dom'

import './verification.css'
import { AppContext } from 'AppContext'
import { UPDATE_USER } from 'modules/Users/graphql/Mutations'
import ClientProfileNon1New from './ClientProfileNon1New'
import RiskProfileNew from './RiskProfileNew'
import SummaryNew from './SummaryNew'
import InvestmentPlanningNew from './InvestmentPlanningNew'
import RiskProfileFormNew from './RiskProfileFormNew'
import SelectTypeFormNew from './SelectTypeFormNew'
import CourierPageNew from './CourierPageNew'
import ClientProfile1New from './ClientProfile1New'
import ClientProfile2New from './ClientProfile2New'
import ClientProfileNon2New from './ClientProfileNon2New'
import FAQsModelNew from './Model/FAQsModalNew'

const { Step } = Steps

const Verification = () => {
  const history = useHistory()
  let {
    dispatch,
    state: { currentUser, showFAQScreen },
  } = useContext(AppContext)
  const { state } = useContext(AppContext)
  const { completedSteps, isPanCardIndividual } = state?.currentUser

  useEffect(() => {
    window.location.hash = 'no-back-button'
    window.location.hash = 'Again-No-back-button'
    window.onhashchange = function () {
      window.location.hash = 'no-back-button'
    }
  }, [])

  const stepsIndexList = {
    LOGIN_CREATED: 1,
    SELECT_USER_TYPE: 2,
    CLIENT_PROFILE_1: 3,
    CLIENT_PROFILE_2: 4,
    RISK_PROFILE: 5,
    SUMMARY: 6,
    INVESTMENT_PLANNING: 7,
    COURIER_INSTRUCTION: 8,
  }
  let tempCurrent = 0
  switch (completedSteps) {
    case 'LOGIN_CREATED':
      tempCurrent = 1
      break
    case 'SELECT_USER_TYPE':
      tempCurrent = 2
      break
    case 'CLIENT_PROFILE_1':
      tempCurrent = 3
      break
    case 'CLIENT_PROFILE_2':
      tempCurrent = 4
      break
    case 'RISK_PROFILE':
      tempCurrent = 5
      break
    case 'SUMMARY':
      tempCurrent = 6
      break
    case 'INVESTMENT_PLANNING':
      tempCurrent = 7
      break
    // case 'AGREEMENT_SIGN':
    //   tempCurrent = 8
    //   break
    case 'COURIER_INSTRUCTION':
      tempCurrent = 8
      break
    case 'DONE':
      tempCurrent = 9
      break
    default:
      break
  }

  const [current, setCurrent] = useState(tempCurrent)

  const next = (nextStep = 1) => {
    if (
      current === 5 &&
      currentUser.reEvaluate === true &&
      currentUser.cvlStatus === true
    ) {
      setCurrent(9)
    } else if (
      currentUser?.isPanCardIndividual &&
      current === 5 &&
      currentUser.cvlStatus === true
    ) {
      setCurrent(8)
    } else if (
      currentUser?.isPanCardIndividual &&
      current === 7 &&
      currentUser.reEvaluate
    ) {
      client
        .mutate({
          mutation: UPDATE_USER,
          variables: {
            data: {
              completedSteps: 'DONE',
              reEvaluate: false,
            },
            id: parseInt(currentUser.id),
          },
        })
        .then((res) => {
          dispatch({ type: 'CURRENT_USER', data: res.data.updateUser })
          dispatch({ type: 'IS_VERIFICATION_DONE', data: true })
          history.push('/')
        })
        .catch((err) => console.log(err))
    } else {
      setCurrent(current + nextStep)
    }
  }

  const back = (backStep = 1) => {
    if (currentUser?.completedSteps === 'RISK_PROFILE') {
      setCurrent(current - backStep)
    }
  }

  const newEdit = (data) => {
    client
      .mutate({
        mutation: UPDATE_USER,
        variables: {
          id: currentUser?.id,
          data: { completedSteps: data?.completedSteps },
        },
      })
      .then((res) => {
        dispatch({ type: 'CURRENT_USER', data: res?.data?.updateUser })
        setCurrent(stepsIndexList[data?.completedSteps])
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const steps = [
    {
      title: 'Disclaimer',
      content: <RiskProfileFormNew next={next} />,
    },
    {
      title: 'Select Type',
      content: <SelectTypeFormNew next={next} />,
    },
    {
      title: 'Client Basic Details',
      content: (
        <>
          {isPanCardIndividual ? (
            <ClientProfile1New
              next={next}
              reEvaluate={currentUser.reEvaluate}
              newEdit={newEdit}
            />
          ) : (
            <ClientProfileNon1New
              next={next}
              reEvaluate={currentUser.reEvaluate}
              newEdit={newEdit}
            />
          )}
        </>
      ),
    },
    {
      title: 'Client Profile',
      content: (
        <>
          {isPanCardIndividual ? (
            <ClientProfile2New
              next={next}
              reEvaluate={currentUser.reEvaluate}
              newEdit={newEdit}
            />
          ) : (
            <ClientProfileNon2New
              newEdit={newEdit}
              next={next}
              reEvaluate={currentUser.reEvaluate}
            />
          )}
        </>
      ),
    },
    {
      title: 'Risk Profile Assessment',
      content: (
        <RiskProfileNew
          next={next}
          reEvaluate={currentUser.reEvaluate}
          newEdit={newEdit}
        />
      ),
    },
    {
      title: 'Summary',
      content: (
        <SummaryNew
          back={back}
          next={next}
          reEvaluate={currentUser.reEvaluate}
          newEdit={newEdit}
        />
      ),
    },
    {
      title: 'Investment Planning',
      content: (
        <InvestmentPlanningNew
          next={next}
          reEvaluate={currentUser.reEvaluate}
          newEdit={newEdit}
        />
      ),
    },
    // {
    //   title: 'Agreement Sign',
    //   content: (
    //     <AgreementSign next={next} reEvaluate={currentUser.reEvaluate} />
    //   ),
    // },
    {
      title: 'Courier Instruction',
      content: (
        <CourierPageNew
          next={next}
          reEvaluate={currentUser.reEvaluate}
          newEdit={newEdit}
        />
      ),
    },
  ]

  return (
    <>
      <div className='.gx-app-login-content.content-wrapper'>
        {showFAQScreen ? (
          <FAQsModelNew />
        ) : (
          <>
            <Steps size='small' current={current} style={{ display: 'none' }}>
              {steps.map((item) => {
                return <Step key={item.title} title={item.title} />
              })}
            </Steps>
            <div className='progress' style={{ display: 'none' }}>
              <Progress
                percent={Math.round((100 / 9) * (tempCurrent + 1))}
                format={(p) => `${p}% Progress`}
              />
            </div>
            <div className='steps-content'>{steps[current]?.content}</div>
          </>
        )}
      </div>
    </>
  )
}

export default Verification
