import { Button, Popover, Table } from 'antd'
import client from 'apollo'
import { GET_LEDGER_REPORT_DATA } from 'modules/Reports/graphql/Queries'
import moment from 'moment'
import React, { useContext, useState } from 'react'
import { CSVLink } from 'react-csv'
// import { Table } from 'antd'
// import moment from 'moment'
import { DownloadOutlined } from '@ant-design/icons'
import { convertNumberToCommaSeparate } from 'utils/utilities'
import PdfIcon from 'assets/images/new-images/pdficon.svg'
import ExcelIcon from 'assets/images/new-images/excelIcon.svg'
import { get } from 'lodash'
import { AppContext } from 'AppContext'

export default function ({
  reportData,
  clientData,
  id,
  isLedger,
  StrategyCode,
}) {
  const { ClientCode, ClientName } = clientData
  const {
    state: { isMobile },
  } = useContext(AppContext)

  const [loading, setLoading] = useState(false)
  const columns = [
    {
      title: 'Entry Date',
      dataIndex: 'Entrydate',
      key: 'Entrydate',
      width: isMobile && 150,
      className: 'report-text',
      render: (record) => {
        if (record !== 'Total' && record !== 'Net Value') {
          return moment(record).format('DD-MM-YYYY')
        } else {
          return record
        }
      },
    },
    {
      title: 'Debit Amount',
      dataIndex: 'DR_Amount',
      key: 'DR_Amount',
      className: 'report-numeric',
      render: (record) => convertNumberToCommaSeparate(record, true),
    },
    {
      title: 'Credit Amount',
      dataIndex: 'CR_Amount',
      key: 'CR_Amount',
      className: 'report-numeric',
      // render: (record) => console.log(record),
      render: (record) => convertNumberToCommaSeparate(+record, true),
    },
    {
      title: 'Cum Amount',
      dataIndex: 'CumAmount',
      key: 'CumAmount',
      className: 'report-numeric',
      render: (record) => convertNumberToCommaSeparate(record, true),
    },
    {
      title: 'Remarks',
      dataIndex: 'remark',
      key: 'remark',
      className: 'report-numeric-remark',
    },
  ]

  // const data = reportData
  let data = reportData.map((report, key) => {
    return { key, ...report }
  })

  const handleDownloadPdf = () => {
    setLoading(true)
    client
      .query({
        query: GET_LEDGER_REPORT_DATA,
        variables: {
          where: { id: parseInt(id), isLedger, strategyCode: StrategyCode },
        },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        setLoading(false)
        get(res, 'data.getLedgerReport') &&
          window.open(get(res, 'data.getLedgerReport'), '_blank')
      })
      .catch((err) => console.log(err))
  }

  const content = (
    <div style={{ width: '100px' }}>
      <div
        onClick={handleDownloadPdf}
        style={{
          marginBottom: `${isMobile ? '0px' : '10px'}`,
          fontSize: '14px',
          fontWeight: '600',
          lineHeight: '24px',
          textAlign: 'left',
          color: ' #000',
          cursor: 'pointer',
        }}
      >
        <img src={PdfIcon} style={{ marginRight: '10px' }} alt='pdf' /> Pdf
      </div>

      <CSVLink
        style={{ paddingRight: 0 }}
        data={reportData}
        className='csv-btn'
        filename={`${
          isLedger
            ? `Ledger Report ${ClientCode}.csv`
            : `Ledger Beta Report ${ClientCode}.csv`
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          style={{
            marginBottom: `${isMobile ? '0px' : '10px'}`,
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '24px',
            textAlign: 'left',
            color: ' #000',
            cursor: 'pointer',
          }}
        >
          <img src={ExcelIcon} style={{ marginRight: '10px' }} alt='csv' />{' '}
          Excel
        </div>
      </CSVLink>
    </div>
  )
  return (
    <>
      <div className='report_first_Container'>
        <div className='report_Inner_Container'>
          <div className='Client_Info_Container'>
            <div className='client_Info'>
              Client Code:&nbsp;
              <span>{ClientCode}</span>
            </div>
            <div className='client_Info'>
              Client Name:&nbsp;
              <span>{ClientName}</span>
            </div>
          </div>

          <Popover content={content} placement='bottom' trigger='click'>
            <Button
              loading={loading}
              style={{
                margin: '0',
                padding: isMobile ? '4px 8px' : '5px 16px',
                fontSize: isMobile ? '12px' : '14px',
                fontWeight: '600',
                lineHeight: '15px',
                textAlign: 'left',
                color: '#AF0000',
                background: '#AF00000D',
                border: '1px solid #AF000080',
                height: '30px',
                borderRadius: '8px',
              }}
            >
              Export{' '}
              <DownloadOutlined color='#AF0000' style={{ fontSize: '16px' }} />
            </Button>
          </Popover>
        </div>
      </div>
      <Table
        className='ledger-ilts-report'
        bordered
        dataSource={data}
        columns={columns}
        style={{
          overflow: 'auto',
          height: isMobile ? '430px' : '100%',
          width: isMobile ? '100%' : 'auto',
        }}
        // scroll={{ x: true, y: 240 }}
        pagination={{ pageSize: 1000, hideOnSinglePage: true }}
      />
    </>
  )
}
