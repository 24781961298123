import React, { useContext, useEffect, useState } from 'react'
import { Form, Input, Select, Button, Row, Col, Tooltip } from 'antd'
import { get, isEmpty } from 'lodash'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/react-hooks'
import client from 'apollo'

import './verification.css'
import {
  CHECK_EXIST_CLIENT_CODE,
  GET_USERS_FOR_CLIENT,
} from 'modules/Users/graphql/Queries'
import { AppContext } from 'AppContext'
import Page404 from 'components/Page404'
import CustomMeta from 'components/CustomMeta'
import openNotification from 'utils/Notification'
import { COMPLETE_STEP, FETCH_CVL_STATUS } from './graphql/Mutations'
import ClientProfileModel1 from './Model/ClientProfile1'
import { UPDATE_USER } from 'modules/Users/graphql/Mutations'
import { handleInputChange } from 'utils/utilities'
import { GET_IMAGES } from 'modules/LoginImages/graphql/Query'
import Loader from 'components/loaders/Loader'
import { random } from 'lodash'

const FormItem = Form.Item
const { Option } = Select

const ClientProfile1New = ({ next, reEvaluate }) => {
  const [form] = Form.useForm()
  const [showFormModal, setShowFormModal] = useState(false)
  const [formValue, setFormValue] = useState({})
  // const [dataEmail, setDataEmail] = useState(true)
  const [reEvaluateValue, setReEvaluateValue] = useState(reEvaluate)
  const [loadings, setLoadings] = useState(false)
  const [clientCodeExist, setClientCodeExist] = useState('')
  const [imageUrl, setImageUrl] = useState('')
  const { dispatch, state } = useContext(AppContext)
  const {
    firstName,
    lastName,
    userName,
    email,
    createdBy,
    phone,
    panCardNo,
    clientCode,
    loginType,
    isPanCardIndividual,
  } = state.currentUser

  const {
    state: { isMobile },
  } = useContext(AppContext)
  const { data, loading, error } = useQuery(GET_USERS_FOR_CLIENT, {
    fetchPolicy: 'network-only',
  })

  const { data: existClientCode, loading: clientCodeLoading } = useQuery(
    CHECK_EXIST_CLIENT_CODE,
    {
      variables: { where: { clientCode: clientCodeExist } },
      fetchPolicy: 'network-only',
    }
  )

  const {
    loading: imagesLoading,
    error: imagesError,
    data: imagesData,
  } = useQuery(GET_IMAGES, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (!clientCodeLoading) {
      setImageUrl(
        imagesData?.getAllLoginImages[
          random(0, imagesData?.getAllLoginImages?.length - 1)
        ].imageUrl
      )
    }
  }, [imagesData, clientCodeLoading])

  console.log(loading || imagesLoading)
  if (loading || imagesLoading) return <Loader />
  if (error || imagesError) return <Page404 error={error} />

  let getUsers = []
  if (!loading && get(data, 'getUsersForClient.users')) {
    getUsers = data.getUsersForClient.users
  }

  const onFinish = (values) => {
    // values.clientEmail = values.clientEmail.toLowerCase()
    values.firstName =
      values.firstName.trim().charAt(0).toUpperCase() +
      values.firstName.trim().slice(1).toLowerCase()
    values.lastName =
      values.lastName.trim().charAt(0).toUpperCase() +
      values.lastName.trim().slice(1).toLowerCase()
    if (reEvaluateValue === true) {
      onNext()
    } else {
      setFormValue(values)
      setShowFormModal(true)
    }
  }

  const prefixSelector = (
    <Form.Item name='prefixMobile' noStyle>
      <Select getPopupContainer={(trigger) => trigger.parentElement} disabled>
        <Option value='91'>+91</Option>
      </Select>
    </Form.Item>
  )

  let ReferenceBy = ''
  if (createdBy) {
    ReferenceBy = createdBy.id
  }

  const handleHideFormModal = () => {
    setShowFormModal(false)
  }

  const editAccessFunc = () => {
    setReEvaluateValue(false)
  }

  const onNext = () => {
    setLoadings(true)
    dispatch({ type: 'IS_ACCEPT_LOADING', data: true })
    const { id } = state.currentUser
    let { clientSummaryDetails } = state
    const currentIndex = clientSummaryDetails.findIndex(
      (item) => item?.name === 'Client Basic Details'
    )
    const updateSummaryUiDetails = {
      completedSteps: 'SELECT_USER_TYPE',
      name: 'Client Basic Details',
      isDisplayEdit: true,
      data: {
        'Full Name': firstName + ' ' + lastName,
        'Email ID': email,
        'Mobile Number': phone,
        'Pan Card': panCardNo,
      },
    }

    if (currentIndex !== -1) {
      clientSummaryDetails[currentIndex] = updateSummaryUiDetails
    } else {
      clientSummaryDetails.push(updateSummaryUiDetails)
    }

    client
      .mutate({
        mutation: UPDATE_USER,
        variables: {
          id,
          data: {
            completedSteps: 'CLIENT_PROFILE_1',
          },
        },
      })
      .then((res) => {
        client.mutate({ mutation: FETCH_CVL_STATUS })
        client.mutate({
          mutation: COMPLETE_STEP,
          variables: {
            data: {
              step: 'CLIENT_PROFILE_1',
              from: 'WEB',
            },
          },
        })
        dispatch({ type: 'CURRENT_USER', data: res.data.updateUser })
        dispatch({ type: 'CLIENT_SUMMARY_DETAILS', data: clientSummaryDetails })
        openNotification('success', 'Client Basic Details Updated Successfully')
        setLoadings(false)
        dispatch({ type: 'IS_ACCEPT_LOADING', data: false })
        next()
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoadings(false)
        dispatch({ type: 'IS_ACCEPT_LOADING', data: false })
      })
  }

  return (
    <div
      style={{
        height: '100dvh',
      }}
    >
      <Row
        className='row-style'
        style={{
          overflow: 'auto',
          scrollbarWidth: 'none',
        }}
      >
        <Col sm={24} lg={16} className='col-left disclaimer-col'>
          <div>
            <CustomMeta title='Client Profile 1' />
            <div className='form-outer-div'>
              <Row>
                <Col span={22} style={{ textAlign: 'center' }}></Col>
                <Col span={2}>
                  {reEvaluateValue && (
                    <Button type='primary' onClick={editAccessFunc}>
                      Edit
                    </Button>
                  )}
                </Col>
              </Row>
              <Form
                // {...formItemLayout}
                form={form}
                name='register'
                onFinish={onFinish}
                initialValues={{
                  prefixMobile: '91',
                  firstName,
                  lastName,
                  userName,
                  clientCode,
                  email,
                  clientEmail: email || '',
                  check: true,
                  referenceBy: ReferenceBy || undefined,
                  phone,
                  panCardNo,
                }}
                labelAlign='left'
                scrollToFirstError
                layout='vertical'
                className='client-detail-form'
              >
                <Row className='client-profile-row'>
                  <Col className='gutter-row client-column' lg={12} sm={24}>
                    <FormItem
                      name='firstName'
                      label='First Name'
                      rules={[
                        {
                          required: true,
                          pattern: new RegExp(/\b([A-Za-z]+[ ]*)+/),
                          message: 'First Name is Required',
                        },
                      ]}
                    >
                      <Input
                        placeholder='First Name'
                        autoFocus={true}
                        style={{ textTransform: 'capitalize' }}
                        className='client-detail-input'
                        disabled={!reEvaluateValue ? false : true}
                        onChange={(e) =>
                          handleInputChange(form, 'firstName', e)
                        }
                      />
                    </FormItem>
                  </Col>

                  <Col className='gutter-row client-column' lg={12} sm={24}>
                    <FormItem
                      name='lastName'
                      label='Last Name'
                      rules={[
                        {
                          required: true,
                          pattern: new RegExp(/\b([A-Za-z]+[ ]*)+/),
                          message: 'Last Name is Required',
                        },
                      ]}
                    >
                      <Input
                        placeholder='Last Name'
                        style={{ textTransform: 'capitalize' }}
                        disabled={!reEvaluateValue ? false : true}
                        className='client-detail-input'
                        onChange={(e) => handleInputChange(form, 'lastName', e)}
                      />
                    </FormItem>
                  </Col>

                  <span style={{ color: '#ff4d4f', fontSize: '14px' }}>
                    {!clientCodeLoading &&
                    !existClientCode?.checkExistClientCode &&
                    !isEmpty(clientCodeExist)
                      ? 'client code already exist'
                      : ''}
                  </span>

                  <Col className='gutter-row client-column' lg={12} sm={24}>
                    <FormItem
                      name='clientCode'
                      initialValues={clientCode}
                      label={
                        <span>
                          Client Code&nbsp;{' '}
                          <Tooltip
                            title='Input the trading
                account code where you intend to take the trades for Index Long Term
                Strategy. Not sure?? Then leave the field blank'
                          >
                            <QuestionCircleOutlined />
                          </Tooltip>
                        </span>
                      }
                      rules={[
                        {
                          required: true,
                          message:
                            !clientCodeLoading &&
                            !existClientCode?.checkExistClientCode &&
                            !isEmpty(clientCodeExist)
                              ? 'client code already exist'
                              : '',
                        },
                      ]}
                    >
                      <Input
                        placeholder='Client Code'
                        disabled={!reEvaluateValue ? false : true}
                        onChange={(e) => setClientCodeExist(e.target.value)}
                        className='client-detail-input'
                      />
                    </FormItem>
                  </Col>

                  <Col className='gutter-row client-column' lg={12} sm={24}>
                    <FormItem
                      name='userName'
                      label='User Name'
                      rules={[{ required: true }]}
                    >
                      <Input placeholder='User Name' disabled />
                    </FormItem>
                  </Col>

                  <Col className='gutter-row client-column' lg={12} sm={24}>
                    <FormItem
                      name='email'
                      label='Email'
                      rules={[
                        {
                          type: 'email',
                          message: 'The input is not valid Email!',
                        },
                        {
                          required: true,
                          message: 'Please input your Email!',
                        },
                      ]}
                    >
                      <Input
                        placeholder='Email'
                        disabled={!reEvaluateValue ? false : true}
                        className='client-detail-input'
                      />
                    </FormItem>
                    {/* <FormItem
                      name='check'
                      valuePropName='checked'
                      className='checkedEmail'
                      rules={[
                        ({ getFieldValue, setFieldsValue }) => ({
                          validator(_, value) {
                            if (value) {
                              setFieldsValue({
                                clientEmail: getFieldValue('email'),
                              })
                            }
                            return Promise.resolve()
                          },
                        }),
                      ]}
                    >
                      <Checkbox
                        disabled={!reEvaluateValue ? false : true}
                        style={{
                          display: 'flex',
                          justifyContent: 'start',
                          width: '100%',
                        }}
                      >
                        Same as Above Email
                      </Checkbox>
                    </FormItem> */}
                    {/* <br /> */}
                  </Col>

                  {/* <Col className='gutter-row client-column' lg={12} sm={24}>
                    <FormItem
                      name='clientEmail'
                      label='Client Email'
                      rules={[
                        {
                          required: true,
                          message: 'Please input your E-mail!!!!',
                        },
                        ({ getFieldValue, setFieldsValue }) => ({
                          validator(_, value) {
                            const isSame = getFieldValue('email') === value
                            if (value) {
                              setFieldsValue({ check: isSame })
                            }
                            if (value) {
                              if (!isSame && dataEmail) {
                                setDataEmail(false)
                              } else if (isSame && !dataEmail) {
                                setDataEmail(true)
                              }
                            }
                            return Promise.resolve()
                          },
                        }),
                      ]}
                    >
                      <Input
                        placeholder='Client Email'
                        style={{ textTransform: 'lowercase' }}
                        disabled={!email ? false : true}
                        className='client-detail-input'
                      />
                    </FormItem>
                  </Col> */}

                  <Col className='gutter-row client-column' lg={12} sm={24}>
                    <FormItem
                      name='phone'
                      label='Mobile Number'
                      rules={[
                        {
                          required: true,
                          message: 'Please input your mobile number!',
                        },
                      ]}
                    >
                      <Input
                        addonBefore={prefixSelector}
                        style={{
                          width: '100%',
                          height: '40px',
                        }}
                        placeholder='Mobile number'
                        maxLength='10'
                        disabled={!reEvaluateValue ? false : true}
                      />
                    </FormItem>
                  </Col>

                  <Col className='gutter-row client-column' lg={12} sm={24}>
                    <FormItem
                      name='panCardNo'
                      label='PAN No'
                      rules={[
                        {
                          required: true,
                          message: 'Please input your valid Pan number!',
                          pattern: new RegExp(
                            /[A-Za-z]{3}[ABCFGHLJPTFabcfghjpltf]{1}[A-Za-z]{1}[0-9]{4}[A-Za-z]{1}/g
                          ),
                        },
                      ]}
                    >
                      <Input
                        placeholder='Enter PAN Card Number'
                        maxLength='10'
                        style={{ textTransform: 'uppercase' }}
                        disabled={!reEvaluateValue ? false : true}
                        className='client-detail-input'
                      />
                    </FormItem>
                  </Col>
                  <Col
                    className='gutter-row client-column'
                    lg={12}
                    sm={24}
                  ></Col>
                  {reEvaluate === true
                    ? ' '
                    : loginType !== 'GOOGLE' && (
                        <>
                          <Col
                            className='gutter-row client-column'
                            lg={12}
                            sm={24}
                          >
                            <FormItem
                              name='password'
                              label='Password'
                              rules={[
                                {
                                  required: true,
                                  message:
                                    'password must be between 8 and 16 characters!',
                                  min: 8,
                                  max: 16,
                                },
                              ]}
                              hasFeedback
                            >
                              <Input
                                type='password'
                                placeholder='Enter Password'
                                disabled={!reEvaluateValue ? false : true}
                              />
                            </FormItem>
                          </Col>

                          <Col
                            className='gutter-row client-column'
                            lg={12}
                            sm={24}
                          >
                            <FormItem
                              name='passwordConfirm'
                              label='Confirm Password'
                              dependencies={['password']}
                              hasFeedback
                              rules={[
                                {
                                  required: true,
                                  message: 'Please confirm your password!',
                                },
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    if (
                                      !value ||
                                      getFieldValue('password') === value
                                    ) {
                                      return Promise.resolve()
                                    }
                                    return Promise.reject(
                                      new Error(
                                        'The two passwords that you entered do not match!'
                                      )
                                    )
                                  },
                                }),
                              ]}
                            >
                              <Input
                                type='password'
                                placeholder='Enter Password'
                                disabled={!reEvaluateValue ? false : true}
                              />
                            </FormItem>
                          </Col>
                        </>
                      )}
                </Row>
                <div
                  className='verify_next_button'
                  style={{
                    marginRight: '18px',
                    marginBottom: isMobile ? '50px' : '10px',
                  }}
                >
                  <Button
                    // id='btn-click'
                    className='yes-next-button'
                    disabled={
                      !existClientCode?.checkExistClientCode &&
                      !isEmpty(clientCodeExist)
                        ? true
                        : false
                    }
                    type='primary'
                    htmlType='submit'
                    loading={loadings}
                    // style={{ display: 'none' }}
                  >
                    Verify And Next
                  </Button>
                  {showFormModal && (
                    <ClientProfileModel1
                      next={next}
                      formValue={{ ...formValue, isPanCardIndividual }}
                      getUsers={getUsers}
                      visible={showFormModal}
                      prefixSelector={prefixSelector}
                      onCancel={() => handleHideFormModal()}
                      reEvaluate={reEvaluate}
                    />
                  )}
                </div>
              </Form>
            </div>
          </div>
        </Col>
        <Col span={8} className='col-right disclaimer-col disclaimer-col-image'>
          <div
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src={imageUrl} alt='img' />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default ClientProfile1New
