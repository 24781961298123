import React, { useContext } from 'react'
import { get } from 'lodash'
import { useQuery } from '@apollo/react-hooks'
import { DownloadOutlined } from '@ant-design/icons'
import NoData from 'components/NoData'
import Page404 from 'components/Page404'
import UnhedgeTable from './UnhedgeTable'
import Loader from 'components/loaders/Loader'
import { GET_UNHEDGE_REPORT } from '../graphql/Queries'
import PdfIcon from 'assets/images/new-images/pdficon.svg'
import ExcelIcon from 'assets/images/new-images/excelIcon.svg'
import '../index.css'
import { Button, Popover } from 'antd'
import { CSVLink } from 'react-csv'
import { exportGraphAsPdf } from 'utils/Graph'
import { AppContext } from 'AppContext'

export default function () {
  const {
    state: { isMobile },
  } = useContext(AppContext)

  const { data, loading, error } = useQuery(GET_UNHEDGE_REPORT, {
    variables: { where: { ii: 0, strategy: 'ILTS' } },
  })

  if (loading) return <Loader />
  if (error) return <Page404 error={error} />
  if (!get(data, 'spUnhedgeReportInput')) return <NoData />

  const {
    spUnhedgeReportInput: [spUnhedgeReportInput],
  } = data

  const handleDownloadPdf = () => {
    exportGraphAsPdf(
      'UnHedge-report',
      'UNHEDGE-REPORT-PDF',
      null,
      true
    ).finally(() => console.log('======== UnHedge-report download ========'))
  }

  const content = (
    <div style={{ width: '100px' }}>
      <div
        onClick={handleDownloadPdf}
        style={{
          marginBottom: `${isMobile ? '0px' : '10px'}`,
          fontSize: '14px',
          fontWeight: '600',
          lineHeight: '24px',
          textAlign: 'left',
          color: ' #000',
          cursor: 'pointer',
        }}
      >
        <img src={PdfIcon} style={{ marginRight: '10px' }} alt='pdf' /> PDF
      </div>

      <CSVLink
        style={{ paddingRight: 0 }}
        data={spUnhedgeReportInput}
        className='csv-btn'
        filename={`UnHedge-report.csv`}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          style={{
            marginBottom: `${isMobile ? '0px' : '10px'}`,
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '24px',
            textAlign: 'left',
            color: ' #000',
            cursor: 'pointer',
          }}
        >
          <img src={ExcelIcon} style={{ marginRight: '10px' }} alt='excel' />{' '}
          Excel
        </div>
      </CSVLink>
    </div>
  )

  return (
    <>
      {/* <DownloadReport
        data={spUnhedgeReportInput}
        fileName='UnHedge-report'
        id='UNHEDGE-REPORT-PDF'
      /> */}
      <div className='report_Container'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: '100%',
            paddingBottom: '20px',
          }}
        >
          <Popover content={content} placement='bottom' trigger='click'>
            <div className='container_Export_Popover'>
              <Button>
                Export{' '}
                <DownloadOutlined
                  color='#AF0000'
                  style={{ fontSize: '16px' }}
                />
              </Button>
            </div>
          </Popover>
        </div>
        <div className='report-table-wrapper' id='UNHEDGE-REPORT-PDF'>
          <UnhedgeTable
            reportData={spUnhedgeReportInput.filter((report, key) => key >= 0)}
          />
        </div>
      </div>
    </>
  )
}
