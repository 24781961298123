import React, { Fragment, useContext, useState } from 'react'
import moment from 'moment'
import get from 'lodash/get'
// import { NavLink } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { Table, Button, Tooltip, Modal } from 'antd'
import {
  EditTwoTone,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'

import {
  CREATE_NOTIFICATION,
  UPDATE_NOTIFICATION,
  DELETE_NOTIFICATION,
} from './graphql/Mutations'
import { GET_SCHEDULE_NOTIFICATIONS } from './graphql/Queries'
import CreateNotificationModal from './CreateNotificationModal'
import openNotification from 'utils/Notification'
import CustomMeta from 'components/CustomMeta'
import Page404 from 'components/Page404'
import client from 'apollo'
import { AppContext } from 'AppContext'

const { confirm } = Modal

export default function () {
  let tableData = []
  const [showFormModal, setShowFormModal] = useState(false)
  const [selectedNotification, setSelectedNotification] = useState(undefined)
  const [loader, setLoader] = useState(false)

  const {
    state: { isMobile },
  } = useContext(AppContext)

  const { data, loading, error } = useQuery(GET_SCHEDULE_NOTIFICATIONS, {
    fetchPolicy: 'network-only',
  })

  if (error) return <Page404 error={error} />

  let totalCount = 0

  if (!loading && get(data, 'scheduleNotifications')) {
    totalCount = data.scheduleNotifications.length
    tableData = data?.scheduleNotifications?.map(
      (scheduleNotification, key) => ({
        key: key.toString(),
        ...scheduleNotification,
      })
    )
  }

  function showConfirm(id) {
    confirm({
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
      title: `Do you want to delete this notification?`,
      content: `When clicked the OK button, this notification will be Deleted`,
      onOk() {
        client
          .mutate({
            mutation: DELETE_NOTIFICATION,
            variables: { id: parseInt(id) },
            refetchQueries: [{ query: GET_SCHEDULE_NOTIFICATIONS }],
          })
          .catch((err) => console.log(err))
      },
    })
  }

  function handleHideFormModal() {
    setShowFormModal(false)
    setSelectedNotification(undefined)
  }

  function renderAction(record) {
    return (
      <div className='action-icons'>
        {record.date >= moment().format('YYYY-MM-DD') && (
          <Tooltip title='Edit'>
            <EditTwoTone
              onClick={() => {
                setShowFormModal(true)
                setSelectedNotification(record)
              }}
            />
          </Tooltip>
        )}
        <Tooltip title='Delete Notification'>
          <DeleteOutlined onClick={() => showConfirm(record.id)} />
        </Tooltip>
      </div>
    )
  }

  function handleCreateNotification(values, resetForm) {
    const data = values
    setLoader(true)
    client
      .mutate({
        mutation: CREATE_NOTIFICATION,
        variables: { data },
        refetchQueries: [{ query: GET_SCHEDULE_NOTIFICATIONS }],
      })
      .then((res) => {
        setLoader(false)
        openNotification('success', 'Notification Added Successfully')
        handleHideFormModal()
        resetForm()
      })
      .catch((err) => console.log(err))
  }

  function handleUpdateNotification(values, resetForm) {
    const data = values
    setLoader(true)
    const { id } = selectedNotification
    client
      .mutate({
        mutation: UPDATE_NOTIFICATION,
        variables: { data, id: parseInt(id) },
        refetchQueries: [{ query: GET_SCHEDULE_NOTIFICATIONS }],
      })
      .then((res) => {
        setLoader(false)
        openNotification('success', 'Notification Updated Successfully')
        handleHideFormModal()
        resetForm()
      })
      .catch((err) => console.log(err))
  }
  // isWhatsappNotification
  const columns = [
    {
      title: 'Client Name',
      dataIndex: 'clientName',
      key: 'clientName',
      render: (text, record) =>
        `${record?.receivers[0]?.firstName} ${record?.receivers[0]?.lastName}`,
    },
    {
      title: 'Client Code',
      dataIndex: 'clientCode',
      key: 'clientCode',
      render: (text, record) => record?.receivers[0]?.clientCode,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'To Mail Ids',
      dataIndex: 'toMailIds',
      key: 'toMailIds',
      render: (text, record) =>
        record?.toMailIds?.map((item) => (
          <span>
            {item}
            <br />
          </span>
        )),
    },
    {
      title: 'Cc Mail Ids',
      dataIndex: 'ccMailIds',
      key: 'ccMailIds',
      render: (text, record) =>
        record?.ccMailIds?.map((item) => (
          <span>
            {item}
            <br />
          </span>
        )),
    },
    {
      title: 'Draft Template',
      dataIndex: 'draftTemplate',
      key: 'draftTemplate',
      render: (text, record) => (
        <span style={{ textTransform: 'capitalize' }}>
          {record?.draftTemplate}
        </span>
      ),
      width: 100,
    },
    {
      title: 'Whatsapp Notification',
      dataIndex: 'isWhatsappNotification',
      key: 'isWhatsappNotification',
      render: (text, record) =>
        record?.isWhatsappNotification === 1 ? 'True' : 'False',
      width: 120,
    },
    {
      title: 'Schedule Date',
      dataIndex: 'date',
      key: 'date',
      render: (text, record) =>
        record?.date ? moment(record?.date).format('DD-MMM-YYYY') : '-',
    },
    // {
    //   title: 'Created At',
    //   dataIndex: 'createdAt',
    //   key: 'createdAt',
    //   render: (date) => moment(date).format('Do MMMM YYYY'),
    //   sorter: (a, b) => a.name.length - b.name.length,
    //   width: '40%',
    // },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => renderAction(record),
      width: '10%',
    },
  ]

  return (
    <Fragment>
      <CustomMeta title='Notifications' />
      {showFormModal && (
        <CreateNotificationModal
          visible={showFormModal}
          onCreate={handleCreateNotification}
          onUpdate={handleUpdateNotification}
          selectedNotification={selectedNotification}
          isEdit={selectedNotification !== undefined}
          onCancel={() => handleHideFormModal()}
          loader={loader}
        />
      )}
      <div style={{ padding: `${isMobile ? '10px' : '32px 40px'}` }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
          }}
        >
          <div
            style={{
              fontSize: '24px',
              fontWeight: '600',
              lineHeight: '26px',
              letterSpacing: '-0.02em',
              textAlign: 'left',
              color: '#181C32',
            }}
          >
            Notifications
          </div>
          <div style={{ display: 'flex', gap: '10px' }}>
            <Button
              onClick={() => setShowFormModal(true)}
              style={{
                margin: '0',
                padding: '8px 16px',
                fontSize: '14px',
                fontWeight: '600',
                lineHeight: '24px',
                textAlign: 'left',
                color: '#AF0000',
                background: '#AF00000D',
                border: '1px solid #AF000080',
                height: '40px',
                borderRadius: '8px',
              }}
            >
              Add Notification
            </Button>
          </div>
        </div>
        <Table
          loading={loading}
          columns={columns}
          dataSource={tableData}
          scroll={{ x: true, y: true }}
          pagination={{ total: totalCount }}
        />
      </div>
    </Fragment>
  )
}
