export const capitalizeFirstLetter = (input) => {
  const words = input.split(' ')
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  )
  return capitalizedWords.join(' ')
}

export const handleInputChange = (form, fieldName, e) => {
  const transformedValue = capitalizeFirstLetter(e.target.value)
  form.setFieldsValue({ [fieldName]: transformedValue })
}

const capitalizeAllLetters = (value) => {
  return value.toUpperCase()
}

export const handlePanCard = (form, fieldName, e) => {
  const transformedValue = capitalizeAllLetters(e.target.value)
  form.setFieldsValue({ [fieldName]: transformedValue })
}

export function convertNumberToCommaSeparate(number, isToFixed = false) {
  if (isNaN(number)) {
    return ''
  }

  let fixedNumber = number
  if (isToFixed) {
    fixedNumber = number.toFixed(2)
    const intNumber = +fixedNumber.split('.')[0]
    return intNumber?.toLocaleString('en-IN') + '.' + fixedNumber.split('.')[1]
  } else {
    return fixedNumber?.toLocaleString('en-IN')
  }
}

export const formatToLakhsOrCrores = (number) => {
  if (number >= 10000000) {
    return (number / 10000000).toFixed(2) + ' Crores'
  } else if (number >= 100000) {
    return (number / 100000).toFixed(2) + ' Lakhs'
  }
  return number.toString()
}

export const numberToWord = (num) => {
  const single = [
    'Zero',
    'One',
    'Two',
    'Three',
    'Four',
    'Five',
    'Six',
    'Seven',
    'Eight',
    'Nine',
  ]

  const double = [
    'Ten',
    'Eleven',
    'Twelve',
    'Thirteen',
    'Fourteen',
    'Fifteen',
    'Sixteen',
    'Seventeen',
    'Eighteen',
    'Nineteen',
  ]

  const tens = [
    '',
    'Ten',
    'Twenty',
    'Thirty',
    'Forty',
    'Fifty',
    'Sixty',
    'Seventy',
    'Eighty',
    'Ninety',
  ]

  const formatTenth = (digit, prev) =>
    digit === 0 ? '' : ` ${digit === 1 ? double[prev] : tens[digit]}`

  const formatOther = (digit, next, denom) =>
    `${digit !== 0 && next !== 1 ? ` ${single[digit]}` : ''}${
      next !== 0 || digit > 0 ? ` ${denom}` : ''
    }`

  if (isNaN(parseInt(num)) || parseInt(num) <= 0 || num.length > 10) return ''

  const words = []
  num = num.toString()

  for (let index = num.length - 1; index >= 0; index--) {
    const digit = Number(num[index])
    const next = index > 0 ? Number(num[index - 1]) : 0
    const position = num.length - index - 1

    switch (position) {
      case 0:
        words.push(formatOther(digit, next, ''))
        break
      case 1:
        words.push(formatTenth(digit, Number(num[index + 1])))
        break
      case 2:
        words.push(
          digit !== 0
            ? ` ${single[digit]} Hundred${
                num[index + 1] !== '0' && num[index + 2] !== '0' ? ' and' : ''
              }`
            : ''
        )
        break
      case 3:
        words.push(formatOther(digit, next, 'Thousand'))
        break
      case 4:
        words.push(formatTenth(digit, Number(num[index + 1])))
        break
      case 5:
        words.push(formatOther(digit, next, 'Lakh'))
        break
      case 6:
        words.push(formatTenth(digit, Number(num[index + 1])))
        break
      case 7:
        words.push(formatOther(digit, next, 'Crore'))
        break
      case 8:
        words.push(formatTenth(digit, Number(num[index + 1])))
        break
      case 9:
        words.push(
          digit !== 0
            ? ` ${single[digit]} Hundred${
                num[index + 1] !== '0' || num[index + 2] !== '0'
                  ? ' and'
                  : ' Crore'
              }`
            : ''
        )
        break
      default:
        break
    }
  }

  return words.reverse().join('')
}
