import React, { useContext, useEffect, useState } from 'react'
import {
  Input,
  Button,
  Form,
  Table,
  Popconfirm,
  Space,
  Row,
  Col,
  Select,
} from 'antd'
import {
  SaveTwoTone,
  EditTwoTone,
  CloseCircleTwoTone,
  DeleteFilled,
} from '@ant-design/icons'
import { PARTNERS_DETAILS } from './graphql/Queries'
import { useQuery } from '@apollo/react-hooks'
import { AppContext } from 'AppContext'
import ClientProfileModalNon2 from './Model/ClientProfile2Non'
import { UPDATE_PARTNER_DETAILS } from './graphql/Mutations'
import client from 'apollo'
import CustomMeta from 'components/CustomMeta'
import { UPDATE_USER } from 'modules/Users/graphql/Mutations'
import KeyInfo from './KeyInfo'

const { Option } = Select

const ClientProfileNon2New = ({ next, reEvaluate, newEdit }) => {
  let {
    dispatch,
    state: {
      currentUser: { id, fullName, panCardNo },
      clientSummaryDetails,
      isMobile,
    },
  } = useContext(AppContext)

  const FormItem = Form.Item

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 16,
      },
    },
  }

  const [form] = Form.useForm()
  const [data, setData] = useState([])
  const [editingKey, setEditingKey] = useState('')
  const [count, setCount] = useState(2)
  const [showVerifyModal, setShowVerifyModal] = useState(false)
  const [loading, setLoading] = useState(false)
  // const [politicallyValue, setPoliticallyValue] = useState()
  const [emptyFields, setEmptyFields] = useState(false)

  const { data: partnerDetails, loading: partnerLoading } = useQuery(
    PARTNERS_DETAILS,
    {
      variables: { where: { userId: parseInt(id) } },
      fetchPolicy: 'network-only',
    }
  )

  useEffect(() => {
    setData(
      partnerDetails?.partnerDetails?.map((data, key) => {
        const obj = {
          key: key.toString(),
          pan: data?.pan,
          name: data?.name,
          uidOrDin: data?.uidOrDin,
          address: data?.address,
          relationshipWithApplicant: data?.relationshipWithApplicant,
          userId: id,
          politicallyExposed: data?.politicallyExposed,
        }

        return obj
      })
    )
  }, [id, partnerDetails])

  const columns = [
    {
      title: 'Sr. No',
      dataIndex: 'srNo',
      render: (text, record) => +record.key + 1 || 1,
    },
    {
      title: 'Individual PAN Number',
      dataIndex: 'pan',
      width: `${isMobile ? '200px' : '15%'}`,
      editable: true,
      render: (text, record) => record.pan.toUpperCase(),
    },
    {
      title: 'Full Name',
      dataIndex: 'name',
      width: `${isMobile ? '200px' : '20%'}`,
      editable: true,
      render: (text, record) => (
        <div style={{ textTransform: 'capitalize' }}>{record.name}</div>
      ),
    },
    {
      title: 'DIN (For Directors) / Aadhaar Number (For Others)',
      dataIndex: 'uidOrDin',
      width: `${isMobile ? '200px' : '10%'}`,
      editable: true,
      render: (text, record) => (
        <div style={{ textTransform: 'uppercase' }}>{record.uidOrDin}</div>
      ),
    },
    {
      title: 'Residential Address',
      dataIndex: 'address',
      width: `${isMobile ? '200px' : '10%'}`,
      editable: true,
      render: (text, record) => (
        <div style={{ textTransform: 'capitalize' }}>{record.address}</div>
      ),
    },
    {
      title:
        'Relationship With Applicant (I.E. Promoters , Whole Time, Directors Etc.)',
      dataIndex: 'relationshipWithApplicant',
      width: `${isMobile ? '200px' : '10%'}`,
      editable: true,
      render: (text, record) => (
        <div style={{ textTransform: 'capitalize' }}>
          {record.relationshipWithApplicant}
        </div>
      ),
    },
    {
      title: 'Whether Politically Exposed',
      dataIndex: 'politicallyExposed',
      width: `${isMobile ? '200px' : '15%'}`,
      editable: true,
      render: (text, record) => (
        <div style={{ textTransform: 'capitalize' }}>
          {record.politicallyExposed}
        </div>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'Actions',
      width: '10%',
      render: (_, record, key) => {
        const editable = isEditing(record)
        return (
          <>
            <Space size='small'>
              {editable ? (
                <span style={{ display: 'flex' }}>
                  <Popconfirm
                    title='Sure to save?'
                    onClick={() => save(record.key)}
                  >
                    <div
                      style={{
                        marginRight: 8,
                        cursor: 'pointer',
                      }}
                    >
                      <SaveTwoTone style={{ fontSize: '18px' }} />
                    </div>
                  </Popconfirm>
                  <Popconfirm
                    title='Sure to cancel?'
                    onConfirm={() => cancel(record)}
                  >
                    <div style={{ cursor: 'pointer' }}>
                      <CloseCircleTwoTone
                        twoToneColor={'red'}
                        style={{ fontSize: '18px' }}
                      />
                    </div>
                  </Popconfirm>
                </span>
              ) : (
                <>
                  <div
                    style={{ cursor: 'pointer' }}
                    disabled={editingKey !== ''}
                    onClick={() => edit(record)}
                  >
                    <EditTwoTone style={{ fontSize: '18px' }} />
                  </div>
                  <div
                    style={{ cursor: 'pointer' }}
                    disabled={editingKey !== ''}
                    onClick={() => handleDelete(record)}
                  >
                    <DeleteFilled
                      style={{ fontSize: '18px' }}
                      twoToneColor={'red'}
                    />
                  </div>
                </>
              )}
            </Space>
          </>
        )
      },
    },
  ]

  const isEditing = (record) => record.key === editingKey

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode =
      dataIndex === 'pan' ? (
        <FormItem
          style={{ marginBottom: '0' }}
          name='pan'
          rules={[
            {
              required: true,
              message: 'Please input your valid Pan number!',
              pattern: new RegExp(
                /[A-Za-z]{3}[ABCFGHLJPTFabcfghjpltf]{1}[A-Za-z]{1}[0-9]{4}[A-Za-z]{1}/g
              ),
            },
          ]}
        >
          <Input maxLength='10' style={{ textTransform: 'uppercase' }} />
        </FormItem>
      ) : dataIndex === 'politicallyExposed' ? (
        <FormItem name={'politicallyExposed'}>
          <Select
            getPopupContainer={(trigger) => trigger.parentElement}
            // onChange={(e) => setPoliticallyValue(e)}
            // name='politicallyExposed'
            // value={politicallyValue}
          >
            <Option value='YES'>PEP</Option>
            <Option value='RELATED'>RPEP</Option>
            <Option value='NO'>NO</Option>
          </Select>
        </FormItem>
      ) : dataIndex === 'uidOrDin' ? (
        <Input style={{ textTransform: 'uppercase' }} />
      ) : (
        <Input style={{ textTransform: 'capitalize' }} />
      )

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: dataIndex !== 'pan' ? `Please Input ${title}!` : '',
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    )
  }
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    }
  })

  const edit = (record) => {
    form.setFieldsValue({
      pan: '',
      name: '',
      uidOrDin: '',
      address: '',
      relationshipWithApplicant: '',
      politicallyExposed: '',
      ...record,
    })
    setEditingKey(record.key)
  }
  const cancel = () => {
    setEditingKey('')
  }

  const save = async (key) => {
    try {
      const row = await form.validateFields()
      const newData = [...data]
      const index = newData.findIndex((item) => key === item.key)
      if (index > -1) {
        const item = newData[index]
        newData.splice(index, 1, {
          ...item,
          ...row,
          userId: +id,
          key: index,
        })
        setData(newData)
        setEmptyFields(false)
        setEditingKey('')
      } else {
        newData.push({
          ...row,
          userId: +id,
          key: index,
        })
        setData(newData)
        setEmptyFields(false)
        setEditingKey('')
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo)
    }
  }

  const handleAdd = () => {
    const newData = {
      key: data?.length,
      pan: '',
      name: '',
      uidOrDin: '',
      address: '',
      relationshipWithApplicant: '',
      politicallyExposed: '',
    }
    setData([...data, newData])
    setCount(count + 1)
    edit(newData)
  }
  const handleDelete = ({ key }) => {
    const newData = data.filter((item) => item.key !== key)
    setData(
      newData?.map((data, key) => {
        const obj = {
          key: key.toString(),
          pan: data?.pan,
          name: data?.name,
          uidOrDin: data?.uidOrDin,
          address: data?.address,
          relationshipWithApplicant: data?.relationshipWithApplicant,
          userId: id,
          politicallyExposed: data?.politicallyExposed,
        }

        return obj
      })
    )
    setEmptyFields(false)
  }

  const onFinish = (values) => {
    setLoading(true)
    dispatch({ type: 'IS_ACCEPT_LOADING', data: true })
    const datas = data?.map((item) => {
      delete item?.key
      delete item?.panCardNo
      return {
        ...item,
        pan: item.pan.toString().toUpperCase(),
      }
    })
    client
      .mutate({
        mutation: UPDATE_PARTNER_DETAILS,
        variables: {
          data: datas,
        },
      })
      .then((res) => {
        client
          .mutate({
            mutation: UPDATE_USER,
            variables: { id, data: { panCardNo: values.panCardNo } },
          })
          .then((res) => {
            const currentIndex = clientSummaryDetails.findIndex(
              (item) => item?.name === 'Client Profile'
            )
            const partnerData = datas.map((item) => {
              let partnerObj = {}
              partnerObj['Individual PAN Number'] = item.pan
              partnerObj['Full Name'] = item.name
              partnerObj['DIN'] = item.uidOrDin
              partnerObj['Residential Address'] = item.address
              partnerObj['Relationship With Applicant'] =
                item.relationshipWithApplicant
              partnerObj['Whether Politically Exposed'] =
                item.politicallyExposed
              return partnerObj
            })
            const updateSummaryUiDetails = {
              completedSteps: 'CLIENT_PROFILE_1',
              name: 'Client Profile',
              isDisplayEdit: true,
              data: partnerData,
            }

            if (currentIndex !== -1) {
              clientSummaryDetails[currentIndex] = updateSummaryUiDetails
            } else {
              clientSummaryDetails.push(updateSummaryUiDetails)
            }

            setLoading(false)
            dispatch({
              type: 'CLIENT_SUMMARY_DETAILS',
              data: clientSummaryDetails,
            })
            dispatch({ type: 'IS_ACCEPT_LOADING', data: false })
            setShowVerifyModal(true)
            // openNotification('success', 'Partners Added Successfully')
          })
          .catch((err) => console.log(err))
      })
      .catch((err) => console.log(err))
  }

  const handleHideFormModal = () => {
    setShowVerifyModal(false)
    setData(
      data?.map((data, key) => {
        const obj = {
          key: key.toString(),
          pan: data?.pan,
          name: data?.name,
          uidOrDin: data?.uidOrDin,
          address: data?.address,
          relationshipWithApplicant: data?.relationshipWithApplicant,
          userId: id,
          politicallyExposed: data?.politicallyExposed,
        }
        return obj
      })
    )
  }

  useEffect(() => {
    if (data?.length) {
      data.forEach((item) => {
        if (
          item.address === '' ||
          item.name === '' ||
          item.pan === '' ||
          item.politicallyExposed === '' ||
          item.relationshipWithApplicant === '' ||
          item.uidOrDin === ''
        ) {
          setEmptyFields(true)
        }
      })
    }
  }, [data])
  return (
    <>
      <Row className='row-style'>
        <Col lg={16} sm={24} className='col-left disclaimer-col'>
          <div className='form-outer-div'>
            <CustomMeta title='Client Details' />
            <div>
              <Form
                {...formItemLayout}
                form={form}
                name='register'
                onFinish={onFinish}
                initialValues={{
                  fullName,
                  panCardNo,
                }}
                labelAlign='left'
                scrollToFirstError
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: `${isMobile ? '0px' : '17px'}`,
                  }}
                >
                  <div
                    style={{
                      fontWeight: '500',
                      fontSize: '16px',
                      lineHeight: '18.8px',
                      color: '#000',
                      width: '65%',
                    }}
                  >
                    Details of Promoters/Partners/Karta/ Trustees and whole time
                    directions forming a part of know your Client (KYC)
                    Application Form for Non-individuals
                  </div>

                  <Button
                    onClick={handleAdd}
                    type='primary'
                    style={{
                      marginBottom: 16,
                      float: 'right',
                    }}
                    disabled={emptyFields ? true : false}
                  >
                    Add
                  </Button>
                </div>
                <div
                  className='advisory_table'
                  style={{ margin: `${isMobile ? '20px 0' : '17px'}` }}
                >
                  <Table
                    components={{
                      body: {
                        cell: EditableCell,
                      },
                    }}
                    bordered
                    loading={partnerLoading}
                    dataSource={data}
                    columns={mergedColumns}
                    rowClassName='editable-row'
                    pagination={{
                      onChange: cancel,
                    }}
                    style={{ overflow: 'scroll' }}
                  />
                </div>
                <div
                  className='verify_next_button'
                  style={{
                    marginRight: '18px',
                  }}
                >
                  <Button
                    id='btn-click'
                    type='primary'
                    htmlType='submit'
                    className='yes-next-button'
                    loading={loading}
                    disabled={
                      data?.length >= 1 && editingKey === '' && !emptyFields
                        ? false
                        : true
                    }
                  >
                    Verify and Next
                  </Button>
                  {showVerifyModal && (
                    <ClientProfileModalNon2
                      next={next}
                      setShowVerifyModal={setShowVerifyModal}
                      visible={setShowVerifyModal}
                      onCancel={handleHideFormModal}
                      reEvaluate={reEvaluate}
                      // setDeleteData={setDeleteData}
                    />
                  )}
                </div>
              </Form>
            </div>
          </div>
        </Col>
        <Col lg={8} sm={24} className='col-right disclaimer-col'>
          <KeyInfo newEdit={newEdit} />
        </Col>
      </Row>
    </>
  )
}

export default ClientProfileNon2New
