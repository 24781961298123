import React from 'react'
import { Col, Row } from 'antd'
import ClientDetail from 'components/ClientDetail'
import KeyInfo from './KeyInfo'

const ClientProfile2New = ({ next, reEvaluate, currentUser, newEdit }) => {
  return (
    <>
      <Row className='row-style'>
        <Col lg={16} sm={24} className='col-left'>
          <ClientDetail
            next={next}
            reEvaluate={reEvaluate}
            currentUser={currentUser}
          />
        </Col>
        <Col lg={8} sm={24} className='col-right'>
          <KeyInfo newEdit={newEdit} />
        </Col>
      </Row>
    </>
  )
}

export default ClientProfile2New
