import { AppContext } from 'AppContext'
import React, { useContext } from 'react'
import Individual from './individual'
import NonIndividual from './nonIndividual'

const Verification = () => {
  const { state } = useContext(AppContext)
  const { isPanCardIndividual } = state?.currentUser
  return <div style={{height: '100%'}}>{isPanCardIndividual ? <Individual /> : <NonIndividual />}</div>
}

export default Verification
