import React, { useState } from 'react'
import 'react-markdown-editor-lite/lib/index.css'
import { UploadOutlined } from '@ant-design/icons'
import { Modal, Form, Radio, Upload, Button, Select } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { GET_BRAVO_TEMPLATES } from '../graphql/Queries'
import Page404 from 'components/Page404'
import ReactQuill from 'react-quill'

const { Option } = Select

const CollectionCreateForm = ({ visible, onSend, onCancel }) => {
  const [form] = Form.useForm()
  const [type, setType] = useState('SMS')
  const [fileList, setFileList] = useState(undefined)
  const [value, setValue] = useState('')
  const [templateId, setTemplateId] = useState(undefined)

  function resetForm() {
    form.resetFields()
  }

  let newValue = value

  const { data, loading, error } = useQuery(GET_BRAVO_TEMPLATES, {
    fetchPolicy: 'network-only',
  })

  if (error) return <Page404 />

  const handleValue = (content, delta, source, editor) => {
    newValue = editor.getHTML()
  }

  const normFile = (e) => {
    setFileList([])
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const customProps = {
    multiple: false,
    onRemove: (file) => setFileList([]),
    beforeUpload: (file) => {
      setFileList(file)
      return false
    },
    fileList,
  }

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote', 'code-block'],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ direction: 'rtl' }], // text direction

      [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],

      ['clean'], // remove formatting button
    ],

    keyboard: {
      bindings: {
        'list autofill': {
          prefix: /^\s*()$/,
        },
      },
    },
  }

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'color',
    'background',
    'image',
    'video',
    'align',
    'direction',
    'code-block',
    'formula',
  ]

  const handleSelect = (id) => {
    const template = data?.getBravoTemplatesData?.filter(
      (item) => item?.id === id
    )
    setValue(template[0].htmlContent)
    setTemplateId(id)
  }

  return (
    <Modal
      width='80%'
      maskClosable={false}
      visible={visible}
      className='feedback_form'
      title={'Send Notification'}
      okText={'Send'}
      cancelText='Cancel'
      onCancel={() => {
        resetForm()
        onCancel()
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            values = { ...values, content: newValue, templateId }
            onSend(values, resetForm)
          })
          .catch((info) => console.log('Validate Failed:', info))
      }}
    >
      <Form
        form={form}
        layout='vertical'
        name='form_in_modal'
        initialValues={{ modifier: 'public' }}
      >
        <Form.Item
          name='sendVia'
          label='Notification Type'
          rules={[
            { required: true, message: 'Please select notification type!' },
          ]}
        >
          <Radio.Group onChange={(e) => setType(e.target.value)}>
            <Radio value={'SMS'}>SMS</Radio>
            <Radio value={'EMAIL'}>EMAIL</Radio>
            <Radio value={'BOTH'}>BOTH</Radio>
            <Radio value={'TEMPLATE'}>TEMPLATE</Radio>
          </Radio.Group>
        </Form.Item>
        {type === 'TEMPLATE' ? (
          <>
            <Form.Item
              name='templateId'
              label='Select Template'
              rules={[
                { required: true, message: 'Please select Template type!' },
              ]}
            >
              <Select
              getPopupContainer={(trigger) => trigger.parentElement}
                className='gx-mr-3 gx-select-sm'
                defaultValue=''
                onChange={(value) => handleSelect(value)}
                style={{ minWidth: 100 }}
                loading={loading}
              >
                <Option value='' disabled>
                  All Templates
                </Option>
                {data?.getBravoTemplatesData?.map((template) => (
                  <Option value={template?.id}>{template?.name}</Option>
                ))}
              </Select>
            </Form.Item>
            <div dangerouslySetInnerHTML={{ __html: value }} />
          </>
        ) : (
          <>
            <ReactQuill
              className='abcdef'
              theme='snow'
              value={value}
              onChange={handleValue}
              modules={modules}
              formats={formats}
            />
          </>
        )}
        {type !== 'SMS' && (
          <Form.Item
            name='file'
            label='Attachment'
            valuePropName='fileList'
            getValueFromEvent={normFile}
          >
            <Upload
              name='attachment'
              listType='text'
              {...customProps}
              accept='.jpeg,.png,.jpg,.pdf,.docx,.doc'
            >
              <Button>
                <UploadOutlined /> Click to upload
              </Button>
            </Upload>
          </Form.Item>
        )}

        <div className='footer-btn-div'>
          <Button
            className='footer-cancel-btn'
            onClick={() => {
              resetForm()
              onCancel()
            }}
          >
            Cancel
          </Button>
          <Button
            className='footer-submit-btn'
            onClick={() => {
              form
                .validateFields()
                .then((values) => {
                  values = { ...values, content: newValue, templateId }
                  onSend(values, resetForm)
                })
                .catch((info) => console.log('Validate Failed:', info))
            }}
            type='primary'
          >
            Send Notification
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

const CreateGroupModal = (props) => {
  return (
    <div>
      <CollectionCreateForm {...props} />
    </div>
  )
}

export default CreateGroupModal
