import React from 'react'
import Widget from '../../../components/Widget'

const IconWithTextCardNew = ({ cardColor, icon, title, subTitle }) => {
  return (
    <Widget styleName={`gx-card-full`}>
      <div style={{ width: '212px', height: '180px' }}>
        <div style={{ margin: '20px' }}>
          <img src={icon} alt='icon' />
        </div>
        <div style={{ margin: '27px 20px 20px' }}>
          <span
            style={{
              fontWeight: '700',
              fontSize: '28px',
              lineHeight: '38px',
              letter: '-2%',
            }}
          >
            {title}
          </span>
          <p
            style={{
              marginTop: '5px',
              fontWeight: '600',
              fontSize: '14px',
              lineHeight: '14px',
            }}
          >
            {subTitle}
          </p>
        </div>
        {/* <div className='gx-mr-2 gx-mr-xxl-3'>
          <i className={`icon icon-${icon} gx-fs-icon-lg`} />
        </div>
        <div className='gx-media-body'>
          <h1 className='gx-fs-xl gx-font-weight-semi-bold gx-mb-1'>{title}</h1>
          <p className='gx-mb-0'>{subTitle}</p>
        </div> */}
      </div>
    </Widget>
  )
}

export default IconWithTextCardNew
