import React, { useContext, useState } from 'react'
import { Button, Col, DatePicker, Drawer, Input } from 'antd'
import { useQuery, useMutation } from '@apollo/react-hooks'
import _isEmpty from 'lodash/isEmpty'
import _sortBy from 'lodash/sortBy'
import _get from 'lodash/get'
import moment from 'moment'

import MailList from './MailList'
import ComposeMail from './Compose'
import folders from './data/folders'
import MailDetail from './MailDetail'
import Spinner from 'components/loaders/Spinner'
import openNotification from 'utils/Notification'
import { COMPOSE_EMAIL } from './graphql/Mutations'
import CustomScrollbars from 'utils/CustomScrollbars'
import { FETCH_EMAIL_BY_ID, FETCH_EMAIL_DATA } from './graphql/ Queries'
import { AppContext } from 'AppContext'

export default function Mail(props) {
  const {
    match: {
      params: { email },
    },
  } = props
  const [currentMail, setCurrentMail] = useState(0)
  const [search, setSearch] = useState('')
  const [date, setDate] = useState('')
  const [composeMail, setComposeMail] = useState(false)
  const [drawerState, setDrawerState] = useState(false)
  const [selectedFolder, setSelectedFolder] = useState('INBOX')

  const [sendMail, { loading: composeMailLoading }] = useMutation(COMPOSE_EMAIL)
  const {
    data: emailsData,
    loading: emailsLoading,
    error: emailsError,
  } = useQuery(FETCH_EMAIL_DATA, {
    variables: {
      emailFilter: email,
      fetchFrom: selectedFolder,
    },
    fetchPolicy: 'network-only',
  })

  const {
    state: { isMobile },
  } = useContext(AppContext)

  const {
    data: detailEmailData,
    loading: detailEmailLoading,
    error: detailEmailError,
  } = useQuery(FETCH_EMAIL_BY_ID, {
    variables: {
      messageId: currentMail,
      fetchFrom: selectedFolder,
    },
    fetchPolicy: 'network-only',
  })

  let allEmails = null
  let detailEmail = null
  if (!emailsError && _get(emailsData, 'fetchEmail.result.count')) {
    allEmails = _get(emailsData, 'fetchEmail.result.data')
  }

  if (currentMail === 0) {
    detailEmail = null
  } else if (
    !detailEmailError &&
    _get(detailEmailData, 'fetchEmailById.result')
  ) {
    detailEmail = _get(detailEmailData, 'fetchEmailById.result')
  } else {
    detailEmail = 'None'
  }

  function MailSideBar() {
    return (
      <div className='gx-module-side'>
        <div className='gx-module-side-header'>
          <div className='gx-module-logo'>
            <i className='icon icon-chat gx-mr-4' />
            Mailbox
          </div>
        </div>

        <div className='gx-module-side-content'>
          <CustomScrollbars className='gx-module-side-scroll'>
            <div className='gx-module-add-task'>
              <Button
                type='primary'
                className='gx-btn-block'
                onClick={() => {
                  setComposeMail(true)
                }}
              >
                <i
                  className='icon icon-edit gx-mr-2'
                  style={{
                    fontSize: '20px',
                  }}
                />
                COMPOSE
              </Button>
            </div>

            <ul className='gx-module-nav'>{getNavFolders()}</ul>
          </CustomScrollbars>
        </div>
      </div>
    )
  }

  function getNavFolders() {
    return folders.map((folder, index) => (
      <li
        key={index}
        onClick={() => {
          setCurrentMail(0)
          setSelectedFolder(folder.id)
        }}
      >
        <span
          className={`${
            selectedFolder === folder.id ? 'active gx-link' : 'gx-link'
          }`}
        >
          <i className={`icon icon-${folder.icon}`} />
          <span>{folder.title}</span>
        </span>
      </li>
    ))
  }

  function handleRequestClose() {
    setComposeMail(false)
  }

  function displayMail(currentMail, folderMails) {
    const isNone = currentMail === 'None'
    const filteredMails = folderMails
      ? folderMails
          .filter((mail) =>
            mail?.subject.toLowerCase()?.includes(search.toLowerCase())
          )
          .filter((mail) =>
            !date
              ? true
              : moment(mail?.date).format('DD/MM/YYY') ===
                moment(date).format('DD/MM/YYY')
          )
      : []

    return (
      <div className='gx-module-box-column'>
        {currentMail === null ? (
          !folderMails ? (
            <div className='gx-no-content-found gx-text-light gx-d-flex gx-align-items-center gx-justify-content-center'>
              No Mail found
            </div>
          ) : (
            <MailList
              onMailSelect={onMailSelect}
              mails={_sortBy(
                filteredMails,
                (dateObj) => new Date(dateObj.date)
              )}
            />
          )
        ) : isNone ? (
          <div className='gx-no-content-found gx-text-light gx-d-flex gx-align-items-center gx-justify-content-center'>
            Request timeout, Please try again...
          </div>
        ) : (
          <MailDetail mail={currentMail} />
        )}
      </div>
    )
  }

  function Filters() {
    return (
      <div className='' style={{}}>
        <div
          className='gx-d-flex  gx-align-items-center gx-justify-content-between gx-px-3'
          style={{
            minHeight: 79,
            borderBottom: 'solid 1px #e8e8e8',
            gap: isMobile ? '5px' : '6px',
          }}
        >
          <Col className='gx-d-flex gx-px-0' xs={12} sm={12} md={12} lg={10}>
            <Input
              placeholder='Search Mails'
              onChange={(e) => setSearch(e.target.value)}
              allowClear
              className='gx-pl-2'
            />
          </Col>
          <Col>
            <DatePicker
              style={{ height: '38px' }}
              getPopupContainer={(trigger) => trigger.parentElement}
              format={'DD-MMM-YYYY'}
              onChange={(e) => setDate(e)}
            />
          </Col>
        </div>
      </div>
    )
  }

  async function onMailSend(variables) {
    if (variables.attachment) {
      let attachment = []
      for (const file of variables.attachment) {
        attachment.push(file.originFileObj)
      }
      delete variables.attachment
      variables.attachment = attachment
    }
    if (!_isEmpty(variables.toEmailsAddress)) {
      const tempEmails = [
        variables.toEmailAddress,
        ...variables.toEmailsAddress.map(
          (to, key) => to[`toEmailAddress_${key}`]
        ),
      ]
      variables.toEmailAddress = tempEmails.join(',')
    }
    delete variables.toEmailsAddress
    sendMail({ variables })
      .then(() => openNotification('success', 'Mail Sent Successfully'))
      .finally(() => setComposeMail(false))
  }

  function onMailSelect(mail) {
    setCurrentMail(mail)
  }

  function onToggleDrawer() {
    setDrawerState(!drawerState)
  }

  return (
    <div
      className='gx-main-content'
      style={{ padding: isMobile ? '14px 20px' : '32px 40px' }}
    >
      <div className='gx-app-module'>
        <div className='gx-d-block gx-d-lg-none'>
          <Drawer
            placement='left'
            closable={false}
            visible={drawerState}
            onClose={onToggleDrawer}
          >
            {MailSideBar()}
          </Drawer>
        </div>
        <div className='gx-module-sidenav gx-d-none gx-d-lg-flex'>
          {MailSideBar()}
        </div>

        <div className='gx-module-box'>
          <div className='gx-module-box-content'>
            {Filters()}
            {emailsLoading || (currentMail !== 0 && detailEmailLoading) ? (
              <div className='gx-loader-view'>
                <Spinner />
              </div>
            ) : (
              displayMail(detailEmail, allEmails)
            )}
            {composeMail && (
              <ComposeMail
                to={email}
                open={composeMail}
                onMailSend={onMailSend}
                onClose={handleRequestClose}
                composeMailLoading={composeMailLoading}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
