import React, { useContext } from 'react'
import { Table } from 'antd'
import { AppContext } from 'AppContext'

export default function ({ reportData }) {
  const {
    state: { isMobile },
  } = useContext(AppContext)
  const data = reportData.map((report, key) => {
    return { key, ...report }
  })
  const columns = [
    {
      title: 'Unhedge Report',
      className: 'report-title main-title',
      children: [
        {
          title: 'ClientCode',
          dataIndex: 'ClientCode',
          key: 'ClientCode',
          width: '20%',
          className: 'report-text',
        },
        {
          title: 'CE_QTY',
          dataIndex: 'CE_QTY',
          key: 'CE_QTY',
          width: isMobile && 100,
          className: 'report-numeric',
        },
        {
          title: 'PE_QTY',
          dataIndex: 'PE_QTY',
          key: 'PE_QTY',
          width: isMobile && 100,
          className: 'report-numeric',
        },
        {
          title: 'TotalEQ',
          dataIndex: 'TotalEQ',
          key: 'TotalEQ',
          width: isMobile && 100,
          className: 'report-numeric',
        },
        {
          title: 'UnhedgeQty',
          dataIndex: 'UnhedgeQty',
          key: 'UnhedgeQty',
          width: isMobile && 100,
          className: 'report-numeric',
        },
      ],
    },
  ]
  return (
    <Table
      columns={columns}
      dataSource={data}
      className='unhedge-report'
      pagination={{ pageSize: 1000, hideOnSinglePage: true }}
      scroll={{ x: true, y: true }}
    />
  )
}
