import React from 'react'
import { Modal, Form, Input, Select, Button } from 'antd'

import { getName } from 'utils/User'
import { GET_ALL_CLIENT } from '../../Users/graphql/Queries'
import { useQuery } from '@apollo/react-hooks'
import Page404 from 'components/Page404'

const { Option } = Select

const CollectionCreateForm = ({
  visible,
  onCreate,
  onUpdate,
  onCancel,
  isEdit,
  selectedGroup,
}) => {
  const [form] = Form.useForm()

  function resetForm() {
    form.resetFields()
  }

  const { data, loading, error } = useQuery(GET_ALL_CLIENT, {
    variables: {
      where: {},
      limit: 10000,
    },
    fetchPolicy: 'network-only',
  })

  if (error) return <Page404 />

  return (
    <Modal
      maskClosable={false}
      visible={visible}
      title={isEdit ? 'Edit Group' : 'Add New Group'}
      okText={isEdit ? 'Update Group' : 'Add Group'}
      cancelText='Cancel'
      onCancel={() => {
        resetForm()
        onCancel()
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            if (isEdit) {
              onUpdate(values, resetForm)
            } else {
              onCreate(values, resetForm)
            }
          })
          .catch((info) => console.log('Validate Failed:', info))
      }}
    >
      <Form
        form={form}
        layout='vertical'
        name='form_in_modal'
        initialValues={{
          modifier: 'public',
          ...selectedGroup,
        }}
      >
        <Form.Item
          name='name'
          label='Group Name'
          rules={[
            {
              required: true,
              message: 'Please input your group name!',
              whitespace: true,
            },
          ]}
          hasFeedback
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='userIds'
          label='Select Users'
          rules={[{ required: true, message: 'Please select users!' }]}
          hasFeedback
        >
          <Select
          getPopupContainer={(trigger) => trigger.parentElement}
            mode='multiple'
            loading={loading}
            filterOption={true}
            optionFilterProp='title'
            // onSearch={handleSearch}
            style={{ width: '100%' }}
            placeholder='Please select users'
          >
            {!loading &&
              data?.getClientUsers.users.map((user, key) => {
                return (
                  <Option
                    key={key}
                    value={user.id}
                    title={`${user.clientCode} - ${getName(user)}`}
                  >
                    {user.clientCode} - {getName(user)}
                  </Option>
                )
              })}
          </Select>
        </Form.Item>
        <div className='footer-btn-div'>
          <Button
            className='footer-cancel-btn'
            onClick={() => {
              resetForm()
              onCancel()
            }}
          >
            Cancel
          </Button>
          <Button
            className='footer-submit-btn'
            onClick={() => {
              form
                .validateFields()
                .then((values) => {
                  if (isEdit) {
                    onUpdate(values, resetForm)
                  } else {
                    onCreate(values, resetForm)
                  }
                })
                .catch((info) => console.log('Validate Failed:', info))
            }}
            type='primary'
          >
            {isEdit ? 'Update Group' : 'Add Group'}
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

const CreateGroupModal = (props) => {
  return (
    <div>
      <CollectionCreateForm {...props} />
    </div>
  )
}

export default CreateGroupModal
