import React, { useContext, useState } from 'react'
import { Table, Tooltip } from 'antd'
import { EditTwoTone } from '@ant-design/icons'
import { useQuery } from '@apollo/react-hooks'

import Page404 from 'components/Page404'
import { GET_ADVISORY_TIME } from './graphql/Query'
import CreateAdvisoryTime from './CreateAdvisoryTime'
import { AppContext } from 'AppContext'

const AdvisoryTime = () => {
  const [openModal, setOpenModal] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  let { data, loading, error } = useQuery(GET_ADVISORY_TIME, {
    fetchPolicy: 'network-only',
  })
  const {
    state: { isMobile },
  } = useContext(AppContext)
  if (error) return <Page404 />

  const handleCancel = () => {
    setOpenModal(false)
  }

  const updateAdvisoryTime = (values, resetForm) => {}

  const renderAction = (data) => {
    return (
      <div className='action-icons'>
        <Tooltip title={isMobile ? '' : 'Edit'}>
          <EditTwoTone onClick={() => setOpenModal(!openModal)} />
        </Tooltip>
      </div>
    )
  }

  const submitAdvisoryTime = (values, resetForm) => {
    updateAdvisoryTime(values, resetForm)
  }

  const columns = [
    {
      title: 'Start Time',
      dataIndex: 'startTime',
      render: (startTime) => {
        const formattedStartTime = startTime?.slice(0, 8)
        return formattedStartTime
      },
    },
    {
      title: 'End Time',
      dataIndex: 'endTime',
      render: (endTime) => {
        const formattedEndTime = endTime?.slice(0, 8)
        return formattedEndTime
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => renderAction(data?.getAdvisoryTime),
      width: 100,
    },
  ]

  return (
    <>
      <div className='main_dashboard_div'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
          }}
        >
          <div
            style={{
              fontSize: isMobile ? '18px' : '24px',
              fontWeight: '600',
              lineHeight: '26px',
              letterSpacing: '-0.02em',
              textAlign: 'left',
              color: '#181C32',
            }}
          >
            Advisory Working Time Dashboard
          </div>
        </div>
        <Table
          loading={loading}
          dataSource={
            data && data?.getAdvisoryTime ? [data?.getAdvisoryTime] : []
          }
          columns={columns}
        />
      </div>

      {openModal && (
        <CreateAdvisoryTime
          onCreate={submitAdvisoryTime}
          isModalOpen={openModal}
          setOpenModal={setOpenModal}
          setModalLoading={setModalLoading}
          handleCancel={handleCancel}
          modalLoading={modalLoading}
          data={data}
        />
      )}
    </>
  )
}

export default AdvisoryTime
