import React, { useState, useEffect, useContext } from 'react'
import { Button, Col, DatePicker, Form, InputNumber, Row, Select } from 'antd'
import client from 'apollo'
import { GET_GENERAL_SETTING } from '../graphql/Queries'
import moment from 'moment'
import { UPDATE_GENERAL_SETTING } from '../graphql/Mutations'
import { AppContext } from 'AppContext'
import openNotification from 'utils/Notification'
import CustomMeta from 'components/CustomMeta'
import Loader from 'components/loaders/Loader'

const FormItem = Form.Item
const formItemLayout = {
  labelCol: { xs: { span: 24 }, sm: { span: 24 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 24 } },
}

const GeneralInvoice = () => {
  const { dispatch } = useContext(AppContext)

  const [loading, setLoading] = useState(false)
  const [getGeneralData, setGetGeneralData] = useState({})
  const [updateGeneralSetting, setUpdateGeneralSetting] = useState(false)

  useEffect(() => {
    setLoading(true)
    client
      .query({
        query: GET_GENERAL_SETTING,
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        dispatch({
          type: 'GENERAL_SETTING',
          data: res.data.getGeneralSetting,
        })
        setGetGeneralData(res.data.getGeneralSetting)
        setLoading(false)
      })
    // .catch(() => )
  }, [dispatch])

  const onFinish = (values) => {
    setUpdateGeneralSetting(true)
    values = {
      ...values,
      amcEndDate: values.amcEndDate.format('YYYY-MM-DD'),
    }
    client
      .mutate({
        mutation: UPDATE_GENERAL_SETTING,
        variables: { data: values },
      })
      .then((res) => {
        setUpdateGeneralSetting(false)
        openNotification('success', 'General Setting Update Successfully!')
      })
      .catch((err) => console.log(err))
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const handleChange = (value) => {
    console.log(`selected ${value}`)
  }
  const onSearch = (value) => {
    console.log('search:', value)
  }

  const options = [
    {
      value: 'FIA',
      label: 'FIA',
    },
    {
      value: 'FIT',
      label: 'FIT',
    },
  ]

  return loading ? (
    <Loader />
  ) : (
    <div className='main_dashboard_div'>
      <CustomMeta title={'Invoice'} description='' />
      <div className='agreement_main_div'>
        <div className='agreement_text'>General Setting</div>
      </div>

      <Form
        {...formItemLayout}
        name='register'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        scrollToFirstError
        className='client-form'
        initialValues={{
          amcEndDate: moment(getGeneralData?.amcEndDate),
          niftyLotSize: getGeneralData?.niftyLotSize,
          company: getGeneralData?.company,
        }}
      >
        <Row>
          <Col
            className='gutter-row general_setting_col'
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
          >
            <FormItem
              name='amcEndDate'
              label='AMC End Date'
              rules={[
                {
                  required: true,
                  message: 'Amc End Date is require',
                },
              ]}
            >
              <DatePicker
                style={{ width: '100%' }}
                getPopupContainer={(trigger) => trigger.parentElement}
                placeHolder='AMC End Date'
                format={'DD-MMM-YYYY'}
              />
            </FormItem>
          </Col>
          <Col
            className='gutter-row general_setting_col'
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
          >
            <Form.Item
              label='Nifty Lot Size'
              name='niftyLotSize'
              rules={[
                {
                  required: true,
                  message: 'Please Enter Nifty Lot Size!',
                },
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                min={1}
                placeHolder='Nifty Lot Size'
              />
            </Form.Item>
          </Col>
          <Col
            className='gutter-row general_setting_col'
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
          >
            <Form.Item
              label='Company'
              name='company'
              rules={[
                {
                  required: true,
                  message: 'Please Select Company!',
                },
              ]}
            >
              <Select
                showSearch
                placeholder='Select a Company'
                getPopupContainer={(trigger) => trigger.parentElement}
                optionFilterProp='children'
                onChange={handleChange}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={options}
              />
            </Form.Item>
          </Col>
        </Row>
        <div className='general_setting_col'>
          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button
              type='primary'
              htmlType='submit'
              loading={updateGeneralSetting}
            >
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}

export default GeneralInvoice
