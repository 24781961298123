import { Button, Form, Input, Select, Drawer, Popover } from 'antd'
import React, { useState, useContext, useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { get, isEmpty } from 'lodash'
import _map from 'lodash/map'
import client from 'apollo'
import { FilterOutlined, DownloadOutlined } from '@ant-design/icons'
import './index.css'
import '../index.css'
import NoData from 'components/NoData'
import { AppContext } from 'AppContext'
// import DownloadReport from '../DownloadReport'
import { SP_CURRENT_POSITIONFO } from '../graphql/Queries'
import PositionFOTable from './PositionFOTable'
import { GET_STRATEGY_LIST } from 'modules/Settings/graphql/Queries'
import Page404 from 'components/Page404'
import Loader from 'components/loaders/Loader'
import FilterBackButton from 'assets/images/new-images/filter-back-button.svg'
import PdfIcon from 'assets/images/new-images/pdficon.svg'
import ExcelIcon from 'assets/images/new-images/excelIcon.svg'
import { exportGraphAsPdf } from 'utils/Graph'
import { CSVLink } from 'react-csv'
import { GET_CLIENT_USERS_FOR_STAFF } from 'modules/Users/graphql/Queries'
import { getName } from 'utils/User'

const { Option } = Select

export default function () {
  const [form] = Form.useForm()
  const [strategyList, setStrategyList] = useState([])
  const [open, setOpen] = useState(false)
  const {
    state: {
      isAdmin,
      isStaff,
      isMobile,
      currentUser: { clientCode, role },
    },
    dispatch,
  } = useContext(AppContext)
  const [filters, setFilters] = useState({
    clientCode: isAdmin || isStaff ? '' : clientCode,
    strategy: '',
    script: '',
  })
  const [selectedClientName, setSelectedClientName] = useState('')
  useEffect(() => {
    dispatch({ type: 'SELECTED_GROUP', data: undefined })
    dispatch({ type: 'SELECTED_STRATEGY', data: 'ILTS' })
  }, [dispatch])

  useEffect(() => {
    client
      .query({ query: GET_STRATEGY_LIST, fetchPolicy: 'network-only' })
      .then((res) => {
        setStrategyList(res.data.getStrategyList)
      })
  }, [])
  const { data: allClient, loading: userLoading } = useQuery(
    GET_CLIENT_USERS_FOR_STAFF,
    {
      variables: { where: {}, limit: 10000 },
      fetchPolicy: 'network-only',
    }
  )

  const { data, loading, error } = useQuery(SP_CURRENT_POSITIONFO, {
    variables: { where: filters },
  })

  if (loading) return <Loader />
  if (error) return <Page404 error={error} />
  if (!get(data, 'spCurrentPositionFO')) return <NoData />

  let currentPositionFOBalanceData
  const { spCurrentPositionFO } = data
  currentPositionFOBalanceData = spCurrentPositionFO.map((EqBalance) => {
    // delete EqBalance.company
    return {
      ...EqBalance,
      Strike: Math.round(EqBalance.Strike),
      qty: Math.round(EqBalance.qty),
      Value: Math.round(EqBalance.NetPrice),
      NetPrice: Math.round(EqBalance.NetPrice),
    }
  })

  const child = [
    {
      title: 'Client Code',
      dataIndex: 'ClientCode',
      key: 'ClientCode',
      width: isMobile && 100,
      className: 'report-text',
    },
    {
      title: 'Strategy Code',
      dataIndex: 'StrategyCode',
      key: 'StrategyCode',
      width: isMobile && 150,
      className: 'report-numeric',
    },
    {
      title: 'Script',
      dataIndex: 'script',
      key: 'script',
      width: isMobile && 150,
      className: 'report-numeric-position-FO',
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      width: isMobile && 100,
      className: 'report-numeric',
    },
    {
      title: 'Expiry Date',
      dataIndex: 'ExpiryDate',
      key: 'ExpiryDate',
      width: isMobile && 80,
      className: 'report-numeric',
    },
    {
      title: 'Strike',
      dataIndex: 'Strike',
      key: 'Strike',
      width: isMobile && 100,
      className: 'report-numeric',
    },
    {
      title: 'CP',
      dataIndex: 'CP',
      key: 'CP',
      width: isMobile && 100,
      className: 'report-numeric',
    },
    {
      title: 'qty',
      dataIndex: 'qty',
      key: 'qty',
      width: isMobile && 100,
      className: 'report-numeric',
    },
    {
      title: 'Value',
      dataIndex: 'Value',
      key: 'Value',
      width: isMobile && 100,
      className: 'report-numeric',
      // render: (text) => Math.round(text)
    },
    {
      title: 'NetPrice',
      dataIndex: 'NetPrice',
      key: 'NetPrice',
      width: isMobile && 100,
      className: 'report-numeric',
    },
  ]

  const columns = [
    {
      title: 'FO Position Report',
      className: 'report-title main-title',
      children:
        role !== 'ADMIN'
          ? child
          : child?.filter((item) => item?.title !== 'Company'),
    },
  ]

  const onFinish = (values) => {
    setFilters({
      ...values,
      clientCode: selectedClientName,
      strategy: values.strategy ? values.strategy : '',
      script: values.script ? values.script : '',
    })
    setOpen(false)
  }

  let staticHtml = ``
  if (get(data, 'spCurrentPositionFO')) {
    staticHtml = `
<div class="ant-table-wrapper nlv-tracker-report">
  <div class="ant-spin-nested-loading">
    <div class="ant-spin-container">
      <div class="ant-table">
        <div class="ant-table-container">
          <div class="ant-table-content">
            <table style="table-layout: auto;">
              <colgroup>
                <col style="width: 60px; min-width: 60px;">
                <col>
                <col>
                <col>
                <col>
                <col>
                <col>
                <col>
                <col style="width: 200px; min-width: 200px;">
              </colgroup>
              <thead class="ant-table-thead">
                <tr>
                  <th colspan="14" class="ant-table-cell report-title main-title">FO Position Report</th>
                </tr>
                <tr>
                  <th class="ant-table-cell report-text">Client Code</th>
                  <th class="ant-table-cell report-text">StrategyCode</th>
                  <th class="ant-table-cell report-text">script</th>
                  <th class="ant-table-cell report-text">company</th>
                  <th class="ant-table-cell report-numeric">ExpiryDate</th>
                  <th class="ant-table-cell report-numeric">Strike</th>
                  <th class="ant-table-cell report-text">CP</th>
                  <th class="ant-table-cell report-numeric">qty</th>
                  <th class="ant-table-cell report-numeric">Value</th>
                  <th class="ant-table-cell report-numeric">NetPrice</th>
                </tr>
              </thead>
              <tbody class="ant-table-tbody">`
    staticHtml = `${staticHtml}`
    data.spCurrentPositionFO.forEach((data) => {
      const {
        ClientCode,
        StrategyCode,
        script,
        company,
        ExpiryDate,
        Strike,
        CP,
        qty,
        Value,
        NetPrice,
      } = data
      staticHtml = `${staticHtml}
                <tr data-row-key="KYP" class="ant-table-row ant-table-row-level-0">
                  <td class="ant-table-cell report-text">${ClientCode} </td>
                  <td class="ant-table-cell report-text">${StrategyCode}</td>
                  <td class="ant-table-cell report-text">${script}</td>
                  <td class="ant-table-cell report-text">${company}</td>
                  <td class="ant-table-cell report-numeric">${ExpiryDate}</td>
                  <td class="ant-table-cell report-numeric">${Strike}</td>
                  <td class="ant-table-cell report-text">${CP}</td>
                  <td class="ant-table-cell report-numeric">${qty}</td>
                  <td class="ant-table-cell report-numeric">${Value.toFixed(
                    2
                  )}</td>
                  <td class="ant-table-cell report-numeric">${NetPrice}</td>
                </tr>`
    })
    staticHtml = `${staticHtml}
              </tbody >
            </table >
          </div >
        </div >
      </div >
    </div >
  </div >
</div >
      `
  }

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  const handleDownloadPdf = () => {
    exportGraphAsPdf(
      'current-positionFO',
      'SP_CURRENT_POSITIONFO_PDF',
      staticHtml,
      true
    ).finally(() =>
      console.log(' ====== current-positionFO downloaded ====== ')
    )
  }

  const content = (
    <div style={{ width: '100px' }}>
      <div
        onClick={handleDownloadPdf}
        style={{
          marginBottom: `${isMobile ? '0px' : '10px'}`,
          fontSize: '14px',
          fontWeight: '600',
          lineHeight: '24px',
          textAlign: 'left',
          color: ' #000',
          cursor: 'pointer',
        }}
      >
        <img src={PdfIcon} style={{ marginRight: '10px' }} alt='pdf' /> PDF
      </div>

      <CSVLink
        style={{ paddingRight: 0 }}
        data={spCurrentPositionFO}
        className='csv-btn'
        filename={`current-positionFO`}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          style={{
            marginBottom: `${isMobile ? '0px' : '10px'}`,
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '24px',
            textAlign: 'left',
            color: ' #000',
            cursor: 'pointer',
          }}
        >
          <img src={ExcelIcon} style={{ marginRight: '10px' }} alt='excel' />{' '}
          Excel
        </div>
      </CSVLink>
    </div>
  )

  return (
    <>
      {/* <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          margin: 0,
        }}
      >
        {!error && (
          <DownloadReport
            data={spCurrentPositionFO}
            staticHtml={staticHtml}
            fileName='current-positionFO'
            id='SP_CURRENT_POSITIONFO_PDF'
          />
        )}
      </div> */}

      <Drawer
        title={null}
        headerStyle={{ display: 'none' }}
        closable={false}
        placement='right'
        onClose={onClose}
        visible={open}
        width={472}
      >
        <div className='Filter_Form'>
          <div onClick={onClose}>
            <img src={FilterBackButton} alt='FilterBackButton' />
          </div>
          <div className='filter_panel_heading'>Filter</div>
        </div>

        <Form
          form={form}
          onFinish={onFinish}
          name='advanced_search'
          layout='vertical'
          className='client-detail-form'
        >
          <div className='filter_Form_div_Container'>
            <Form.Item
              name='clientCode'
              label='Select Client Code'
              rules={[{ required: true, message: 'Please select clientCode!' }]}
              hasFeedback
            >
              <Select
                style={{ width: '100%' }}
                getPopupContainer={(trigger) => trigger.parentElement}
                className='display-field'
                showSearch
                loading={userLoading}
                placeholder='Select Client Code'
                optionFilterProp='children'
                filterOption={(input, option) =>
                  (option?.children[0] ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase()) ||
                  (option?.children[2] ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onChange={(value, option) => {
                  setSelectedClientName(value)
                }}
              >
                {!userLoading &&
                  allClient &&
                  allClient?.getClientUsersForStaff?.users.map((user, key) => {
                    return (
                      <Option
                        key={key}
                        value={user?.clientCode}
                        title={getName(user)}
                      >
                        {user?.clientCode}-{getName(user)}
                      </Option>
                    )
                  })}
              </Select>
            </Form.Item>
            <Form.Item name='script' label='script'>
              <Input placeholder='Enter script' style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item name='strategy' label='strategy'>
              <Select
                className='gx-mr-3 gx-select-sm'
                getPopupContainer={(trigger) => trigger.parentElement}
                onChange={(e) =>
                  dispatch({ type: 'SELECTED_STRATEGY', data: e })
                }
              >
                {_map(strategyList, (strategy, key) => {
                  const { StrategyName, StrategyCode } = strategy
                  return (
                    <Option value={StrategyCode} key={key}>
                      {StrategyName}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
          </div>
          <div className='filter_panel_button'>
            <Button
              onClick={() => {
                form.resetFields()
                setFilters({})
                setOpen(false)
              }}
            >
              Clear
            </Button>
            <Button type='primary' htmlType='submit'>
              Search
            </Button>
          </div>
        </Form>
      </Drawer>
      {error ? (
        <Page404 error={error} isShow={false} />
      ) : (
        <div className='report_Container'>
          <div className='container_Header'>
            <div className='report_Heading'>Position FO</div>

            <div className='container_Export_Popover'>
              {!error ? (
                <Popover content={content} placement='bottom' trigger='click'>
                  <div className='container_Export_Popover'>
                    <Button>
                      Export{' '}
                      <DownloadOutlined
                        color='#AF0000'
                        style={{ fontSize: '16px' }}
                      />
                    </Button>
                  </div>
                </Popover>
              ) : (
                ''
              )}

              <Button
                style={{
                  color: '#000000',
                  border: '1px solid #919EAB52',

                  backgroundColor: 'white',
                }}
                onClick={showDrawer}
              >
                Filter{' '}
                <FilterOutlined color='#000000' style={{ fontSize: '16px' }} />
              </Button>
            </div>
          </div>

          {!isEmpty(filters) && (
            <div
              className='report-table-wrapper'
              id='SP_CURRENT_POSITIONFO_PDF'
            >
              <PositionFOTable
                filters={filters}
                data={currentPositionFOBalanceData}
                columns={columns}
                loading={loading}
              />
            </div>
          )}
        </div>
      )}
    </>
  )
}
