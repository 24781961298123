import React, { useState, useContext, Fragment } from 'react'
import { useQuery } from '@apollo/react-hooks'
import _reverse from 'lodash/reverse'
import _forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'
import _sortBy from 'lodash/sortBy'
// import { PageHeader } from 'antd'
import _find from 'lodash/find'
import pick from 'lodash/pick'
import omit from 'lodash/omit'
import get from 'lodash/get'
import moment from 'moment'

import './clients.css'
import client from 'apollo'
import { getName } from 'utils/User'
import { AppContext } from 'AppContext'
import Page404 from 'components/Page404'
import CustomMeta from 'components/CustomMeta'
import Loader from 'components/loaders/Loader'
import ClientForm from './components/ClientForm'
import openNotification from 'utils/Notification'
import { GET_CURRENT_USER } from 'auth/graphql/Queries'
import { GET_USER, GET_USER_REVENUE_BY_CLIENT } from '../Users/graphql/Queries'
import {
  UPDATE_USER,
  UPDATE_KYC,
  CREATE_USER_REVENUE,
  UPDATE_USER_REVENUE,
  DELETE_USER_REVENUE,
} from '../Users/graphql/Mutations'
import FilterBackIcon from 'assets/images/new-images/filter-back-button.svg'

async function getFilesObject(tempFiles) {
  let files = pick(tempFiles, [
    'panCard',
    'signature',
    'aadharCard',
    'passportSize',
    'bankStatement',
    'cancelledChequeSnap',
  ])

  if (tempFiles.panCard.length !== 0) {
    files.panCard = get(files, 'panCard[0].originFileObj')
  } else {
    files.panCard = null
  }

  if (tempFiles.signature.length !== 0) {
    files.signature = get(files, 'signature[0].originFileObj')
  } else {
    files.signature = null
  }

  if (tempFiles.aadharCard.length !== 0) {
    files.aadharCard = get(files, 'aadharCard[0].originFileObj')
  } else {
    files.aadharCard = null
  }

  if (tempFiles.passportSize.length !== 0) {
    files.passportSize = get(files, 'passportSize[0].originFileObj')
  } else {
    files.passportSize = null
  }

  if (tempFiles.bankStatement.length !== 0) {
    files.bankStatement = get(files, 'bankStatement[0].originFileObj')
  } else {
    files.bankStatement = null
  }

  if (tempFiles.cancelledChequeSnap.length !== 0) {
    let chequeFile = []
    tempFiles.cancelledChequeSnap.forEach((data) => {
      if (data?.originFileObj) {
        const file = get(data, 'originFileObj')
        chequeFile.push(file)
      } else {
        chequeFile.push(data.url)
      }
    })
    files.cancelledChequeSnap = chequeFile
  } else {
    files.cancelledChequeSnap = null
  }

  return files
}

export default function (props) {
  let initialValues = {}
  const { state, dispatch } = useContext(AppContext)
  const {
    state: { isMobile },
  } = useContext(AppContext)
  const [formLoading, setFormLoading] = useState(false)
  let {
    history,
    match: {
      path,
      params: { clientId },
    },
  } = props
  const isProfilePage = path.includes('profile')
  clientId = clientId || state.currentUser.id
  const { data, loading, error } = useQuery(GET_USER, {
    variables: { id: clientId },
    fetchPolicy: 'network-only',
  })
  const UserData = data
  const {
    data: revenueData,
    loading: revenueLoading,
    error: revenueError,
  } = useQuery(GET_USER_REVENUE_BY_CLIENT, {
    variables: { clientId: parseInt(clientId) },
    fetchPolicy: 'network-only',
  })

  if (loading || revenueLoading) return <Loader />
  if (error || revenueError) return <Page404 />

  if (!loading && get(data, 'getUser')) {
    initialValues = JSON.parse(JSON.stringify(data.getUser))
    initialValues.amcStartDate = initialValues.amcStartDate
      ? moment(initialValues.amcStartDate)
      : undefined
    initialValues.sipStartDate = initialValues.sipStartDate
      ? moment(initialValues.sipStartDate)
      : undefined
    initialValues.disableDate = initialValues.disableDate
      ? moment(initialValues.disableDate)
      : undefined
    initialValues.dateOfBirth = initialValues.dateOfBirth
      ? moment(initialValues.dateOfBirth)
      : undefined
    initialValues.closedDate = initialValues.closedDate
      ? moment(initialValues.closedDate)
      : undefined
    initialValues.createdAt = initialValues.createdAt
      ? moment(initialValues.createdAt)
      : undefined
    initialValues.registrationDate = initialValues.registrationDate
      ? moment(initialValues.registrationDate)
      : undefined
    initialValues.investmentDate = initialValues.investmentDate
      ? moment(initialValues.investmentDate)
      : undefined
    initialValues.createdById = initialValues.createdBy
      ? initialValues.createdBy.id
      : undefined
    initialValues.otherMailIds = initialValues.otherMailIds || []
    initialValues.discountStatus = +initialValues.discountStatus || null
    initialValues.currentCompletedSteps = initialValues.completedSteps
  }

  if (!loading && get(revenueData, 'getUserRevenueByClient')) {
    const allRatio = _sortBy(get(revenueData, 'getUserRevenueByClient'), [
      'level',
    ])
    _forEach(allRatio, function (ratio) {
      initialValues[`ratio_${ratio.chainMember.id}`] = ratio.ratio
    })
  }

  async function getNewValues(values) {
    let users = []
    Object.keys(values).forEach((value, key) => {
      if (
        (values[value] || values[value] === 0) &&
        typeof values[value] === 'number' &&
        value.startsWith('ratio_')
      ) {
        users.push({
          userId: value.replace('ratio_', ''),
          ratio: values[value],
        })
        delete values[value]
      } else if (value.startsWith('ratio_') && values[value] === null) {
        delete values[value]
      }
    })
    values.ratio = JSON.stringify(users)
    return values
  }

  function addUpdateRatio(ratio, level, isNew) {
    const find = _find(
      get(revenueData, 'getUserRevenueByClient'),
      (revenue) => revenue.chainMember.id === ratio.userId
    )
    if (!isNew && find) {
      client
        .mutate({
          mutation: UPDATE_USER_REVENUE,
          variables: {
            data: {
              ratio: parseFloat(ratio.ratio),
            },
            where: {
              id: find.id,
            },
          },
        })
        .catch((err) => console.log(err))
    } else {
      client
        .mutate({
          mutation: CREATE_USER_REVENUE,
          variables: {
            data: {
              level,
              clientId: parseInt(clientId),
              ratio: parseFloat(ratio.ratio),
              year: parseInt(moment().format('YYYY')),
              chainMemberId: parseInt(ratio.userId),
            },
          },
        })
        .catch((err) => console.log(err))
    }
  }

  async function onFinish(values) {
    values = {
      ...values,
      firstName:
        values.firstName.charAt(0).toUpperCase() +
        values.firstName.slice(1).toLowerCase(),
      lastName:
        values.lastName.charAt(0).toUpperCase() +
        values.lastName.slice(1).toLowerCase(),
      motherName:
        values.motherName.charAt(0).toUpperCase() +
        values.motherName.slice(1).toLowerCase(),
      fatherName:
        values.fatherName.charAt(0).toUpperCase() +
        values.fatherName.slice(1).toLowerCase(),
      email: values.email.toLowerCase(),
      dateOfBirth: values?.dateOfBirth?.format('YYYY-MM-DD'),
      amcStartDate: values?.amcStartDate
        ? values?.amcStartDate?.format('YYYY-MM-DD')
        : null,
      closedDate: values?.closedDate?.format('YYYY-MM-DD'),
      disableDate: values?.disableDate
        ? values?.disableDate?.format('YYYY-MM-DD')
        : null,
      investmentDate: values?.investmentDate
        ? values?.investmentDate?.format('YYYY-MM-DD')
        : null,
      registrationDate: values?.registrationDate
        ? values?.registrationDate?.format('YYYY-MM-DD')
        : null,
      sipStartDate: values?.sipStartDate
        ? values?.sipStartDate?.format('YYYY-MM-DD')
        : null,
      commissionSelectUser:
        values?.commissionSelectUser !== undefined
          ? values?.commissionSelectUser
          : null,
      createdById:
        values?.createdById === undefined ? null : values?.createdById,
      discountStatus: values?.discountStatus?.toString(),
      isBookingAdvisoryDateSelected:
        (values?.completedSteps !== 'CONGRATULATION' ||
          values?.completedSteps === 'DONE') &&
        false,
      isNewAuaAdvisoryGenerate: false,
      isActive:
        values?.disableDate && new Date(values?.disableDate) <= new Date()
          ? false
          : true,
    }
    let allRatio
    setFormLoading(true)
    let data = omit(values, [
      'panCard',
      'signature',
      'aadharCard',
      'passportSize',
      'bankStatement',
      'cancelledChequeSnap',
    ])
    data = await getNewValues(data)

    if (data.plan) {
      data.planId = parseInt(data.plan)
    }
    delete data.plan
    if (data.ratio) {
      allRatio = JSON.parse(data.ratio)
      allRatio = _reverse(allRatio)
      delete data.ratio
    }

    const queryVariables = { id: clientId, data }
    if (initialValues.createdById !== data.createdById) {
      await client
        .mutate({
          mutation: DELETE_USER_REVENUE,
          variables: { where: { clientId: parseInt(clientId) } },
        })
        .catch((err) => console.log(err))
    }

    _forEach(allRatio, function (ratio, key) {
      addUpdateRatio(
        ratio,
        key + 1,
        initialValues.createdById !== data.createdById
      )
    })

    client
      .mutate({ mutation: UPDATE_USER, variables: queryVariables })
      .then(async (res) => {
        const files = await getFilesObject(values)
        if (isEmpty(files)) {
          openNotification('success', 'Client Updated Successfully')
          if (isProfilePage) {
            client
              .query({ query: GET_CURRENT_USER, fetchPolicy: 'network-only' })
              .then((res) =>
                dispatch({
                  type: 'CURRENT_USER',
                  data: res.data.currentUser,
                })
              )
              .catch((err) => console.log(err))
          }
          history.goBack()
        } else {
          client
            .mutate({
              mutation: UPDATE_KYC,
              variables: { id: parseInt(clientId), ...files },
            })
            .then((res) => {
              openNotification('success', 'Client Updated Successfully')
              history.goBack()
              setFormLoading(false)
            })
            .catch((err) => console.log(err))
        }
      })
      .catch((err) => console.log(err))
  }

  return (
    <Fragment>
      <CustomMeta title={getName(initialValues)} description='Edit Client' />
      <div style={{ padding: `${state.isMobile ? '20px' : '32px 40px'}` }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '16px',
            width: '100%',
          }}
        >
          <div
            style={{
              fontSize: isMobile ? '18px' : '24px',
              fontWeight: '600',
              lineHeight: '26px',
              letterSpacing: '-0.02em',
              textAlign: 'left',
              color: '#181C32',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div
              onClick={() => history.goBack()}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <img style={{ cursor: 'pointer' }} src={FilterBackIcon} alt='' />{' '}
              &nbsp; Edit Client
            </div>
          </div>
        </div>
        <ClientForm
          onFinish={onFinish}
          initialValues={initialValues}
          loading={formLoading}
          isUpdate
          userData={UserData}
        />
      </div>
    </Fragment>
  )
}
