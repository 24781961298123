import { get, isEmpty } from 'lodash'
import React, { useContext, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Form, Select, Button, Drawer } from 'antd'

import { DownloadOutlined, FilterOutlined } from '@ant-design/icons'
import '../index.css'
import { getName } from 'utils/User'
import NoData from 'components/NoData'
import Page404 from 'components/Page404'
import Loader from 'components/loaders/Loader'
import { GET_CLIENT_USERS_FOR_STAFF } from 'modules/Users/graphql/Queries'
import {
  GET_CAPITAL_GAIN,
  GET_FINANCIAL_YEAR,
  GET_TAXATION_REPORT_PDF,
} from '../graphql/Queries'
import client from 'apollo'
import './index.css'
import { convertNumberToCommaSeparate } from 'utils/utilities'
import FilterBackButton from 'assets/images/new-images/filter-back-button.svg'
import { AppContext } from 'AppContext'

const { Option } = Select

export default function IltsTaxationReport() {
  const [form] = Form.useForm()
  const [filters, setFilters] = useState({})
  const [selectedFinancialYear, setSelectedFinancialYear] = useState({})
  const [open, setOpen] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)

  const {
    state: { isMobile },
  } = useContext(AppContext)
  const {
    data = {},
    loading,
    error,
  } = useQuery(GET_CAPITAL_GAIN, {
    variables: filters,
    fetchPolicy: 'network-only',
  })
  const { data: financialYearData, loading: financialYearLoading } = useQuery(
    GET_FINANCIAL_YEAR,
    { fetchPolicy: 'network-only' }
  )

  const { data: allClient, loading: userLoading } = useQuery(
    GET_CLIENT_USERS_FOR_STAFF,
    {
      variables: { where: {}, limit: 10000 },
    }
  )

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  let profitLossData = []
  let descriptionData = []
  let finalProfitLoss, finalFuture, expenses, totalProfitLoss

  if (!loading && get(data, 'capitalGain')) {
    profitLossData = data.capitalGain.filter((entry) =>
      entry.hasOwnProperty('Particular')
    )
    descriptionData = data.capitalGain.filter((entry) =>
      entry.hasOwnProperty('Description')
    )
    const profitLoss = profitLossData?.filter(
      (item) => item?.Particular === 'PROFIT/LOSS'
    )

    finalProfitLoss = profitLoss[0]?.CALL + profitLoss[0]?.PUT
    finalFuture = profitLoss[0]?.FUTURE
    expenses = descriptionData?.find(
      (item) => item?.Description === 'Total'
    )?.Value
    totalProfitLoss = finalProfitLoss + expenses
  }

  const onFinish = (values) => {
    const { clientCode, selectYear } = values
    const financialYear = financialYearData.getFinancialYear.find(
      (f) => f.FA_YEAR_ID === selectYear
    )
    setSelectedFinancialYear(financialYear)
    const openingDate = financialYear.OPENING_DATE
    const startDate = financialYear.START_DATE
    const endDate = financialYear.END_DATE
    setFilters({
      where: {
        openingDate,
        clientCode,
        startDate,
        endDate,
      },
    })
    setOpen(false)
  }

  const downloadTaxationReport = () => {
    setBtnLoading(true)
    client
      .query({
        query: GET_TAXATION_REPORT_PDF,
        variables: { ...filters },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        setBtnLoading(false)
        window.open(res?.data?.getTaxationReportPdf, '_blank')
      })
      .catch((err) => {
        setBtnLoading(false)
        console.log(err)
      })
  }
  return (
    <>
      <Drawer
        title={null}
        headerStyle={{ display: 'none' }}
        closable={false}
        placement='right'
        onClose={onClose}
        visible={open}
        width={472}
      >
        <div
          style={{
            margin: `${isMobile ? '20px 0 20px 0' : '32px 0 50px 0'}`,
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <div onClick={onClose}>
            <img src={FilterBackButton} alt='FilterBackButton' />
          </div>
          <div className='taxation_btn_Filter'>Filter</div>
        </div>
        <Form
          form={form}
          onFinish={onFinish}
          name='advanced_search'
          style={{ marginTop: '16px' }}
          layout='vertical'
          className='client-detail-form'
        >
          <div
            style={{
              height: `${
                isMobile ? 'calc(100vh - 13rem)' : 'calc(100vh - 12rem)'
              }`,
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            <Form.Item
              name='clientCode'
              label='Select Users'
              rules={[{ required: true, message: 'Please select users!' }]}
              hasFeedback
            >
              <Select
                getPopupContainer={(trigger) => trigger.parentElement}
                showSearch
                loading={userLoading}
                optionFilterProp='children'
                style={{ width: '100%' }}
                placeholder='Please select users'
                className='display-field'
                filterOption={(input, option) =>
                  (option?.children[0] ?? '').toLowerCase().includes(input) ||
                  (option?.children[2] ?? '').toLowerCase().includes(input)
                }
              >
                {!userLoading &&
                  allClient &&
                  allClient?.getClientUsersForStaff?.users.map((user, key) => {
                    return (
                      <Option
                        key={key}
                        value={user.clientCode}
                        title={getName(user)}
                      >
                        {user.clientCode}-{getName(user)}
                      </Option>
                    )
                  })}
              </Select>
            </Form.Item>
            <Form.Item
              name='selectYear'
              label='Select Year'
              rules={[{ required: true, message: 'Please select year!' }]}
              hasFeedback
            >
              <Select
                showSearch
                getPopupContainer={(trigger) => trigger.parentElement}
                loading={financialYearLoading}
                style={{ width: '100%' }}
                placeholder='Select Year'
                optionFilterProp='children'
                className='display-field'
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {!financialYearLoading &&
                  financialYearData &&
                  financialYearData.getFinancialYear.map((financial) => {
                    return (
                      <Option value={financial.FA_YEAR_ID}>
                        {financial.FA_YEAR}
                      </Option>
                    )
                  })}
              </Select>
            </Form.Item>
          </div>
          <div
            style={{
              display: 'flex',
              gap: '20px',
              height: isMobile ? '50px' : '56px',
            }}
          >
            <Button
              onClick={() => {
                form.resetFields()
                setFilters({})
                setOpen(false)
                setSelectedFinancialYear({})
              }}
              style={{
                width: '100%',
                borderRadius: '8px',
                padding: isMobile ? '8px' : '16px',
                height: isMobile ? 'auto' : '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              Cancel
            </Button>
            <Button
              loading={loading}
              type='primary'
              htmlType='submit'
              style={{
                width: '100%',
                borderRadius: '8px',
                padding: isMobile ? '8px' : '16px',
                height: isMobile ? 'auto' : '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              Apply
            </Button>
          </div>
        </Form>
      </Drawer>

      <div className='report_Container'>
        <div className='container_Header'>
          <div className='report_Heading'>
            {!selectedFinancialYear?.START_DATE?.length &&
            !selectedFinancialYear?.END_DATE?.length
              ? 'F&O Derivative P&L Report'
              : `Summary of F&O Derivative P&L Report ${selectedFinancialYear?.START_DATE} To
            ${selectedFinancialYear?.END_DATE}`}
          </div>
          <div className='container_Export_Popover'>
            {selectedFinancialYear?.START_DATE?.length &&
              selectedFinancialYear?.END_DATE?.length && (
                <Button disabled={btnLoading} onClick={downloadTaxationReport}>
                  Export{' '}
                  <DownloadOutlined
                    color='#AF0000'
                    style={{ fontSize: '16px' }}
                  />
                </Button>
              )}
            <Button
              style={{
                color: '#000000',
                border: '1px solid #919EAB52',
                backgroundColor: 'white',
              }}
              onClick={showDrawer}
            >
              Filter{' '}
              <FilterOutlined color='#000000' style={{ fontSize: '16px' }} />
            </Button>
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : error ? (
          <Page404 />
        ) : !get(data, 'capitalGain') || isEmpty(filters) ? (
          <NoData />
        ) : (
          <div className='taxation_Report_Table'>
            <div
              className='taxation_Report_Data_Table'
              style={{ display: 'flex' }}
            >
              <div className='taxation_Report_Table_Heading'>
                Profit & Loss in Futures
                <div className='taxation_Report_Data_Values'>
                  ₹{' '}
                  {convertNumberToCommaSeparate(
                    +parseFloat(finalFuture).toFixed()
                  )}
                </div>
              </div>
              <div className='taxation_Report_Table_Heading'>
                Profit & Loss in Options
                <div className='taxation_Report_Data_Values'>
                  ₹{' '}
                  {convertNumberToCommaSeparate(
                    +parseFloat(finalProfitLoss).toFixed()
                  )}
                </div>
              </div>
              <div className='taxation_Report_Table_Heading'>
                Expenses
                <div className='taxation_Report_Data_Values'>
                  ₹{' '}
                  {
                    +convertNumberToCommaSeparate(
                      parseFloat(expenses).toFixed()
                    )
                  }
                </div>
              </div>
              <div
                className='taxation_Report_Table_Heading'
                style={{ borderBottom: 'none', borderRight: 'none' }}
              >
                Total Profit & Loss
                <div className='taxation_Report_Data_Values'>
                  ₹{' '}
                  {convertNumberToCommaSeparate(
                    +parseFloat(totalProfitLoss).toFixed()
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
