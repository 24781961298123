import gql from 'graphql-tag'

export const GET_SECTIONS = gql`
  query getSections($planId: ID!, $forNonIndividual: Boolean) {
    getSections(
      where: { planId: $planId, forNonIndividual: $forNonIndividual }
    ) {
      id
      section
      index
      forNonIndividual
      questions {
        id
        question
        index
        type
        isRequired
        point
        includeInvestmentValidation
        options {
          id
          option
          point
          isDefault
          value
          multiplication
        }
      }
    }
  }
`

export const GET_QUESTIONS = gql`
  query getQuestions {
    getQuestions {
      id
      question
      index
      type
      point
      includeInvestmentValidation
      options {
        id
        option
        point
        isDefault
        value
        multiplication
      }
    }
  }
`
export const GET_PLANS_BASED_POINTS = gql`
  query fetchPlansBasedOnPoints($points: Int) {
    fetchPlansBasedOnPoints(where: { points: $points }) {
      id
      name
      riskAppetite
      planRiskType
    }
  }
`
