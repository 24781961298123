import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Row } from 'antd'
import client from 'apollo'

import { UPDATE_USER } from 'modules/Users/graphql/Mutations'
import { AppContext } from 'AppContext'
import CustomMeta from 'components/CustomMeta'
import { GET_IMAGES } from 'modules/LoginImages/graphql/Query'
import Loader from 'components/loaders/Loader'
import { useQuery } from '@apollo/react-hooks'
import Page404 from 'components/Page404'
import { random } from 'lodash'
// import PlayStore from 'assets/images/new-images/play-store.svg'
// import AppStore from 'assets/images/new-images/app-store.svg'
// import PhoneImage from 'assets/images/new-images/phone-image.png'

const RiskProfileFormNew = ({ next }) => {
  const { dispatch, state } = useContext(AppContext)
  const [imageUrl, setImageUrl] = useState('')
  const { id } = state.currentUser
  const { isMobile } = state

  const onAgree = () => {
    dispatch({ type: 'IS_ACCEPT_LOADING', data: true })
    client
      .mutate({
        mutation: UPDATE_USER,
        variables: {
          id,
          data: {
            completedSteps: 'LOGIN_CREATED',
            memberName: state?.currentUser?.memberName
              ? state?.currentUser?.memberName
              : 5,
            createdById: state?.currentUser?.createdBy?.id
              ? state?.currentUser?.createdBy?.id
              : '1',
          },
        },
      })
      .then((res) => {
        dispatch({
          type: 'CURRENT_USER',
          data: res.data.updateUser,
        })
        dispatch({ type: 'IS_ACCEPT_LOADING', data: false })
        next()
      })
      .catch((err) => console.log(err))
  }

  const { loading, error, data } = useQuery(GET_IMAGES, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (!loading) {
      setImageUrl(
        data?.getAllLoginImages[random(0, data?.getAllLoginImages?.length - 1)]
          .imageUrl
      )
    }
  }, [data, loading])

  if (error) return <Page404 error={error} />
  if (loading) return <Loader />

  return (
    <>
      <Row className='row-style'>
        <Col sm={24} lg={16} className='col-left disclaimer-col'>
          <div>
            <CustomMeta title='Risk Profile' />
            <div className='risk-profile-form'>
              <div className='heading-title-for-disclaimer'>Dear Investor,</div>
              <div className='disclaimer-description'>
                Clients' financial risk tolerance - attitudes, values,
                motivations, preferences and experiences, is measured with a
                risk profile. The risk profile questionnaire helps in
                understanding the risk tolerance level as well as time horizon
                in investing. The questionnaire is designed to show which type
                of investment approach may suit you best. Each answer would be
                given a point. The total score would suggest the appropriate
                risk profiling and risk assessment and suitability of service
                for you.
              </div>
              <div className='disclaimer-description'>
                Investment involves risk. The price of securities may go down as
                well as up, and under certain circumstances an investor may
                sustain a total or substantial loss of investment. Past
                performance is not necessarily indicative of the future or
                likely performance. Investors should read the relevant
                information document/ statement of additional information and do
                their own further research before making any investment
                decisions. An Investor should make an appraisal of the risks
                involved in investing in these products and should consult their
                own independent and professional advisors, to ensure that any
                decision made is suitable with regards to their circumstances
                and financial position.
              </div>
              <div className='disclaimer-description'>
                Our advice: There are not any right or wrong answers, please
                follow your instincts and answer the questions. Please answer
                the following questions by selecting only one response to each
                question
              </div>
              <div className='disclaimer-description'>
                IMPORTANT: Before making any investment decisions, it is
                important to understand your attitude towards risk. This helps
                identify an appropriate mix of investments that you are
                comfortable with. The Finideas Investment Advisor Private
                Limited Risk Profiling Tool will help you understand your
                ability to bear risk and identify the asset classes to match
                your investment needs
              </div>
              <div className='disclaimer-notes'>
                NOTE :- All fields are mandatory.
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: `${isMobile ? 'end' : 'center'}`,
                  marginTop: '10px',
                  marginBottom: isMobile ? '10dvh' : '2px',
                }}
              >
                <Button
                  // id='btn-click'
                  type='primary'
                  onClick={onAgree}
                  className='yes-next-button'
                >
                  I Agree
                </Button>
              </div>
            </div>
          </div>
        </Col>
        <Col span={8} className='col-right disclaimer-col disclaimer-col-image'>
          <div
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src={imageUrl} alt='img' />
          </div>
        </Col>
      </Row>
    </>
  )
}

export default RiskProfileFormNew
