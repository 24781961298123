import React from 'react'

import { Button, Tabs, Table } from 'antd'

import './pendingAdvisory.css'
import { convertNumberToCommaSeparate } from 'utils/utilities'

const TabPane = Tabs.TabPane

const ScenarioPendingAdvisoryIndex = ({
  onFinish,
  loading,
  data,
  onReject,
  rejectLoading,
}) => {
  const columns = [
    {
      title: 'Script',
      dataIndex: 'Script',
      key: 'Script',
    },
    {
      title: 'Trade',
      dataIndex: 'Trade',
      key: 'Trade',
    },
    {
      title: 'Quantity',
      dataIndex: 'Quantity',
      key: 'Quantity',
    },
    {
      title: 'Price',
      dataIndex: 'TradePrice',
      key: 'TradePrice',
    },
  ]

  const otherColumns = [
    {
      title: 'Script',
      dataIndex: 'Script',
      key: 'Script',
    },
    {
      title: 'Total Quantity',
      dataIndex: 'Quantity',
      key: 'Quantity',
    },
  ]

  return (
    <>
      <Tabs style={{ marginTop: '8px' }} defaultActiveKey='Current Advisory'>
        <TabPane tab='Current Advisory' key='Current Advisory'>
          <div className='sub-data-box'>
            <div className='assessment-details-box'>
              <div className='assessment-title'>Overall Cash Requirement</div>
              <div className='assessment-description'>
                ₹&nbsp;
                {convertNumberToCommaSeparate(+data?.totalCostValue)}
              </div>
            </div>
            <div className='assessment-details-box'>
              <div className='assessment-title'>
                Futures and Options Cash flow
              </div>
              <div className='assessment-description'>
                ₹&nbsp;
                {convertNumberToCommaSeparate(+data?.cashOutFlow)}
              </div>
            </div>
            <div className='assessment-details-box-1'>
              <div className='assessment-title-1'>SIP Amount</div>
              <div className='assessment-description-1'>
                ₹&nbsp;
                {convertNumberToCommaSeparate(data?.sip ? +data?.sip : 0)}
              </div>
            </div>
          </div>

          <div className='advisory_table' style={{ marginTop: '16px' }}>
            <Table
              bordered
              columns={columns}
              dataSource={data?.currentAdvisoryData}
              pagination={false}
              style={{ overflow: 'auto' }}
            />
          </div>
        </TabPane>
        <TabPane tab='Current Open Position' key='Current Open Position'>
          <div className='advisory_table' style={{ marginTop: '16px' }}>
            <Table
              bordered
              columns={otherColumns}
              dataSource={data?.currentPosition}
              pagination={false}
              style={{ overflow: 'auto' }}
            />
          </div>
        </TabPane>
        <TabPane
          tab='Open Position after advisory Execution'
          key='Open Position after advisory Execution'
        >
          <div className='advisory_table' style={{ marginTop: '16px' }}>
            <Table
              bordered
              columns={otherColumns}
              dataSource={data?.afterExe}
              pagination={false}
              style={{ overflow: 'auto' }}
            />
          </div>
        </TabPane>
      </Tabs>

      <div
          className='verify_next_button'
          style={{
            marginRight: '18px',
            marginTop: '10px'
          }}
      >
        <Button
          // id='confirm-btn'
          className='confirm-next-button'
          onClick={onFinish}
          loading={loading}
        >
          Accept
        </Button>
        <Button
          // type='primary'
          className='yes-next-button'
          loading={rejectLoading}
          onClick={onReject}
          id='cancel-btn'
        >
          Reject
        </Button>
      </div>
    </>
  )
}
export default ScenarioPendingAdvisoryIndex
