import React from 'react'
import { Modal, Form, Input, Button } from 'antd'

const CollectionCreateForm = ({ visible, onCreate, onCancel, loading }) => {
  const [form] = Form.useForm()

  function resetForm() {
    form.resetFields()
  }

  return (
    <Modal
      maskClosable={false}
      visible={visible}
      title={'Close Ticket'}
      okText={'Close'}
      cancelText='Cancel'
      // onCancel={() => {
      //   resetForm()
      //   onCancel()
      // }}
      // onOk={() => {
      //   form
      //     .validateFields()
      //     .then((values) => onCreate(values, resetForm))
      //     .catch((info) => console.log('Validate Failed:', info))
      // }}
    >
      <Form
        form={form}
        layout='vertical'
        name='form_in_modal'
        initialValues={{
          modifier: 'public',
        }}
      >
        <Form.Item
          name='reason'
          label='Closing Description'
          rules={[
            {
              required: true,
              message: 'Please write your description!',
              whitespace: true,
            },
          ]}
          hasFeedback
        >
          <Input.TextArea rows={5} />
        </Form.Item>
        <div className='footer-btn-div'>
          <Button
            className='footer-cancel-btn'
            onClick={() => {
              resetForm()
              onCancel()
            }}
          >
            Cancel
          </Button>
          <Button
            className='footer-submit-btn'
            onClick={() => {
              form
                .validateFields()
                .then((values) => onCreate(values, resetForm))
                .catch((info) => console.log('Validate Failed:', info))
            }}
            type='primary'
            loading={loading}
          >
            Close Ticket
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

const CreateTicketModal = (props) => {
  return (
    <div>
      <CollectionCreateForm {...props} />
    </div>
  )
}

export default CreateTicketModal
