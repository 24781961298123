import React, { useState } from 'react'
import { CSVLink } from 'react-csv'
import { Button, Popover, Select } from 'antd'

import { exportGraphAsPdf } from 'utils/Graph'
import client from 'apollo'
import {
  GET_COMPARISON_OF_FUND_REPORT_DATA,
  GET_FUTURE_AND_OPTIONS_REPORT_DATA,
  GET_ILTS_P_REPORT,
  GET_LEDGER_REPORT_DATA,
  GET_STOCK_REPORT_DATA,
} from '../graphql/Queries'
import get from 'lodash/get'
import pdfImage from '../../../assets/images/pdf-svgrepo-com.svg'
import csvImage from '../../../assets/images/file-csv-solid.svg'
import { DownloadOutlined } from '@ant-design/icons'
import PdfIcon from 'assets/images/new-images/pdficon.svg'
import ExcelIcon from 'assets/images/new-images/excelIcon.svg'

const { Option } = Select

export default function DownloadReport({
  data = [],
  fileName,
  id,
  staticHtml = null,
  isLandscape = true,
  className = '',
  onlyCSV = false,
  getAllData = false,
  setSelectPeriod,
  selectPeriod,
  isYearPeriod = false,
  loadingSpData,
  isILTSNewReport,
  clientId,
  isComparisonReport = false,
  clientCode,
  newPdfIcon,
  isStockReport = false,
  isFutureAndOptionReport = false,
  isLedgerReport = false,
}) {
  const [buttonLoading, setButtonLoading] = useState(false)

  function handleExport(value) {
    if (value === 'CSV') return false
    setButtonLoading(true)
    if (isILTSNewReport) {
      client
        .query({
          query: GET_ILTS_P_REPORT,
          variables: { where: { id: parseInt(clientId) } },
          fetchPolicy: 'network-only',
        })
        .then((res) => {
          setButtonLoading(false)
          get(res, 'data.getIltspreport') &&
            window.open(get(res, 'data.getIltspreport'), '_blank')
        })
        .catch((err) => console.log(err))
        .finally(() => setButtonLoading(false))
    } else if (isComparisonReport) {
      client
        .query({
          query: GET_COMPARISON_OF_FUND_REPORT_DATA,
          variables: { where: { clientCode, selectPeriod } },
          fetchPolicy: 'network-only',
        })
        .then((res) => {
          setButtonLoading(false)
          get(res, 'data.getComparisonOfFundReport') &&
            window.open(get(res, 'data.getComparisonOfFundReport'), '_blank')
        })
        .catch((err) => console.log(err))
        .finally(() => setButtonLoading(false))
    } else if (isStockReport) {
      client
        .query({
          query: GET_STOCK_REPORT_DATA,
          variables: { where: { id: parseInt(clientId) } },
          fetchPolicy: 'network-only',
        })
        .then((res) => {
          setButtonLoading(false)
          get(res, 'data.getStockReport') &&
            window.open(get(res, 'data.getStockReport'), '_blank')
        })
        .catch((err) => console.log(err))
        .finally(() => setButtonLoading(false))
    } else if (isFutureAndOptionReport) {
      client
        .query({
          query: GET_FUTURE_AND_OPTIONS_REPORT_DATA,
          variables: { where: { id: parseInt(clientId) } },
          fetchPolicy: 'network-only',
        })
        .then((res) => {
          setButtonLoading(false)
          get(res, 'data.getFutureAndOptionReport') &&
            window.open(get(res, 'data.getFutureAndOptionReport'), '_blank')
        })
        .catch((err) => console.log(err))
        .finally(() => setButtonLoading(false))
    } else if (isLedgerReport) {
      client
        .query({
          query: GET_LEDGER_REPORT_DATA,
          variables: { where: { id: parseInt(clientId) } },
          fetchPolicy: 'network-only',
        })
        .then((res) => {
          setButtonLoading(false)
          get(res, 'data.getLedgerReport') &&
            window.open(get(res, 'data.getLedgerReport'), '_blank')
        })
        .catch((err) => console.log(err))
        .finally(() => setButtonLoading(false))
    } else {
      exportGraphAsPdf(fileName, id, staticHtml, isLandscape).finally(() =>
        setButtonLoading(false)
      )
    }
  }

  const handleChange = (e) => {
    setSelectPeriod(e)
  }
  const content = (
    <div>
      <Button
        onClick={() => handleExport('PDF')}
        loading={buttonLoading}
        style={{
          padding: '0px',
          marginBottom: '0px',
          fontSize: '14px',
          fontWeight: '600',
          lineHeight: '24px',
          textAlign: 'left',
          color: ' #000',
          cursor: 'pointer',
          border: 'none',
        }}
      >
        <img src={PdfIcon} style={{ marginRight: '10px' }} alt='pdf' /> PDF
      </Button>
      <br />
      <CSVLink
        style={{ paddingRight: 0 }}
        data={data}
        className='csv-btn'
        filename={`${fileName}.csv`}
        onClick={(e) => e.stopPropagation()}
      >
        <Button
          onClick={() => handleExport('CSV')}
          style={{
            padding: '0px',
            marginBottom: '0px',
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '24px',
            textAlign: 'left',
            color: ' #000',
            cursor: 'pointer',
            border: 'none',
          }}
        >
          <img src={ExcelIcon} style={{ marginRight: '10px' }} alt='pdf' /> CSV
        </Button>
      </CSVLink>
      {/* <Option value='1' disabled>
        {getAllData ? 'Export All' : 'Export AS'}
      </Option>
      {!onlyCSV && <Option value='PDF'>PDF</Option>}
      <Option value='CSV'>
        <CSVLink
          data={data}
          className='csv-btn'
          filename={`${fileName}.csv`}
          onClick={(e) => e.stopPropagation()}
        >
          CSV
        </CSVLink>
      </Option> */}
    </div>
  )
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        {!onlyCSV && newPdfIcon && (
          <img
            src={pdfImage}
            alt=''
            className={`gx-mb-2 gx-select-sm download-button ${className}`}
            onClick={() => handleExport('PDF')}
            width={25}
            height={30}
            style={{
              cursor: `${buttonLoading ? 'not-allowed' : 'pointer'}`,
              marginRight: '10px',
            }}
          />
        )}

        {newPdfIcon && (
          <CSVLink
            style={{ paddingRight: 0 }}
            data={data}
            className='csv-btn'
            filename={`${fileName}.csv`}
            onClick={(e) => e.stopPropagation()}
          >
            <img
              src={csvImage}
              alt=''
              className={`gx-mb-2 gx-select-sm download-button ${className}`}
              onClick={() => handleExport('CSV')}
              width={25}
              height={30}
              style={{ cursor: `${buttonLoading ? 'not-allowed' : 'pointer'}` }}
            />
          </CSVLink>
        )}
      </div>

      {!newPdfIcon && (
        <div className='download_report_container'>
          <Popover content={content} placement='bottom' trigger='click'>
            <div className='container_Export_Popover'>
              <Button>
                Export{' '}
                <DownloadOutlined
                  color='#AF0000'
                  style={{ fontSize: '16px' }}
                />
              </Button>
            </div>
          </Popover>
        </div>
      )}
      {isYearPeriod && (
        <Select
          getPopupContainer={(trigger) => trigger.parentElement}
          className={`gx-mb-2 gx-select-sm download-button ${className}`}
          value={selectPeriod}
          placeholder='Select Type'
          onChange={(e) => handleChange(e)}
          loading={loadingSpData}
          style={{ marginRight: '20px' }}
        >
          <Option value='Daily'>Daily</Option>
          <Option value='Monthly'>Monthly</Option>
          <Option value='Quarterly'>Quarterly</Option>
          <Option value='HalfYearly'>Half Yearly</Option>
          <Option value='Yearly'>Yearly</Option>
        </Select>
      )}
    </>
  )
}
