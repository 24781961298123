import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Collapse, Radio, Input, Checkbox, Form, Button, Row, Col } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { get, includes, isEmpty, sum } from 'lodash'

import '../verification.css'
import { GET_SECTIONS, GET_USER_ANSWERS } from '../graphql/Queries'
import Loader from '../../../components/loaders/Loader'
import Page404 from 'components/Page404'
import CustomMeta from 'components/CustomMeta'
import { AppContext } from 'AppContext'
// import { GET_PLANS } from 'modules/Plans/graphql/Query'
import RiskProfile from '../Model/RiskProfileModel'
import _sortBY from 'lodash/sortBy'
import { UPDATE_USER } from 'modules/Users/graphql/Mutations'
import { CONSENT_DATA, RISK_ASSESSMENT_MAIL } from '../graphql/Mutations'
import client from 'apollo'
import openNotification from 'utils/Notification'
import DropDownForCollapse from 'assets/images/new-images/dropdownForCollapse.svg'
import DropUPForCollapse from 'assets/images/new-images/dropUPForCollapse.svg'
import KeyInfo from '../KeyInfo'
import { GET_PLANS_BASED_POINTS } from 'modules/Questionnaires/graphql/Query'
import { formatToLakhsOrCrores } from 'utils/utilities'

const { Panel } = Collapse
const FormItem = Form.Item
export default function ({ next, reEvaluate, currentUser, newEdit }) {
  let initialValues = {}
  const [form] = Form.useForm()
  const [formValue, setFormValue] = useState({})
  const [showVerifyModal, setShowVerifyModal] = useState(false)
  const [points, setPoints] = useState({})
  const [reEvaluateValue, setReEvaluateValue] = useState(reEvaluate)
  const [loadingData, setLoadingData] = useState(false)
  const [userAnswers, setUserAnswers] = useState([])
  const [userAnswersLoading, setUserAnswersLoading] = useState(false)
  const [userAnswersError, setUserAnswersError] = useState('')
  let {
    dispatch,
    state: {
      currentUser: { annualIncome, id, plan, isPanCardIndividual },
      clientSummaryDetails,
    },
  } = useContext(AppContext)
  const { data, loading, error } = useQuery(GET_SECTIONS, {
    variables: {
      where: {
        forNonIndividual: !isPanCardIndividual,
        planName: 'Power Booster Plan',
      },
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    setUserAnswersLoading(true)
    client
      .query({
        query: GET_USER_ANSWERS,
        variables: { where: { planName: 'Power Booster Plan' } },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        if (!res) {
          setUserAnswersError(true)
        }
        setUserAnswers(res?.data)
        setUserAnswersLoading(false)
      })
      .catch((err) => {
        if (err.message.split(':')[1] !== 'NO_USER_FOUND') {
          setUserAnswersLoading(false)
        }
      })
  }, [])

  useEffect(() => {
    if (!userAnswersLoading && userAnswers?.getUserAnswers?.length) {
      let tempPoints = {}
      userAnswers.getUserAnswers.forEach((answer) => {
        if (answer?.question) {
          if (answer.question.type === 'RADIO') {
            tempPoints[answer.question.id] = JSON.parse(
              answer.selectedOptions
            )[0].point
          } else if (answer.question.type === 'TEXT') {
            const option = JSON.parse(answer.selectedOptions)
            tempPoints[answer.question.id] =
              option.length && option[0]?.option ? option[0]?.point : 0
          } else {
            const option = JSON.parse(answer.selectedOptions)
            if (option.length) {
              tempPoints[answer.question.id] = sum(
                JSON.parse(answer.selectedOptions).map((d) => d.point)
              )
            }
          }
        }
      })
      dispatch({
        type: 'CLIENT_RISK_SCORE',
        data: sum(Object.values(tempPoints)),
      })
      setPoints(tempPoints)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAnswers, userAnswersLoading])

  useEffect(() => {
    let tempPoints = {}
    if (!loading && get(data, 'getSections')) {
      if (!userAnswers?.getUserAnswers?.length) {
        data.getSections.forEach((section) => {
          section.questions.forEach((question) => {
            if (question.type === 'RADIO') {
              question.options.forEach((option) => {
                if (option.isDefault) {
                  tempPoints[question.id] = option.point
                }
              })
            }
            if (question.type === 'CHECK_BOX') {
              tempPoints[question.id] = sum(
                question.options
                  .filter((options) => options.isDefault)
                  .map((option) => option?.point)
              )
            }
          })
        })
        dispatch({
          type: 'CLIENT_RISK_SCORE',
          data: sum(Object.values(tempPoints)),
        })
        setPoints(tempPoints)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, userAnswers])

  if (!loading && get(data, 'getSections')) {
    if (!userAnswers?.getUserAnswers?.length) {
      initialValues = getDefaultValues(data.getSections)
    }
  }

  if (!get(userAnswers, 'getUserAnswers')) {
    return false
  }

  if (get(userAnswers, 'getUserAnswers')) {
    userAnswers.getUserAnswers.forEach((d) => {
      if (d.question?.type === 'TEXT') {
        initialValues[d.question.id] = JSON.parse(d.selectedOptions)[0]?.option
      } else if (d.question?.type === 'RADIO') {
        initialValues[d.question?.id] = `${
          JSON.parse(d.selectedOptions)[0]?.id
        }-radio`
      } else {
        initialValues[d.question?.id] = JSON.parse(d.selectedOptions).map((o) =>
          o.id.toString()
        )
      }
    })
  }

  function getDefaultValues(sections) {
    let initialValues = {}
    sections.forEach((section) => {
      section.questions.forEach((question) => {
        if (question.type === 'RADIO') {
          question.options.forEach((option) => {
            if (option.isDefault) {
              initialValues[question.id] = `${option.id}-radio`
            }
          })
        }
        if (question.type === 'CHECK_BOX') {
          initialValues[question.id] = question.options
            .filter((options) => options.isDefault)
            .map((option) => option?.id)
        }
      })
    })

    return initialValues
  }
  const editAccessFunc = () => {
    setReEvaluateValue(false)
  }
  const onFinish = (values) => {
    setLoadingData(true)
    dispatch({ type: 'IS_ACCEPT_LOADING', data: true })
    if (reEvaluateValue === true) {
      client
        .mutate({
          mutation: UPDATE_USER,
          variables: {
            id,
            data: {
              completedSteps: 'RISK_PROFILE',
              clientScore: sum(Object.values(points)),
            },
          },
        })
        .then((res) => {
          dispatch({ type: 'CURRENT_USER', data: res.data.updateUser })
          client
            .query({
              query: GET_PLANS_BASED_POINTS,
              variables: { points: res?.data?.updateUser?.clientScore },
              fetchPolicy: 'network-only',
            })
            .then((response) => {
              setLoadingData(false)
              dispatch({ type: 'IS_ACCEPT_LOADING', data: false })

              const getAnswers = Object.keys(values).map((val) => {
                return {
                  questionId: parseInt(val),
                  selectedOptionsId: parseInt(values[val]?.split('-')[0]),
                }
              })
              const currentIndex = clientSummaryDetails.findIndex(
                (item) => item?.name === 'Risk Profile Assessment'
              )
              let updateSummaryUiData = {
                completedSteps: 'CLIENT_PROFILE_2',
                name: 'Risk Profile Assessment',
                isDisplayEdit: true,
              }

              const findIncomeQuestion = data.getSections?.flatMap((section) =>
                section.questions.filter(
                  (question) => question.includeInvestmentValidation
                )
              )

              const getIncomeId = findIncomeQuestion?.map((item) => item?.id)
              const getIncomeAnswer = getAnswers?.filter((item) =>
                getIncomeId?.includes(item?.questionId.toString())
              )

              let arrayPoints = []
              let updateSummaryData = {
                'Eligible Plan': response?.data?.fetchPlansBasedOnPoints
                  ?.map((item) => item?.name)
                  ?.join(','),
                description: 'Exposure Limit on the basis of',
              }

              findIncomeQuestion.forEach((question) => {
                const selectedOption = getIncomeAnswer?.find(
                  (item) => item?.questionId === parseInt(question?.id)
                )?.selectedOptionsId
                const findOption = question?.options.find(
                  (item) => parseInt(item?.id) === selectedOption
                )
                if (isPanCardIndividual) {
                  if (
                    question?.question?.includes('What is your annual Income')
                  ) {
                    updateSummaryData['Annual Income'] = formatToLakhsOrCrores(
                      findOption?.value
                    )
                    arrayPoints.push(
                      findOption?.value * findOption?.multiplication
                    )
                  }
                } else {
                  updateSummaryData['Annual Income'] = formatToLakhsOrCrores(
                    +currentUser?.annualIncome
                  )
                  arrayPoints.push(+currentUser?.annualIncome)
                }
                if (question?.question?.includes('Your net worth?')) {
                  updateSummaryData['Net Worth'] = formatToLakhsOrCrores(
                    findOption?.value
                  )
                  arrayPoints.push(
                    findOption?.value * findOption?.multiplication
                  )
                }
                if (
                  question?.question?.includes(
                    'Market Value of all Liquid Assets (Deposits, Shares, Mutual funds etc)'
                  )
                ) {
                  updateSummaryData['All Liquid Assets'] =
                    formatToLakhsOrCrores(findOption?.value)
                  arrayPoints.push(
                    findOption?.value * findOption?.multiplication
                  )
                }
              })
              updateSummaryData['Risk Profile Score'] = sum(
                Object.values(points)
              )
              updateSummaryData['Maximum Exposure'] = formatToLakhsOrCrores(
                Math.max(...arrayPoints)
              )
              updateSummaryUiData = {
                ...updateSummaryUiData,
                data: updateSummaryData,
              }

              if (currentIndex !== -1) {
                clientSummaryDetails[currentIndex] = updateSummaryUiData
              } else {
                clientSummaryDetails.push(updateSummaryUiData)
              }

              client.mutate({ mutation: RISK_ASSESSMENT_MAIL })
              client.mutate({ mutation: CONSENT_DATA })

              dispatch({
                type: 'CLIENT_SUMMARY_DETAILS',
                data: clientSummaryDetails,
              })
              openNotification('success', 'Risk Profile Updated Successfully')
              next()
            })
            .catch((err) => {
              console.log(err)
            })
        })
    } else {
      setShowVerifyModal(true)
      let temp = Object.keys(values)
        .filter((e) => e !== 'annualIncome')
        .map((e) => {
          const tempObject = {
            questionId: parseInt(e),
            planId: plan ? plan.id : null,
          }
          if (typeof values[e] === 'object') {
            tempObject['selectedOptionsId'] = values[e].map(Number)
          } else {
            if (includes(values[e], '-radio')) {
              tempObject['selectedOptionsId'] = parseInt(
                values[e].split('-')[0]
              )
            } else {
              tempObject['answer'] = values[e]
            }
          }
          return tempObject
        })
      setFormValue({
        answers: temp,
        annualIncome: values['annualIncome'],
      })
      dispatch({ type: 'IS_ACCEPT_LOADING', data: false })
    }
  }

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name)
  }

  const checkBoxChange = (values, id, options) => {
    let tempPoints = { ...points }
    let point = 0
    values.forEach((value) => {
      point = point + options.find((option) => option.id === value).point
    })
    tempPoints[id] = point

    dispatch({
      type: 'CLIENT_RISK_SCORE',
      data: sum(Object.values(tempPoints)),
    })
    setPoints(tempPoints)
  }

  const radioChange = ({ target: { value } }, id, options) => {
    value = value.replace('-radio', '')
    let tempPoints = { ...points }
    tempPoints[id] = options.find((option) => option.id === value).point
    dispatch({
      type: 'CLIENT_RISK_SCORE',
      data: sum(Object.values(tempPoints)),
    })
    setPoints(tempPoints)
  }

  const inputChange = (id, point, { target: { value } }) => {
    let tempPoints = { ...points }
    tempPoints[id] = value ? point : 0
    dispatch({
      type: 'CLIENT_RISK_SCORE',
      data: sum(Object.values(tempPoints)),
    })
    setPoints(tempPoints)
  }

  const handleHideFormModal = () => {
    setLoadingData(false)
    dispatch({ type: 'IS_ACCEPT_LOADING', data: false })
    setShowVerifyModal(false)
  }

  if (loading || userAnswersLoading) return <Loader />
  if (error || userAnswersError) return <Page404 error={error} />

  return (
    <>
      <Row className='row-style'>
        <Col lg={16} sm={24} className='col-left risk-profile-col'>
          <Row>
            <Col span={22} style={{ textAlign: 'center' }}></Col>
            <Col span={2} style={{ textAlign: 'end' }}>
              {reEvaluateValue && (
                <Button type='primary' onClick={editAccessFunc}>
                  Edit
                </Button>
              )}
            </Col>
          </Row>
          <div>
            <CustomMeta title='Risk Profile' />
            <Form
              form={form}
              onFinish={onFinish}
              className='risk-profile-form'
              initialValues={{
                annualIncome: annualIncome || undefined,
                ...initialValues,
              }}
              onFinishFailed={onFinishFailed}
            >
              <div
                className='risk-profile-collapse'
                style={{ marginBottom: '10px' }}
              >
                <div className='collapse collapse-question'>
                  {_sortBY(data.getSections, ['index']).map(
                    (section, key, index) => {
                      return (
                        <Collapse
                          key={key}
                          collapsible='header'
                          defaultActiveKey={[key]}
                          style={{ background: '#fff', marginBottom: '10px' }}
                          expandIconPosition={'end'}
                          expandIcon={({ isActive }) =>
                            isActive ? (
                              <img
                                src={DropDownForCollapse}
                                alt='DropDownForCollapse'
                                style={{ userSelect: 'none' }}
                              />
                            ) : (
                              <img
                                src={DropUPForCollapse}
                                alt='DropUPForCollapse'
                                style={{ userSelect: 'none' }}
                              />
                            )
                          }
                        >
                          <Panel header={section.section} key={key}>
                            {_sortBY(section.questions, ['index']).map(
                              (question, key) => {
                                if (question.type === 'TEXT') {
                                  return (
                                    <Fragment key={key}>
                                      <p className='text-verification'>
                                        {question.question}
                                        <span className='require-star'>
                                          {question.isRequired ? '*' : ''}
                                        </span>
                                      </p>
                                      <FormItem
                                        name={question.id}
                                        className='text-verification'
                                        rules={[
                                          {
                                            required: question.isRequired
                                              ? question.isRequired
                                              : false,
                                            message: 'Answer is require',
                                          },
                                        ]}
                                      >
                                        <Input
                                          placeholder='Please enter here'
                                          className='verification-input-field'
                                          onChange={(value) =>
                                            inputChange(
                                              question.id,
                                              question.point,
                                              value
                                            )
                                          }
                                          disabled={
                                            !reEvaluateValue ? false : true
                                          }
                                        />
                                      </FormItem>
                                      {/* <hr /> */}
                                    </Fragment>
                                  )
                                } else if (question.type === 'RADIO') {
                                  return (
                                    <Fragment key={key}>
                                      <p className='text-verification'>
                                        {question.question}
                                        <span className='require-star'>
                                          {question.isRequired ? '*' : ''}
                                        </span>
                                      </p>
                                      <FormItem
                                        name={question.id}
                                        rules={[
                                          {
                                            required: question.isRequired
                                              ? question.isRequired
                                              : false,
                                            message: 'Answer is require',
                                          },
                                        ]}
                                      >
                                        <Radio.Group
                                          className='gender-radio-group'
                                          onChange={(e) =>
                                            radioChange(
                                              e,
                                              question.id,
                                              question.options
                                            )
                                          }
                                        >
                                          {question.options.map(
                                            (option, key) => {
                                              return (
                                                <label
                                                  key={key}
                                                  style={{
                                                    whiteSpace: 'nowrap',
                                                  }}
                                                >
                                                  <Radio.Button
                                                    className='gender-radio-button'
                                                    value={`${option.id}-radio`}
                                                    key={key}
                                                    disabled={
                                                      !reEvaluateValue
                                                        ? false
                                                        : true
                                                    }
                                                  >
                                                    <span
                                                      style={{
                                                        whiteSpace: 'normal',
                                                        fontSize: '12px',
                                                      }}
                                                      className='wrapper'
                                                    >
                                                      {option.option}
                                                    </span>
                                                  </Radio.Button>
                                                </label>
                                              )
                                            }
                                          )}
                                        </Radio.Group>
                                      </FormItem>
                                      {/* <hr /> */}
                                    </Fragment>
                                  )
                                } else {
                                  return (
                                    <Fragment key={key}>
                                      <p className='text-verification'>
                                        {question.question}
                                        <span className='require-star'>
                                          {question.isRequired ? ' * ' : ''}
                                        </span>
                                      </p>
                                      <FormItem
                                        name={question.id}
                                        rules={[
                                          {
                                            required: question.isRequired
                                              ? question.isRequired
                                              : false,
                                            message: 'Answer is require',
                                          },
                                        ]}
                                      >
                                        <Checkbox.Group
                                          className='checkbox'
                                          onChange={(value) =>
                                            checkBoxChange(
                                              value,
                                              question.id,
                                              question.options
                                            )
                                          }
                                        >
                                          {question.options.map(
                                            (option, key) => {
                                              return (
                                                <Checkbox
                                                  key={key}
                                                  value={option.id}
                                                  disabled={
                                                    !reEvaluateValue
                                                      ? false
                                                      : true
                                                  }
                                                >
                                                  {option.option}
                                                </Checkbox>
                                              )
                                            }
                                          )}
                                        </Checkbox.Group>
                                      </FormItem>
                                      {/* <hr /> */}
                                    </Fragment>
                                  )
                                }
                              }
                            )}
                          </Panel>
                        </Collapse>
                      )
                    }
                  )}
                </div>
              </div>
              <div
                className='verify_next_button'
                style={{
                  marginRight: '18px',
                }}
              >
                <Button
                  // id='btn-click'
                  className='yes-next-button'
                  type='primary'
                  htmlType='submit'
                  loading={loadingData}
                >
                  Verify And Next
                </Button>
              </div>
              {showVerifyModal && !isEmpty(formValue) && (
                <RiskProfile
                  section={data.getSections}
                  next={next}
                  formValue={formValue}
                  visible={setShowVerifyModal}
                  onCancel={() => handleHideFormModal()}
                  points={sum(Object.values(points))}
                  reEvaluate={reEvaluate}
                />
              )}
            </Form>
          </div>
        </Col>
        <Col lg={8} sm={24} className='col-right risk-profile-col'>
          <KeyInfo newEdit={newEdit} />
        </Col>
      </Row>
    </>
  )
}
