import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  Row,
  Spin,
  Tag,
  Modal,
} from 'antd'
import GoogleButton from 'react-google-button'

import {
  LOGIN_SIGNUP_WITH_GOOGLE,
  LOGIN_WITH_OTP_FOR_WEB,
  SEND_LOGIN_OTP_FOR_WEB,
} from 'auth/graphql/Mutations'
import Logo from 'assets/images/logo-tagline-new.png'
import CustomMeta from 'components/CustomMeta'
import { LOGIN } from './graphql/Mutations'
import { authLogin, authWithOtp } from '.'
import client from 'apollo'
import './index.css'
import history from 'CustomHistory'
import openNotification from 'utils/Notification'
import { ArrowLeftOutlined, DownOutlined } from '@ant-design/icons'
import { GET_IMAGES } from 'modules/LoginImages/graphql/Query'
import { useQuery } from '@apollo/react-hooks'
import Page404 from 'components/Page404'
import { random } from 'lodash'
import * as Auth from '.'
import { AppContext } from 'AppContext'

const FormItem = Form.Item

export default function () {
  const [form] = Form.useForm()
  const {
    state: { isMobile },
  } = useContext(AppContext)
  const [userNameClient, setUserName] = useState()
  const [loginType, setLoginType] = useState('')
  const [loader, setLoader] = useState(false)
  const [showPasswordField, setShowPasswordField] = useState(false)
  const [screenType, setScreenType] = useState('input')
  const [selectionData, setSelectionData] = useState([])
  const [inputOtp, setInputOtp] = useState('')
  const [userId, setUserId] = useState()
  const [valueData, setValueData] = useState()
  const [newKycRegistration, setNewKycRegistration] = useState(false)
  const [selectedUser, setSelectedUser] = useState()
  const [imageUrl, setImageUrl] = useState('')
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const isAuthenticated = Auth.isAuthenticated()

  const [counter, setCounter] = useState(0)
  const OTP_RESEND_INTERVAL = 180

  useEffect(() => {
    let timer
    if (counter > 0) {
      timer = setInterval(() => {
        setCounter((prevCounter) => prevCounter - 1)
      }, 1000)
    }
    return () => clearInterval(timer)
  }, [counter])

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/')
    }
  }, [isAuthenticated])

  const urlQuery = useLocation()
  const userName = urlQuery.search.split('=')[1]

  useEffect(() => {
    userName && setUserName(userName)
  }, [userName])

  const { loading, error, data } = useQuery(GET_IMAGES, {
    fetchPolicy: 'network-only',
  })

  if (error) return <Page404 error={error} />

  useEffect(() => {
    if (!loading) {
      setImageUrl(
        data.getAllLoginImages[random(0, data.getAllLoginImages?.length - 1)]
          .imageUrl
      )
    }
  }, [data, loading])

  function handleLogin(values) {
    setLoader(true)
    setValueData(values)
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    const phoneRegex = /^\d{10}$/

    if (
      screenType === 'input' &&
      !emailRegex.test(values?.userName) &&
      !phoneRegex.test(values?.userName)
    ) {
      setShowPasswordField(true)
      setScreenType('password')
      setLoginType('userName')
      setLoader(false)
    } else if (screenType === 'input') {
      if (
        emailRegex.test(values?.userName) ||
        phoneRegex.test(values?.userName)
      )
        setLoader(true)
      setLoginType(
        `${emailRegex.test(values?.userName) === true ? 'email' : 'mobile'}`
      )
      let obj = {}
      if (emailRegex.test(values?.userName)) {
        obj['email'] = values.userName
      } else {
        obj['phone'] = values.userName
      }
      client
        .mutate({
          mutation: SEND_LOGIN_OTP_FOR_WEB,
          variables: { ...obj },
        })
        .then((res) => {
          setScreenType('otp')
          setCounter(OTP_RESEND_INTERVAL)
          setShowPasswordField(true)
          if (!res.data.checkPhoneBelongsTo.length) {
            openNotification('error', 'Mobile Number is not Registered')
            setLoader(false)
            return false
          }
          setLoader(false)
        })
        .catch((err) => {
          if (err.message.split(':')[1] !== 'NO_USER_FOUND') {
            setLoader(false)
          }
        })
    } else if (screenType === 'password') {
      setLoginType('userName')
      if (showPasswordField) {
        client
          .mutate({
            mutation: LOGIN,
            variables: {
              data: { userName: values.userName, password: values.password },
            },
          })
          .then((res) => {
            authLogin(res)
            setLoader(false)
          })
          .catch((err) => {
            setLoader(false)
            console.log(err)
          })
      }
    }

    if (screenType === 'otp') {
      client
        .mutate({
          mutation: LOGIN_WITH_OTP_FOR_WEB,
          variables: { otp: values.text },
        })
        .then((res) => {
          if (
            res.data.loginWithOtpForWeb?.length === 1 &&
            res.data.loginWithOtpForWeb[0].completedSteps !== 'DONE'
          ) {
            client
              .mutate({
                mutation: LOGIN_WITH_OTP_FOR_WEB,
                variables: {
                  otp: values.text,
                  signerUserId:
                    res?.data?.loginWithOtpForWeb[0]?.id?.toString(),
                },
              })
              .then((res) => {
                authWithOtp(res)
                setLoader(false)
              })
              .catch((err) => {
                setLoader(false)
                console.log(err)
              })
          } else {
            setLoginType('otp')
            setScreenType('selection')
            setSelectionData(res.data.loginWithOtpForWeb)
            setSelectedUser(res.data.loginWithOtpForWeb[0])
            setUserId(res.data.loginWithOtpForWeb[0]?.id)
          }
          setInputOtp(values.text)
          setLoader(false)
        })
        .catch((err) => {
          setLoader(false)
          console.log(err)
        })
    }

    if (screenType === 'selection' && !newKycRegistration) {
      client
        .mutate({
          mutation: LOGIN_WITH_OTP_FOR_WEB,
          variables: { otp: inputOtp, signerUserId: userId.toString() },
        })
        .then((res) => {
          authWithOtp(res)
          setLoader(false)
        })
        .catch((err) => {
          setLoader(false)
          console.log(err)
          if (err.message.split(':')[1] !== ' Invalid OTP') {
            history.push('/login')
          }
        })
    }

    if (newKycRegistration === true) {
      openNotification('error', 'Please complete the Pending KYC')
      setLoader(false)
      //   let obj = {}
      //   if (emailRegex.test(valueData.userName)) {
      //     obj['email'] = valueData.userName
      //   } else {
      //     obj['phone'] = valueData.userName
      //   }

      //   client
      //     .mutate({
      //       mutation: LOGIN_WITH_OTP_FOR_WEB,
      //       variables: {
      //         ...obj,
      //         otp: inputOtp,
      //         newKycRegistration: newKycRegistration,
      //       },
      //     })
      //     .then((res) => {
      //       authWithOtp(res)
      //       setLoader(false)
      //     })
      //     .catch((err) => {
      //       setLoader(false)
      //       console.log(err)
      //       if (err.message.split(':')[1] !== ' Invalid OTP') {
      //         history.push('/login')
      //       }
      //     })
    }
  }

  function handleGoogleLogin() {
    client
      .mutate({ mutation: LOGIN_SIGNUP_WITH_GOOGLE })
      .then((res) => (window.location.href = res?.data?.loginSignupWithGoogle))
      .catch((err) => console.log(err))
  }

  const handleMenuClick = (client) => {
    setSelectedUser(client)
    setUserId(client?.id)
    if (typeof client === 'string' && client === 'New KYC Registration') {
      const data = selectionData.filter(
        (item) => item?.completedSteps !== 'DONE'
      )
      if (data.length) {
        openNotification('error', 'Please complete the Pending KYC')
      } else {
        setOpenConfirmModal(true)
      }
      setNewKycRegistration(true)
    } else {
      setNewKycRegistration(false)
    }
  }

  const handleResendOtp = (e) => {
    if (counter <= 0) {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      let obj = {}
      if (emailRegex.test(valueData?.userName)) {
        obj['email'] = valueData.userName
      } else {
        obj['phone'] = valueData.userName
      }
      client
        .mutate({
          mutation: SEND_LOGIN_OTP_FOR_WEB,
          variables: { ...obj },
        })
        .then((res) => {
          setCounter(OTP_RESEND_INTERVAL)
          openNotification('success', 'Resend Otp Successfully')
        })
    }
  }

  const newKycGenerate = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    setModalLoading(true)
    let obj = {}
    if (emailRegex.test(valueData.userName)) {
      obj['email'] = valueData.userName
    } else {
      obj['phone'] = valueData.userName
    }

    client
      .mutate({
        mutation: LOGIN_WITH_OTP_FOR_WEB,
        variables: {
          ...obj,
          otp: inputOtp,
          newKycRegistration: newKycRegistration,
        },
      })
      .then((res) => {
        authWithOtp(res)
        setModalLoading(false)
      })
      .catch((err) => {
        setModalLoading(false)
        console.log(err)
        if (err.message.split(':')[1] !== ' Invalid OTP') {
          history.push('/login')
        }
      })
  }

  const menu = (
    <Menu>
      {selectionData?.map((client) => {
        return (
          <Menu.Item
            className='main-tag-menu'
            key={client.id}
            onClick={() => handleMenuClick(client)}
          >
            {client.clientCode ? client.clientCode : 'N/A'}{' '}
            {client.firstName && '-'} {client.firstName} {client.lastName}{' '}
            {client.role && '-'} {client.role} &nbsp;&nbsp;
            {client.completedSteps !== 'DONE' ? (
              <Tag color='processing'>Pending KYC</Tag>
            ) : null}
          </Menu.Item>
        )
      })}
      <Menu.Item
        key={'New KYC Registration'}
        onClick={() => handleMenuClick('New KYC Registration')}
      >
        <b>{'New KYC Registration'}</b>
      </Menu.Item>
    </Menu>
  )

  return (
    <div>
      <CustomMeta title='Login' />
      <Row gutter={16}>
        <Col xs={0} sm={0} md={12} xl={14} lg={14}>
          <div
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {loading ? (
              <Spin />
            ) : (
              <img
                src={imageUrl}
                style={{ height: 'auto', width: '100%' }}
                alt='img'
              />
            )}
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} xl={8} lg={8}>
          <div
            style={{
              display: 'grid',
              alignItems: 'center',
              height: '100vh',
              padding: `${isMobile ? '0 20px' : '0 70px'}`,
            }}
          >
            <div style={{ display: 'grid' }}>
              <div
                className='gx-app-logo'
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '50px',
                }}
              >
                <img alt='Finideas' src={Logo} style={{ height: '100px' }} />
              </div>
              <div
                style={{
                  fontSize: '30px',
                  fontWeight: 500,
                  color: '#000',
                  marginBottom: '10px',
                }}
              >
                Welcome to Finideas
              </div>
              {screenType !== 'input' && (
                <div
                  style={{
                    display: 'flex',
                    marginBottom: '15px',
                  }}
                  className='vector-img'
                >
                  <div
                    style={{
                      display: 'flex',
                      cursor: 'pointer',
                      marginBottom: '15px',
                    }}
                    onClick={() => {
                      setScreenType('input')
                      setLoginType('')
                      setShowPasswordField(false)
                    }}
                  >
                    <div>
                      <ArrowLeftOutlined />
                    </div>
                    <div style={{ marginLeft: '0.3rem' }}>Back</div>
                  </div>
                </div>
              )}
              <Form
                form={form}
                onFinish={handleLogin}
                className='gx-signin-form gx-form-row0'
              >
                <>
                  {screenType !== 'selection' && (
                    <FormItem
                      name='userName'
                      onChange={(e) => {
                        setUserName(e.target.value)
                      }}
                      rules={[
                        {
                          required: true,
                          message: 'Please input your valid username!',
                        },
                      ]}
                    >
                      <Input
                        name='userName'
                        value={'8490939000'}
                        placeholder='User Name / Mobile Number / Email Id'
                        style={{
                          border: 'none',
                          borderBottom: '2px solid black',
                        }}
                        disabled={showPasswordField ? true : false}
                        autoFocus={true}
                      />
                    </FormItem>
                  )}
                  {loginType === 'userName' && showPasswordField && (
                    <FormItem
                      name='password'
                      rules={[
                        {
                          required: true,
                          message: 'Please enter your password!',
                        },
                      ]}
                    >
                      <Input
                        style={{
                          border: 'none',
                          borderBottom: '2px solid black',
                        }}
                        type='password'
                        placeholder='Password'
                      />
                    </FormItem>
                  )}
                </>

                {loginType === 'mobile' && (
                  <>
                    <p className='otp-form-text'>
                      Enter the 6 digit OTP sent via SMS on your mobile number
                    </p>
                    <FormItem
                      name='text'
                      rules={[
                        {
                          required: true,
                          message: 'Please input your OTP number!',
                          pattern: new RegExp(/^\d{6}(,\d{6})*$/g),
                        },
                      ]}
                      disabled={showPasswordField ? true : false}
                      autoFocus={true}
                    >
                      <Input placeholder='OTP' autoFocus={true} />
                    </FormItem>
                    <p style={{ color: '#7e0a0a' }}>
                      Did not receive? &nbsp;&nbsp;{' '}
                      <p
                        style={{
                          color: '#7e0a0a',
                          marginTop: '4px',
                          cursor: counter > 0 ? 'not-allowed' : 'pointer',
                        }}
                      >
                        <Link
                          style={{
                            cursor: counter > 0 ? 'not-allowed' : 'pointer',
                          }}
                          onClick={handleResendOtp}
                        >
                          {counter > 0
                            ? `Resend OTP in ${counter} seconds`
                            : 'Resend OTP'}
                        </Link>
                      </p>
                    </p>
                  </>
                )}

                {loginType === 'email' && (
                  <>
                    <p className='otp-form-text'>
                      {' '}
                      Enter the 6 digit OTP sent on Your Email Id
                    </p>
                    <FormItem
                      name='text'
                      rules={[
                        {
                          required: true,
                          message: 'Please input your OTP number!',
                          pattern: new RegExp(/^\d{6}(,\d{6})*$/g),
                        },
                      ]}
                    >
                      <Input placeholder='OTP' autoFocus={true} />
                    </FormItem>
                    <p style={{ color: '#7e0a0a' }}>
                      Did not receive? &nbsp;&nbsp;{' '}
                      <p
                        style={{
                          color: '#7e0a0a',
                          marginTop: '4px',
                          cursor: counter > 0 ? 'not-allowed' : 'pointer',
                        }}
                      >
                        <Link
                          style={{
                            cursor: counter > 0 ? 'not-allowed' : 'pointer',
                          }}
                          onClick={handleResendOtp}
                        >
                          {counter > 0
                            ? `Resend OTP in ${counter} seconds`
                            : 'Resend OTP'}
                        </Link>
                      </p>
                    </p>
                  </>
                )}

                {screenType === 'selection' && (
                  <>
                    {selectionData && (
                      <>
                        <div className='select-client-dropdown'>
                          Kindly select the below client
                        </div>
                        <div className='user-dropdown'>
                          <Dropdown
                            overlay={menu}
                            trigger={['click']}
                            style={{ width: '100%' }}
                          >
                            <div>
                              <div>
                                <Button style={{ width: '100%' }}>
                                  {' '}
                                  {selectedUser
                                    ? typeof selectedUser === 'string'
                                      ? 'New KYC Registration'
                                      : `${selectedUser.clientCode}
                                ${
                                  selectedUser.firstName
                                    ? '- ' + selectedUser.firstName
                                    : ''
                                } ${' '} ${selectedUser.lastName || ''}`
                                    : 'Select User'}
                                  <DownOutlined
                                    style={{
                                      fontSize: '18px',
                                      float: 'right',
                                      marginTop: '0.5rem',
                                    }}
                                  />
                                </Button>
                              </div>
                            </div>
                          </Dropdown>
                        </div>
                      </>
                    )}
                  </>
                )}

                <FormItem>
                  <Button
                    htmlType='submit'
                    type='primary'
                    style={{ width: '100%', fontSize: '16px' }}
                    loading={loader}
                  >
                    {/* screenType === 'selection' */}
                    {screenType === 'input'
                      ? 'Next'
                      : screenType === 'otp'
                      ? 'Next'
                      : screenType === 'selection'
                      ? 'Next'
                      : 'Login'}
                  </Button>
                  <div style={{ fontWeight: '700' }}>
                    <span
                      style={{
                        display: 'flex',
                        color: '#000',
                        fontWeight: 500,
                      }}
                    >
                      Sebi Registration No. INA000018045
                    </span>
                    <span style={{ display: 'flex', fontWeight: 500 }}>
                      <a
                        href='https://smartodr.in/'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        Online Dispute Resolution (ODR) Portal
                      </a>
                    </span>
                  </div>
                </FormItem>
                <FormItem>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {!showPasswordField && (
                      <GoogleButton
                        className='google-logo'
                        label='Continue with Google'
                        type='dark'
                        onClick={handleGoogleLogin}
                        style={{
                          height: '35px',
                          width: '200px',
                          borderRadius: '5px',
                        }}
                      />
                    )}
                    {loginType === 'userName' && showPasswordField && (
                      <span className='forgot-password'>
                        <Link to={`/forgot-password?name=${userNameClient}`}>
                          Forgot Password?
                        </Link>
                      </span>
                    )}
                  </div>
                </FormItem>
              </Form>
            </div>
          </div>
        </Col>
      </Row>

      {openConfirmModal && (
        <Modal
          className='new_kyc_modal'
          visible={openConfirmModal}
          footer={null}
          onCancel={() => setOpenConfirmModal(false)}
        >
          <div>
            <span>Do you want to start New KYC?</span>
          </div>
          <div className='new_kyc_modal_btn'>
            <Button
              type='primary'
              loading={modalLoading}
              onClick={newKycGenerate}
            >
              Yes
            </Button>
            <Button onClick={() => setOpenConfirmModal(false)}>No</Button>
          </div>
        </Modal>
      )}
    </div>
  )
}
