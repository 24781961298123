import React, { useContext } from 'react'
import { Modal, Button } from 'antd'

import './BookAdvisory.css'
import moment from 'moment'
import { AppContext } from 'AppContext'
import { convertNumberToCommaSeparate } from 'utils/utilities'

const BookAdvisoryModal = ({
  isModalOpen,
  onCancel,
  isTimeUp,
  data,
  onCreate,
  loading,
  advisoryTime,
  amcAmount,
  isMobile,
}) => {
  const {
    state: {
      currentUser: { mandateId, umrnNumber, isNewAuaAdvisoryGenerate },
    },
  } = useContext(AppContext)

  return (
    <>
      {isTimeUp ? (
        <Modal
          className='book_advisory_modal'
          visible={isModalOpen}
          footer={null}
          centered
          width={isMobile ? '100%' : '40%'}
        >
          <div>
            <div>
              <div className='book_advisory_text'>Dear {data?.name}</div>
              <div className='book_advisory_text_description'>
                Greetings from Finideas!!!
              </div>
              <div className='book_advisory_text_sub_text'>
                Thank you for selecting {data?.date}. We will deliver your
                advisory during working hours from&nbsp;
                {moment(advisoryTime?.startTime, 'hh:mm A').format('LT')}{' '}
                to&nbsp;
                {moment(advisoryTime?.endTime, 'hh:mm A').format('LT')} on that
                day.{' '}
                <p style={{ marginTop: '8px', marginBottom: '0px' }}>
                  Please remember to log in again on the specified date.
                </p>
              </div>
            </div>
          </div>
          <div className='book_advisory_btn'>
            <Button
              className='advisory_cancel_btn'
              onClick={onCancel}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              className='advisory_verify_btn'
              type='primary'
              loading={loading}
              onClick={onCreate}
            >
              Confirm
            </Button>
          </div>
        </Modal>
      ) : (
        <Modal
          visible={isModalOpen}
          footer={null}
          centered
          width={isMobile ? '100%' : '40%'}
        >
          <div className='modal_text_heading'>Do you want advisory now?</div>

          {(mandateId || umrnNumber) && (
            <>
              {!isNewAuaAdvisoryGenerate ? (
                <div className='modal_note_text_description'>
                  Congratulations ! You have received a 5% discount. The
                  Finideas advisory fee of Rs.
                  {convertNumberToCommaSeparate(Math.round(amcAmount))}/- will
                  be debited through the advisory mandate within 48 hours.
                </div>
              ) : (
                <div className='modal_note_text_description'>
                  Congratulations ! The Finideas advisory fee of Rs.
                  {convertNumberToCommaSeparate(Math.round(amcAmount))}/- will
                  be debited through the advisory mandate within 48 hours.
                </div>
              )}
            </>
          )}

          <div className='book_advisory_btn'>
            <Button
              className='advisory_cancel_btn'
              onClick={onCancel}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              className='advisory_verify_btn'
              type='primary'
              loading={loading}
              onClick={onCreate}
            >
              Confirm
            </Button>
          </div>
        </Modal>
      )}
    </>
  )
}
export default BookAdvisoryModal
