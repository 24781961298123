import React from 'react'

const BookAppointment = () => {
  return (
    <div
      style={{
        height: '100%',
        marginBottom: '50px',
      }}
    >
      <iframe
        src='https://www.finideas.com/appointment/'
        title='Book An Appointment'
        height={'100%'}
        width={'100%'}
        style={{ border: 'none' }}
      />
    </div>
  )
}
export default BookAppointment
